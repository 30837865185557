import { Avatar as AvatarMui, Typography, Box } from "@mui/material";
import store2 from "store2";
import { AccountCircle } from "@mui/icons-material";
import { baseUrl } from "../../../../api/config";

type User = {
    id: string;
    avatar: string;
    first_name: string;
    last_name: string;
    role: string;
};
type UserCreatedProps = {
    value: {
        user_created?: User;
        row?: {
            user_created?: User;
        };
    };
};

export function UserCreated({ value }: UserCreatedProps) {
    if (!value.user_created && !value.row?.user_created) return <AccountCircle fontSize="large" color="disabled" />;

    const user = value?.user_created || value?.row?.user_created;

    return (
        <Box display="flex" alignItems="center" gap={2}>
            <AvatarMui
                src={`${baseUrl}/assets/${user?.avatar}?key=thumbnail&access_token=${
                    store2.get("auth").access_token
                }`}
            />
            <Typography>
                {user?.first_name} {user?.last_name}
            </Typography>
        </Box>
    );
}
