import { TableRow, Typography } from "@mui/material";
import { getUserRowStyle, StyledTableCell } from "./styles";
import { workDays } from "../../utils/scheduleUtils";

type UserScheduleRowProps = {
    userName: string;
    userColor?: string;
    dailyTotals: Record<string, number>;
    weeklyTotal: number;
    categoryKey?: string;
};

export const UserScheduleRow = ({ userName, userColor, dailyTotals, weeklyTotal, categoryKey }: UserScheduleRowProps) => {
    return (
        <TableRow key={categoryKey ? `${categoryKey}-${userName}` : userName} sx={getUserRowStyle(userColor)}>
            <StyledTableCell className="name-column">
                <Typography variant="body2">{userName}</Typography>
            </StyledTableCell>
            {workDays.map((day) => (
                <StyledTableCell key={day} className="data-column">
                    <Typography variant="body2">{dailyTotals[day]}</Typography>
                </StyledTableCell>
            ))}
            <StyledTableCell className="data-column">
                <Typography variant="body2">{weeklyTotal.toFixed(1)}</Typography>
            </StyledTableCell>
        </TableRow>
    );
}; 