import { api } from "./config";

export const getGlobalSearch = async (searchValue: string) => {
    if (!searchValue) return [];
    const searchResults = [];

    const globalSearchResults = await api({
        method: "GET",
        url: "/global-search",
        params: { search: searchValue },
    });
    searchResults.push(...globalSearchResults.data);

    return searchResults;
};
