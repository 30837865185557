import { useContext } from "react";
import { RecordsContext } from "../components/records/Records";
import { RelatedRecordsContext } from "../components/recordsRelated/RecordsRelated";

export type ContextType = "records" | "relatedRecords";

export const useRecordsContext = (type: ContextType) => {
    const context = useContext(type === "records" ? RecordsContext : RelatedRecordsContext);
    if (!context) {
        throw new Error("useRecordsContext must be used within a RecordsContext.Provider");
    }
    return context;
};
