import TextField from "@mui/material/TextField";
import { Controller } from "react-hook-form";
import { FieldProps } from "../renderFieldInput";

interface IntegerInputFieldProps extends FieldProps {
    value: number;
}

export const DecimalInputField = ({ field, value, entityForm, options, extraOptions }: IntegerInputFieldProps) => {
    return (
        <Controller
            name={extraOptions?.field ? extraOptions?.field : field.field}
            control={entityForm.control}
            defaultValue={value || 0}
            render={({ field: inputField }) => (
                <TextField
                    {...inputField}
                    disabled={options?.disabled}
                    label={field.headerName}
                    fullWidth
                    type="number"
                    variant="standard"
                    {...options}
                />
            )}
        />
    );
};
