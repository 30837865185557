import { Controller, UseFormReturn } from "react-hook-form";
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AvailabilityPreferences, AvailabilitySlot, DayPeriod, WeekDay, WeeklyAvailabilityFromApi } from "../utils/types";
import pt from "date-fns/locale/pt";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { useEffect, useMemo } from "react";
import { ClinicsDropdown } from "../../general-dropdowns/clinics-dropdown/ClinicsDropdown";
import { AsyncAutoCompleteNew } from "../../async-auto-complete/AsyncAutoCompleteNew";
import { CLINIC_SCHEDULES } from "../../../utils/constants";
import { createInitialTimeRanges } from "../utils/utils";
import { format } from "date-fns";
import { getRelateServicesFromConstants } from "../../../utils/getRelatedServicesFromConstants";
import { LeadOrTratmentSelect } from "./LeadOrTratmentSelect";
import { useStore } from "../../../store/store";
import { getWeeksSlots } from "../../../api/availabilities-finder";
import { getFieldsFromParams, getValueByEntity } from "../../../api/useItemsFromSearchParams";
import { useSearchParams } from "react-router-dom";
import { PreferredDays } from "./PreferredDays";
import { ScreenSearchDesktop } from "@mui/icons-material";
import { findAvailabilityFormValidations } from "../utils/formsValidations";

export const WEEK_DAYS: { value: WeekDay; label: string }[] = [
    { value: "monday", label: "Segunda-feira" },
    { value: "tuesday", label: "Terça-feira" },
    { value: "wednesday", label: "Quarta-feira" },
    { value: "thursday", label: "Quinta-feira" },
    { value: "friday", label: "Sexta-feira" },
    { value: "saturday", label: "Sábado" },
    { value: "sunday", label: "Domingo" },
];

type AvailabilityFormFinderProps = {
    entityForm: UseFormReturn<AvailabilityPreferences>;
    setSelectedSlots: (slots: AvailabilitySlot[]) => void;
    setWeeks: (weeks: WeeklyAvailabilityFromApi[]) => void;
    setIsLoading: (isLoading: boolean) => void;
    selectedSlots: AvailabilitySlot[];
    isLoading: boolean;
};

export const AvailabilityFormFinder = ({
    entityForm,
    setIsLoading,
    selectedSlots,
    setSelectedSlots,
    isLoading,
    setWeeks,
}: AvailabilityFormFinderProps) => {
    const { control } = entityForm;
    const selectedServiceFirst = entityForm.watch("firstService");
    const selectedServiceSecond = entityForm.watch("secondService");
    const treatmentSelected = entityForm.watch("treatment");
    const requireConsecutiveSessions = entityForm.watch("requireConsecutiveSessions");
    const scheduleRanges = createInitialTimeRanges(new Date(), "both", CLINIC_SCHEDULES);
    const psyRelated = getRelateServicesFromConstants("psy");
    const { notifications } = useStore();
    const [params] = useSearchParams();

    const allFields = useMemo(() => getFieldsFromParams(params), [params]);

    const handleFormSubmit = async (data: AvailabilityPreferences) => {
        setIsLoading(true);

        if (!findAvailabilityFormValidations(data, notifications)) {
            setIsLoading(false);
            return;
        }

        const weeks = await getWeeksSlots(data, selectedSlots);

        setWeeks(weeks);
        setIsLoading(false);
    };

    useEffect(() => {
        const technician = treatmentSelected?.theurapists_involved_in_treamtent?.[0]?.technicians_id;
        const technicianSelected = entityForm.getValues("technician");

        if (!requireConsecutiveSessions) {
            entityForm.setValue("secondService", undefined);
        }

        if (!technicianSelected && selectedServiceFirst && psyRelated.includes(selectedServiceFirst.id)) {
            entityForm.setValue("technician", technician);
        }

        if (!technicianSelected && selectedServiceSecond && psyRelated.includes(selectedServiceSecond.id)) {
            entityForm.setValue("technician", technician);
        }

        const hasPsyService =
            (selectedServiceFirst?.id && psyRelated.includes(selectedServiceFirst.id)) ||
            (selectedServiceSecond?.id && psyRelated.includes(selectedServiceSecond.id));

        if (!hasPsyService) {
            entityForm.setValue("technician", undefined);
        }

        setWeeks([]);
    }, [treatmentSelected, selectedServiceFirst, selectedServiceSecond, requireConsecutiveSessions]);

    return (
        <form onSubmit={entityForm.handleSubmit(handleFormSubmit)}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <ClinicsDropdown
                        fieldName="clinic_id"
                        value={getValueByEntity(allFields, "clinics")}
                        entityForm={entityForm as unknown as UseFormReturn}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={pt}>
                        <Controller
                            name="startDate"
                            control={control}
                            render={({ field }) => (
                                <DatePicker
                                    label="Procurar a partir de"
                                    value={field.value}
                                    onChange={(newValue) => field.onChange(newValue || new Date())}
                                    // minDate={new Date()}
                                    slotProps={{
                                        textField: { fullWidth: true },
                                    }}
                                />
                            )}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Controller
                        name="dayPeriod"
                        control={control}
                        render={({ field }) => (
                            <FormControl fullWidth>
                                <InputLabel>Preferred Time</InputLabel>
                                <Select
                                    {...field}
                                    label="Período do dia"
                                    onChange={(event: SelectChangeEvent<DayPeriod>) =>
                                        field.onChange(event.target.value as DayPeriod)
                                    }
                                >
                                    <MenuItem value="morning">
                                        Manhã ({format(scheduleRanges[0].start, "HH:mm")} -{" "}
                                        {format(scheduleRanges[0].end, "HH:mm")})
                                    </MenuItem>
                                    <MenuItem value="afternoon">
                                        Tarde ({format(scheduleRanges[1].start, "HH:mm")} -{" "}
                                        {format(scheduleRanges[1].end, "HH:mm")})
                                    </MenuItem>
                                    <MenuItem value="both">Ambos</MenuItem>
                                </Select>
                            </FormControl>
                        )}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={3} p={2}>
                <Grid item xs={12} md={10}>
                    <PreferredDays entityForm={entityForm as unknown as UseFormReturn} />
                </Grid>

                <Grid item xs={12} md={2} textAlign="right">
                    <Controller
                        name="requireConsecutiveSessions"
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={field.value}
                                        onChange={(e) => {
                                            setSelectedSlots([]);
                                            field.onChange(e.target.checked);
                                        }}
                                    />
                                }
                                label="Sessões consecutivas"
                            />
                        )}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={3} p={2}>
                <Grid item xs={12} md={4}>
                    <LeadOrTratmentSelect
                        itemFromParams={allFields}
                        fieldName="leadOrTreatment"
                        entityForm={entityForm as unknown as UseFormReturn}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                        <AsyncAutoCompleteNew
                            entityForm={entityForm as unknown as UseFormReturn}
                            fieldName="firstService"
                            placeholder="Selecione o serviço"
                            enabled={true}
                            recordsOptions={{
                                entity: "services",
                                fieldsToQuery: "*",
                                labelKey: "name",
                                customFilter: (value: string) => ({
                                    name: { _icontains: value },
                                }),
                            }}
                        />
                        {selectedServiceFirst && psyRelated.includes(selectedServiceFirst.id)
                            ? renderTechnicianField(entityForm as unknown as UseFormReturn)
                            : null}
                    </Box>
                </Grid>
                {requireConsecutiveSessions && (
                    <Grid item xs={12} md={4}>
                        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                            <AsyncAutoCompleteNew
                                entityForm={entityForm as unknown as UseFormReturn}
                                fieldName="secondService"
                                enabled={true}
                                placeholder="Selecione o serviço"
                                recordsOptions={{
                                    entity: "services",
                                    fieldsToQuery: "*",
                                    labelKey: "name",
                                    customFilter: (value: string) => ({
                                        name: { _icontains: value },
                                    }),
                                }}
                            />
                            {selectedServiceSecond && psyRelated.includes(selectedServiceSecond.id)
                                ? renderTechnicianField(entityForm as unknown as UseFormReturn)
                                : null}
                        </Box>
                    </Grid>
                )}
            </Grid>

            <Grid container spacing={3} mt={2}>
                <Grid item xs={12}>
                    <Button startIcon={<ScreenSearchDesktop />} type="submit" variant="contained" color="secondary" fullWidth>
                        {isLoading ? "A procurar..." : "Procurar disponibilidades"}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

const renderTechnicianField = (entityForm: UseFormReturn) => {
    return (
        <AsyncAutoCompleteNew
            entityForm={entityForm as unknown as UseFormReturn}
            fieldName="technician"
            placeholder="Select Technician"
            recordsOptions={{
                entity: "technicians",
                fieldsToQuery: "*,user_id.*",
                labelKey: (option) => `${option.user_id.first_name} ${option.user_id.last_name}`,
                customFilter: (value: string) => ({
                    _or: [{ user_id: { first_name: { _icontains: value } } }, { user_id: { last_name: { _icontains: value } } }],
                }),
            }}
        />
    );
};
