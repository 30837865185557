import { Typography } from "@mui/material";
import { FieldView, FormatedField } from "../../../types/fields.types";

type FloatFieldProps = {
    value: number;
    field: FormatedField;
    fieldView: FieldView;
};

export function FloatField({ field, value, fieldView }: FloatFieldProps) {
    const prefix = field.settings.display_options?.prefix;
    const sufix = field.settings.display_options?.sufix;
    const formatedValue =
        fieldView === "grid"
            ? value
            : new Intl.NumberFormat("pt-PT", {
                  minimumFractionDigits: 2,
              }).format(value);

    return (
        <Typography variant="body1">{`${prefix ? prefix : ""} ${value ? formatedValue : ""} ${sufix ? sufix : ""}`}</Typography>
    );
}
