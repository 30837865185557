import { Box } from "@mui/material";
import { TasksForm } from "./TasksForm";
import { useContext } from "react";
import { RecordContext } from "../record/Record";
import { TasksDetail } from "./TasksDetail";

export function TasksComponent() {
	const context = useContext(RecordContext);

	if (!context) return null;

	return (
		<Box sx={{ backgroundColor: "#fff", p: 4 }}>
			{context.type === "detail" ? (
				<TasksDetail context={context} />
			) : (
				<TasksForm context={context} />
			)}
		</Box>
	);
}
