import { useEffect } from "react";
import { format, startOfWeek, endOfWeek } from "date-fns";
import { useRecords } from "../../../api/useRecords";
import { Box } from "@mui/material";
import { fieldsQuery } from "../sessionRoutes";
import { useStore } from "../../../store/store";
import { useForm } from "react-hook-form";
import { SessionsCalendarHeader } from "./SessionsCalendarHeader";
import { createCalendarFilter, useDefaultFilters } from "../../../components/calendar/calendarUtils";
import { CalendarLoader } from "../../../components/calendar/CalendarLoader";
import { useIsMobile } from "../../../utils/useIsMobile";
import { useWindowWidth } from "../../../utils/useWindowWith";
import Calendar from "../../../components/calendar/Calendar";

type CalendarProps = {
    setView: React.Dispatch<React.SetStateAction<"calendar" | "list">>;
};

export function SessionsCalendar({ setView }: CalendarProps) {
    const {
        calendar: { calendarFilters, setCalendarFilters },
    } = useStore();

    const defaultFilters = useDefaultFilters({
        status: ["booked", "confirmed", "awaiting_in_clinic", "in_session", "done"],
    });

    const entityForm = useForm();
    const watchFields = entityForm.watch;
    const isMobile = useIsMobile();
    const windowWith = useWindowWidth();

    const { data, status, refetch } = useRecords("sessions", fieldsQuery, {
        extraOptions: {
            filter: createCalendarFilter(calendarFilters),
            pageSize: 1000,
        },
        enabled: false,
    });

    useEffect(() => {
        if (calendarFilters === null) {
            setCalendarFilters(defaultFilters);
        }
    }, [calendarFilters, defaultFilters, setCalendarFilters]);

    useEffect(() => {
        if (!calendarFilters) return;

        refetch();
        const subscription = watchFields((value) => {
            if (calendarFilters !== value) {
                if (value.clinic_id === undefined) value.clinic_id = 1;
                if (!calendarFilters?.dateRange)
                    value.dateRange = {
                        start_date: format(startOfWeek(new Date(), { weekStartsOn: 1 }), "yyyy-MM-dd HH:mm:ss"),
                        end_date: format(endOfWeek(new Date(), { weekStartsOn: 1 }), "yyyy-MM-dd HH:mm:ss"),
                    };

                setCalendarFilters({
                    ...calendarFilters,
                    ...value,
                });
            }
        });
        return () => subscription.unsubscribe();
    }, [watchFields, entityForm, setCalendarFilters, calendarFilters, refetch]);

    if (status === "error") return null;

    return (
        <Box style={{ width: isMobile ? windowWith - 16 : "100%", backgroundColor: "#fff" }}>
            <SessionsCalendarHeader entityForm={entityForm} calendarFilters={calendarFilters} setView={setView} />
            {status === "loading" ? <CalendarLoader /> : null}
            {
                <Box>
                    <Calendar
                        items={data?.items || []}
                        refetch={refetch}
                        isMobile={isMobile}
                        views={{ week: true, month: true, agenda: true, day: true, year: false }}
                        defaultView={isMobile ? "agenda" : "week"}
                    />
                </Box>
            }
        </Box>
    );
}
