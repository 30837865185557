import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { baseUrl } from "./config";

export type RecoverCredentials = {
    email: string;
};

export const usePasswordRecovery = () => {
    return useMutation({
        mutationFn: (recoverCredentials: RecoverCredentials) => reocverPassword(recoverCredentials),
    });
};

const reocverPassword = async (recoverCredentials: RecoverCredentials) => {
    const response = await axios({
        method: "POST",
        url: `${baseUrl}/auth/password/request`,
        data: {
            email: recoverCredentials.email.toLowerCase(),
            reset_url: `https://services.neuroimprove.pt/recover-password?device=web`,
        },
    });

    return response;
};
