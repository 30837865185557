import { Record } from "../../components/record/Record";
import { Records } from "../../components/records/Records";
import { TemplateHeader } from "./TemplateHeader";
import { tabs } from "./templateTabs";
import { EntityForm } from "../../components/entityForm/EntityForm";
import { Groups } from "../../components/groups/Groups";
import { DetailTabs } from "../../components/detailTabs/DetailTabs";
import { templatesOptions } from "./templatesOptions";

export const fieldsQuery = "*.*";

export const templatesRoutes = [
    {
        path: "/templates_content",
        element: (
            <Records entity="templates_content" fieldsQuery={fieldsQuery} options={templatesOptions} heightOfHeaders={190} />
        ),
    },
    {
        path: "/templates_content/:id",
        element: (
            <Record
                entity="templates_content"
                type="detail"
                fieldsQuery={fieldsQuery}
                header={<TemplateHeader />}
                body={<DetailTabs tabs={tabs} />}
            />
        ),
        children: [
            {
                path: "/templates_content/:id/detail",
                element: <Groups />,
            },
        ],
    },
    {
        path: "/templates_content/:id/update",
        element: (
            <Record
                entity="templates_content"
                type="update"
                header={<TemplateHeader />}
                body={<EntityForm groupsOptions={templatesOptions?.groupsOptions} />}
            />
        ),
    },
    {
        path: "/templates_content/create",
        element: (
            <Record
                entity="templates_content"
                type="create"
                body={<EntityForm groupsOptions={templatesOptions?.groupsOptions} />}
            />
        ),
    },
];
