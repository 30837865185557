import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import store2 from "store2";

export const useDashboard = (view: string, criteria?: string, workspace?: string) => {
    return useQuery({
        queryKey: [`dashboard-${view}`],
        queryFn: () => {
            const userToken = store2.get("auth").access_token;
            return getDashboard(view, userToken, criteria, workspace);
        },
        retry: false,
        refetchOnWindowFocus: false,
    });
};

const getDashboard = async (view: string, userToken: string, criteria?: string, workspace?: string) => {
    const response = await axios({
        method: "POST",
        url: "/.netlify/functions/getDashboard",
        data: {
            view: view,
            userToken: userToken,
            criteria: criteria,
            workspace: workspace,
        },
    });

    return response.data;
};
