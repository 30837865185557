import { Box, Typography} from "@mui/material";
import { AvailabilitySlot, Session } from "../utils/types";
import { format, parseISO } from "date-fns";

type SessionsCreationFeedbackProps = {
    sessionsOrSlots: Array<Session | AvailabilitySlot>;
    type: "success" | "error";
};

export const SessionsCreationFeedback = ({ sessionsOrSlots, type }: SessionsCreationFeedbackProps) => {
    const formatSessionInfo = (slot: Session | AvailabilitySlot) => {
        const date = format(parseISO(slot.start_date), "dd/MM");

        const start = format(parseISO(slot.start_date), "HH:mm");
        const end = format(parseISO(slot.end_date), "HH:mm");

        return {
            date,
            time: `${start} - ${end}`,
            service: "id" in slot ? slot.service.name : slot.serviceName,
        };
    };

    const title = type === "success" ? "Sessões criadas com sucesso" : "Estas disponibilidades já não se encontram disponíveis";

    return (
        <Box sx={{ mb: 2, p: 2 }}>
            <Typography variant="subtitle1" fontWeight="bold">
                {title}
            </Typography>
            {sessionsOrSlots.map((session, index) => {
                const { date, time, service } = formatSessionInfo(session);
                return (
                    <Typography variant="body2" key={"id" in session ? session.id : session.slotId}>
                        {`${date}:${time} - ${service}`}
                    </Typography>
                );
            })}
        </Box>
    );
};
