import { addMilliseconds, endOfDay, endOfWeek, format, parseISO, startOfWeek, parse, getDay, addMinutes } from "date-fns";
import { useStore } from "../../store/store";
import { useSearchParams } from "react-router-dom";
import { CalendarFiltersType, SessionStatus } from "./calendar.types";
import { useUpdateRecord } from "../../api/useUpdateRecord";
import { dateFnsLocalizer, DateLocalizer } from "react-big-calendar";
import pt from "date-fns/locale/pt";
import { useNavigate } from "react-router-dom";
import { useMemo } from "react";

export const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek: () => startOfWeek(new Date(), { weekStartsOn: 1 }),
    getDay,
    locales: { "pt-PT": pt },
});

export const handleDefaultDates = (dateString: string | null, type: string) => {
    const dateFromParams = dateString ? parseISO(dateString) : null;

    const startOfTheWeek = format(startOfWeek(dateFromParams || new Date(), { weekStartsOn: 1 }), "yyyy-MM-dd HH:mm:ss");
    const endOfTheWeek = format(endOfWeek(dateFromParams || new Date(), { weekStartsOn: 1 }), "yyyy-MM-dd HH:mm:ss");

    if (type === "start") return startOfTheWeek;
    if (type === "end") return endOfTheWeek;

    return format(new Date(), "yyyy-MM-dd HH:mm:ss");
};

type CalendarRange =
    | Date[]
    | {
          start: Date;
          end: Date;
      };

export const handleRanges = (range: CalendarRange) => {
    const isArray = Array.isArray(range);

    if (isArray && range.length > 1) {
        return {
            start_date: format(startOfWeek(range[0], { weekStartsOn: 1 }), "yyyy-MM-dd HH:mm:ss"),
            end_date: format(endOfWeek(range[0], { weekStartsOn: 1 }), "yyyy-MM-dd HH:mm:ss"),
        };
    }

    if (isArray && range.length === 1) {
        return {
            start_date: format(range[0], "yyyy-MM-dd HH:mm:ss"),
            end_date: format(endOfDay(range[0]), "yyyy-MM-dd HH:mm:ss"),
        };
    }
    if (!isArray) {
        return {
            start_date: format(range.start, "yyyy-MM-dd HH:mm:ss"),
            end_date: format(range.end, "yyyy-MM-dd HH:mm:ss"),
        };
    }
};

type DefaultFiltersOptions = {
    status?: SessionStatus;
};

export const useDefaultFilters = (options?: DefaultFiltersOptions) => {
    const [params] = useSearchParams();

    const {
        calendar: { defaultDate },
    } = useStore();

    const defaultFilters = {
        clinic_id: [1],
        dateRange: {
            start_date: handleDefaultDates(params.get("start_date") || defaultDate, "start"),
            end_date: handleDefaultDates(params.get("start_date") || defaultDate, "end"),
        },
        ...options,
    };

    return defaultFilters;
};

export const useEventDrop = (refetch: () => void, entity: string) => {
    const { mutateAsync } = useUpdateRecord();

    const onEventChange = async ({ event, start, end }: any) => {
        await mutateAsync({
            id: event.id,
            entity: entity,
            data: {
                start_date: start,
                end_date: end,
            },
        });

        refetch();
    };

    return onEventChange;
};

export const createCalendarFilter = (calendarFilters: CalendarFiltersType | null) => {
    if (!calendarFilters) return;

    const filters = {
        _and: [
            {
                start_date: {
                    _gte: calendarFilters?.dateRange?.start_date,
                },
            },
            {
                end_date: {
                    _lte: calendarFilters?.dateRange?.end_date,
                },
            },
        ],
    } as any;

    if (calendarFilters.clinic_id && calendarFilters.clinic_id?.length > 0)
        filters._and.push({
            clinic_id: { id: { _in: calendarFilters.clinic_id } },
        });
    if (calendarFilters.service_category && calendarFilters.service_category?.length > 0)
        filters._and.push({
            service: { category: { _in: calendarFilters.service_category } },
        });
    if (calendarFilters.technician && calendarFilters.technician?.length > 0)
        filters._and.push({
            technician: { id: { _in: calendarFilters.technician } },
        });
  
    if (calendarFilters.status && calendarFilters.status?.length !== 0) filters._and.push({ status: { _in: calendarFilters.status } });

    return filters;
};

export const minTime = new Date();
minTime.setHours(8, 0, 0);

export const maxTime = new Date();
maxTime.setHours(21, 0, 0);

export const scrollToTime = new Date();
scrollToTime.setHours(20, 0, 0, 0);

export const handleCalendarDate = (dateRange: { start_date: string; end_date: string }) => {
    const startDate = parseISO(dateRange.start_date);
    const endDate = parseISO(dateRange.end_date);
    // Calculate the midpoint date of the given range

    const midpointMillis = (endDate.getTime() - startDate.getTime()) / 2;
    const midpointDate = addMilliseconds(startDate, midpointMillis);

    return midpointDate;
};

export type HandleSelectSlotParams = {
    start: Date;
    entity: string;
    defaultMinutes: number;
};

export const useHandleSelectSlot = () => {
    const navigate = useNavigate();

    const handleSelectSlot = ({ start, entity, defaultMinutes }: HandleSelectSlotParams) => {
        const startDate = format(start, "yyyy-MM-dd HH:mm", { locale: pt });
        const endDate = format(addMinutes(start, defaultMinutes), "yyyy-MM-dd HH:mm", { locale: pt });

        navigate(`/${entity}/create?start_date=${startDate}&end_date=${endDate}`);
    };

    return handleSelectSlot;
};

export const createUrl = (params: any, entity: string) => {
    const startDate = params?.data?.start_date;

    const dateFromSession =
        startDate instanceof Date ? startDate : parseISO(params?.recordContext?.item?.start_date || startDate);

    const start_date = format(dateFromSession, "yyyy-MM-dd");
    const clinic_id = params.data.clinic_id || params.recordContext?.item?.clinic_id?.id;

    return `/${entity}?start_date=${start_date}&clinic_id=${clinic_id}`;
};

export const useFormats = (type: "start_date" | "end_date" | "both") => {
    return useMemo(() => {
        return {
            formats: {
                eventTimeRangeFormat: ({ start, end }: { start: Date; end: Date }, culture: string, localizer: DateLocalizer) => {
                    if (type === "start_date") return localizer.format(start, "HH:mm", culture);
                    if (type === "end_date") return localizer.format(end, "HH:mm - ", culture);
                    return `${localizer.format(start, "HH:mm", culture)} - ${localizer.format(end, "HH:mm", culture)}`;
                },
            },
        };
    }, []);
};
