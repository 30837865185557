import { Button } from "@mui/material";

import { Box, Checkbox, FormControlLabel } from "@mui/material";
import { WEEK_DAYS } from "./AvailabilityFormFinder";
import { Controller, UseFormReturn } from "react-hook-form";

type PreferredDaysProps = {
    entityForm: UseFormReturn;
};

export const PreferredDays = ({ entityForm }: PreferredDaysProps) => {
    const currentDays = entityForm.getValues("preferredDays");
    const hasSelectedDays = currentDays.length > 0;

    return (
        <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
            {WEEK_DAYS.map(({ value, label }) => (
                <Controller
                    key={value}
                    name="preferredDays"
                    control={entityForm.control}
                    render={({ field }) => (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={field.value.includes(value)}
                                    onChange={(e) => {
                                        const newValue = e.target.checked
                                            ? [...field.value, value]
                                            : field.value.filter((day: any) => day !== value);

                                        // Sort the days according to WEEK_DAYS order
                                        const orderedDays = WEEK_DAYS.filter((day) => newValue.includes(day.value)).map(
                                            (day) => day.value
                                        );

                                        field.onChange(orderedDays);
                                    }}
                                />
                            }
                            label={label}
                        />
                    )}
                />
            ))}
            <Button
                size="small"
                variant="outlined"
                color="secondary"
                sx={{ height: 28 }}
                onClick={() => {
                    entityForm.setValue("preferredDays", hasSelectedDays ? [] : WEEK_DAYS.map((day) => day.value));
                }}
            >
                {hasSelectedDays ? "Desselecionar todos" : "Selecionar todos"}
            </Button>
        </Box>
    );
};
