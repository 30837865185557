import { DataGridOptions } from "../components/datagrid/DataGrid";
import { renderFieldView } from "../components/fieldsViews/renderFieldView";
import { FieldExtraOption, FieldMeta, UnknownField, UnknownFields } from "../types/fields.types";
import { FormatedFields, FormatedField } from "../types/fields.types";
import { sortBy, some } from "lodash";
import { getFieldExtraOption } from "./getFieldExtraOption";
import { merge } from "lodash";
import { format, parseISO } from "date-fns";
import pt from "date-fns/locale/pt";

export type FieldsToRemoveType = {
    fieldNamesToRemove?: Array<string>;
    fieldTypesToRemove?: Array<string>;
};

export type FieldsOptions = {
    fieldsToRemove?: FieldsToRemoveType;
    dataGridOptions?: DataGridOptions;
    groupsOptions?: any;
    fieldsWithExtraOptions?: any;
};

export function formatFields(fields: UnknownFields, options?: FieldsOptions) {
    checkIfHasUserAndAddFIelds(fields);

    const formatedFields = fields.map((field) => {
        const formatedField = formatField(field);
        const fieldExtraOptions =
            options?.fieldsWithExtraOptions ||
            options?.dataGridOptions?.fieldsWithExtraOptions ||
            options?.groupsOptions?.fieldsWithExtraOptions;
            
        const fieldExtraOption = getFieldExtraOption(fieldExtraOptions, formatedField);

        const newFormatedField = overrideFieldSettings(formatedField, fieldExtraOption);

        handleDataGridValues(newFormatedField, fieldExtraOption);

        return newFormatedField;
    });

    const sortedFields = sortFields(formatedFields);

    const newFields = removeFields(sortedFields, options?.fieldsToRemove);

    return newFields;
}

const handleDataGridValues = (newFormatedField: any, fieldExtraOption: FieldExtraOption | null) => {
    newFormatedField.valueGetter = (params: any) => {
        const fieldType = newFormatedField.settings.type;

        if (params.value && fieldType === "date") {
            return format(parseISO(params.value), "dd/MM/yyyy");
        }
        if (params.value && (fieldType === "datetime" || fieldType === "dateTime" || fieldType === "timestamp")) {
            return format(parseISO(params.value), fieldExtraOption?.dateFormat || "dd/MM/yyyy HH:mm", { locale: pt });
        }

        if (params.value && (fieldType === "float" || fieldType === "decimal")) {
            return new Intl.NumberFormat("pt-PT", {
                minimumFractionDigits: 2,
            }).format(params.value);
        }
        return params.value;
    };

    newFormatedField.renderCell = (params: any) => {
        return renderFieldView(newFormatedField, params, "grid", fieldExtraOption);
    };
};

function sortFields(fields: FormatedFields): FormatedFields {
    // Separate fields into groups, non-group fields, and ungrouped fields
    const groups = fields.filter((field) => field.settings.isGroup);
    const nonGroupFields = fields.filter((field) => !field.settings.isGroup);
    const ungroupedFields = nonGroupFields.filter((field) => !field.settings.group);

    // Sort groups by their sort property
    const sortedGroups = sortBy(groups, "sort");

    // Create a mapping of group name to the list of fields within that group
    const groupFields: { [key: string]: FormatedFields } = nonGroupFields.reduce<{
        [key: string]: FormatedFields;
    }>((acc, field) => {
        const groupName = field.settings.group;
        if (groupName) {
            acc[groupName] = acc[groupName] || [];
            acc[groupName].push(field);
        }

        return acc;
    }, {});

    // Sort the fields within each group
    for (const groupName in groupFields) {
        groupFields[groupName] = sortBy(groupFields[groupName], "sort");
    }

    // Sort ungrouped fields
    const sortedUngroupedFields = sortBy(ungroupedFields, "sort");

    // Combine the sorted groups, sorted fields within each group, and sorted ungrouped fields in the final sorted order
    const sortedFields: FormatedFields = [...sortedUngroupedFields];
    for (const group of sortedGroups) {
        const groupName = group.field;
        sortedFields.push(group, ...(groupFields[groupName] || []));
    }

    return sortedFields;
}

export function removeFields(formatedFields: FormatedFields, fieldsToRemove?: FieldsToRemoveType) {
    const defaultNames = ["sort", "user_updated"];
    const defaultTypes = [] as Array<string>;
    const fieldNamesToRemove = fieldsToRemove?.fieldNamesToRemove || [];
    const fieldTypesToRemove = fieldsToRemove?.fieldTypesToRemove || [];

    const fieldNames = [...new Set(defaultNames.concat(fieldNamesToRemove))];
    const fieldTypes = [...new Set(defaultTypes.concat(fieldTypesToRemove))];

    return formatedFields.filter((formatedField) => {
        return !fieldNames?.includes(formatedField.field) && !fieldTypes?.includes(formatedField.settings.type);
    });
}

export const formatField = (field: UnknownField) => {
    const formatedField: FormatedField = {
        field: field.field,
        headerName: field?.meta?.translations ? field?.meta?.translations[0]?.translation : field.field,
        settings: {
            type: field.type,
            isUser: field.field === "user_id" ? true : false,
            entity: field?.meta?.collection,
            isRelational: field?.schema?.foreign_key_table ? true : false,
            relatedTable: field?.schema?.foreign_key_table,
            relatedTableColumn: field.schema?.foreign_key_column,
            display: field?.meta?.display,
            display_options: field?.meta?.display_options,
            group: field?.meta?.group,
            isGroup: field?.meta?.interface === "group-raw" ? true : false,
            interface: field?.meta?.interface,
            options: field?.meta?.options,
            readonly: field?.meta?.readonly,
            conditions: field?.meta?.conditions,
            hidden: field.meta?.hidden,
            special: field.meta?.special,
            required: (field.schema?.is_nullable === true || !field.schema) && field.meta.required === false ? false : true,
        },
        width: 250,
        sort: field?.meta?.sort,
    };

    return formatedField;
};

function checkIfHasUserAndAddFIelds(fields: Array<{ [key: string]: unknown }>) {
    const userField = fields.find((field) => field.field === "user_id");
    const itsClientsCollection = fields.some((item) => item.collection === "clients");

    if (userField && !some(fields, { field: "user_first_name" })) {
        const meta = userField.meta as FieldMeta;

        if (!itsClientsCollection) {
            fields.push({
                field: "user_avatar",
                type: "avatar",
                meta: {
                    display: "avatar",
                    group: meta.group,
                    translations: [{ translation: "Avatar" }],
                },
            });
        }
        fields.push(
            {
                field: "user_first_name",
                headerName: "Primeiro nome",
                type: "text",
                meta: {
                    display: "text",
                    group: meta.group,
                    translations: [{ translation: "Primeiro nome" }],
                },
            },
            {
                field: "user_last_name",
                headerName: "Ultimo nome",
                type: "text",
                meta: {
                    display: "text",
                    group: meta.group,
                    translations: [{ translation: "Ultimo nome" }],
                },
            },
            {
                field: "user_email",
                headerName: "Email",
                type: "email",
                meta: {
                    display: "email",
                    group: meta.group,
                    translations: [{ translation: "Email" }],
                },
            },
            {
                field: "user_phone",
                headerName: "Telemóvel",
                type: "text",
                meta: {
                    display: "text",
                    group: meta.group,
                    translations: [{ translation: "Telemóvel" }],
                },
            },
            {
                field: "client_user_id",
                headerName: "Id",
                type: "integer",
                meta: {
                    display: "text",
                    group: meta.group,
                    translations: [{ translation: "User id" }],
                },
            }
        );
    }
}

function overrideFieldSettings(formatedField: FormatedField, fieldExtraOption: any) {
    if (fieldExtraOption && formatedField.field === fieldExtraOption.field) {
        return merge({}, formatedField, fieldExtraOption);
    }
    return formatedField;
}
