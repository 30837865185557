import { useContext, useEffect } from "react";
import { SubmitHandler } from "react-hook-form";
import { Groups } from "../groups/Groups";
import { useNavigate } from "react-router-dom";
import { RecordContext } from "../record/Record";
import { useCreateRecord } from "../../api/useCreateRecord";
import { useUpdateRecord } from "../../api/useUpdateRecord";
import { sanitizeData } from "../../utils/sanitizeFormData";
import { setFieldsErrors } from "../../utils/setFieldsErrors";
import { GroupsOptions } from "../../types/groups.types";
import { useStore } from "../../store/store";
import { pick } from "lodash";

export type FormFields = {
    [key: string]: any;
};

type EntityFormProps = {
    groupsOptions?: GroupsOptions;
    component?: React.ReactNode;
};

export const EntityForm = ({ groupsOptions, component }: EntityFormProps) => {
    const recordContext = useContext(RecordContext);
    const entityForm = recordContext?.entityForm;
    const createRecord = useCreateRecord();
    const updateRecord = useUpdateRecord();
    const navigate = useNavigate();
    const { notifications } = useStore();

    useEffect(() => {
        if (!entityForm) return;

        if (updateRecord.isSuccess && updateRecord.variables) {
            entityForm.reset();
            if (groupsOptions && groupsOptions.navigateAfterUpdate) {
                navigate(groupsOptions.navigateAfterUpdate(updateRecord.variables));
            } else {
                if (updateRecord.data?.id) {
                    navigate(`/${updateRecord.variables.entity}/${updateRecord.data.id}/detail`);
                } else {
                    navigate(`/${updateRecord.variables.entity}`);
                }
            }
        } else if (updateRecord.isError && Object.keys(entityForm.formState.errors).length === 0) {
            const errors = updateRecord.error.response.data.errors;
            const otherErrors = errors[0].extensions.code;
            if ((otherErrors === "INTERNAL_SERVER_ERROR" || otherErrors === "FORBIDDEN") && !notifications.notification.isOpen) {
                notifications.setNotification({
                    isOpen: true,
                    message: `Error - ${errors[0].message}`,
                    severity: "error",
                });
            }

            if (otherErrors !== "INTERNAL_SERVER_ERROR" && otherErrors !== "FORBIDDEN") {
                setFieldsErrors(errors, entityForm);
            }
        }

        if (createRecord.isSuccess && createRecord.variables) {
            entityForm.reset();
            if (groupsOptions && groupsOptions.navigateAfterCreate) {
                navigate(groupsOptions.navigateAfterCreate(createRecord.variables));
            } else {
                navigate(`/${createRecord.variables.entity}/${createRecord.data.id}/detail`);
            }
        } else if (createRecord.isError && Object.keys(entityForm.formState.errors).length === 0) {
            const errors = createRecord.error.response.data.errors;
            const otherErrors = errors[0].extensions.code;

            if ((otherErrors === "INTERNAL_SERVER_ERROR" || otherErrors === "FORBIDDEN") && !notifications.notification.isOpen) {
                notifications.setNotification({
                    isOpen: true,
                    message: `Error - ${errors[0].message}`,
                    severity: "error",
                });
            }
            if (otherErrors !== "INTERNAL_SERVER_ERROR" && otherErrors !== "FORBIDDEN") {
                setFieldsErrors(errors, entityForm);
            }
        }
    }, [createRecord, updateRecord, navigate, entityForm, notifications, groupsOptions]);

    if (!recordContext) return null;
    const { id, entity, type } = recordContext;

    const onSubmit: SubmitHandler<FormFields> = async (data) => {
        if (!entityForm) return;

        const sanitizedData = sanitizeData(data, recordContext.formatedFields);

        if (groupsOptions && groupsOptions.isFormValid) {
            const validation = groupsOptions.isFormValid(sanitizedData);

            if (validation.valid === false) {
                notifications.setNotification({
                    isOpen: true,
                    message: validation.message,
                    severity: "error",
                });
                return;
            }
        }

        if (type === "update") {
            if (!id) return;
            const dirtyData = pick(data, Object.keys(entityForm.formState.dirtyFields));

            await updateRecord.mutateAsync({ data: dirtyData, entity: entity, id: id, recordContext: recordContext });
        }

        if (type === "create") {
            await createRecord.mutateAsync({ data: sanitizedData, entity: entity });
        }
    };

    return (
        <form
            id={entity}
            onSubmit={(e) => {
                if (!entityForm) return;
                entityForm.clearErrors();
                entityForm.handleSubmit(onSubmit)(e);
            }}
            style={{ width: "100%", backgroundColor: "#fff" }}
        >
            {component || <Groups entityForm={entityForm} type={type} groupsOptions={groupsOptions} />}
        </form>
    );
};
