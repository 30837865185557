import { Badge, Box } from "@mui/material";
import { ModeComment } from "@mui/icons-material";

type CommentsProps = {
	value: {
		row: {
			comments: [
				{
					date_updated: string;
				}
			];
		};
	};
};

export const Comments = ({ value }: CommentsProps) => {
	const comments = value.row?.comments;
	const totalComments = comments?.length;

	return (
		<Box>
			<Badge badgeContent={totalComments} color="secondary">
				<ModeComment color="action" />
			</Badge>
		</Box>
	);
};
