import { UseRecordsOptions } from "../../api/useRecords";
import { RecordContextType } from "../../components/record/Record";
import { clientsSearchSettings } from "../../utils/createSearchFilter";

export const treatmentsOptions = {
    fieldsToRemove: {
        fieldNamesToRemove: ["id", "user_created", "treatment_status"],
        fieldTypesToRemove: [""],
    },
    groupsOptions: {
        fieldsWithExtraOptions: [
            {
                field: "client_manager",
                relatedTable: "technicians",
                displayTemplate: "{{user_id.first_name}} {{user_id.last_name}}",
            },
            {
                field: "theurapists_involved_in_treamtent",
                relatedTable: "technicians",
                displayTemplate: "{{user_id.first_name}} {{user_id.last_name}}",
                enabled: true,
            },
            {
                field: "relative_resposible_for_treatment",
                relatedTable: "relatives",
                relatedWith: "client",
                displayTemplate: "{{Nome}}",
                createFilter: (params: any) => filterRelatives(params),
                enabled: true,
                limit: 100
            },
            {
                field: "client_owner",
                sort: "user_id.first_name",
                enabled: true,
            },
            {
                field: "client",
                createFilter: (inputValue: string) => clientsSearchSettings(inputValue),
            },
            {
                field: "contracts",
                fileFolder: "dea81570-81a4-4723-bb95-35f36b973bba",
            },
            {
                field: "other_treatment_documents",
                fileFolder: "a8a68c39-f6ed-4572-a9be-4b03478079da",
            },
            {
                field: "partners",
                relatedTable: "professional_partners",
                displayTemplate: "{{specialization}} - {{name}} - {{phone}}",
                enabled: true,
            },
        ],
    },

    createRelatedParams: (params: RecordContextType | undefined) => createTreatmentParams(params),
} as UseRecordsOptions;

const createTreatmentParams = async (recordContext: RecordContextType | undefined) => {
    if (!recordContext?.item) return;
    if (recordContext.entity === "clients") return `[clients][id]=${recordContext.item.id}`;
};

const filterRelatives = (params: any) => {
    var filter = {};

    const clientId = params?.id;
    if (clientId) {
        filter = {
            _and: [
                {
                    client_id: {
                        id: {
                            _eq: clientId,
                        },
                    },
                },
            ],
        };
    }

    return filter;
};
