import { TableRow, TableCell, Button, Box, Table, TableHead, TableBody, Typography, IconButton } from "@mui/material";
import { format, parseISO, isSameDay } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { calculateHours } from "../workLogsUtils";
import { WorkLog, TimeOff } from "../types";
import { useContext } from "react";
import { StaffWorkLogsContext } from "../StaffWorkLogsDetail";
import { Delete } from "@mui/icons-material";
import { useDeleteRecord } from "../../../api/useDeleteRecord";

const TIMEZONE = "Europe/Lisbon";

interface DayEntriesProps {
    date: Date;
    workLogs: WorkLog[];
    timeOffs: TimeOff[];
}

const formatDateTime = (date: string) => {
    return format(utcToZonedTime(parseISO(date), TIMEZONE), "HH:mm");
};

const getSortedEntries = (date: Date, workLogs: WorkLog[], timeOffs: TimeOff[]) => {
    const dayWorkLogs = workLogs
        .filter((log) => isSameDay(parseISO(log.start_date), date))
        .sort((a, b) => parseISO(a.start_date).getTime() - parseISO(b.start_date).getTime());

    const dayTimeOffs = timeOffs
        .filter((timeOff) => isSameDay(parseISO(timeOff.start_date), date))
        .sort((a, b) => parseISO(a.start_date).getTime() - parseISO(b.start_date).getTime());

    return [...dayWorkLogs, ...dayTimeOffs];
};

const getGroupedEntries = (entries: Array<WorkLog | TimeOff>) => {
    const workHours = entries.filter((entry) => entry.type === "schedules" || entry.type === "holiday");
    const clientSessions = entries.filter((entry) => entry.type === "clients");
    return { workHours, clientSessions };
};

export const DayEntries = ({ date, workLogs, timeOffs }: DayEntriesProps) => {
    const { payment, workLogForm, setIsModalOpen } = useContext(StaffWorkLogsContext);
    const allEntries = getSortedEntries(date, workLogs, timeOffs);
    const { workHours, clientSessions } = getGroupedEntries(allEntries);
    const deleteSchedule = useDeleteRecord();

    const handleEdit = (entry: any, type: "schedules" | "time_off") => {
        workLogForm.setValue("id", entry.id);
        workLogForm.setValue("start_date", parseISO(entry.start_date));
        workLogForm.setValue("end_date", parseISO(entry.end_date));
        workLogForm.setValue("notes", entry.notes);
        workLogForm.setValue("type", type);
        setIsModalOpen(true);
    };



    const renderEntriesTable = (entries: Array<WorkLog | TimeOff>, title: string) => {
        return (
            <Box sx={{ p: 2 }}>
                <Typography variant="subtitle1" fontWeight="bold" color="primary">
                    {title}
                </Typography>
                <Table size="small" sx={{ backgroundColor: "white", boxShadow: 1 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell width="20%" align="center">
                                Hora de Início
                            </TableCell>
                            <TableCell width="20%" align="center">
                                Hora de Fim
                            </TableCell>
                            <TableCell width="20%" align="center">
                                Duração
                            </TableCell>
                            <TableCell width="20%" align="center">
                                Notas
                            </TableCell>
                            <TableCell width="20%" align="center">
                                Ações
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {entries.map((entry: any) => {
                            return (
                                <TableRow
                                    key={entry.id}
                                    sx={{
                                        "&:nth-of-type(odd)": { backgroundColor: "#fafafa" },
                                        ...(entry.type === "holiday" && {
                                            backgroundColor: "#fff4f4",
                                            "& td": { color: "secondary.main" },
                                        }),
                                    }}
                                >
                                    <TableCell align="center">{formatDateTime(entry.start_date)}</TableCell>
                                    <TableCell align="center">{formatDateTime(entry.end_date)}</TableCell>
                                    <TableCell align="center">{calculateHours(entry.start_date, entry.end_date)}</TableCell>
                                    <TableCell align="center">{entry.notes}</TableCell>
                                    <TableCell align="center">
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            onClick={() => handleEdit(entry, entry.type === "holiday" ? "time_off" : "schedules")}
                                            disabled={entry.type === "clients" || payment?.admin_validated}
                                        >
                                            Editar
                                        </Button>
                                        {(entry.type === "schedules" || entry.type === "holiday") && (
                                            <IconButton
                                                size="small"
                                                color="error"
                                                disabled={entry.type === "clients" || payment?.admin_validated}
                                                onClick={() => {
                                                    deleteSchedule.mutateAsync({
                                                        entity: entry.type === "holiday" ? "time_off" : "schedules",
                                                        id: entry.id,
                                                    });
                                                }}
                                            >
                                                <Delete color="error" />
                                            </IconButton>
                                        )}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Box>
        );
    };

    return (
        <Box
            sx={{
                gap: 2,
                width: "100%",
                padding: 2,
                backgroundColor: "#f8f8f8",
                borderRadius: 1,
            }}
        >
            <Box>{workHours.length > 0 && renderEntriesTable(workHours, "Horários de trabalho")}</Box>
            <Box>{clientSessions.length > 0 && renderEntriesTable(clientSessions, "Sessões realizadas")}</Box>
        </Box>
    );
};
