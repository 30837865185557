import { useEffect, useState } from "react";

export const useRenderOnce = (channelName: string) => {
    const [shouldRender, setShouldRender] = useState(false);
    const bc = new BroadcastChannel(channelName);

    useEffect(() => {
        const isRendered = localStorage.getItem(channelName);
        if (!isRendered) {
            localStorage.setItem(channelName, "true");
            setShouldRender(true);
            bc.postMessage("rendered");
        }

        const handleMessage = (event: MessageEvent) => {
            if (event.data === "rendered" && !shouldRender) {
                setShouldRender(false);
            }
        };

        bc.addEventListener("message", handleMessage);

        return () => {
            bc.removeEventListener("message", handleMessage);
        };
    }, [channelName]);

    useEffect(() => {
        const handleBeforeUnload = () => {
            if (shouldRender) {
                localStorage.removeItem(channelName);
            }
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, [channelName, shouldRender]);

    return { shouldRender, setShouldRender };
};

