import { isSameDay } from "date-fns";
import { CalendarItem } from "../calendar.types";

export type DayEvents = {
    psychologyEvent: CalendarItem | undefined;
    neurofeedback: CalendarItem | undefined;
    eeg: CalendarItem | undefined;
    report: CalendarItem | undefined;
};

export type EventConfig = {
    type: keyof DayEvents;
    position: { top?: number; bottom?: number; left?: number; right?: number };
    condition: (events: DayEvents) => boolean;
};

export const EVENT_CONFIGS: EventConfig[] = [
    {
        type: "psychologyEvent",
        position: { top: 0, left: 4 },
        condition: (events) => !!events.psychologyEvent,
    },
    {
        type: "neurofeedback",
        position: { bottom: -3, right: 4 },
        condition: (events) => !!events.neurofeedback,
    },
    {
        type: "eeg",
        position: { top: 0, right: 4 },
        condition: (events) => !!events.eeg,
    },
    {
        type: "report",
        position: { bottom: -3, left: 4 },
        condition: (events) => !!events.report,
    },
];

export const BACKGROUND_RENDER_ORDER: Array<keyof DayEvents> = ["psychologyEvent", "neurofeedback", "eeg", "report"];

export const shouldRenderBackground = (eventType: keyof DayEvents, events: DayEvents): boolean => {
    const index = BACKGROUND_RENDER_ORDER.indexOf(eventType);
    if (index === -1) return false;

    // Check if any higher priority event exists
    return !BACKGROUND_RENDER_ORDER.slice(0, index).some((type) => !!events[type]);
};

export const getDayEvent = (day: Date, events?: CalendarItem[]): DayEvents => {
    const dayEvents = events?.filter((event) => isSameDay(event.start, day)) ?? [];

    const psychologyEvent = dayEvents.find((event) => event?.service?.category === "psicologia");
    const neurofeedback = dayEvents.find((event) => event?.service?.category === "neurofeedback");
    const eeg = dayEvents.find((event) => event?.service?.category === "avaliacoes");
    const report = dayEvents.find((event) => event?.service?.category === "report");

    return { psychologyEvent, neurofeedback, eeg, report };
};

export const getDayColor = (dayEvent: CalendarItem) => {
    let color = "";
    switch (dayEvent.status) {
        case "done":
            color = "#4BC417";
            break;
        case "cancelled":
        case "missed":
            color = "#FF0000";
            break;
        default:
            color = "#1A7FEA";
    }

    return color;
};

export const getIconColor = (event: CalendarItem, events: DayEvents): string => {
    const category = event.service?.category;
    const nfbEvent = events.neurofeedback;
    const psyEvent = events.psychologyEvent;

    // If it's psychology or neurofeedback, always white
    if (category === "psicologia" || category === "neurofeedback") {
        return "white";
    }

    // If it's another service and there's no psychology or neurofeedback event
    // Use the status color
    if (category !== "psicologia" && category !== "neurofeedback" && (psyEvent || nfbEvent)) {
        const psychologyEventColor = psyEvent && getDayColor(psyEvent);
        const neurofeedbackColor = nfbEvent && getDayColor(nfbEvent);
        const otherEventColor = getDayColor(event);

        if (otherEventColor === neurofeedbackColor || otherEventColor === psychologyEventColor) {
            return "white";
        }

        return getDayColor(event);
    }

    // In all other cases (other services with psychology/neurofeedback present)
    return "white";
};
