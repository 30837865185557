import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import { OpenInNewTab } from "../datagrid/OpenInNewTab";
import { TreatmentProvidedField } from "../fieldsViews/fields/customized/TreatmentProvidedField";

export const columns: GridColDef[] = [
    {
        field: "new_tab",
        headerName: "Nova tab",
        width: 150,
        renderCell: (params: GridCellParams) => <OpenInNewTab params={params} entity={"treatments"} />,
    },

    {
        field: "client_id",
        headerName: "Cliente",
        width: 100,
    },
    {
        field: "client_name",
        headerName: "Nome",
        width: 200,
    },
    {
        field: "treatment_plan",
        headerName: "Plano",
        width: 175,
    },
    {
        field: "next_step",
        headerName: "Próximo Passo",
        width: 175,
    },

    {
        field: "service_33_done",
        headerName: "NFB Realizado",
        width: 100,
    },
    {
        field: "service_34_done",
        headerName: "Psy Realizada",
        width: 100,
    },
    {
        field: "service_33_bought",
        headerName: "NFB Comprado",
        width: 100,
    },
    {
        field: "service_34_bought",
        headerName: "Psy Comprada",
        width: 100,
    },
    {
        field: "fast_notes",
        headerName: "Notas Rápidas",
        width: 200,
    },
    {
        field: "treatment_provided",
        headerName: "Tratamento",
        width: 600,
        renderCell: (params: GridCellParams) => <TreatmentProvidedField fieldView="grid" value={params} />,
    },
];
