import { Session, WeeklyAvailabilityFromApi } from "../components/availability-finder/utils/types";
import { AvailabilityPreferences, AvailabilitySlot } from "../components/availability-finder/utils/types";
import { api } from "./config";

export const getWeeksSlots = async (
    preferences: AvailabilityPreferences,
    selectedSlots: AvailabilitySlot[]
): Promise<WeeklyAvailabilityFromApi[]> => {
    const response = await api({
        method: "POST",
        url: "/availabilities-finder",
        data: {
            preferences,
            selectedSlots,
        },
    });
    return response.data;
};

export const createSessionsFromAvailabilitiesFinder = async (
    preferences: AvailabilityPreferences,
    selectedSlots: AvailabilitySlot[]
): Promise<{ isValid: boolean; sessionsCreated: Session[]; slotsNotAvailable: AvailabilitySlot[] }> => {
    const response = await api({
        method: "POST",
        url: "/availabilities-finder/create-sessions",
        data: {
            preferences,
            selectedSlots,
        },
    });

    return response.data;
};
