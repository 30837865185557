import { useState } from "react";
import Grid from "@mui/material/Grid";
import { LoginForm } from "../../components/authentication/loginForm/LoginForm";
import logo from "/assets/images/logo.png";
import { RecoverPasswordForm } from "../../components/authentication/recoverPassword/RecoverPasswordForm";
import { useIsMobile } from "../../utils/useIsMobile";

export const Login = () => {
    const isMobile = useIsMobile();
    const [recoverPasswordActive, setRecoverPasswordActive] = useState(false);

    return (
        <Grid container sx={{ height: "100vh" }} justifyContent="center">
            <Grid
                item
                xs={12}
                sm={4}
                md={7}
                sx={{
                    backgroundColor: "primary.main",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: !isMobile ? "0 30px 30px 0" : null,
                    padding: 10,
                }}
            >
                <img src={logo} alt="Logo" style={{ width: "70%" }} />
            </Grid>

            {recoverPasswordActive ? (
                <RecoverPasswordForm setRecoverPassword={setRecoverPasswordActive} />
            ) : (
                <LoginForm setRecoverPassword={setRecoverPasswordActive} />
            )}
        </Grid>
    );
};
