import { TableBody } from "@mui/material";
import { getScheduleTotals, groupEventsByUser, sortByUserName } from "../../utils/scheduleUtils";
import { CustomEventType } from "../../utils/types";

import { UserScheduleRow } from "./UserScheduleRow";

export type ScheduleWeekTotalByUserProps = {
    events: Array<CustomEventType>;
};

export const ScheduleWeekTotalByUser = ({ events }: ScheduleWeekTotalByUserProps) => {
    const userEvents = groupEventsByUser(events);
    const sortedUsers = sortByUserName(userEvents);

    return (
        <TableBody>
            {sortedUsers.map(({ userName, userColor, events }) => {
                const { dailyTotals, weeklyTotal } = getScheduleTotals(events);

                return (
                    <UserScheduleRow
                        key={userName}
                        userName={userName}
                        userColor={userColor}
                        dailyTotals={dailyTotals}
                        weeklyTotal={weeklyTotal}
                    />
                );
            })}
        </TableBody>
    );
};
