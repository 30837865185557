import { Box, Typography } from "@mui/material";
import { formatCurrency } from "../../../utils/formatCurrency";
import { useContext } from "react";
import { StaffWorkLogsContext } from "../StaffWorkLogsDetail";

type WorkHoursSummaryProps = {
    isAdminOrCurrentUser: boolean;
    isServiceProvider: boolean;
};

export const WorkHoursSummary = ({ isAdminOrCurrentUser, isServiceProvider }: WorkHoursSummaryProps) => {
    const {
        totals: { effectiveWorkHours, totalWorkHours },
        staffAgreement: { value_per_hour },
    } = useContext(StaffWorkLogsContext);

    const totalValue = value_per_hour ? effectiveWorkHours * value_per_hour : null;

    return (
        <Box>
            <Typography color="primary" variant="h6">
                Horas Trabalhadas
            </Typography>
            <Typography>Total horas trabalhadas: {totalWorkHours.toFixed(2)}</Typography>
            {isAdminOrCurrentUser && isServiceProvider && <Typography>Valor a receber: {totalValue && formatCurrency(totalValue)}</Typography>}
        </Box>
    );
};
