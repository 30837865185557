import { useEffect } from "react";
import store2 from "store2";
import { refreshToken } from "../api/useAuth";

export const useUpdateTokens = () => {
    useEffect(() => {
        const refresh_token = store2.get("auth")?.refresh_token;
        const updateTokens = async () => {
            if (refresh_token) {
                const updatedTokens = await refreshToken(refresh_token);
                store2.set("auth", updatedTokens);
            }
        };

        updateTokens();
    }, []);

    useEffect(() => {
        const intervalId = setInterval(async () => {
            const refresh_token = store2.get("auth")?.refresh_token;
            if (refresh_token) {
                const updatedTokens = await refreshToken(refresh_token);
                store2.set("auth", updatedTokens);
            }
        }, 700000); // Refresh every 12 minutes

        return () => clearInterval(intervalId); // Cleanup on unmount
    }, []);
};
