import { Record } from "../../components/record/Record";
import { Records } from "../../components/records/Records";
import { tabs } from "./relativesTabs";
import { DetailTabs } from "../../components/detailTabs/DetailTabs";
import { EntityForm } from "../../components/entityForm/EntityForm";
import { Groups } from "../../components/groups/Groups";
import { relativesOptions } from "./relativesOptions";

const fieldsToQuery = "*.*,client_id.user_id.*";

export const relativesRoutes = [
  {
    path: "/relatives",
    element: <Records entity="relatives" fieldsQuery={fieldsToQuery} options={relativesOptions} />,
  },
  {
    path: "/relatives/:id",
    element: <Record entity="relatives" fieldsQuery={fieldsToQuery} type="detail" body={<DetailTabs tabs={tabs} />} />,
    children: [
      {
        path: "/relatives/:id/detail",
        element: <Groups />,
      },
    ],
  },
  {
    path: "/relatives/:id/update",
    element: <Record entity="relatives" type="update" body={<EntityForm />} />,
  },
  {
    path: "/relatives/create",
    element: <Record entity="relatives" type="create" body={<EntityForm groupsOptions={relativesOptions.groupsOptions} />} />,
  },
];
