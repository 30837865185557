import { api } from "./config";
import { useQuery } from "@tanstack/react-query";

export const useCollection = (entity: string) => {
	return useQuery({
		queryKey: [`collections-${entity}`],
		queryFn: () => getCollection(entity),
		refetchOnWindowFocus: false,
		staleTime: Infinity,
	});
};

export const getCollection = async (entity: string) => {
	const response = await api({
		method: "GET",
		url: `collections/${entity}`,
	});

	return response.data.data;
};
