import SortableList, { SortableItem } from "react-easy-sort";
import { Box, Typography, Unstable_Grid2 as Grid } from "@mui/material";
import { DragHandle } from "@mui/icons-material";
import { arrayMoveImmutable } from "array-move";
import { ToggleColumnVisibility } from "./ToggleColumnVisibility";
import { SetColumnWidth } from "./SetColumnWidth";
import { useStore } from "../../store/store";

export const ConfigColumns = () => {
    const { gridSettings } = useStore();

    const onSortEnd = (oldIndex: number, newIndex: number) => {
        gridSettings.setGridSettings({ gridColumns: arrayMoveImmutable(gridSettings.gridColumns, oldIndex, newIndex) });
    };

    return (
        <Box padding={2}>
            <SortableList onSortEnd={onSortEnd} className="list" draggedItemClassName="dragged" lockAxis="y">
                <Box sx={{ p: 4 }}>
                    {gridSettings.gridColumns.map((gridColumn) => (
                        <SortableItem key={gridColumn.field}>
                            <Grid container width={600} alignItems="center" justifyContent="space-between" sx={{ zIndex: 9999 }}>
                                <Grid xs={2}>
                                    <DragHandle sx={{ mt: 2 }} />
                                </Grid>
                                <Grid xs={4}>
                                    <Typography>{gridColumn.headerName}</Typography>
                                </Grid>
                                <Grid xs={2}>
                                    <ToggleColumnVisibility gridColumn={gridColumn} />
                                </Grid>
                                <Grid xs={4}>
                                    <SetColumnWidth gridColumn={gridColumn} />
                                </Grid>
                            </Grid>
                        </SortableItem>
                    ))}
                </Box>
            </SortableList>
        </Box>
    );
};
