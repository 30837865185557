import TextField from "@mui/material/TextField";
import { Controller } from "react-hook-form";
import { FieldProps } from "../renderFieldInput";

interface CityInputFieldProps extends FieldProps {
	value: string;
}

export const CityInputField = ({
	field,
	value,
	entityForm,
	options,
	extraOptions,
}: CityInputFieldProps) => {
	// const error = entityForm.formState.errors[field.field];

	return (
		<Controller
			name={extraOptions?.field ? extraOptions?.field : field.field}
			control={entityForm.control}
			defaultValue={value || ""}
			render={({ field: fieldInput }) => (
				<TextField
					{...fieldInput}
					disabled={options?.disabled}
					label={field.headerName}
					fullWidth
					{...options}
				/>
			)}
		/>
	);
};
