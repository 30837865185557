import { LinearProgress } from "@mui/material";
import { UseRecordsOptions } from "../../api/useRecords";
import { RecordsHeader } from "../../components/recordsHeader/RecordsHeader";
import { useCollection } from "../../api/useCollection";

import { Records } from "../../components/records/Records";
import { ToggleBetweenSummaryAndList } from "./ToggleBetweenSummaryAndList";
import { TreatmentsSummary } from "../../components/treatmentsSummary/TreatmentsSummary";
import { Collection } from "../../types/collections.types";
import { useStore } from "../../store/store";

type SessionsListProps = {
    entity: string;
    fieldsQuery: string;
    options?: UseRecordsOptions;
};

export function Treatments({ entity, fieldsQuery, options }: SessionsListProps) {
    const { treatments } = useStore();
    const { data: collection, status: statusCollection } = useCollection(entity);

    if (treatments.view === "list") {
        if (statusCollection === "loading") return <LinearProgress color="secondary" />;
        if (statusCollection === "error") return <div>error</div>;

        return (
            <Records
                entity={entity}
                fieldsQuery={fieldsQuery}
                options={options}
                heightOfHeaders={222}
                header={<TreatmentsHeader collection={collection} />}
            />
        );
    }

    return (
        <TreatmentsSummary
            heightOfHeaders={222}
            header={<TreatmentsHeader collection={collection} headerTitle="Sumário de tratamentos" />}
        />
    );
}

type TreatmentsHeaderProps = {
    collection: Collection;
    headerTitle?: string;
};

const TreatmentsHeader = ({ collection, headerTitle }: TreatmentsHeaderProps) => {
    return (
        <RecordsHeader
            collection={collection}
            optionalComponents={{
                bellowTitle: <ToggleBetweenSummaryAndList />,
                headerTitle: headerTitle,
            }}
        />
    );
};
