import { UseRecordsOptions } from "../../api/useRecords";
import { RecordContextType } from "../../components/record/Record";
import { treatmentsSearchSettings } from "../../utils/createSearchFilter";

export const feedbackOptions = {
    fieldsToRemove: {
        fieldNamesToRemove: ["id", "content"],
        fieldTypesToRemove: ["alias"],
    },

    groupsOptions: {
        fieldsWithExtraOptions: [
            {
                field: "treatment",
                createFilter: (inputValue: string) => treatmentsSearchSettings(inputValue),
            },
        ],
    },

    createRelatedParams: (params: RecordContextType | undefined) => createOrderParams(params),
} as UseRecordsOptions;

const createOrderParams = async (recordContext: RecordContextType | undefined) => {
    if (!recordContext?.item) return;

    if (recordContext.entity === "clients") return `[clients][id]=${recordContext.item.id}`;

    if (recordContext.entity === "treatments") return `[treatments][id]=${recordContext.item.id}`;
};



