import { GridColDef, GridActionsCellItem, GridValidRowModel, GridRowsProp, GridRowId } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { calculateRowTotal } from "./utils";

type ColumnsOptions = {
    rows: GridRowsProp;
    setRows?: React.Dispatch<React.SetStateAction<readonly GridValidRowModel[]>>;
    setRowsToDelete?: (any: any) => void;
    type: "create" | "detail" | "update";
};

export const useColumns = (options: ColumnsOptions) => {
    const columns: GridColDef[] = [
        { field: "sku", headerName: "Sku", width: 150 },
        { field: "serviceName", headerName: "Serviço", width: 200 },
        { field: "agreementName", headerName: "Acordo", width: 150 },
        { field: "type", headerName: "Tipo", width: 150 },
        { field: "quantity", headerName: "Quantidade", width: 100, editable: isEditable(options.type), type: "number" },
        {
            field: "price",
            headerName: "Preço Unit",
            width: 100,
            type: "number",
            editable: isEditable(options.type),
            renderCell: (params) => `€ ${params.row.price}`,
        },
        {
            field: "discount",
            headerName: "Desconto",
            width: 100,
            editable: isEditable(options.type),
            renderCell: (params) => `${params.row.discount || 0} %`,
        },
        {
            field: "total",
            headerName: "Total",
            width: 100,
            type: "number",
            renderCell: (params) => `€ ${calculateRowTotal(params.row)}`,
        },
    ];

    const actions = createActions(options);

    if (actions) {
        columns.push(actions);
    }

    return columns;
};

const handleDeleteClick =
    (
        id: GridRowId,
        rows: GridRowsProp,
        setRows: React.Dispatch<React.SetStateAction<readonly GridValidRowModel[]>>,
        setRowsToDelete?: (any: any) => void
    ) =>
    () => {
        setRowsToDelete && setRowsToDelete(rows.filter((row) => row.id === id));
        setRows(rows.filter((row) => row.id !== id));
    };

const createActions = (options: ColumnsOptions) => {
    if (options.type === "detail") return;

    return {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        width: 100,
        cellClassName: "actions",
        getActions: ({ id }: any) => {
            return [
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Delete"
                    onClick={options.setRows && handleDeleteClick(id, options.rows, options.setRows, options.setRowsToDelete)}
                    color="inherit"
                />,
            ];
        },
    };
};

const isEditable = (type: string) => {
    switch (type) {
        case "create":
        case "update":
            return true;
        case "detail":
            return false;
        default:
            return false;
    }
};
