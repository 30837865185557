import { Box, TableBody, TableRow, Typography, Tooltip, Divider, LinearProgress } from "@mui/material";
import { Schedule, CalendarMonth, Info } from "@mui/icons-material";
import { Fragment } from "react";
import { categorizeEvents, getScheduleTotals, getSessionCountsByCategory, workDays } from "../../utils/scheduleUtils";
import { StyledTableCell } from "./styles";
import { CustomEventType, CategoryKey } from "../../utils/types";
import { CATEGORIES_CONFIG, OVERBOOKED_RATIO } from "../../../../utils/constants";
import { useRecords } from "../../../../api/useRecords";
import { createCalendarFilter, useDefaultFilters } from "../../../../components/calendar/calendarUtils";
import { UserScheduleRow } from "./UserScheduleRow";
import { useStore } from "../../../../store/store";
import { getRelateServicesFromConstants } from "../../../../utils/getRelatedServicesFromConstants";

export type ScheduleWeekTotalByServiceProps = {
    scheduleEvents: Array<CustomEventType>;
};

export const ScheduleWeekTotalByService = ({ scheduleEvents }: ScheduleWeekTotalByServiceProps) => {
    const categorizedEvents = categorizeEvents(scheduleEvents);
    const {
        schedules: { calendarFilters },
    } = useStore();
    const defaultFilters = useDefaultFilters();

    const filters = createCalendarFilter(calendarFilters || defaultFilters);
    const { data: sessionsData, status } = useRecords("sessions", "id,service,status,start_date", {
        extraOptions: { filter: filters, pageSize: 1000 },
    });

    if (status === "loading") {
        return (
            <Box sx={{ width: "100%", height: 1000 }}>
                <LinearProgress color="primary" />
            </Box>
        );
    }

    type SessionCountDisplayProps = {
        sessionsBooked: number;
        possibleSessions: number;
    };

    const SessionCountDisplay = ({ sessionsBooked, possibleSessions }: SessionCountDisplayProps) => {
        const ratio = sessionsBooked / possibleSessions;
        const isOverBooked = ratio >= OVERBOOKED_RATIO;

        return (
            <Typography
                variant="body2"
                sx={{
                    backgroundColor: isOverBooked ? "rgba(230, 17, 17, 0.467)" : "transparent",
                    borderRadius: "4px",
                    padding: "2px 4px",
                }}
            >
                {sessionsBooked}/{possibleSessions}
            </Typography>
        );
    };

    return (
        <TableBody>
            {Object.entries(categorizedEvents).map(([categoryKey, category]) => {
                const relatedServicesIds = getRelateServicesFromConstants(categoryKey as CategoryKey);
                const sessionCounts =
                    categoryKey !== "others" ? getSessionCountsByCategory(relatedServicesIds, sessionsData) : null;

                return (
                    <Fragment key={categoryKey}>
                        <TableRow>
                            <StyledTableCell className="category-header">
                                <Box display="flex" alignItems="center" gap={1}>
                                    <Typography variant="body1">{category.name}</Typography>
                                    <Tooltip
                                        placement="bottom-start"
                                        componentsProps={{
                                            tooltip: {
                                                sx: {
                                                    minWidth: 500,
                                                },
                                            },
                                        }}
                                        title={
                                            <Box>
                                                <Typography variant="body1">Valores para o cálculo de sessões:</Typography>
                                                <Divider color="inherit" sx={{ my: 1 }} />
                                                <Typography variant="body2">
                                                    Duração da sessão: {CATEGORIES_CONFIG[categoryKey as CategoryKey].duration}
                                                    minutos
                                                </Typography>
                                                <Typography variant="body2">
                                                    Alerta vermelho: {OVERBOOKED_RATIO * 100}% de sessões em relação aos horários
                                                </Typography>
                                            </Box>
                                        }
                                    >
                                        <Info fontSize="small" sx={{ cursor: "help" }} />
                                    </Tooltip>
                                </Box>
                            </StyledTableCell>
                            {workDays.map((day) => (
                                <StyledTableCell key={day} className="data-column-category">
                                    <Box display="flex" alignItems="center" justifyContent="center" gap="4px">
                                        <Schedule />
                                        <Typography variant="body2">{category.dailyTotals[day].toFixed(0)}</Typography>
                                        <CalendarMonth />
                                        {categoryKey !== "others" ? (
                                            <SessionCountDisplay
                                                sessionsBooked={sessionCounts?.daily[day] ?? 0}
                                                possibleSessions={category.possibleSessions[day]}
                                            />
                                        ) : (
                                            <Typography variant="body2">{category.possibleSessions[day]}</Typography>
                                        )}
                                    </Box>
                                </StyledTableCell>
                            ))}
                            <StyledTableCell className="data-column-category">
                                <Box display="flex" alignItems="center" justifyContent="center" gap="4px">
                                    <Schedule />
                                    <Typography variant="body2">
                                        {Object.values(category.dailyTotals)
                                            .reduce((acc, curr) => acc + curr, 0)
                                            .toFixed(0)}
                                    </Typography>
                                    <CalendarMonth />
                                    {categoryKey !== "others" ? (
                                        <SessionCountDisplay
                                            sessionsBooked={sessionCounts?.total ?? 0}
                                            possibleSessions={category.possibleSessions.weekly}
                                        />
                                    ) : (
                                        <Typography variant="body2">{category.possibleSessions.weekly.toFixed(0)}</Typography>
                                    )}
                                </Box>
                            </StyledTableCell>
                        </TableRow>
                        {Object.values(category.users).map(({ userName, userColor, events }) => {
                            const { dailyTotals, weeklyTotal } = getScheduleTotals(events);

                            return (
                                <UserScheduleRow
                                    key={`${categoryKey}-${userName}`}
                                    userName={userName}
                                    userColor={userColor}
                                    dailyTotals={dailyTotals}
                                    weeklyTotal={weeklyTotal}
                                    categoryKey={categoryKey}
                                />
                            );
                        })}
                    </Fragment>
                );
            })}
        </TableBody>
    );
};
