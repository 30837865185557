import { Modal, Paper, Grid, Select, MenuItem, Button, FormControl, InputLabel } from "@mui/material";
import { useCreateRecord } from "../../../api/useCreateRecord";
import { useUpdateRecord } from "../../../api/useUpdateRecord";
import { renderFieldInput } from "../../../components/fieldsInputs/renderFieldInput";
import { useFields } from "../../../api/useFields";
import { findField } from "../../../utils/findField";
import { useContext } from "react";
import { StaffWorkLogsContext } from "../StaffWorkLogsDetail";
import { Controller } from "react-hook-form";

export const WorkLogModal = () => {
    const { workLogForm, setIsModalOpen, isModalOpen } = useContext(StaffWorkLogsContext);

    const createRecord = useCreateRecord();
    const updateRecord = useUpdateRecord();

    const { formatedFields, status: fieldsStatus } = useFields("schedules");
    const startDateField = findField(formatedFields, "start_date");
    const endDateField = findField(formatedFields, "end_date");
    const noteField = findField(formatedFields, "notes");

    const handleSave = async (data: any) => {
        const entity = data.type === "schedules" ? "schedules" : "time_off";

        try {
            if (data.id) {
                await updateRecord.mutateAsync({
                    entity,
                    id: data.id,
                    data: {
                        start_date: data.start_date,
                        end_date: data.end_date,
                        user: data.user,
                        notes: data.notes,
                    },
                });
            } else {
                await createRecord.mutateAsync({
                    entity,
                    data: {
                        start_date: data.start_date,
                        end_date: data.end_date,
                        user: data.user,
                        notes: data.notes,
                        type: entity === "time_off" ? "holiday" : undefined,
                    },
                });
            }
            workLogForm.reset();
            setIsModalOpen(false);
        } catch (error) {
            console.error("Error saving record => ", error);
        }
    };

    if (fieldsStatus === "loading") return null;
    return (
        <Modal
            open={isModalOpen}
            onClose={() => {
                workLogForm.reset();
                setIsModalOpen(false);
            }}
        >
            <Paper sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", width: 400, p: 4 }}>
                <form onSubmit={workLogForm.handleSubmit(handleSave)}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            {startDateField && renderFieldInput(startDateField, null, "group", workLogForm)}
                        </Grid>
                        <Grid item xs={12}>
                            {endDateField && renderFieldInput(endDateField, null, "group", workLogForm)}
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="entry-type-label">Tipo de Registo</InputLabel>
                                <Controller
                                    name="type"
                                    control={workLogForm.control}
                                    render={({ field }) => (
                                        <Select
                                            labelId="entry-type-label"
                                            label="Tipo de Registo"
                                            value={field.value}
                                            onChange={(e) => {
                                                field.onChange(e.target.value);
                                            }}
                                            fullWidth
                                        >
                                            <MenuItem value="schedules">Horas</MenuItem>
                                            <MenuItem value="time_off">Férias</MenuItem>
                                        </Select>
                                    )}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            {noteField && renderFieldInput(noteField, null, "group", workLogForm)}
                        </Grid>
                    </Grid>
                    <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
                        Guardar
                    </Button>
                </form>
            </Paper>
        </Modal>
    );
};
