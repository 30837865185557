type Filter = {
    [key: string]: any;
};

type FilterObject = {
    _and?: Filter[];
    _or?: Filter[];
};

const customFiltersFields = ["treatment_provided"];
export const createFilters = (filter: FilterObject) => {
    if (!filter) return;

    let filterKey: keyof FilterObject | null = filter.hasOwnProperty("_and")
        ? "_and"
        : filter.hasOwnProperty("_or")
        ? "_or"
        : null;
    let customFilters: Filter = {};
    let normalFilters: Filter | Filter[] = [];

    if (filterKey) {
        (filter[filterKey] || []).forEach((subFilter) => {
            let key = Object.keys(subFilter)[0];

            if (customFiltersFields.includes(key)) {
                customFilters[key] = subFilter[key];
            } else {
                normalFilters.push(subFilter);
            }
        });
    } else {
        // If there's no _and or _or, treat the entire filter object as a single filter condition
        normalFilters = filter;
    }

    // Check if normalFilters is empty or a single filter condition
    const isNormalFiltersEmpty = Array.isArray(normalFilters) && normalFilters.length === 0;
    const outputNormalFilters = isNormalFiltersEmpty ? undefined : filterKey ? { [filterKey]: normalFilters } : normalFilters;

    // Check if customFilters is empty
    const isCustomFiltersEmpty = Object.keys(customFilters).length === 0;
    const outputCustomFilters = isCustomFiltersEmpty ? undefined : customFilters;

    return {
        normalFilters: outputNormalFilters,
        customFilters: outputCustomFilters,
    };
};
