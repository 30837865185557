import { LinearProgress } from "@mui/material";
import { useDashboard } from "../../api/useDashboard";

type DashboardProps = {
    view: string;
    criteria?: string;
    workspace?: string;
};

export const Dashboard = ({ view, criteria, workspace }: DashboardProps) => {
    const { data, status } = useDashboard(view, criteria, workspace);

    let zohoViewUrl = "";

    if (data) {
        zohoViewUrl = data + "&frameorigin=https://crm.neuroimprove.pt/";
    }

    if (status !== "success") return <LinearProgress color="secondary" />;

    return (
        <div style={{ flex: 1, position: "relative" }}>
            <iframe
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    border: "none",
                }}
                src={zohoViewUrl}
                title="Dashboard"
            ></iframe>
        </div>
    );
};
