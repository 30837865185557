import { useContext } from "react";
import {
	Box,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Typography,
	Divider,
	LinearProgress,
} from "@mui/material";
import { useRecords } from "../../api/useRecords";
import { format, parseISO } from "date-fns";
import { RecordContext } from "../record/Record";
import { Avatar } from "../fieldsViews/fields/Avatar";
import { removeHtmlTagsAndEntities } from "../../utils/removeHtmlTagsAndEntities";
import { EmojiPeople, Groups, Psychology } from "@mui/icons-material";

type NotesListProps = {
	selectedItem: number | null;
	setSelectedItem: (id: number | null) => void;
	extraEntities?: Array<string>;
};

type Note = {
	content: string;
	id: number;
	leads: any;
	treatments: any;
	clients: any;
	date_created: string;
	user_created: {
		avatar?: string;
	};
};

export function NotesList({
	selectedItem,
	setSelectedItem,
	extraEntities,
}: NotesListProps) {
	const recordContext = useContext(RecordContext);

	const extraFilter =
		(recordContext?.id &&
			extraEntities &&
			createExtraNotesFilter(extraEntities, recordContext.id)) ||
		[];

	const notesFilter = {
		_or: [
			recordContext && {
				[recordContext.entity]: {
					id: {
						_eq: recordContext?.id,
					},
				},
			},
			...extraFilter,
		],
	};

	const { data, status } = useRecords("notes", "*.*", {
		extraOptions: {
			filter: notesFilter,
		},
	});

	if (status === "loading") return <LinearProgress />;
	if (status === "error") return <div>error...</div>;

	return (
		<Box>
			<List sx={{ width: "100%" }}>
				{data.items.map((item: Note) => {
					const shortString = item.content?.substring(0, 100);
					const plainText = removeHtmlTagsAndEntities(shortString);
					return (
						<Box key={item.id}>
							<ListItem
								alignItems="flex-start"
								sx={{
									cursor: "pointer",
									backgroundColor:
										selectedItem === item.id ? "grey.200" : "transparent",
								}}
								onClick={() => {
									setSelectedItem(item.id);
								}}
							>
								<ListItemAvatar>
									<Avatar value={item?.user_created?.avatar} />
								</ListItemAvatar>
								<ListItemText
									primary={
										plainText.length > 50
											? `${plainText.substring(0, 50)}...`
											: plainText
									}
									secondary={
										<Box display="flex" alignItems="end">
											<EntitiesIcons item={item} />
											<Typography
												sx={{ display: "inline", ml: 2 }}
												component="span"
												variant="body2"
												color="text.primary"
											>
												{format(
													parseISO(item.date_created),
													"yyyy-MM-dd HH:mm"
												)}
											</Typography>
										</Box>
									}
								/>
							</ListItem>
							<Divider variant="fullWidth" component="li" />
						</Box>
					);
				})}
			</List>
		</Box>
	);
}

const createExtraNotesFilter = (
	extraEntities: Array<string>,
	mainEntityId: string | number
) => {
	return extraEntities.map((entity) => {
		const filter = {
			[entity]: {
				client: {
					id: {
						_eq: mainEntityId,
					},
				},
			},
		};

		return filter;
	});
};

type EntitiesIconsProps = {
	item: Note;
};

const EntitiesIcons = ({ item }: EntitiesIconsProps) => {
	if (item.leads) return <EmojiPeople color="secondary" />;
	if (item.treatments) return <Psychology color="secondary" />;
	if (item.clients) return <Groups color="secondary" />;

	return null;
};
