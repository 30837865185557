import { useState } from "react";
import { Button, IconButton } from "@mui/material";
import axios from "axios";
import { useStore } from "../../../store/store";
import { UploadFile } from "@mui/icons-material";
import { UserFromAPI } from "../../../types/auth.types";

interface SalariesUploadButtonProps {
    selectedYear: number;
    selectedMonth: number;
    type: "salaries" | "service_provider_receipt";
    buttonText: string;
    display?: "icon" | "text";
    currentUser?: UserFromAPI;
}

export const SalariesUploadButton = ({
    selectedYear,
    selectedMonth,
    type,
    buttonText,
    display = "text",
    currentUser,
}: SalariesUploadButtonProps) => {
    const [isUploading, setIsUploading] = useState(false);
    const { setNotification } = useStore((state) => state.notifications);

    const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) return;

        setIsUploading(true);

        const formData = new FormData();
        formData.append("file", file);
        formData.append("year", selectedYear.toString());
        formData.append("month", (selectedMonth + 1).toString());
        formData.append("type", type);
        if (currentUser) formData.append("user_id", currentUser.id);
        try {
            setNotification({ isOpen: true, message: "A enviar, podera demorar alguns segundos", severity: "warning" });
            await axios.post("https://hook.eu1.make.com/mffcivbweaynbr4jf299k76hy2lh3cf1", formData);
            setNotification({ isOpen: true, message: "Arquivo carregado com sucesso", severity: "success" });
        } catch (error) {
            const errorMessage =
                axios.isAxiosError(error) && error.response?.data ? error.response.data : "Erro ao carregar arquivo";
            setNotification({ isOpen: true, message: errorMessage, severity: "error" });
        } finally {
            setIsUploading(false);
        }
    };

    if (display === "icon") {
        return (
            <IconButton disabled={isUploading} component="label" color="secondary">
                <UploadFile />
                <input type="file" hidden onChange={handleFileUpload} />
            </IconButton>
        );
    }

    return (
        <Button variant="contained" component="label" disabled={isUploading} sx={{ minWidth: 200 }} color="secondary">
            {isUploading ? "A enviar..." : buttonText}
            <input type="file" hidden onChange={handleFileUpload} />
        </Button>
    );
};
