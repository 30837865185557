import React from "react";
import { Button } from "@mui/material";
import * as ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { ExportSettings } from "../../screens/staff/types";

type ExportExcelProps = {
    getData: () => ExportSettings;
    fileName: string;
    includeHeaders: boolean;
    buttonText?: string;
    format?: 'xlsx' | 'txt';
};

export const ExportExcel = ({ getData, buttonText, fileName, includeHeaders, format = 'xlsx' }: ExportExcelProps) => {
    const exportToFile = async () => {
        const { data, columns } = getData();

        if (format === 'txt') {
            let content = '';
            
            if (includeHeaders) {
                content += columns.map(col => col.header || col.key).join('\t') + '\n';
            }
            
            data.forEach(row => {
                content += columns.map(col => row[col.key]).join('\t') + '\n';
            });
            
            const blob = new Blob([content], { type: 'text/plain;charset=utf-8' });
            saveAs(blob, `${fileName}.txt`);
            return;
        }

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Sheet1");

        worksheet.columns = columns.map((col) => ({
            header: includeHeaders ? col.header || col.key : undefined,
            key: col.key,
            width: col.width,
        }));

        data.forEach((row) => {
            worksheet.addRow(columns.map((col) => row[col.key]));
        });

        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        saveAs(blob, `${fileName}.xlsx`);
    };

    return (
        <Button variant="contained" onClick={exportToFile} sx={{ minWidth: 200 }} color="secondary">
            {buttonText}
        </Button>
    );
};
