import { Box, Button, IconButton, Paper, Typography } from "@mui/material";
import { Save, Edit, Close, Delete } from "@mui/icons-material";
import Grid from "@mui/material/Unstable_Grid2";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { RecordContext, RecordContextType } from "../../components/record/Record";
import { useContext, useEffect } from "react";
import { Collection } from "../../types/collections.types";
import { ActionsMenu, Actions } from "../actionsMenu/ActionsMenu";
import { useStore } from "../../store/store";
import { useDeleteRecord } from "../../api/useDeleteRecord";
import { Alerts } from "../../store/store.types";
import { componentStyles } from "./styles";

export type DetailHeaderTypes = "detail" | "update";

export type DetailHeaderProps = {
    children?: React.ReactNode;
    collection?: Collection;
    actions?: Actions;
    entity?: string;
    id?: string | number | null;
    deleteOnUpdate?: {
        forceRoute?: string;
    };
};

export const DetailHeader = ({ children, collection, actions, entity, id, deleteOnUpdate }: DetailHeaderProps) => {
    const recordContext = useContext(RecordContext);
    const navigate = useNavigate();
    const location = useLocation();
    const isDetail = location.pathname.split("/").pop() === "detail";
    const isUpdateOrCreate = location.pathname.split("/").pop() === "update" || location.pathname.split("/").pop() === "create";
    const { alerts } = useStore();
    const mutation = useDeleteRecord();
    const styles = componentStyles();

    useEffect(() => {
        if (alerts.allowExecution && recordContext && recordContext.id) {
            mutation.mutate({
                entity: recordContext.entity,
                id: recordContext.id,
                doStuffAfterDelete: () => doStuffAfterDelete(navigate, alerts, recordContext, deleteOnUpdate?.forceRoute),
            });

            alerts.setAllowExecution(false);
        }
    }, [alerts, alerts.allowExecution, mutation, navigate, recordContext]);

    const doStuffAfterDelete = (navigate: any, alerts: Alerts, recordContext: RecordContextType, forceRoute?: string) => {
        navigate(forceRoute ? `/${forceRoute}` : `/${recordContext?.entity}`);
        alerts.setIsOpen(false);
        return;
    };

    if (!entity && !id && recordContext) {
        entity = recordContext.entity;
        id = recordContext.id;
    }

    return (
        <Paper sx={{ mb: 2, p: 4, minHeight: 100 }} elevation={1}>
            <Grid container xs={12} sx={styles.mainWrapper}>
                <Grid xs={12} md={9}>
                    {children || (
                        <>
                            <Typography variant="h4">
                                {collection?.meta.translations && collection.meta.translations[0].singular}
                            </Typography>
                        </>
                    )}
                </Grid>
                <Grid spacing={2} xs={12} md={3}>
                    {isDetail ? (
                        <Box display="flex">
                            {actions ? <ActionsMenu actions={actions} /> : null}
                            <Button fullWidth component={Link} to={`/${entity}/${id}/update`} startIcon={<Edit />}>
                                Editar
                            </Button>
                            <IconButton
                                onClick={() => {
                                    alerts.setIsOpen(true);
                                }}
                                size="large"
                                color="error"
                            >
                                <Delete />
                            </IconButton>
                        </Box>
                    ) : null}
                    {isUpdateOrCreate ? (
                        <Box display="flex">
                            <Button color="secondary" startIcon={<Close />} onClick={() => navigate(-1)}>
                                Cancelar
                            </Button>
                            <Button type="submit" form={entity} startIcon={<Save />} sx={{ marginLeft: 2 }}>
                                Gravar
                            </Button>
                            {deleteOnUpdate && (
                                <IconButton
                                    onClick={() => {
                                        alerts.setIsOpen(true);
                                    }}
                                    size="large"
                                    color="error"
                                >
                                    <Delete />
                                </IconButton>
                            )}
                        </Box>
                    ) : null}
                </Grid>
            </Grid>
        </Paper>
    );
};
