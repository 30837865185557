import { useEffect, useState } from "react";
import { useRelatedRecords } from "../../api/useRelatedRecords";
import { RecordContext } from "../../components/record/Record";
import { useContext } from "react";
import { DataGrid, GridRowModel } from "@mui/x-data-grid";
import { useColumns } from "./columns";
import { useCalculateOrder } from "./utils";
import { OrdersFooter } from "./OrdersFooter";
import { convertApiItemsToGridRows } from "./utils";
import { Typography, Box } from "@mui/material";

type OrdersDetailProps = {
    id?: number | string;
};

export const OrdersDetail = ({ id }: OrdersDetailProps) => {
    const recordContext = useContext(RecordContext);
    const { data } = useRelatedRecords(
        "order_items",
        "*.*,service_variant.service.*.*,service_variant.agreement.*",
        "order_id",
        id || recordContext?.item.id
    );
    const [rows, setRows] = useState<GridRowModel[] | []>([]);
    const columns = useColumns({
        rows: rows,
        type: "detail",
    });

    const calculateOrderTotal = useCalculateOrder();

    useEffect(() => {
        const records = data?.items;

        if (records && records.length > 0) {
            const items = convertApiItemsToGridRows(records);
            setRows(items);
        }
    }, [data]);

    const orderTotal = calculateOrderTotal(rows);

    if (!data) return null;
    if (!recordContext) return null;

    return (
        <Box mt={1} ml={0.5} p={2} flexDirection="column" height={500} width="100%" sx={{ backgroundColor: "#fff" }}>
            <Box pt={6} pb={6}>
                <Typography variant="h5">Serviços Adquiridos:</Typography>
            </Box>
            <Box flexDirection="column" display="grid">
                {rows.length > 0 && (
                    <DataGrid
                        autoHeight
                        sx={{ width: "100%" }}
                        rows={rows}
                        columns={columns}
                        slots={{
                            footer: OrdersFooter,
                        }}
                        slotProps={{
                            footer: { orderTotal, recordContext, type: "detail" },
                        }}
                    />
                )}
            </Box>
        </Box>
    );
};
