import { Box, Typography } from "@mui/material";
import { formatCurrency } from "../../../utils/formatCurrency";
import { useContext } from "react";
import { StaffWorkLogsContext } from "../StaffWorkLogsDetail";

export const ClientHoursSummary = () => {
    const {
        totals: { totalClientHours },
        staffAgreement: { value_per_client },
    } = useContext(StaffWorkLogsContext);
    const totalValue = value_per_client ? totalClientHours * value_per_client : null;

    return (
        <Box textAlign="left">
            <Typography variant="h6" color="primary">
                Horas Clientes
            </Typography>
            <Typography>Total horas clientes: {totalClientHours.toFixed(2)}</Typography>
            {totalValue && <Typography>Valor a receber: {formatCurrency(totalValue)}</Typography>}
        </Box>
    );
};
