type CreateRoute = {
    entity: string;
    limit: number;
    page: number;
    sort: string;
    customFilters: any;
};

export const createRoute = (options: CreateRoute) => {
    const { entity, limit, page, sort, customFilters } = options;
    const queryParams = `limit=${limit}&sort=${sort}&page=${page}&meta=*`;

    const systemEntities = ["directus_users"];
    if (systemEntities.includes(entity)) {
        return `/${entity.replace("directus_", "")}`;
    } else if (customFilters) {
        return `/records-custom-filter/${entity}?${queryParams}`;
    } else {
        return `/items/${entity}?${queryParams}`;
    }
};
