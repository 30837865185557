import { CalendarMonth, Schedule, Settings, SupportAgent } from "@mui/icons-material";
import { MenuItem } from "./MenuItems";

export const settingsMenuItems: MenuItem[] = [
    {
        name: "Templates",
        icon: <Settings />,
        link: "/templates_content",
        collection: "templates_content",
    },
    {
        name: "Operadores Telefone",
        icon: <SupportAgent />,
        link: "/call_center_licenses",
        collection: "call_center_licenses",
    },
    {
        name: "Disponibilidades Equipa",
        icon: <CalendarMonth />,
        link: "/schedules",
        collection: "schedules",
    },
    {
        name: "Registos de horas",
        icon: <Schedule />,
        link: "/staff-work-logs",
        collection: "staff-work-logs",
    },
];
