import { Record } from "../../components/record/Record";
import { SessionHeader } from "./components/SessionHeader";
import { tabs } from "./sessionTabs";
import { EntityForm } from "../../components/entityForm/EntityForm";
import { Groups } from "../../components/groups/Groups";
import { DetailTabs } from "../../components/detailTabs/DetailTabs";
import { Sessions } from "./Sessions";
import { sessionsOptions } from "./sessionsOptions";
import { AvailabilityFinder } from "../../components/availability-finder/AvailabilityFinder";


export const fieldsQuery = "*.*,client.user_id.*,technician.user_id.*,service.*,treatment.client.*, treatment.client.user_id.*";

export const sessionRoutes = [
    {
        path: "/sessions",
        element: <Sessions entity="sessions" fieldsQuery={fieldsQuery} options={sessionsOptions} />,
    },
    {
        path: "/sessions/:id",
        element: (
            <Record
                entity="sessions"
                fieldsQuery={fieldsQuery}
                type="detail"
                header={<SessionHeader />}
                body={<DetailTabs tabs={tabs} />}
            />
        ),
        children: [
            {
                path: "/sessions/:id/detail",
                element: <Groups groupsOptions={sessionsOptions} />,
            },
        ],
    },
    {
        path: "/sessions/:id/update",
        element: (
            <Record
                entity="sessions"
                fieldsQuery={fieldsQuery}
                type="update"
                header={<SessionHeader />}
                body={<EntityForm groupsOptions={sessionsOptions} />}
            />
        ),
    },
    {
        path: "/sessions/create",
        element: (
            <Record
                entity="sessions"
                fieldsQuery={fieldsQuery}
                type="create"
                body={<EntityForm groupsOptions={sessionsOptions} />}
            />
        ),
    },
    {
        path: "/sessions/find-availabilities",
        element: <AvailabilityFinder />,
    },
];
