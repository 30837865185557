const commonOptions = [
    {
        label: "Está vazio",
        value: "_empty",
    },
    {
        label: "Não está vazio",
        value: "_nempty",
    },
];

export const typeOfFilters = [
    {
        type: ["string", "text", "input", "email", "inputmultiline"],
        options: [
            {
                label: "Igual a",
                value: "_eq",
            },
            {
                label: "Diferente de",
                value: "_neq",
            },
            {
                label: "Contêm",
                value: "_icontains",
            },
            {
                label: "Não Contêm",
                value: "_incontains",
            },
            {
                label: "Começa com",
                value: "_starts_with",
            },
            {
                label: "Não começa com",
                value: "_nstarts_with",
            },
            {
                label: "Termina com",
                value: "_ends_with",
            },
            {
                label: "Não termina com",
                value: "_nends_with",
            },
            ...commonOptions,
        ],
    },
    {
        type: ["integer", "integer", "float", "decimal"],
        options: [
            {
                label: "Maior que",
                value: "_gt",
            },
            {
                label: "Maior ou igual que",
                value: "_gte",
            },
            {
                label: "Menor que",
                value: "_lt",
            },
            {
                label: "Menor ou igual que",
                value: "_lte",
            },
            {
                label: "Igual a",
                value: "_eq",
            },
            {
                label: "Diferente de",
                value: "_neq",
            },
            {
                label: "Está vazio",
                value: "_null",
            },
            {
                label: "Não está vazio",
                value: "_nnull",
            },
        ],
    },
    {
        type: ["date", "datetime", "timestamp"],
        options: [
            {
                label: "Maior que",
                value: "_gt",
            },
            {
                label: "Maior ou igual que",
                value: "_gte",
            },
            {
                label: "Menor que",
                value: "_lt",
            },
            {
                label: "Menor ou igual que",
                value: "_lte",
            },
            {
                label: "Igual a",
                value: "_eq",
            },
            {
                label: "Diferente de",
                value: "_neq",
            },
            {
                label: "Está vazio",
                value: "_null",
            },
            {
                label: "Não está vazio",
                value: "_nnull",
            },
            {
                label: "Hoje",
                value: "_eq",
            },
        ],
    },
    {
        type: ["boolean", "selectdropdownm2o", "selectdropdown"],
        options: [
            {
                label: "Igual a",
                value: "_eq",
            },
            {
                label: "Diferente de",
                value: "_neq",
            },
            {
                label: "Está vazio",
                value: "_null",
            },
            {
                label: "Não está vazio",
                value: "_nnull",
            },
        ],
    },
    {
        type: ["selectmultipledropdown", "listm2m"],
        options: [
            {
                label: "É um dos",
                value: "_in",
            },
            {
                label: "Não é um dos",
                value: "_nin",
            },
        ],
    },
    {
        type: ["treatment_provided"],
        options: [
            {
                label: "Igual a",
                value: "_eq",
            },
        ],
    },
];
