import { Typography, Box } from "@mui/material";
import { useContext } from "react";
import { StaffWorkLogsContext } from "../StaffWorkLogsDetail";

export const TimeOffSummary = () => {
    const { totals: {totalHolidays}, thisYearHolidays } = useContext(StaffWorkLogsContext);
    return (
        <Box textAlign="left">
            <Typography color="primary" variant="h6">
                Férias
            </Typography>
            {totalHolidays > 0 && <Typography>Mês: {totalHolidays} dias</Typography>}
            {thisYearHolidays > 0 && <Typography>Ano: {thisYearHolidays} dias</Typography>}
        </Box>
    );
};
