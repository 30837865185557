import { Typography } from "@mui/material";
import { format } from "date-fns";
import { FieldView } from "../../../types/fields.types";

type DateFieldProps = {
    value: string;
    fieldView: FieldView;
};

export function DateField({ value, fieldView }: DateFieldProps) {
    if (!value) return null;

    const dateValue = fieldView === "grid" && value ? value : format(new Date(value), "dd/MM/yyyy");

    return <Typography variant="body1">{dateValue}</Typography>;
}
