import { format } from "date-fns";
import { FormFields } from "../components/entityForm/EntityForm";
import { FormatedFields } from "../types/fields.types";
import _ from "lodash";

export const sanitizeData = (data: FormFields, fields: FormatedFields) => {
    // Extract field names that have a type of 'integer'
    const integerFields = _.map(
        _.filter(fields, (field) => field.settings.type === "integer"),
        "field"
    );

    // Extract field names that have a type of 'date', 'datetime', or 'timestamp'
    const dateFields = _.map(
        _.filter(fields, (field) => ["date"].includes(field.settings.type)),
        "field"
    );

    Object.keys(data).forEach((key) => {
        // Check if the key exists in the integerFields list and if its value is an empty string
        if (_.includes(integerFields, key) && data[key] === "") {
            data[key] = null;
        }

        // Check if the key exists in the dateFields list and if its value is a Date object
        if (_.includes(dateFields, key) && data[key] instanceof Date) {
            data[key] = format(data[key], "yyyy-MM-dd HH:mm:ss");
        }

        if (data[key] !== null && typeof data[key] === "object" && data[key].constructor === Object) {
            if (key.includes("multiple")) {
                const mainKey = key.replace("multiple_", "");
                data[mainKey] = data[key];
            } else {
                data[key] = data[key].id;
            }
        }
    });

    return data;
};
