import { DetailHeader } from "../../components/detailHeader/DetailHeader";
import { Box, CircularProgress, Typography } from "@mui/material";
import { RecordContext } from "../../components/record/Record";
import { useContext } from "react";
import { Actions } from "../../components/actionsMenu/ActionsMenu";
import { ClientSessionsSummary } from "./ClientSessionsSummary";
import { useClientSessionsSummary } from "../../api/useClientSessionsSummary";


type ClientHeaderProps = {
    actions?: Actions;
};

export const ClientHeader = ({ actions }: ClientHeaderProps) => {
    const recordContext = useContext(RecordContext);
    const { data: sessions, status } = useClientSessionsSummary(recordContext?.item?.id);

    if (!recordContext) return null;
    const { user_first_name, user_last_name, client_id } = recordContext.item;

    return (
        <DetailHeader actions={actions} collection={recordContext.collection}>
            <Box minWidth={300} display="flex" columnGap={10}>
                <Box>
                    <Typography variant="h6" color="secondary">
                        Cliente
                    </Typography>
                    <Typography variant="h5">{client_id}</Typography>
                    <Typography variant="body1">
                        {user_first_name} {user_last_name}
                    </Typography>
                </Box>
                {status === "loading" ? <CircularProgress /> : null}
                {sessions && <ClientSessionsSummary sessions={sessions} options={{ flexDirection: "row" }} />}
            </Box>
        </DetailHeader>
    );
};
