import { Info, Notes } from "@mui/icons-material";

export const tabs = [
	{
		icon: <Info />,
		entity: "detail",
		label: "Detalhes",
	},
	{
		icon: <Notes />,
		entity: "notes",
		label: "Notas",
	},
];
