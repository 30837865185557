import { FormatedField } from "../types/fields.types";
import { AddTemplateTypes, addTemplate } from "./addTemplate";

export const handleTypesOfRelatedFields = (
	field: FormatedField,
	value: any,
	type?: AddTemplateTypes,
	entity?: string
) => {
	let fieldValues = [];

	if (field.settings.type === "manytoany") {
		if (type === "autoCompleteOptions") {
			const templates = field.settings?.template_many_to_any;
			const templatesWithoutItem = templates?.map((template) => {
				template.templatesWithoutItem = template.template.replaceAll("item.", "");
				return template;
			});
			fieldValues = [...addTemplate(value, templatesWithoutItem, type, entity)];
		} else {
			const many_to_any_templates = field.settings?.template_many_to_any;
			fieldValues = [...addTemplate(value, many_to_any_templates, type, entity)];

		}
	} else {
		fieldValues = [...value];
	}


	return fieldValues;
};
