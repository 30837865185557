import { Calendar as ReactBigCalendar, View } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "../../../components/calendar/overrides.css";
import { ScheduleCustomEvent } from "./ScheduleCustomEvent";
import { Box } from "@mui/material";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import {
    handleCalendarDate,
    handleRanges,
    localizer,
    useEventDrop,
    useFormats,
    useHandleSelectSlot,
} from "../../../components/calendar/calendarUtils";
import { useNavigate } from "react-router-dom";
import { set, startOfDay } from "date-fns";
import { useStore } from "../../../store/store";
import { CustomEventType } from "../utils/types";

const minTime = set(new Date(), { hours: 6, minutes: 0, seconds: 0 });
const maxTime = set(new Date(), { hours: 23, minutes: 59, seconds: 59 });

type SchedulesCalendarProps = {
    refetch: () => void;
    filteredEvents: Array<CustomEventType>;
};

export const SchedulesCalendar = ({ refetch, filteredEvents }: SchedulesCalendarProps) => {
    const {
        schedules: { calendarFilters, setCalendarFilters },
    } = useStore();

    const handleSelectSlot = useHandleSelectSlot();
    const navigate = useNavigate();
    const { formats } = useFormats("both");

    const onEventChange = useEventDrop(refetch, "schedules");

    const DnDCalendar = withDragAndDrop(ReactBigCalendar);

    return (
        <Box style={{ height: "1000px" }}>
            <DnDCalendar
                localizer={localizer}
                culture="pt-PT"
                events={filteredEvents}
                date={calendarFilters?.dateRange && handleCalendarDate(calendarFilters.dateRange)}
                onNavigate={(date) => {}}
                // @ts-ignore
                startAccessor="start"
                // @ts-ignore
                endAccessor="end"
                defaultView="week"
                min={minTime}
                max={maxTime}
                timeslots={4}
                step={15}
                views={["week"]}
                selectable={true}
                onSelectSlot={(e) =>
                    handleSelectSlot({
                        start: e.start,
                        entity: "schedules",
                        defaultMinutes: 240,
                    })
                }
                onSelectEvent={(event: any) => {
                    const entity = event.type === "schedule" ? "schedules" : "time_off";
                    navigate(`/${entity}/${event.id}/update`);
                }}
                components={{
                    // @ts-ignore
                    event: ScheduleCustomEvent,
                }}
                eventPropGetter={(event: any) => {
                    return {
                        style: {
                            backgroundColor: event?.userColor,
                        },
                    };
                }}
                // @ts-ignore
                formats={formats}
                draggableAccessor={(event) => true}
                onEventDrop={onEventChange}
                resizable
                onEventResize={onEventChange}
                onRangeChange={(range: any, view: View | undefined) => {
                    const newFilters = {
                        ...calendarFilters,
                        dateRange: handleRanges(view === "day" ? [startOfDay(new Date())] : range),
                    };

                    setCalendarFilters(newFilters);
                }}
                dayLayoutAlgorithm="no-overlap"
            />
        </Box>
    );
};
