import { Font } from "@react-pdf/renderer";
import { StyleSheet } from "@react-pdf/renderer";

// Register fonts
Font.register({
    family: "Montserrat",
    fonts: [
        { src: "/fonts/Montserrat/static/Montserrat-Regular.ttf", fontWeight: "normal" },
        { src: "/fonts/Montserrat/static/Montserrat-Bold.ttf", fontWeight: "bold" },
    ],
});

export const styles = StyleSheet.create({
    page: {
        padding: 40,
        paddingBottom: 80,
        fontFamily: "Montserrat",
        textAlign: "justify",
    },
    header: {
        alignItems: "center",
        marginBottom: 50,
    },
    logo: {
        width: 250,
        alignSelf: "center",
    },
    titleContainer: {
        alignItems: "center",
        marginBottom: 50,
    },
    title: {
        fontSize: 18,
        fontFamily: "Montserrat",
        fontWeight: "bold",
        textAlign: "center",
    },
    secondPageTitle: {
        fontSize: 18,
        fontFamily: "Montserrat",
        fontWeight: "bold",
        textAlign: "left",
    },
    subtitle: {
        fontSize: 14,
        fontWeight: "bold",
        textAlign: "left",
        marginTop: 5,
    },
    tableContainer: {
        flexDirection: "column",
    },
    topSection: {
        marginBottom: 15,
    },
    otherFields: {
        marginTop: 10,
    },
    tableRow: {
        flexDirection: "row",
        marginBottom: 5,
    },
    tableLabel: {
        width: "40%",
        fontSize: 10,
        fontFamily: "Montserrat",
        fontWeight: "bold",
    },
    tableContent: {
        width: "60%",
        fontSize: 10,
    },

    column: {
        width: "48%",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    sectionTitle: {
        marginTop: 20,
        fontSize: 14,
        fontWeight: "bold",
        marginBottom: 5,
    },

    conectividadeSection: {
        marginTop: 10,
        marginBottom: 10,
    },
    sectionSubTitle: {
        fontSize: 12,
        fontWeight: 600,
        marginBottom: 3,
    },
    contentContainer: {
        marginBottom: 20,
    },
    text: {
        fontSize: 10,
        marginBottom: 5,
    },
    centeredText: {
        textAlign: "center",
    },
    footer: {
        position: "absolute",
        bottom: 30,
        left: 30,
        right: 30,
        textAlign: "center",
        fontSize: 10,
        width: 500,
        alignSelf: "center",
    },
    footerContent: {
        color: "grey",
    },
    footerBold: {
        fontFamily: "Montserrat",
        fontWeight: "bold",
    },
    brainImage: {
        alignSelf: "center",
    },
    signature: {
        width: 50,
        height: 50,
    },
    contentWrapper: {
        flexGrow: 1,
        flexShrink: 1,
        display: "flex",
        flexDirection: "column",
    },
    signatureContent: {
        alignSelf: "flex-end",
        alignItems: "center",
        position: "absolute",
        right: 30,
        bottom: 30,
    },
    professionalInfo: {
        fontSize: 10,
        textAlign: "center",
    },
    technicianName: {
        fontSize: 10,
    },
    noteSection: {
        marginTop: 20,
        marginBottom: 20,
    },
    noteText: {
        fontSize: 10,
    },
    separator: {
        marginBottom: 6,
        borderWidth: 0.5,
        borderColor: "#000",
        borderStyle: "solid",
        width: "150px",
    },
});
