import { useStore } from "../store/store";
import { Setting } from "../store/store.types";
import { useUser } from "./useAuth";
import { useCreateRecord } from "./useCreateRecord";
import { useUpdateRecord } from "./useUpdateRecord";

export const useSaveGridConfig = () => {
    const updateSetting = useUpdateRecord();
    const createSetting = useCreateRecord();
    const { data: user } = useUser();
    const {
        gridSettings: { gridColumns, entity, selectedSettings, setGridSettings },
        progressFilter,
        modalFilter,
        recordsFilter,
    } = useStore();

    const saveConfig = async (nameValue?: string, isNew?: boolean) => {
        const settingsName = nameValue || selectedSettings?.name;
        if (!entity) return;

        if (!settingsName) {
            alert("Nome da configuração é obrigatório");
            return;
        }

        const config = gridColumns.map((gridColumn) => {
            return {
                field: gridColumn.field,
                headerName: gridColumn.headerName,
                visible: gridColumn.visible,
                width: gridColumn.width,
            };
        });

        const { isActive, id } = selectedSettings ?? {};
        const settingsModalFilter = modalFilter;
        const settingsProgressFilter = progressFilter;
        const settingsRecordsFilter = recordsFilter;

        const settingsBody = {
            collection: entity,
            name: settingsName,
            isActive: isActive,
            config: {
                columns: config,
                filters: { modal: settingsModalFilter, progress: settingsProgressFilter, records: settingsRecordsFilter },
            },
            user: user?.id,
        };

        if (id && !isNew) {
            updateSetting.mutate({
                entity: "settings",
                id: id,
                data: settingsBody,
            });
        } else {
            const response = (await createSetting.mutateAsync({
                entity: "settings",
                data: settingsBody,
            })) as Setting;

            setGridSettings({ selectedSettings: response });
        }

        setGridSettings({ configColumnsModalIsOpen: false });
    };

    return saveConfig;
};
