import { Box, CircularProgress } from "@mui/material";
import { DataGrid } from "../../../components/datagrid/DataGrid";
import { Suspense } from "react";
import { SPECIAL_USERS, ROLES } from "../../../utils/constants";
import { useState } from "react";
import Calendar from "../../../components/calendar/Calendar";
import { ToggleBetweenCalendarAndList } from "./ToggleBetweenCalendarAndList";
import { useRecordsContext } from "../../../utils/useRecordsContext";
import { useUser } from "../../../api/useAuth";

type RelatedSessionsProps = {
    heightOfHeaders: number;
};

export const SessionsRelated = ({ heightOfHeaders }: RelatedSessionsProps) => {
    const { entity, items, paginationModel, refetch } = useRecordsContext("relatedRecords");
    const { data: user } = useUser();
    const isRecepcionistOrJoana = user?.role.id === ROLES.recepcionist || user?.id === SPECIAL_USERS.joana_director;

    const [calendarView, setCalendarView] = useState<"calendar" | "list">(
        entity === "sessions" && !isRecepcionistOrJoana ? "calendar" : "list"
    );
    return (
        <Box>
            <ToggleBetweenCalendarAndList setView={setCalendarView} style={{ justifyContent: "flex-start", mr: 4, mt: -16, mb: 4 }} />
            {calendarView === "calendar" && (
                <Suspense fallback={<CircularProgress />}>
                    {refetch && (
                        <Calendar
                            items={items}
                            isMobile={false}
                            refetch={refetch}
                            views={{ day: false, agenda: false, week: false, month: false, year: true }}
                            defaultView="year"
                            height={1800}
                        />
                    )}
                </Suspense>
            )}
            {calendarView === "list" && (
                <DataGrid paginationModel={paginationModel} heightOfHeaders={heightOfHeaders} type="relatedRecords" />
            )}
        </Box>
    );
};
