import { UseRecordsOptions } from "../../api/useRecords";
import { RecordContextType } from "../../components/record/Record";

export const relativesOptions = {
    fieldsToRemove: {
        fieldTypesToRemove: ["alias"],
    },
    createRelatedParams: (params: RecordContextType | undefined) => createRelativesParams(params),
    groupsOptions: {
        fieldsWithExtraOptions: [],
    },
} as UseRecordsOptions;

const createRelativesParams = async (recordContext: RecordContextType | undefined) => {
    if (!recordContext?.item) return;
    if (recordContext.entity === "clients") return `[clients][id]=${recordContext.item.id}`;
    if (recordContext.entity === "leads") return `[leads][id]=${recordContext.item.id}`;
};
