import { useQueryClient } from "@tanstack/react-query";
import { useStore } from "../store/store";
import { SubscriptionData } from "../api/useWebSockets";
import { createConnectionUrl } from "../components/phoneLetsCall/PhoneWidget";
import { useUser } from "../api/useAuth";

export const useUpdatePhoneClient = () => {
    const queryClient = useQueryClient();

    const { data: user } = useUser();
    const { phoneClient } = useStore();

    const updatePhoneClient = (data: SubscriptionData) => {
        const { event, uid, data: licenses } = data;
        if (event === "update" && uid === "call_center_licenses") {
            const connectionUrl = user && createConnectionUrl(licenses, user);
            phoneClient.setUrl(connectionUrl || "");
            queryClient.invalidateQueries({
                predicate(query: any) {
                    return query.queryKey[0].includes("call_center_licenses-records");
                },
            });
        }
    };

    return updatePhoneClient;
};
