import { useEffect, useState } from "react";
import { DataGrid as MuiDataGrid, GridColDef, GridSortModel } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { GridSettings } from "../configColumns/GridSettings";
import { useSettings } from "../../api/useSettings";
import { useStore } from "../../store/store";
import { useRecordsContext } from "../../utils/useRecordsContext";
import { checkVisibility, saveScrollPosition } from "./utils";
import { useGoToScrollPosition } from "./useGoToScrollPosition";
import { QuickSearchToolbar } from "../DataGridQuickSearchToolBar/QuickSearchToolBar";

export type DataGridOptions = {
    extraColumns?: {
        beggining?: GridColDef[];
        end?: GridColDef[];
    };
    quickSearchToolBar?: {
        active: boolean;
        inputLabel: string;
        fieldsToSearch: string[];
    };
    fieldsWithExtraOptions?: any;
};

type DataGridProps = {
    filter?: any;
    fieldsWithExtraOptions?: any;
    paginationModel?: { page: number; pageSize: number };
    heightOfHeaders: number;
    type: "records" | "relatedRecords";
    options?: DataGridOptions;
};

export type GridConfig = Array<{
    field: string;
    headerName: string;
    visible: boolean;
    width: number;
}>;

export function DataGrid({ paginationModel, type, heightOfHeaders, options }: DataGridProps) {
    const navigate = useNavigate();

    const { entity, items, metadata } = useRecordsContext(type);
    const [rowCountState, setRowCountState] = useState(metadata?.filter_count || 0);
    const { gridSettings, setRecordsSort, recordsSort } = useStore();

    useSettings(entity, type);
    const { apiRef } = useGoToScrollPosition(entity);

    useEffect(() => {
        setRowCountState((prevRowCountState: number) =>
            metadata?.filter_count !== undefined ? metadata?.filter_count : prevRowCountState
        );
    }, [metadata?.filter_count, setRowCountState]);

    const handleSortModelChange = (sortModel: GridSortModel) => {
        if (!entity) return;
        if (sortModel.length === 0) return;

        const field = sortModel[0].field;
        const sortType = sortModel[0].sort;
        if (!field || !sortType) return;

        setRecordsSort({
            ...recordsSort,
            [entity]: {
                sort: sortType === "asc" ? field : `-${field}`,
            },
        });
    };


    return (
        <Box
            flexDirection="column"
            height={`calc(100vh - ${heightOfHeaders}px)`}
            display="grid"
            flexGrow={1}
            sx={{ backgroundColor: "#fff" }}
        >
            <MuiDataGrid
                apiRef={apiRef}
                paginationMode="server"
                rowCount={rowCountState}
                rows={items || []}
                paginationModel={paginationModel}
                pageSizeOptions={[paginationModel?.pageSize || 100]}
                columns={gridSettings.gridColumns}
                onPaginationModelChange={(paginationModel) => gridSettings.setGridSettings({ paginationModel: paginationModel })}
                onCellClick={(params) => {
                    saveScrollPosition(gridSettings, apiRef, params, entity);
                }}
                onRowClick={(params) => {
                    navigate(`/${entity}/${params.id}/detail`);
                }}
                columnVisibilityModel={checkVisibility(gridSettings.gridColumns)}
                disableColumnFilter
                sortingMode="server"
                onSortModelChange={handleSortModelChange}
                slots={{ toolbar: options?.quickSearchToolBar?.active ? QuickSearchToolbar : null }}
                slotProps={{
                    toolbar: {
                        inputLabel: options?.quickSearchToolBar?.inputLabel,
                        fieldsToSearch: options?.quickSearchToolBar?.fieldsToSearch,
                        gridSettings: gridSettings,
                        entity: entity,
                    },
                }}
            />
            <GridSettings />
        </Box>
    );
}
