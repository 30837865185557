import { FormatedField } from "../types/fields.types";
import { isCustomizedField } from "../utils/isCustomizedField";

export const setFieldTypeDetail = (field: FormatedField): string => {
    if (isCustomizedField(field)) return field.field;


    if (field.settings.special && (field.settings.special.includes("files") || field.settings.special.includes("file")))
        return "files";
    if (field.settings.interface === "input-rich-text-html") return "inputrichtexthtml";
    return field.settings.display?.replaceAll("-", "") || field.settings.type;
};

export const setFieldTypeForm = (field: FormatedField): string => {
    const fieldType = field.settings.interface?.replaceAll("-", "") || field.settings.type;

    if (isCustomizedField(field)) {
        return field.field;
    } else {
        return fieldType;
    }
};
