import { Box, Button, IconButton, Menu, MenuItem, Switch, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useStore } from "../../store/store";
import { MoreVertTwoTone, Save } from "@mui/icons-material";
import { useDeleteRecord } from "../../api/useDeleteRecord";
import { useSaveGridConfig } from "../../api/useSaveGridConfig";

export const SaveAndUpdateSettings = () => {
    const { gridSettings } = useStore();
    const [nameValue, setNameValue] = useState<string | undefined>();
    const [inputIsActive, setInputIsActive] = useState<boolean>();
    const saveConfig = useSaveGridConfig();

    const deleteSetting = useDeleteRecord();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box sx={{ ml: 2 }}>
            <IconButton id="long-button" onClick={handleClick}>
                <MoreVertTwoTone />
            </IconButton>
            <Menu id="long-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography>Marcar como default</Typography>
                        <Switch
                            checked={gridSettings?.selectedSettings?.isActive || false}
                            onChange={() =>
                                gridSettings.selectedSettings &&
                                gridSettings.setGridSettings({
                                    selectedSettings: {
                                        ...gridSettings.selectedSettings,
                                        isActive: !gridSettings.selectedSettings?.isActive,
                                    },
                                })
                            }
                        />
                    </Box>
                </MenuItem>
                <MenuItem disabled={gridSettings?.selectedSettings?.isSystem} onClick={() => saveConfig()}>
                    Guardar
                </MenuItem>

                <Box>
                    <Button
                        variant="text"
                        color="inherit"
                        sx={{ pl: 4, mb: 1, fontSize: 16 }}
                        onClick={() => {
                            setInputIsActive(!inputIsActive);
                        }}
                    >
                        Guardar como novo
                    </Button>
                    {inputIsActive && (
                        <Box sx={{ display: "flex", alignItems: "center", pl: 4, pb: 2, pr: 4, gap: 2 }}>
                            <TextField
                                variant="outlined"
                                size="small"
                                label="Nome da configuração"
                                value={nameValue || ""}
                                onKeyDown={(e) => e.stopPropagation()}
                                onChange={(e) => {
                                    setNameValue(e.target.value);
                                }}
                            />
                            <IconButton
                                color="primary"
                                onClick={() => {
                                    saveConfig(nameValue, true);
                                    setInputIsActive(false);
                                }}
                            >
                                <Save fontSize="medium" />
                            </IconButton>
                        </Box>
                    )}
                    <MenuItem
                        disabled={gridSettings?.selectedSettings?.isSystem}
                        onClick={() => {
                            gridSettings.selectedSettings &&
                                deleteSetting.mutate({
                                    entity: "settings",
                                    id: gridSettings?.selectedSettings?.id,
                                });
                        }}
                    >
                        Apagar
                    </MenuItem>
                </Box>
            </Menu>
        </Box>
    );
};
