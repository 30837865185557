import { useState, useContext } from "react";
import { Button, Menu, MenuItem, Box, CircularProgress } from "@mui/material";
import { RecordContext } from "../../components/record/Record";
import { useStore } from "../../store/store";
import { useUser } from "../../api/useAuth";

export type Action = {
    code: string;
    name: string;
    actionFn: Function;
};

export type Actions = Array<Action> | undefined;

export type ActionsMenuProps = {
    actions: Actions;
};

export const ActionsMenu = ({ actions }: ActionsMenuProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const recordContext = useContext(RecordContext);
    const [isLoading, setIsLoading] = useState(false);
    const { data: user } = useUser();
    const { notifications } = useStore();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const executeAction = async (action: any) => {
        setAnchorEl(null);
        setIsLoading(true);
        try {
            const result = await action.actionFn(recordContext, action.code, user);
            result &&
                result.showSuccessMessage !== "no" &&
                notifications.setNotification({
                    isOpen: true,
                    message: `Ação ${action.name} executada com sucesso`,
                    severity: "success",
                });
        } catch (error: any) {
            notifications.setNotification({
                isOpen: true,
                message: error.message,
                severity: "error",
            });
        }
        setIsLoading(false);
    };

    return (
        <Box display="flex" sx={{ mr: 2 }}>
            <Button
                onClick={handleClick}
                color="secondary"
                size="large"
                endIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : false}
                disabled={isLoading}
                fullWidth
            >
                Ações
            </Button>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                {actions &&
                    actions.map((action) => {
                        return (
                            <MenuItem key={action.code} onClick={() => executeAction(action)}>
                                {action.name}
                            </MenuItem>
                        );
                    })}
            </Menu>
        </Box>
    );
};
