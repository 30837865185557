import { useField } from '../api/useField';

interface Choice {
  text: string;
  value: string;
}

export function useFieldSelectOptions(entity: string, field: string): Choice[] {
  const { formatedField } = useField(entity, field);
  
  return formatedField?.settings?.options?.choices || [];
}
