import React from "react";
import * as Fields from "./fields/index";
import { FieldExtraOption, FieldView, FormatedField } from "../../types/fields.types";
import { setFieldTypeForm } from "../../api/setFieldType";
import { Typography } from "@mui/material";
import { setFieldValueAcordingToView } from "../../api/setFieldValueAcordingToView";
import { UseFormReturn } from "react-hook-form";

export type FieldOptions = {
    disabled: boolean;
    error: boolean;
    helperText: string;
    variant: "filled" | "outlined" | "standard";
    required: boolean;
};

export type FieldProps = {
    key?: string;
    field: FormatedField;
    value: any;
    fieldView?: FieldView;
    entityForm: UseFormReturn;
    options?: FieldOptions;
    extraOptions?: any;
};

export const renderFieldInput = (
    field: FormatedField,
    value: any,
    fieldView: FieldView,
    entityForm: UseFormReturn,
    extraOptions?: FieldExtraOption | null
) => {
    const fieldType = setFieldTypeForm(field);

    const Field: React.FC<FieldProps> = Fields[fieldType as keyof typeof Fields];

    if (!Field) {
        return <Typography> {field.field} field not configurated yet</Typography>;
    }

    const fieldValue = setFieldValueAcordingToView(field, value, fieldView);

    const fieldError = {
        error: entityForm.formState.errors[field.field] ? true : false,
        helperText: (entityForm.formState.errors[field.field]?.message as string) || "",
    };

    if (extraOptions?.hidden?.create || extraOptions?.hidden?.update) return null;

    return (
        <Field
            key={field.field}
            field={field}
            value={fieldValue}
            fieldView={fieldView}
            entityForm={entityForm}
            options={{
                disabled: field.settings.readonly,
                ...fieldError,
                variant: "outlined",
                required: field.settings.required,
            }}
            extraOptions={extraOptions}
        />
    );
};
