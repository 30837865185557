import { Box, CircularProgress, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { TemplateContent } from "../../types/records.types";
import { UseFormReturn } from "react-hook-form";
import { useState } from "react";
import { useRecords } from "../../api/useRecords";

type SelectTemplateProps = {
    entityForm: UseFormReturn;
    field: string;
    entity: string;
};

const ITEM_HEIGHT = 90;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export const SelectTemplate = ({ entityForm, entity, field }: SelectTemplateProps) => {
    const [template, setTemplate] = useState<TemplateContent>();

    const { data, status } = useRecords("templates_content", "*.*", {
        extraOptions: {
            filter: {
                _and: [
                    { field_with_template: { collection: { _eq: entity } } },
                    { field_with_template: { field: { _eq: field } } },
                ],
            },
            sort: "position",
        },
    });

    if (status === "loading")
        return (
            <Box sx={{ width: "100%" }}>
                <CircularProgress />
            </Box>
        );

    if (status === "error") return <div>Error</div>;

    const templates = data.items as Array<TemplateContent>;
    if (templates.length === 0) return null;

    return (
        <Box sx={{ display: "flex", gap: 4, width: "100%" }}>
            <FormControl fullWidth>
                <InputLabel id="feedback-title">Escolha o template...</InputLabel>
                <Select
                    labelId="feedback-title"
                    label="Escolha o template..."
                    value={template?.id || ""}
                    MenuProps={MenuProps}
                    onChange={(e) => {
                        const newTemplate = templates.find((template) => template.id === e.target.value);
                        setTemplate(newTemplate);
                        entityForm?.setValue(field, newTemplate?.content);
                    }}
                >
                    {templates.map((option: TemplateContent) => (
                        <MenuItem key={option.id} value={option.id}>
                            {option.title}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
};
