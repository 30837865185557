import { createTheme, Theme } from "@mui/material/styles";

export const mainColor = "#44749a";
export const secondaryColor = "#df6449";

export const theme: Theme = createTheme({
    palette: {
        mode: "light",
        primary: {
            main: mainColor, // adjust as needed
        },
        secondary: {
            main: secondaryColor, // adjust as needed
        },
        background: {
            default: "#f5f5f5",
            paper: "#fff",
        },
        text: {
            primary: "#404040",
            secondary: "#666",
        },
    },
    typography: {
        fontFamily:
            'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    },

    components: {
        MuiButton: {
            defaultProps: {
                variant: "contained",
                size: "large",
            },
            styleOverrides: {
                root: {
                    textTransform: "none",
                    borderRadius: 10,
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    backgroundColor: "#fff",
                },
            },
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: mainColor, // your desired color here
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: "#fff",
                },
            },
            defaultProps: {
                elevation: 1,
            },
        },
        MuiChip: {
            styleOverrides: {
                label: {
                    fontSize: "0.9rem",
                },
            },
        },
    },
    spacing: 4, // you can adjust this as needed
});
