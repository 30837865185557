import { Wysiwyg } from "../wysiwyg/Wysiwyg";
import { Unstable_Grid2 as Grid, Typography } from "@mui/material";
import { RecordContextType } from "../record/Record";
import { renderFieldView } from "../fieldsViews/renderFieldView";
import { findField } from "../../utils/findField";
import { TaskComments } from "./TasksComments";
import { TaskDoneShortcut } from "./TaskDoneShortcut";

type TasksDetailProps = {
    context: RecordContextType;
};

export const TasksDetail = ({ context }: TasksDetailProps) => {
    const dueDate = findField(context.formatedFields, "due_date");
    const relatedWith = findField(context.formatedFields, "related_with");
    const assignedUsers = findField(context.formatedFields, "assigned_users");
    const contentField = findField(context.formatedFields, "content");
    const serviceField = findField(context.formatedFields, "service");
    const service_alert_number_field = findField(context.formatedFields, "service_alert_number");

    return (
        <Grid container spacing={2} mt={2} justifyContent="space-between" alignItems="flex-start">
            <Grid xs={12} lg={8}>
                <Wysiwyg
                    entityForm={context.entityForm}
                    initialValue={context.item.content}
                    isDisabled={true}
                    field={contentField}
                />
            </Grid>
            <Grid xs={12} lg={4} container spacing={5} pl={5}>
                <Grid xs={12} display="flex" alignItems="center">
                    <Typography variant="h6" color="primary">
                        Concluída:
                    </Typography>
                    <TaskDoneShortcut id={context.item.id} status={context.item.status} />
                </Grid>
                <Grid xs={12}>
                    <Typography variant="subtitle1" color="primary">
                        Deadline:
                    </Typography>
                    {dueDate && renderFieldView(dueDate, context.item, "group")}
                </Grid>
                <Grid xs={12} justifyContent="center">
                    <Typography variant="subtitle1" color="primary">
                        Atribuido:
                    </Typography>
                    {assignedUsers && renderFieldView(assignedUsers, context.item, "group")}
                </Grid>

                <Grid xs={12}>
                    <Typography variant="subtitle1" color="primary">
                        Associado:
                    </Typography>
                    {relatedWith && renderFieldView(relatedWith, context.item, "group")}
                </Grid>
                {context.item.alert && (
                    <Grid xs={12}>
                        <Typography variant="subtitle1" color="primary">
                            Alerta por nº de sessões:
                        </Typography>
                        <Grid xs={12} container spacing={2}>
                            <Grid display="flex" alignItems="top">
                                {serviceField && renderFieldView(serviceField, context.item, "group")}:
                            </Grid>
                            <Grid display="flex" alignItems="top">
                                {service_alert_number_field && renderFieldView(service_alert_number_field, context.item, "group")}
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>

            <Grid xs={12}>
                <TaskComments comments={context.item.comments} context={context} />
            </Grid>
        </Grid>
    );
};
