import { useContext } from "react";
import { RecordsHeader } from "../../components/recordsHeader/RecordsHeader";
import { RecordsContext } from "../../components/records/Records";
import { Box } from "@mui/material";
import { SelectOperatorOnCall } from "../../components/phoneLetsCall/SelectOperatorOnCall";

export const CallRoutesListHeader = () => {
    const recordsContext = useContext(RecordsContext);
    if (!recordsContext) return null;

    return (
        <Box>
            <RecordsHeader
                collection={recordsContext?.collection}
                hideCreateButton={true}
                optionalComponents={{ leftOfButtons: <SelectOperatorOnCall /> }}
            />
        </Box>
    );
};
