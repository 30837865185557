import { Schedule, Check, HourglassEmpty, Psychology, HowToReg, Cancel } from "@mui/icons-material";
import { Box, SxProps, Typography } from "@mui/material";
import { Avatar } from "../fieldsViews/fields/Avatar";

interface CustomEventProps {
    event: {
        title: string;
        technicianName: string;
        technicianAvatar: string;
        status?: string;
    };
}

export const CustomEventWeek = ({ event }: CustomEventProps) => {

    return (
        <Box display="flex" alignItems="flex-start" width="100%" sx={{ position: "relative" }}>
            <Typography variant="caption">{event.title}</Typography>
            <Typography variant="caption" sx={{ ml: 1 }}>
                {event.technicianName}
            </Typography>
            {event.status && (
                <Status status={event.status} styles={{ width: 15, height: 15, position: "absolute", top: -15, left: 30 }} />
            )}
            {event.technicianAvatar && <Avatar value={event.technicianAvatar} styles={{ ml: 1, width: 25, height: 25 }} />}
        </Box>
    );
};

export const CustomEventDay = ({ event }: CustomEventProps) => {
    return (
        <Box display="flex" alignItems="flex-start" width="100%" sx={{ position: "relative" }}>
            <Typography variant="caption">{event.title}</Typography>
            <Status status={event.status} styles={{ width: 15, height: 15, position: "absolute", top: -15, left: 30 }} />
            {event.technicianAvatar && (
                <Avatar value={event.technicianAvatar} styles={{ ml: 5, mt: -3, width: 30, height: 30 }} />
            )}
        </Box>
    );
};

export const CustomEventAgenda = ({ event }: CustomEventProps) => {
    return (
        <Box display="flex" alignItems="center" width="100%">
            <Typography variant="caption">{event.title}</Typography>
            <Status status={event.status} styles={{ width: 15, height: 15, ml: 1 }} />
            {event.technicianAvatar && <Avatar value={event.technicianAvatar} styles={{ ml: "auto", width: 30, height: 30 }} />}
        </Box>
    );
};

const Status = ({ status, styles }: { status?: string; styles: SxProps }) => {
    switch (status) {
        case "booked":
            return <Schedule sx={styles} />;
        case "confirmed":
            return <Check sx={styles} />;
        case "awaiting_in_clinic":
            return <HourglassEmpty sx={styles} />;
        case "in_session":
            return <Psychology sx={styles} />;
        case "done":
            return <HowToReg sx={styles} />;
        case "canceled":
            return <Cancel sx={styles} />;
        case "missed":
            return <Cancel sx={styles} />;
        default:
            return null;
    }
};
