import { useQuery, useQueryClient } from "@tanstack/react-query";
import { parseISO, addDays } from "date-fns";
import { getRecords } from "./useRecords";
import { some } from "lodash";
import { Collection } from "../types/collections.types";

export type SessionsSummary = {
    nfbBought: number;
    nfbDone: number;
    psyBought: number;
    psyDone: number;
    nfbNext7Days: number;
    psyNext7Days: number;
    evaluationsBought: number;
    evaluationsDone: number;
} | null;

export const useClientSessionsSummary = (parentId: string | number = "", treatmentId?: string | number) => {
    const queryClient = useQueryClient();

    const { collections } = queryClient.getQueryData(["collections"]) as { collections: Collection[] };
    const ordersExistInCollection = some(collections, (collection: Collection) => collection.collection === "order_items");

    return useQuery({
        queryKey: [`client-sessions-summary-${parentId}`],
        queryFn: () => getClientSessionSummary(parentId, ordersExistInCollection, treatmentId),
    });
};

const getClientSessionSummary = async (
    parentId: string | number = "",
    ordersExistInCollection: boolean,
    treatmentId?: string | number
): Promise<SessionsSummary | undefined> => {
    if (!parentId) return null;

    const sessionsFilter = createSessionsFilter(parentId, treatmentId);

    const { items: sessions } = await getRecords("sessions", "*.*", {
        extraOptions: {
            filter: sessionsFilter,
        },
    });

    let orderItems;

    if (ordersExistInCollection) {
        const orderItemsFilter = createOrderItemsFilter(parentId, treatmentId);
        const { items } = await getRecords("order_items", "*.*,service_variant.service.*", {
            extraOptions: {
                filter: orderItemsFilter,
            },
        });

        orderItems = items;
    }

    const sessionsData = {
        nfbBought: orderItems ? getOrderItems(orderItems, "neurofeedback") : 0,
        nfbDone: getSessions(sessions, "neurofeedback", { sessionDone: true }),
        psyBought: orderItems ? getOrderItems(orderItems, "psicologia") : 0,
        psyDone: getSessions(sessions, "psicologia", { sessionDone: true }),
        nfbNext7Days: getSessions(sessions, "neurofeedback", { next7Days: true }),
        psyNext7Days: getSessions(sessions, "psicologia", { next7Days: true }),
        evaluationsBought: orderItems ? getOrderItems(orderItems, "avaliacoes") : 0,
        evaluationsDone: getSessions(sessions, "avaliacoes", { sessionDone: true }),
    };

    return sessionsData;
};

const iseNext7Days = (targetDate: Date) => {
    const startDate = new Date();
    const endDate = addDays(new Date(), 7);
    return targetDate >= startDate && targetDate <= endDate;
};

type SessionsOptions = {
    sessionDone?: boolean;
    next7Days?: boolean;
};

const getSessions = (items: any, category: string, options?: SessionsOptions) => {
    if (options && options.sessionDone) {
        return items.filter((item: any) => item?.service?.category === category && item.status === "done")?.length || 0;
    } else if (options && options.next7Days) {
        return (
            items.filter((item: any) => iseNext7Days(parseISO(item?.start_date)) && item?.service?.category === category)
                .length || 0
        );
    } else {
        return items.filter((item: any) => item.order_item?.service?.category === category)?.length || 0;
    }
};

const getOrderItems = (items: any, category: string) => {
    const paidItems = items.filter((item: any) => {
        const service = item?.service_variant?.service;
        return service?.category === category;
    });

    const totalQuantity = paidItems?.reduce((acc: number, item: any) => {
        return acc + item.quantity;
    }, 0);

    return totalQuantity;
};

const createSessionsFilter = (parentId: string | number = "", treatmentId?: string | number) => {
    if (treatmentId) {
        return {
            treatment: {
                _eq: treatmentId,
            },
        };
    } else {
        return {
            treatment: {
                _and: [{ client: { _eq: parentId } }, { treatment_status_new: { _eq: "all_good" } }],
            },
        };
    }
};

const createOrderItemsFilter = (parentId: string | number = "", treatmentId?: string | number) => {
    if (treatmentId) {
        return {
            order_id: {
                treatment: {
                    _eq: treatmentId,
                },
            },
        };
    } else {
        return {
            order_id: {
                _and: [
                    { treatment: { treatment_status_new: { _eq: "all_good" } } },
                    { treatment: { client: { _eq: parentId } } },
                ],
            },
        };
    }
};
