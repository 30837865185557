import { useState, useRef, useEffect } from "react";

type UseDebouncedInputReturnType = [string, (inputValue: string) => void];

export const useDebouncedInput = (
    initialValue: string,
    minLength: number = 3,
    delay: number = 1000
): UseDebouncedInputReturnType => {
    const [value, setValue] = useState(initialValue);
    const debounceTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

    const handleInputChange = (inputValue: string) => {
        const newValue = inputValue;

        // Clear existing timeout
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        // Set new timeout
        debounceTimeout.current = setTimeout(() => {
            if (newValue.length >= minLength) {
                setValue(newValue);
            }
        }, delay);
    };

    useEffect(() => {
        return () => {
            // Cleanup on component unmount
            if (debounceTimeout.current) {
                clearTimeout(debounceTimeout.current);
            }
        };
    }, []);

    return [value, handleInputChange];
};
