export const entitiesToSearch = [
	{
		entity: "treatments",
		label: "Tratamentos",
	},
	{
		entity: "clients",
		label: "Clientes",
	},
	{
		entity: "leads",
		label: "Leads",
	},
];

export const templates = [
	{
		entity: "treatments",
		template: "[T] {{item.client.client_id}} - {{item.client.user_id.first_name}} {{item.client.user_id.last_name}}",
	},
	{
		entity: "clients",
		template: "[C] {{item.client_id}} {{item.user_id.first_name}} {{item.user_id.last_name}}",
	},
	{
		entity: "leads",
		template: "[L] {{item.first_name}} {{item.last_name}}",
	},
];

export const handleFieldsQuery = (entityToSearch: string) => {
	switch (entityToSearch) {
		case "treatments":
			return "*.*,client.user_id.*";
		case "clients":
			return "*.*,user_id.*";
		case "leads":
			return "*.*";
		default:
			return "*.*";
	}
};



export const entityToSearchLabel = (entityToSearch: string) => {
	switch (entityToSearch) {
		case "treatments":
			return "Tratamentos";
		case "clients":
			return "Clientes";
		case "leads":
			return "Leads";
		default:
			return "Tratamentos";
	}
};
