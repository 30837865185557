import { GridCellParams } from "@mui/x-data-grid";
import { RecordContextType } from "../../components/record/Record";
import { templates } from "../../components/tasks/tasksConfig";
import { TaskDoneShortcut } from "../../components/tasks/TaskDoneShortcut";
import { filterUsersRoles } from "../../utils/filterUsersRoles";

export const tasksOptions = {
    fieldsToRemove: {
        fieldNamesToRemove: ["id"],
        fieldTypesToRemove: [],
    },

    groupsOptions: {
        fieldsWithExtraOptions: [
            {
                field: "related_with",
                settings: {
                    type: "manytoany",
                    template_many_to_any: templates,
                },
            },
            {
                field: "assigned_users",
                relatedTable: "directus_users",
                displayTemplate: "{{first_name}} {{last_name}}",
                filter: filterUsersRoles,
                enabled: true,
            },
            {
                field: "service",
                sort: "name",
                enabled: true,
            },
        ],
    },

    dataGridOptions: {
        extraColumns: {
            beggining: [
                {
                    field: "task_done",
                    headerName: "Concluída",
                    width: 75,
                    renderCell: (params: GridCellParams) => <TaskDoneShortcut id={params.row.id} status={params.row.status} />,
                },
            ],
            end: [],
        },
    },
    createRelatedParams: (params: RecordContextType | undefined) => createOrderParams(params),
    extraOptions: {
        filter: {
            tasks: {
                _and: [
                    {
                        _or: [
                            {
                                status: {
                                    _eq: "open",
                                },
                            },
                            {
                                status: {
                                    _null: true,
                                },
                            },
                        ],
                    },
                    { assigned_users: { directus_users_id: { id: { _in: ["$CURRENT_USER"] } } } },
                ],
            },
        },
    },
};

const createOrderParams = async (recordContext: RecordContextType | undefined) => {
    if (!recordContext?.item) return;

    if (recordContext.entity === "clients") return `[clients][id][*.*,user_id.*]=${recordContext.item.id}`;

    if (recordContext.entity === "leads") return `[leads][id]=${recordContext.item.id}`;

    if (recordContext.entity === "treatments") return `[treatments][id][*.*,client.user_id.*]=${recordContext.item.id}`;
};
