import { Query } from "@tanstack/react-query";

export const findQueryByPrefix = (query: Query, queryStarts: string) => {
	const queryString = (query.queryKey as string[])[0];
	return queryString.startsWith(queryStarts);
};

export type QueryStrings = {
	queryKey: string[];
}

export const findQueryByWords = (queries: QueryStrings[], words: string[]) => {
	for(let query of queries){
		if(words.every(word => query.queryKey[0].includes(word))) {
		  return query.queryKey[0];
		}
	  }
	  return null;
};

