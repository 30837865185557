import { useQuery } from "@tanstack/react-query";
import { api } from "./config";
import { Setting } from "../store/store.types";
import { useStore } from "../store/store";
import { useEffect } from "react";
import { FormatedFields } from "../types/fields.types";
import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import { OpenInNewTab } from "../components/datagrid/OpenInNewTab";
import { ContextType, useRecordsContext } from "../utils/useRecordsContext";
import { uniqBy } from "lodash";
import { mergeFieldsWithGridConfig } from "../components/datagrid/utils";

export const useSettings = (entity: string, contextType: ContextType) => {
    const { recordsFilter, setRecordsFilter, modalFilter, setModalFilters, progressFilter, setProgressFilter, gridSettings } =
        useStore();
    const { formatedFields: fields, dataGridOptions } = useRecordsContext(contextType);

    const modalFilterFromSettings = gridSettings.selectedSettings?.config?.filters?.modal;
    const progressFilterFromSettings = gridSettings.selectedSettings?.config?.filters?.progress;
    const recordsFilterFromSettings = gridSettings.selectedSettings?.config?.filters?.records;
    const { data, status } = useQuery({
        queryKey: [`settings-${entity}`],
        queryFn: () => getSettings(entity),
        staleTime: Infinity,
    });

    useEffect(() => {
        // Merge api fields with front end fields for columns
        const prepareMerge = [...fields] as GridColDef[];

        prepareMerge.unshift({
            headerName: "Nova tab",
            field: "new_tab",
            width: 75,
            renderCell: (params: GridCellParams) => <OpenInNewTab params={params} entity={entity} />,
        });

        if (dataGridOptions && dataGridOptions.extraColumns) {
            if (dataGridOptions.extraColumns.beggining) prepareMerge.unshift(...dataGridOptions.extraColumns.beggining);
            if (dataGridOptions.extraColumns.end) prepareMerge.push(...dataGridOptions.extraColumns.end);
        }

        const uniqColumns = uniqBy(prepareMerge, "field") as FormatedFields;

        const apiSettings = data as Setting[];

        if (apiSettings) {
            let setting = apiSettings.find((item: Setting) => item.id === gridSettings.selectedSettings?.id) as Setting;

            if (!setting) {
                setting =
                    (apiSettings.find((item: Setting) => item.isActive) as Setting) ||
                    (apiSettings.find((item: Setting) => item.isSystem) as Setting);
            }

            const columnsConfig = mergeFieldsWithGridConfig(uniqColumns as FormatedFields, setting?.config?.columns);

            if (
                gridSettings?.gridColumns?.length === 0 ||
                entity !== gridSettings.entity ||
                gridSettings?.apiSettings?.length !== apiSettings.length ||
                gridSettings?.activeOnGrid !== setting.id
            ) {
                gridSettings.setGridSettings({
                    gridColumns: columnsConfig,
                    selectedSettings: setting,
                    entity: entity,
                    apiSettings: apiSettings,
                    activeOnGrid: setting.id,
                });
                setModalFilters(modalFilterFromSettings || null);
                setProgressFilter(progressFilterFromSettings || null);
                setRecordsFilter(recordsFilterFromSettings || null);
            }
        }
    }, [
        data,
        dataGridOptions,
        entity,
        fields,
        gridSettings,
        modalFilterFromSettings,
        progressFilterFromSettings,
        recordsFilterFromSettings,
        setModalFilters,
        setProgressFilter,
        setRecordsFilter,
        status,
    ]);

    useEffect(() => {
        if (modalFilterFromSettings && !modalFilter) {
            setModalFilters(modalFilterFromSettings);
        }

        if (progressFilterFromSettings && !progressFilter) {
            setProgressFilter(progressFilterFromSettings);
        }

        if (!recordsFilter && recordsFilterFromSettings) {
            setRecordsFilter(recordsFilterFromSettings);
        }
    }, [
        modalFilter,
        modalFilterFromSettings,
        progressFilter,
        progressFilterFromSettings,
        recordsFilter,
        recordsFilterFromSettings,
        setModalFilters,
        setProgressFilter,
        setRecordsFilter,
    ]);
};

export const getSettings = async (entity: string) => {
    const filter = {
        _and: [
            {
                _or: [
                    {
                        user_created: {
                            id: {
                                _eq: "$CURRENT_USER",
                            },
                        },
                    },
                    {
                        isSystem: {
                            _eq: true,
                        },
                    },
                ],
            },
            {
                collection: {
                    _eq: entity,
                },
            },
        ],
    };
    const response = await api({
        method: "GET",
        url: `/items/settings?filter=${JSON.stringify(filter)}`,
    });

    return response.data.data;
};
