import { Call } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { useStore } from "../../store/store";

type CallButtonProps = {
    phone: string;
};
export const CallButton = ({ phone }: CallButtonProps) => {
    const isPortuguesePhone = phone.startsWith("9") || phone.startsWith("2");
    const { phoneClient } = useStore();

    return (
        <Box sx={{ display: "flex", alignItems: "center", gap: 6 }}>
            <Button
                startIcon={<Call />}
                size="small"
                onClick={() => {
                    if (!phoneClient.iframeRef) return;
                    phoneClient.setPhoneNumberToCall(phoneClient.iframeRef, phoneClient.url, "351" + phone?.replace(/ /g, ""));
                }}
                disabled={isPortuguesePhone ? false : true}
            >
                {isPortuguesePhone ? "Telefonar" : "Marcar manualmente"}
            </Button>
        </Box>
    );
};
