import { Info } from "@mui/icons-material";


export const tabs = [
  {
    icon: <Info />,
    entity: "detail",
    label: "Detalhes",
  },
];
