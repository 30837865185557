import TextField from "@mui/material/TextField";
import { Controller } from "react-hook-form";
import { FieldProps } from "../renderFieldInput";

interface EmailInputFieldProps extends FieldProps {
	value: string;
}

export const EmailInputField = ({
	field,
	value,
	entityForm,
	options,
	extraOptions,
}: EmailInputFieldProps) => {
	// const error = entityForm.formState.errors[field.field];

	return (
		<Controller
			name={extraOptions?.field ? extraOptions?.field : field.field}
			control={entityForm.control}
			defaultValue={value || ""}
			rules={{
				pattern: {
					value: /\S+@\S+\.\S+/,
					message: "Invalid email address",
				},
			}}
			render={({ field: inputField }) => (
				<TextField
					disabled={options?.disabled}
					{...inputField}
					label={field.headerName}
					fullWidth
					variant="standard"
					{...options}
				/>
			)}
		/>
	);
};
