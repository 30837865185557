import { Box, Slider, TextField } from "@mui/material";
import { FormatedField, FormatedFields } from "../../types/fields.types";
import { useEffect, useState } from "react";
import { useStore } from "../../store/store";

type ColumnWidthProps = {
    gridColumn: FormatedField;
};

export const SetColumnWidth = ({ gridColumn }: ColumnWidthProps) => {
    const [value, setValue] = useState(gridColumn.width);
    const { gridSettings } = useStore();

    useEffect(() => {
        const setWidthByField = (gridColumns: FormatedFields, field: string, width: number) => {
            const newItems = gridColumns.map((gridColumn) => {
                if (gridColumn.field === field) {
                    return { ...gridColumn, width };
                }
                return gridColumn;
            });
            gridSettings.setGridSettings({ gridColumns: newItems });
        };

        if (gridColumn.width !== value) {
            setWidthByField(gridSettings.gridColumns, gridColumn.field, value as number);
        }
    }, [gridColumn, gridSettings, value]);

    return (
        <Box sx={{ display: "flex", alignItem: "center", gap: 5 }}>
            <Slider
                size="medium"
                defaultValue={gridColumn.width}
                value={value}
                min={50}
                max={750}
                onMouseDown={(e) => e.stopPropagation()}
                valueLabelDisplay="auto"
                onChangeCommitted={(e, value) => {
                    setValue(value as number);
                }}
            />
            <TextField
                size="small"
                value={value}
                sx={{ width: 130 }}
                onChange={(e) => {
                    const value = Number(e.target.value);
                    setValue(value);
                }}
            />
        </Box>
    );
};
