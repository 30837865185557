import { Groups } from "../../components/groups/Groups";
import { DetailTabs } from "../../components/detailTabs/DetailTabs";
import { Record } from "../../components/record/Record";
import { Records } from "../../components/records/Records";
import { CallRoutesListHeader } from "./CallRoutesListHeader";
import { tabs } from "./callsTabs";

const fieldsQuery = "*.*";

export const callsRoutes = [
    {
        path: "/calls",
        element: <Records entity="calls" fieldsQuery={fieldsQuery} heightOfHeaders={296} header={<CallRoutesListHeader />} />,
    },
    {
        path: "/calls/:id",
        element: <Record entity="calls" type="detail" fieldsQuery={fieldsQuery} body={<DetailTabs tabs={tabs} />} />,
        children: [
            {
                path: "/calls/:id/detail",
                element: <Groups />,
            },
        ],
    },
];
