import { parseISO } from "date-fns";
import { CalendarItem, CalendarItems } from "../components/calendar/calendar.types";

export function parseCalendarEvents(items: CalendarItems) {
    if (!items) return [];

    const events = items.map((value) => {
        return {
            id: value.id,
            title: handleTitle(value),
            start: parseISO(value.start_date),
            end: parseISO(value.end_date),
            color: setColorEvent(value),
            allDay: value.is_all_day,
            technicianAvatar: value?.technician?.user_id?.avatar,
            status: value?.status,
            service: value?.service,
        };
    });

    return events;
}

const handleTitle = (value: CalendarItem) => {
    const clientId = value.treatment?.client?.client_id;
    const title = value.title;

    const values = [clientId, title].filter((val) => val !== undefined && val !== null);
    return values.join("\n");
};

const setColorEvent = (event: CalendarItem) => {
    if (event.status === "cancelled" || event.status === "missed") {
        return "#e63131";
    }

    const servicesThatPrevailsColor = [33, 35, 39, 40];

    if (servicesThatPrevailsColor.includes(event?.service?.id as number)) {
        return event?.service?.color;
    }

    if (!event.service) {
        return "#d1cfcf";
    }

    return event?.technician?.color || event?.service?.color;
};
