import { GridCellParams } from "@mui/x-data-grid";
import { FormatedFields } from "../../types/fields.types";
import { GridApiCommunity } from "@mui/x-data-grid/internals";
import { GridSettings as StoreGridSettings } from "../../store/store.types";
import { GridConfig } from "./DataGrid";

export const saveScrollPosition = (
    gridSettings: StoreGridSettings,
    apiRef: React.MutableRefObject<GridApiCommunity>,
    params: GridCellParams,
    entity: string
) => {
    const scrollPosition = apiRef.current.getScrollPosition();
    gridSettings.setGridSettings({
        scrollPosition: {
            top: scrollPosition.top,
            left: scrollPosition.left,
            id: params.row.id,
            field: params.field,
        },
        entity: entity,
    });
};



export const mergeFieldsWithGridConfig = (formatedFields: FormatedFields, configServer?: GridConfig) => {
    if (!configServer || configServer.length === 0) return formatedFields;

    const currentConfig = createConfig(configServer, formatedFields);

    const configWithMissingFields = addMissingFields(currentConfig, formatedFields);

    return configWithMissingFields;
};

export const checkVisibility = (gridColumns: FormatedFields) => {
    const hiddenColumns = gridColumns.reduce((acc: { [key: string]: boolean }, column) => {
        if (!column.visible) {
            acc[column.field] = false;
        }
        return acc;
    }, {});

    return hiddenColumns;
};

export const createConfig = (configServer: GridConfig, formatedFields: FormatedFields) => {
    return configServer.reduce((acc: FormatedFields, item) => {
        const overrideItem = formatedFields.find((formatedField) => formatedField.field === item.field);

        if (overrideItem) {
            acc.push({ ...overrideItem, ...item });
        }
        return acc;
    }, []);
};

export const addMissingFields = (currentConfig: FormatedFields, formatedFields: FormatedFields) => {
    formatedFields.forEach((field) => {
        const exists = currentConfig.find((config) => config.field === field.field);
        if (!exists) {
            currentConfig.push({ ...field, visible: false });
        }
    });
    return currentConfig;
};
