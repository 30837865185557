import { Box, Popover } from "@mui/material";
import { ConfigColumns } from "./ConfigColumns";
import { useStore } from "../../store/store";



export const GridSettings = () => {
    const { gridSettings } = useStore();

    const handleClose = () => {
        gridSettings.setGridSettings({ configColumnsModalIsOpen: false, configColumnsModalAnchorEl: null });
    };

    return (
        <Box display="flex" alignItems="center" gap={10} padding={2} maxWidth={400}>
            {gridSettings.configColumnsModalIsOpen && (
                <Popover
                    id="columns-config"
                    open={gridSettings.configColumnsModalIsOpen}
                    anchorEl={gridSettings.configColumnsModalAnchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    sx={{ padding: 20, ml: "auto" }}
                >
                    {gridSettings.selectedSettings && <ConfigColumns />}
                </Popover>
            )}
        </Box>
    );
};
