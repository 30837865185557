import { startOfDay } from "date-fns";
import { useState, useEffect } from "react";
import { Calendar as ReactBigCalendar, View } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./overrides.css";
import { sessionsOptions } from "../../screens/sessions/sessionsOptions";
import { parseCalendarEvents } from "../../utils/parseCalendarEvents";
import { CalendarEvent, CalendarEvents } from "./calendar.types";
import {
    useEventDrop,
    useFormats,
    useHandleSelectSlot,
    localizer,
    scrollToTime,
    handleCalendarDate,
    minTime,
    maxTime,
    handleRanges,
} from "./calendarUtils";
import CustomYearView from "./custom-year-view/CustomYearView";
import { useStore } from "../../store/store";
import { Paper } from "@mui/material";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { SessionPreview } from "../../screens/sessions/components/SessionPreview";
import { CustomEventWeek, CustomEventDay, CustomEventAgenda } from "./CustomEvent";
import { isLightColor } from "../../utils/isLightColor";
import { CalendarView } from "../../store/store.types";

type CalendarProps = {
    items: any;
    refetch: () => void;
    isMobile: boolean;
    views?: {
        year?: boolean;
        month?: boolean;
        week?: boolean;
        day?: boolean;
        agenda?: boolean;
    };
    defaultView: CalendarView;
    height?: number;
};

const DnDCalendar = withDragAndDrop(ReactBigCalendar);

const Calendar = ({ items, refetch, views, defaultView, height }: CalendarProps) => {
    const [eventId, setEventId] = useState<number>();
    const [previewIsOpen, setPreviewIsOpen] = useState<boolean>(false);
    const {
        calendar: { calendarView, setCalendarView, calendarFilters, setCalendarFilters },
    } = useStore();
    const onEventChange = useEventDrop(refetch, "sessions");
    const { formats } = useFormats("start_date");
    const handleSelectSlot = useHandleSelectSlot();
    const [calendarEvents, setCalendarEvents] = useState<CalendarEvents>();

    useEffect(() => {
        const events = parseCalendarEvents(items);
        setCalendarEvents(events);
    }, [items]);

    return (
        <Paper sx={{ p: 2, height: height }}>
            <DnDCalendar
                localizer={localizer}
                culture="pt-PT"
                views={
                    {
                        month: views?.month,
                        week: views?.week,
                        day: views?.day,
                        agenda: views?.agenda,
                        year: views?.year ? CustomYearView : false,
                    } as any
                }
                messages={{ year: "Year" } as any}
                components={{
                    week: {
                        // @ts-ignore
                        event: CustomEventWeek,
                    },
                    day: {
                        // @ts-ignore
                        event: CustomEventDay,
                    },
                    agenda: {
                        // @ts-ignore
                        event: CustomEventAgenda,
                        title: () => <h1>cenas</h1>,
                    },
                }}
                events={calendarEvents}
                // @ts-ignore
                startAccessor="start"
                // @ts-ignore
                endAccessor="end"
                dayLayoutAlgorithm="no-overlap"
                //@ts-ignore
                defaultView={calendarView ? calendarView : defaultView}
                style={{ height: "calc(100vh - 150px)", position: "relative" }}
                min={minTime}
                max={maxTime}
                timeslots={4}
                step={15}
                scrollToTime={scrollToTime}
                date={calendarFilters?.dateRange && handleCalendarDate(calendarFilters.dateRange)}
                // @ts-ignore
                onSelectEvent={(event: CalendarEvent) => {
                    setEventId(event.id);
                    setPreviewIsOpen(true);
                }}
                selectable={true}
                onSelectSlot={(e) =>
                    handleSelectSlot({
                        start: e.start,
                        entity: "sessions",
                        defaultMinutes: 60,
                    })
                }
                onView={(view: View) => {
                    setCalendarView(view as CalendarView);
                }}
                onNavigate={(date) => {}}
                onRangeChange={(range: any, view: View | undefined) => {
                    const newFilters = {
                        ...calendarFilters,
                        dateRange: handleRanges(view === "day" ? [startOfDay(new Date())] : range),
                    };
                    setCalendarFilters(newFilters);
                }}
                eventPropGetter={(event) => {
                    const defaultColor = "#d1cfcf";
                    // @ts-ignore
                    const eventColor = event?.color ? event?.color : defaultColor;

                    return {
                        style: {
                            backgroundColor: eventColor,
                            borderColor: eventColor,
                            // @ts-ignore
                            color: isLightColor(eventColor) ? "#404040" : "#fff",
                            fontSize: "11px",
                        },
                    };
                }}
                // @ts-ignore
                formats={formats}
                draggableAccessor={(event) => true}
                onEventDrop={onEventChange}
            />

            <SessionPreview
                previewIsOpen={previewIsOpen}
                setPreviewIsOpen={setPreviewIsOpen}
                eventId={eventId}
                groupsOptions={sessionsOptions}
                refetchSessions={refetch}
            />
        </Paper>
    );
};

export default Calendar;
