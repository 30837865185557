import { useEffect, useState } from "react";
import { Box, Grid, Avatar, Typography, TextField, Button } from "@mui/material";
import { Lock } from "@mui/icons-material";
import { useLogin } from "../../../api/useAuth";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { Credentials, AuthErrors } from "../../../types/auth.types";

type LoginProps = {
    setRecoverPassword: React.Dispatch<React.SetStateAction<boolean>>;
};

export function LoginForm({ setRecoverPassword }: LoginProps) {
    const { mutate, error, isError } = useLogin();
    const [formErrors, setFormErrors] = useState<AuthErrors>();

    const {
        handleSubmit,
        control,
        formState: { errors: fieldErrors },
    } = useForm<Credentials>();

    const onSubmit: SubmitHandler<Credentials> = async (credentials) => {
        mutate(credentials);
    };

    useEffect(() => {
        if (isError && error) {
            setFormErrors(error.response?.data as AuthErrors);
        }
    }, [isError, error]);

    return (
        <Grid item xs={12} sm={8} md={5} sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <Box
                sx={{
                    my: 8,
                    mx: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                    <Lock />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Entrar
                </Typography>
                <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>
                    <Controller
                        name="email"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                            <TextField
                                {...field}
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                                autoComplete="email"
                                autoFocus
                            />
                        )}
                    />
                    {fieldErrors.email && <Typography color="error">{fieldErrors.email.message}</Typography>}
                    <Controller
                        name="password"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                            <TextField
                                {...field}
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                            />
                        )}
                    />
                    {fieldErrors.password && <Typography color="error">{fieldErrors.password.message}</Typography>}

                    {formErrors
                        ? formErrors.errors.map((error) => (
                              <Typography color="error" key={error.message}>
                                  {error.message}
                              </Typography>
                          ))
                        : null}
                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                        Entrar
                    </Button>
                    <Button variant="text" onClick={() => setRecoverPassword(true)}>
                        Esqueceu a password? Clique aqui para fazer reset.
                    </Button>
                </Box>
            </Box>
        </Grid>
    );
}
