import { ApiFilters } from "../store/store.types";

export const combineFilters = (recordsFilter: ApiFilters | null, progressFilter: ApiFilters | null, entity: string) => {
    const entityRecordsFilter = recordsFilter ? recordsFilter[entity] : null;
    const entityProgressFilter = progressFilter ? progressFilter[entity] : null;

    if (entityRecordsFilter && entityProgressFilter) {
        const filters = {
            ...recordsFilter,
            [entity]: {
                _and: [...entityRecordsFilter._and, entityProgressFilter],
            },
        };

        return filters[entity];
    }

    if (entityRecordsFilter && !entityProgressFilter) {
        return entityRecordsFilter;
    }

    if (!entityRecordsFilter && entityProgressFilter) {
        return entityProgressFilter;
    }
};
