import { Typography } from "@mui/material";
import { Wysiwyg } from "../../../wysiwyg/Wysiwyg";

type TemplateContentInputFieldProps = {
    value: any;
};

export const TemplateContentField = ({ value }: TemplateContentInputFieldProps) => {
    const contentType = value?.field_with_template?.type;

    if (!contentType) return null;
    if (contentType === "text") return <Typography>{value.content}</Typography>;
    if (contentType === "html") return <Wysiwyg initialValue={value.content} />;
};
