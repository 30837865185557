import { Box, Divider, Unstable_Grid2 as Grid, Typography } from "@mui/material";
import { Psychology, CalendarMonth, Troubleshoot, Hub } from "@mui/icons-material";
import { SessionsSummary } from "../../api/useClientSessionsSummary";

type ClientSessionsSummaryProps = {
    sessions: SessionsSummary;
    options: { flexDirection: "row" | "column" };
    isTreatment?: boolean;
};

export const ClientSessionsSummary = ({ sessions, options, isTreatment }: ClientSessionsSummaryProps) => {
    return (
        <Box flexDirection={options.flexDirection || "row"}>
            <Grid container rowSpacing={1} xs={12} display="flex" alignItems="center">
                <Typography variant="h6" color="secondary">
                    Resumo das sessões {isTreatment ? "deste tratamento" : "do tratamento ativo"}
                </Typography>
                <Divider />
            </Grid>
            <Grid container columnSpacing={6} rowSpacing={1} justifyItems="flex-start" display="flex" flexWrap="wrap">
                <Grid display="flex" alignItems="flex-start">
                    <Hub color="primary" sx={{ mr: 2 }} />
                    <Typography variant="body1" color="primary">
                        NeuroFeedback:
                    </Typography>
                    <Typography variant="body1" ml={2}>
                        {sessions?.nfbDone}/{sessions?.nfbBought}
                    </Typography>
                </Grid>
                <Grid display="flex" alignItems="flex-start">
                    <Psychology color="primary" sx={{ mr: 2 }} />
                    <Typography variant="body1" color="primary">
                        Psicologia:
                    </Typography>
                    <Typography variant="body1" ml={2}>
                        {sessions?.psyDone}/{sessions?.psyBought}
                    </Typography>
                </Grid>
                <Grid display="flex" alignItems="flex-start" flexGrow={1}>
                    <Troubleshoot color="primary" sx={{ mr: 2 }} />
                    <Typography variant="body1" color="primary">
                        Avaliações:
                    </Typography>
                    <Typography variant="body1" ml={2}>
                        {sessions?.evaluationsDone}/{sessions?.evaluationsBought}
                    </Typography>
                </Grid>
                <Grid xs={12} />

                <Grid display="flex" alignItems="flex-start">
                    <CalendarMonth color="primary" sx={{ mr: 2 }} />
                    <Typography variant="body1" color="primary" ml={2}>
                        NFB 7 dias:
                    </Typography>
                    <Typography variant="body1">{sessions?.nfbNext7Days}</Typography>
                </Grid>
                <Grid display="flex" alignItems="flex-start">
                    <CalendarMonth color="primary" sx={{ mr: 2 }} />
                    <Typography variant="body1" color="primary" ml={2}>
                        PSY 7 dias:
                    </Typography>
                    <Typography variant="body1">{sessions?.psyNext7Days}</Typography>
                </Grid>
            </Grid>
        </Box>
    );
};
