import { useQuery } from "@tanstack/react-query";
import { api } from "./config";
import { Collection } from "../types/collections.types";


export type CollectionAndFieldsPermission = {
    collections: Collection[];
    fields: any[];
};

export const useCollections = () => {
    return useQuery({
        queryKey: ["collections"],
        queryFn: async () => {
            const collections = await getCollections();
            const fields = await getFields();
         
            return {collections, fields};
        },
        refetchOnWindowFocus: false,
        staleTime: Infinity,
    });
};

export const getCollections = async () => {
    const response = await api.get("/collections");
    return response.data.data;
};

export const getFields = async () => {
    const response = await api.get("/fields");

    return response.data.data;
};
