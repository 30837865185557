import { useCallback } from "react";
import { OrderItem } from "./ordersGridTypes.types";
import { GridValidRowModel } from "@mui/x-data-grid";
import { v4 as uuidv4 } from "uuid";
import { ApiRelatedOrderItem } from "./ordersGridTypes.types";
import { GridRowModel } from "@mui/x-data-grid";

export const calculateRowTotal = (row: OrderItem) => {
    return row.quantity * row.price - row.quantity * row.price * (row.discount / 100);
};

export const useCalculateOrder = () => {
    return useCallback((rows: GridValidRowModel) => {
        return rows.reduce((acumulater: number, current: OrderItem) => {
            return (acumulater = acumulater + calculateRowTotal(current));
        }, 0);
    }, []);
};

export const convertApiItemsToGridRows = (apiItems: ApiRelatedOrderItem[]) => {
    const items = apiItems.map((item: ApiRelatedOrderItem) => {
        const id = uuidv4();

        return {
            id: id,
            order_item_id: item.id,
            variantId: item.service_variant?.id,
            sku: item.service_variant?.sku,
            serviceName: item.service_variant?.service.name,
            agreementName: item.service_variant?.agreement.name,
            type: item.service_variant?.type,
            quantity: item.quantity,
            quantity_used: item.quantity_used,
            price: item.price,
            discount: item.discount,
        };
    });

    return items as GridRowModel[];
};
