import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "./config";
import { ErrorResponsesCreateOrUpdate } from "../types/records.types";
import { MutationOptions } from "../types/mutation.types";
import { useStore } from "../store/store";

export type RecordToDelete = {
    entity: string;
    id: string | number;
    doStuffAfterDelete?: () => void;
};

export const useDeleteRecord = (options?: MutationOptions) => {
    const queryClient = useQueryClient();
    const { notifications } = useStore();

    return useMutation<any, ErrorResponsesCreateOrUpdate, RecordToDelete, any>({
        mutationFn: (record) => deleteRecord(record),
        onSuccess(data, variables) {
            queryClient.invalidateQueries({
                predicate(query: any) {
                    return query.queryKey[0].startsWith(options?.queryInvalidatePrefix || variables.entity);
                },
            });

            if (variables.doStuffAfterDelete) {
                variables.doStuffAfterDelete();
            }
        },
        onError(error: ErrorResponsesCreateOrUpdate) {
            notifications.setNotification({
                isOpen: true,
                message: error.response.data.errors[0].message,
                severity: "error",
            });
            return error.response.data.errors[0].message;
        },
    });
};

const deleteRecord = async (record: RecordToDelete) => {
    const deletedRecord = await api({
        method: "DELETE",
        url: `items/${record.entity}/${record.id}`,
    });

    return deletedRecord.data.data;
};
