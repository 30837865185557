import { useEffect, useState } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import pt from "date-fns/locale/pt";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { Controller } from "react-hook-form";
import { parseISO } from "date-fns";
import { FieldProps } from "../renderFieldInput";
import { useSearchParams } from "react-router-dom";

interface DateTimeInputFieldProps extends FieldProps {
    value: string | null;
}

export const DateTimeInputField = ({ field, value, entityForm, options, extraOptions }: DateTimeInputFieldProps) => {
    const [searchParams] = useSearchParams();
    const relatedField = entityForm.watch(extraOptions?.relatedWith || "");
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        searchParams.forEach((value, key) => {
            if (key === field.field) {
                entityForm.setValue(field.field, new Date(value));
            }
        });
    }, [searchParams, field, entityForm]);

    useEffect(() => {
        if (!relatedField) return;

        if (extraOptions && typeof extraOptions.createFilter === "function") {
            extraOptions.createFilter(relatedField).then((relatedFilter: any) => {
                entityForm.setValue(field.field, new Date(relatedFilter));
            });
        }
    }, [relatedField, extraOptions, entityForm, field.field]);

    useEffect(() => {
        if (value) {
            entityForm.setValue(field.field, parseISO(value));
        }
    }, [value, field.field, entityForm]);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={pt}>
            <Controller
                name={extraOptions?.field ? extraOptions?.field : field.field}
                control={entityForm.control}
                render={({ field: { onChange, value } }) => (
                    <DateTimePicker
                        ampm={false}
                        disabled={options?.disabled}
                        value={value || null}
                        label={field.headerName}
                        format="dd/MM/yyyy HH:mm"
                        onChange={(newValue) => {
                            onChange(newValue);
                        }}
                        timeSteps={{ hours: 1, minutes: 15 }}
                        {...options}
                        sx={{ width: "100%" }}
                        open={isOpen}
                        onClose={() => setIsOpen(false)}
                        slotProps={{
                            textField: {
                                onClick: () => setIsOpen(true),
                            },
                        }}
                    />
                )}
            />
        </LocalizationProvider>
    );
};
