import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { removeHtmlTagsAndEntities } from "../../utils/removeHtmlTagsAndEntities";
import { Avatar } from "../fieldsViews/fields/Avatar";
import { format } from "date-fns";
import { useCreateRecord } from "../../api/useCreateRecord";
import { RecordContextType } from "../record/Record";
import { useUpdateRecord } from "../../api/useUpdateRecord";
import { Wysiwyg } from "../wysiwyg/Wysiwyg";
import { useForm } from "react-hook-form";
import { findField } from "../../utils/findField";

type Comment = {
    id: string;
    content: string;
    user_created: {
        id: string;
        avatar?: string;
        first_name: string;
        last_name: string;
    };
    date_created: string;
    date_updated: string;
};

type TaskCommentsProps = {
    comments: Array<Comment>;
    context: RecordContextType;
};

export const TaskComments = ({ comments, context }: TaskCommentsProps) => {
    const [editComment, setEditingComment] = useState({
        id: "",
        content: "",
    });
    const createRecord = useCreateRecord({ queryInvalidatePrefix: "tasks" });
    const updateRecord = useUpdateRecord({ queryInvalidatePrefix: "tasks" });
    const entityForm = useForm();
    const contentField = findField(context.formatedFields, "content");

    const handleCommentSubmit = async (type: string) => {
        const commentText = entityForm.getValues("content");

        if (!commentText) {
            alert("Tem que adicionar comentário");
            return;
        }
        if (type === "create") {
            await createRecord.mutateAsync({
                entity: "task_comments",
                data: {
                    content: commentText,
                    task: context.item.id,
                    updated_at: new Date(),
                },
            });
        }

        if (type === "edit") {
            await updateRecord.mutateAsync({
                entity: "task_comments",
                id: editComment.id,
                data: {
                    content: commentText,
                    updated_at: new Date(),
                    task: context.item.id,
                },
            });
            setEditingComment({
                id: "",
                content: "",
            });
        }

        entityForm.reset();
    };

    const editCommentSelectedComment = (id: string, content: string) => {
        setEditingComment({
            id: id,
            content: content,
        });
    };

    return (
        <Box>
            <Typography variant="h5">Commentários:</Typography>
            <List>
                {comments.map((comment: Comment) => (
                    <ListItem id={`commentId-${comment.id}`} key={comment.id} disableGutters>
                        <Card variant="outlined" sx={{ backgroundColor: "#f7f2f2", width: "100%" }}>
                            <CardContent>
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <Avatar value={comment.user_created.avatar} />
                                        <Typography variant="body1" color="text.secondary" sx={{ ml: 2 }}>
                                            {comment.user_created.first_name} {comment.user_created.last_name}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <Typography variant="body2" color="text.secondary">
                                            {format(new Date(comment.date_updated || comment.date_created), "dd/MM/yyyy HH:mm")}
                                        </Typography>
                                        <Button
                                            variant="text"
                                            sx={{ ml: 1 }}
                                            onClick={() => editCommentSelectedComment(comment.id, comment.content)}
                                        >
                                            Editar
                                        </Button>
                                    </Box>
                                </Box>
                                {editComment.id && comment.id === editComment.id ? (
                                    <Box p={4}>
                                        <Wysiwyg
                                            entityForm={entityForm}
                                            size="200px"
                                            initialValue={comment?.content}
                                            field={contentField}
                                        />
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => handleCommentSubmit("edit")}
                                            fullWidth
                                            sx={{ mt: 2 }}
                                        >
                                            Atualizar Comentário
                                        </Button>
                                    </Box>
                                ) : (
                                    <Typography variant="body1" color="text.primary" sx={{ p: 2 }}>
                                        {removeHtmlTagsAndEntities(comment.content)}
                                    </Typography>
                                )}
                            </CardContent>
                        </Card>
                    </ListItem>
                ))}
            </List>

            <Wysiwyg entityForm={entityForm} size="200px" field={contentField} initialValue={""} />

            <Button variant="contained" color="primary" onClick={() => handleCommentSubmit("create")} fullWidth sx={{ mt: 2 }}>
                Adicionar Comentário
            </Button>
        </Box>
    );
};
