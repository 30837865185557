import { Troubleshoot, FormatListBulleted } from "@mui/icons-material";
import { Unstable_Grid2 as Grid, IconButton } from "@mui/material";
import { useStore } from "../../store/store";


export const ToggleBetweenSummaryAndList = () => {

    const { treatments } = useStore();

    return (
        <Grid container spacing={2} alignItems="center" display="flex" flexDirection="row">
            <Grid>
                <IconButton onClick={() => treatments.setView("list")}>
                    <FormatListBulleted />
                </IconButton>

                <IconButton onClick={() => treatments.setView("summary")}>
                    <Troubleshoot />
                </IconButton>
            </Grid>
        </Grid>
    );
};
