import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "./config";
import { ErrorResponsesCreateOrUpdate } from "../types/records.types";
import { MutationOptions } from "../types/mutation.types";
import { useStore } from "../store/store";

export type FileToDelete = {
    id: string | number;
    entity: string;
};

export const useDeleteFile = (options?: MutationOptions) => {
    const queryClient = useQueryClient();
    const { notifications } = useStore();

    return useMutation<any, ErrorResponsesCreateOrUpdate, FileToDelete, any>({
        mutationFn: (record) => deleteFile(record),
        onSuccess(data, variables) {
            queryClient.invalidateQueries({
                predicate(query: any) {
                    return query.queryKey[0].startsWith(options?.queryInvalidatePrefix || variables.entity);
                },
            });
        },
        onError(error: ErrorResponsesCreateOrUpdate) {
            notifications.setNotification({
                isOpen: true,
                message: error.response.data.errors[0].message,
                severity: "error",
            });
            return error.response.data.errors[0].message;
        },
    });
};

const deleteFile = async (record: FileToDelete) => {
    const deletedRecord = await api({
        method: "DELETE",
        url: `files/${record.id}`,
    });

    return deletedRecord.data.data;
};
