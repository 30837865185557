import { UseFormReturn } from "react-hook-form";
import { findField } from "../../utils/findField";
import { renderFieldInput } from "../fieldsInputs/renderFieldInput";
import { RecordsContextType } from "../records/Records";
import { getFieldExtraOption } from "../../utils/getFieldExtraOption";

type FilterFieldValueProps = {
    context: RecordsContextType;
    index: number;
    entityForm: UseFormReturn;
};

export const FilterFieldValue = ({ context, index, entityForm }: FilterFieldValueProps) => {
    const watchedFields = entityForm.watch();

    if (!context?.formatedFields) return null;

    const fieldToRender = findField(context?.formatedFields, watchedFields.filters[index].field.field);

    if (!fieldToRender) return null;

    const extraOptions = context?.options?.groupsOptions?.fieldsWithExtraOptions;
    const fieldExtraOptions = context?.options && extraOptions ? getFieldExtraOption(extraOptions, fieldToRender) : null;

    return renderFieldInput(fieldToRender, specialCases(), "filter", entityForm, {
        ...fieldExtraOptions,
        field: `filters.${index}.value`,
        rules: { required: "Este campo não pode estar vazio" },
    });
};

const specialCases = () => {
    return null;
};
