import { SxProps, Typography } from "@mui/material";
import { FieldView, FormatedField } from "../../../../types/fields.types";

type FloatFieldProps = {
    value: any;
    field: FormatedField;
    fieldView: FieldView;
};

export function Amount({ field, value, fieldView }: FloatFieldProps) {
    const amount = fieldView === "grid" ? value.row.amount : value.amount;
    const prefix = field.settings.display_options?.prefix;
    const sufix = field.settings.display_options?.sufix;
    const conditionalFormmating = field.settings.display_options?.conditionalFormatting;

    const formatedValue = new Intl.NumberFormat("pt-PT", {
        minimumFractionDigits: 2,
    }).format(amount);

    return (
        <Typography variant="body1" sx={conditionalFormmating && getStyle(conditionalFormmating, amount)}>
            {`${prefix ? prefix : ""} ${value ? formatedValue : ""} ${sufix ? sufix : ""}`}
        </Typography>
    );
}

const getStyle = (conditionalFormatting: Array<any>, value: number) => {
    let style = {
        borderRadius: 6,
        pl: 4,
        pr: 4,
        pt: 1,
        pb: 1,
        display: "inline-block",
        minWidth: 120,
    } as SxProps;

    conditionalFormatting?.forEach((format) => {
        switch (format.operator) {
            case "lt":
                if (value < format.value) {
                    style = { ...style, color: format.color, backgroundColor: format.background };
                }
                break;
            case "gte":
                if (value >= format.value) {
                    style = { ...style, color: format.color, backgroundColor: format.background };
                }
                break;
            // Add more cases as needed
        }
    });
    return style;
};
