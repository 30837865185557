import { useContext } from "react";
import { RecordContext } from "../../../components/record/Record";
import { DetailHeader } from "../../../components/detailHeader/DetailHeader";

export const ScheduleDetailHeader = () => {
    const schedule = useContext(RecordContext);

    if (!schedule) return null;

    return <DetailHeader collection={schedule.collection} deleteOnUpdate={{ forceRoute: "schedules" }}></DetailHeader>;
};
