import { useQuery } from "@tanstack/react-query";
import { api } from "./config";

type FilesData = {
    entity?: string;
    id?: string | number | null;
    field: string;
    directus_files?: Array<{ directus_files_id: string }>;
};

export const useFilesData = (files: FilesData) => {
    return useQuery({
        queryKey: [`files-${files.entity}-${files.id}-${files.field}-${files.directus_files?.length}`],
        queryFn: () => getFiles(files.directus_files),
    });
};

const getFiles = async (directus_files?: Array<{ directus_files_id: string }>) => {
    if (!directus_files) return [];

    const arrayOfFiles = prepareArrayOfFiles(directus_files);

    const files = [];
    for (const file of arrayOfFiles) {
        if (!file.directus_files_id) continue;
        const response = await api({
            method: "get",
            url: `files/${file.directus_files_id}`,
        });

        files.push(response.data.data);
    }

    return files;
};

const prepareArrayOfFiles = (directus_files: Array<{ directus_files_id: string }> | { id: string }) => {
    if (Array.isArray(directus_files)) {
        return directus_files;
    } else {
        return [
            {
                directus_files_id: directus_files.id,
            },
        ];
    }
};
