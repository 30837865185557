import TextField from "@mui/material/TextField";
import { Controller } from "react-hook-form";
import { FieldProps } from "../renderFieldInput";

interface IntegerInputFieldProps extends FieldProps {
    value: number;
}

export const IntegerInputField = ({ field, value, entityForm, options, extraOptions }: IntegerInputFieldProps) => {
    return (
        <Controller
            name={extraOptions?.field ? extraOptions?.field : field.field}
            control={entityForm.control}
            defaultValue={value || undefined}
            render={({ field: { ref, onChange, ...fieldInput } }) => (
                <TextField
                    {...fieldInput}
                    disabled={options?.disabled}
                    label={field.headerName}
                    fullWidth
                    inputRef={ref}
                    type="number"
                    onChange={(e) => {
                        const val = e.target.value;
                        onChange(val ? parseInt(val, 10) : "");
                    }}
                    variant="standard"
                    {...options}
                />
            )}
        />
    );
};
