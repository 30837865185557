import { Fade, Button, Box, Typography, Divider } from "@mui/material";
import { format, parseISO } from "date-fns";
import { AvailabilitySlot } from "../utils/types";
import { SlotOfTheDay } from "../utils/utils";

type SlotButtonProps = {
    slotOfTheDay: SlotOfTheDay;
    isSlotSelected: boolean;
    onSlotSelect: (slot: AvailabilitySlot | AvailabilitySlot[]) => void;
    onSlotDeselect: (slot: AvailabilitySlot | AvailabilitySlot[]) => void;
    index: number;
};

export const SlotButton = ({ slotOfTheDay, isSlotSelected, onSlotSelect, onSlotDeselect, index }: SlotButtonProps) => {
    return (
        <Fade in={true} timeout={{ enter: 800, exit: 300 }} style={{ transitionDelay: `${index * 200}ms` }}>
            <Button
                variant={isSlotSelected ? "contained" : "outlined"}
                color="secondary"
                onClick={() => {
                    const isGroup = slotOfTheDay.isGroup;
                    if (isGroup) {
                        // If any slot in the group is selected, deselect all
                        if (isSlotSelected) {
                            onSlotDeselect(slotOfTheDay.slots);
                        } else {
                            onSlotSelect(slotOfTheDay.slots);
                        }
                    }

                    if (!isGroup) {
                        // Handle single slot selection/deselection
                        const slot = slotOfTheDay.slots[0];
                        isSlotSelected ? onSlotDeselect(slot) : onSlotSelect(slot);
                    }
                }}
                sx={{ width: "100%", mr: 1, mb: 2, maxWidth: 550 }}
            >
                <SlotButtonText slots={slotOfTheDay.slots} isGroup={slotOfTheDay.isGroup} />
            </Button>
        </Fade>
    );
};

type SlotButtonTextProps = {
    slots: AvailabilitySlot[];
    isGroup: boolean;
};

const SlotButtonText = ({ slots, isGroup }: SlotButtonTextProps) => {
    const startServiceOneOfGroup = format(parseISO(slots[0].start_date), "HH:mm");
    const endServiceOneOfGroup = format(parseISO(slots[0].end_date), "HH:mm");
    const startServiceTwoOfGroup = isGroup ? format(parseISO(slots[1].start_date), "HH:mm") : "";
    const endServiceTwoOfGroup = isGroup ? format(parseISO(slots[1].end_date), "HH:mm") : "";
    const serviceOneName = slots[0].serviceName;
    const serviceTwoName = isGroup ? slots[1].serviceName : "";
    const capacity = slots[0].capacity;
    const serviceOneSentence = `${startServiceOneOfGroup} - ${endServiceOneOfGroup} ${serviceOneName}`;
    const serviceTwoSentence = `${startServiceTwoOfGroup} - ${endServiceTwoOfGroup} ${serviceTwoName}`;

    return isGroup ? (
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: 2 }}>
            <Typography variant="body2">{serviceOneSentence}</Typography>
            <Divider orientation="vertical" flexItem />
            <Typography variant="body2">{serviceTwoSentence}</Typography>
        </Box>
    ) : (
        <Typography variant="body2">
            {serviceOneSentence} ({capacity})
        </Typography>
    );
};
