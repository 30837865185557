import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Filters } from "./Filters";

type FilterDialogProps = {
    filtersIsOpen: boolean;
    setFilterIsOpen: (value: boolean) => void;
};

export const FilterDialog = ({ filtersIsOpen, setFilterIsOpen }: FilterDialogProps) => {
    return (
        <Dialog open={filtersIsOpen} maxWidth="lg" fullWidth>
            <DialogTitle>Adicionar filtro</DialogTitle>
            <DialogContent>
                <Filters setFilterIsOpen={setFilterIsOpen} />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        setFilterIsOpen(false);
                    }}
                    color="secondary"
                >
                    Cancelar
                </Button>
                <Button type="submit" form="filters-form">
                    Filtrar
                </Button>
            </DialogActions>
        </Dialog>
    );
};
