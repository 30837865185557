import { useState } from "react";
import { Box, Grid, Avatar, Typography, TextField, Button } from "@mui/material";
import { Lock } from "@mui/icons-material";
import { useForm, Controller, SubmitHandler } from "react-hook-form";

import { RecoverCredentials, usePasswordRecovery } from "../../../api/usePasswordRecovery";

type RecoverPasswordProps = { setRecoverPassword: React.Dispatch<React.SetStateAction<boolean>> };

export function RecoverPasswordForm({ setRecoverPassword }: RecoverPasswordProps) {
    const { mutateAsync } = usePasswordRecovery();
    const [emailSent, setEmailSent] = useState(false);

    const {
        handleSubmit,
        control,
        formState: { errors: fieldErrors },
        setError,
    } = useForm<RecoverCredentials>({ defaultValues: { email: "" } });

    const onSubmit: SubmitHandler<RecoverCredentials> = async (credentials) => {
        const response = await mutateAsync(credentials);

        if (response.status === 204) {
            setEmailSent(true);
            return;
        }

        if (response.status !== 204) {
            setError("email", { message: "Algo correu mal, por favor tente de novo" });
            return;
        }
    };

    return (
        <Grid item xs={12} sm={8} md={5} sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <Box
                sx={{
                    my: 8,
                    mx: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                    <Lock />
                </Avatar>

                {emailSent ? (
                    <Typography component="h1" variant="h5">
                        Se você tem uma conta com este email, irá receber um link seguro para que possa alterar a sua
                        palavra-passe.
                    </Typography>
                ) : (
                    <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1}}>
                        <Typography component="h1" variant="h5" sx={{ textAlign: "center" }}>
                            Alterar password
                        </Typography>
                        <Controller
                            name="email"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                />
                            )}
                        />
                        {fieldErrors.email && <Typography color="error">{fieldErrors.email.message}</Typography>}
                        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                            Pedir nova password
                        </Button>
                        <Button variant="text" onClick={() => setRecoverPassword(false)}>
                            Voltar ao início de sessão.
                        </Button>
                    </Box>
                )}
            </Box>
        </Grid>
    );
}
