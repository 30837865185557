import { Typography } from "@mui/material";
import { FieldExtraOption, FieldView, FormatedField } from "../../../types/fields.types";
import { removeHtmlTagsAndEntities } from "../../../utils/removeHtmlTagsAndEntities";
import { extractTextAndTruncate } from "../../../utils/extractTextAndTruncate";


type TextFieldProps = {
    value: string;
    field: FormatedField;
    fieldView: FieldView;
    extraOptions: FieldExtraOption;
};

export function TextField({ value, field, fieldView, extraOptions }: TextFieldProps) {
    return (
        <Typography sx={{ whiteSpace: "pre-line" }} variant={fieldView === "grid" ? "body2" : "body1"}>
            {field.settings.interface === "input-rich-text-html"
                ? handleWysiwyg(value)
                : extractTextAndTruncate(value, extraOptions?.charactersLimit)}
        </Typography>
    );
}

const handleWysiwyg = (value: string) => {
    const maxStringLength = 80;
    const stringLength = value?.length;

    if (value && stringLength > maxStringLength) {
        return removeHtmlTagsAndEntities(value.substring(0, maxStringLength)) + "...";
    } else {
        return removeHtmlTagsAndEntities(value);
    }
};
