import { Record } from "../../components/record/Record";
import { Records } from "../../components/records/Records";
import { tabs } from "./evaluationsTabs";
import { DetailTabs } from "../../components/detailTabs/DetailTabs";
import { EntityForm } from "../../components/entityForm/EntityForm";
import { Groups } from "../../components/groups/Groups";
import { evaluationsOptions } from "./evaluationsOptions";
import { actions } from "./evaluationActions";
import { EvaluationHeader } from "./EvaluationHeader";

const fieldsQuery = "*.*,client.user_id.*,treatment.client.*,treatment.client.user_id.*,evaluation_sent_by.user_id.*,evaluation_done_by.user_id.*, treatment.client.user_id.*,treatment.client.*, ec_images.*, eo_images.*,*,evaluation_done_by.*.*,eeg_session.service.name";

export const evaluationRoutes = [
    {
        path: "/evaluations",
        element: <Records entity="evaluations" fieldsQuery={fieldsQuery} options={evaluationsOptions} />,
    },
    {
        path: "/evaluations/:id",
        element: (
            <Record
                entity="evaluations"
                type="detail"
                fieldsQuery={fieldsQuery}
                header={<EvaluationHeader actions={actions}  />}
                body={<DetailTabs tabs={tabs} />}
            />
        ),
        children: [
            {
                path: "/evaluations/:id/detail",
                element: <Groups groupsOptions={evaluationsOptions.groupsOptions} />,
            },
        ],
    },
    {
        path: "/evaluations/:id/update",
        element: <Record entity="evaluations" fieldsQuery={fieldsQuery} type="update" body={<EntityForm groupsOptions={evaluationsOptions.groupsOptions} />} />,
    },
    {
        path: "/evaluations/create",
        element: <Record entity="evaluations" fieldsQuery={fieldsQuery} type="create" body={<EntityForm groupsOptions={evaluationsOptions.groupsOptions}  />} />,
    },
];
