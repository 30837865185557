import { useState } from "react";
import Popover from "@mui/material/Popover";
import { Visibility } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";

type DetailGridPopOverProps = {
  component: React.ReactNode;
};

export function GridPreview({ component }: DetailGridPopOverProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          handleClick(e);
        }}
      >
        <Visibility />
      </IconButton>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box padding={2}>{component}</Box>
      </Popover>
    </Box>
  );
}
