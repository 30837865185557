export const filterUsersRoles = {
    _or: [
        {
            _and: [
                {
                    role: {
                        id: {
                            _in: [
                                "0295dd26-17ab-454c-bacf-044dd349f21e",
                                "f2c6ace2-63b7-4ac8-8119-e23b53f696e9",
                                "3b835836-7e73-4f72-af50-597f9a49261f",
                                "0676bc30-2754-439a-bc48-8e75b81cb36c",
                                "55720c74-c013-44f0-96e5-3737adf14963",
                                "d6b0779e-70fc-4854-9525-91e41e555389",
                            ],
                        },
                    },
                },
                {
                    status: {
                        _eq: "active",
                    },
                },
            ],
        },
    ],
};
