import { OpenInNew } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { EntityToSearch } from "./GlobalSearch";
import { ClientOption, TreatmentOption, LeadOption } from "../../types/globalSearch.types";
import { RenderClientOption, RenderTreatmentOption, RenderLeadOption } from "./RenderOptions";

type GlobalSearchOptionProps = {
    option: any;
    entityToSearch: EntityToSearch;
    filter: any;
    setOpen: (value: boolean) => void;
};

export const GlobalSearchOption = ({ option, setOpen }: GlobalSearchOptionProps) => {

    return (
        <Box key={option.id} display="flex" alignItems="center" justifyContent="space-between" padding={3}>
            <Link
                to={`/${option.entity}/${option.id}/detail`}
                style={{ textDecoration: "inherit", color: "inherit" }}
                onClick={() => {
                    setOpen(false);
                }}
            >
                <RenderOption entityToSearch={option.entity} option={option} />
            </Link>
            <IconButton color="primary" component={Link} to={`/${option.entity}/${option.id}/detail`} target="_blank">
                <OpenInNew />
            </IconButton>
        </Box>
    );
};

type RenderOptionProps = {
    entityToSearch: string;
    option: ClientOption | TreatmentOption | LeadOption;
};

const RenderOption = ({ entityToSearch, option }: RenderOptionProps) => {
  
    switch (entityToSearch) {
        case "clients":
            return <RenderClientOption option={option as ClientOption} />;
        case "treatments":
            return <RenderTreatmentOption option={option as TreatmentOption} />;
        case "leads":
            return <RenderLeadOption option={option as LeadOption} />;
        default:
            return <Typography variant="body1">Not found</Typography>;
    }
};
