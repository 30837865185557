import { UseRecordsOptions } from "../../api/useRecords";

export const partnersOptions = {
    fieldsToRemove: {
        fieldTypesToRemove: ["alias"],
        fieldNamesToRemove: ["id"],
    },
    groupsOptions: {
        fieldsWithExtraOptions: [
            {
                field: "files",
                fileFolder: "0dcbd88d-8175-42d6-8ee9-38c86a96f7be",
            },
            {
                field: "city",
                sort: "name",
                enabled: true,
            },
        ],
    },
    relatedRecords: {
        type: "many_to_many",
        association: true,
        hideCreationButton: true,
    }
} as UseRecordsOptions;
