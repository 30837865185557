import { EntityForm } from "../../components/entityForm/EntityForm";
import { Record } from "../../components/record/Record";
import { Records } from "../../components/records/Records";
import { TasksComponent } from "../../components/tasks/TasksComponent";
import { tasksOptions } from "./tasksOptions";

const fieldsQuery =
    "*.*,assigned_users.directus_users_id.*, related_with.item:leads.*, related_with.item:clients.*,related_with.item:clients.user_id.*,related_with.item:treatments.*, related_with.item:treatments.client.client_id, related_with.item:treatments.client.user_id.*, comments.user_created.*";

export const tasksRoutes = [
    {
        path: "/tasks",
        element: <Records entity="tasks" fieldsQuery={fieldsQuery} options={tasksOptions} heightOfHeaders={190} />,
    },
    {
        path: "/tasks/:id/detail",
        element: (
            <Record
                entity="tasks"
                type="detail"
                fieldsQuery={fieldsQuery}
                body={<TasksComponent />}
                options={tasksOptions.groupsOptions}
            />
        ),
    },
    {
        path: "/tasks/:id/update",
        element: (
            <Record
                entity="tasks"
                type="update"
                fieldsQuery={fieldsQuery}
                options={tasksOptions.groupsOptions}
                body={<EntityForm component={<TasksComponent />} />}
            />
        ),
    },
    {
        path: "/tasks/create",
        element: (
            <Record
                entity="tasks"
                type="create"
                fieldsQuery={fieldsQuery}
                options={tasksOptions.groupsOptions}
                body={<EntityForm component={<TasksComponent />} />}
            />
        ),
    },
];
