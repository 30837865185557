import { DetailHeader } from "../../components/detailHeader/DetailHeader";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { RecordContext } from "../../components/record/Record";
import { useContext } from "react";
import { format } from "date-fns";
import { renderFieldView } from "../../components/fieldsViews/renderFieldView";
import { findField } from "../../utils/findField";

export const OrderHeader = () => {
    const recordContext = useContext(RecordContext);

    if (!recordContext) return null;

    const paymentField = findField(recordContext.formatedFields, "payment_method");
    const treatmentNameField = findField(recordContext.formatedFields, "treatment");

    return (
        <DetailHeader collection={recordContext.collection}>
            <Grid container spacing={10} alignItems="flex-start" xs={12}>
                <Grid xs={12} lg={6}>
                    <Typography variant="body1" color="primary">
                        Tratamento:
                    </Typography>
                    {treatmentNameField &&
                        renderFieldView(treatmentNameField, { treatment: recordContext.item.treatment }, "group")}
                </Grid>
                <Grid xs={12} lg={3}>
                    <Typography variant="body1" color="primary" mb={2}>
                        Data:
                    </Typography>
                    <Typography variant="body1">{format(new Date(recordContext.item.order_date), "yyyy-MM-dd")}</Typography>
                </Grid>
                <Grid xs={12} lg={3}>
                    <Typography variant="body1" color="primary" mb={1}>
                        Meio de Pagamento:
                    </Typography>
                    {paymentField &&
                        renderFieldView(paymentField, { payment_method: recordContext.item.payment_method }, "group")}
                </Grid>
            </Grid>
        </DetailHeader>
    );
};
