import { Typography } from "@mui/material";
import { TypographyProps } from "@mui/material/Typography";
import { format } from "date-fns";
import { FieldExtraOption, FieldView } from "../../../types/fields.types";
import pt from "date-fns/locale/pt";

type DateTimeFieldProps = {
    value: string;
    MuiOptions?: TypographyProps;
    extraOptions?: FieldExtraOption;
    fieldView: FieldView;
};

export function DateTimeField({ value, MuiOptions, extraOptions, fieldView }: DateTimeFieldProps) {
    if (!value) return null;

    const dateValue =
        fieldView === "grid" ? value : format(new Date(value), extraOptions?.dateFormat || "dd/MM/yyyy HH:mm", { locale: pt });
    return (
        <Typography variant="body1" {...MuiOptions}>
            {dateValue}
        </Typography>
    );
}
