import { Paper, TableContainer, TableHead, TableRow, Box, Typography, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { CustomEventType } from "../../utils/types";
import { useState } from "react";
import { workDays } from "../../utils/scheduleUtils";
import { ScheduleWeekTotalByUser } from "./ScheduleWeekTotalByUser";
import { StyledTableCell, StyledTable } from "./styles";
import { ScheduleWeekTotalByService } from "./ScheduleWeekTotalByService";
import { weekDaysTranslation } from "../../../../utils/constants";

type ScheduleWeekTotalProps = {
    events: Array<CustomEventType>;
};

type ViewMode = "byCategory" | "byUser";

export const ScheduleWeekTotal = ({ events }: ScheduleWeekTotalProps) => {
    const [viewMode, setViewMode] = useState<ViewMode>("byUser");

    // Filter out time_off events first
    const scheduleEvents = events.filter((event) => event.type === "schedule");

    const handleViewChange = (_event: React.MouseEvent<HTMLElement>, newView: ViewMode) => {
        if (newView !== null) {
            setViewMode(newView);
        }
    };

    return (
        <Box mt={4} mb={10}>
            <Paper elevation={2} sx={{ p: 4, display: "flex", justifyContent: "space-between", alignItems: "center", mb: 4 }}>
                <Box>
                    <Typography variant="h4">Total de sessões semanais</Typography>
                </Box>
                <ToggleButtonGroup
                    color="secondary"
                    value={viewMode}
                    exclusive
                    onChange={handleViewChange}
                    aria-label="view mode"
                    size="medium"
                >
                    <ToggleButton value="byUser">Ver por colaborador</ToggleButton>
                    <ToggleButton value="byCategory">Ver por categoria de serviço</ToggleButton>
                </ToggleButtonGroup>
            </Paper>

            <TableContainer component={Paper} sx={{ width: "100%", overflowX: "auto" }} elevation={4}>
                <StyledTable>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell className="header name-column">Nome</StyledTableCell>
                            {workDays.map((day) => (
                                <StyledTableCell key={day} className="header weekday-header">
                                    {weekDaysTranslation[day]}
                                </StyledTableCell>
                            ))}
                            <StyledTableCell className="header weekday-header">Total</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    {viewMode === "byUser" ? (
                        <ScheduleWeekTotalByUser events={scheduleEvents} />
                    ) : (
                        <ScheduleWeekTotalByService scheduleEvents={scheduleEvents} />
                    )}
                </StyledTable>
            </TableContainer>
        </Box>
    );
};
