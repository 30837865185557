import { addDays, differenceInYears, format, parseISO, set } from "date-fns";
import { AvailabilityPreferences, AvailabilitySlot, TimeRange, WeekDay } from "./types";
import { CLINIC_SCHEDULES, weekDaysTranslation } from "../../../utils/constants";
import { capitalize, uniqBy } from "lodash";
import { getRecords } from "../../../api/useRecords";

export const createInitialTimeRanges = (
    date: Date,
    dayPeriod: "morning" | "afternoon" | "both",
    clinicSchedules: typeof CLINIC_SCHEDULES
) => {
    const ranges: TimeRange[] = [];
    const { morning, afternoon } = clinicSchedules;

    if (dayPeriod === "morning" || dayPeriod === "both") {
        ranges.push({
            start: set(date, morning.start),
            end: set(date, morning.end),
        });
    }
    if (dayPeriod === "afternoon" || dayPeriod === "both") {
        ranges.push({
            start: set(date, afternoon.start),
            end: set(date, afternoon.end),
        });
    }

    return ranges;
};

export const getDayIndex = (day: WeekDay): number => {
    const dayMap: Record<WeekDay, number> = {
        sunday: 0,
        monday: 1,
        tuesday: 2,
        wednesday: 3,
        thursday: 4,
        friday: 5,
        saturday: 6,
    };
    return dayMap[day];
};

export const formatDayAndDate = (day: WeekDay, weekStartDate: Date) => {
    const capitalizedDay = weekDaysTranslation[capitalize(day)];
    const dateNumber = format(addDays(weekStartDate, getDayIndex(day)), "d");
    return `${capitalizedDay} ${dateNumber}`;
};

export type SlotOfTheDay = {
    slots: Array<AvailabilitySlot>;
    isGroup: boolean;
};

export const getSlotsForDay = (
    weekStartDate: Date,
    day: WeekDay,
    availableSlots: AvailabilitySlot[],
    selectedSlots: AvailabilitySlot[]
) => {
    const dayDate = addDays(weekStartDate, getDayIndex(day));

    // Get selected slots for this specific day
    const selectedSlotsForDay = selectedSlots.filter(
        (slot) => format(parseISO(slot.start_date), "yyyy-MM-dd") === format(dayDate, "yyyy-MM-dd")
    );

    // Merge and remove duplicates by slotId
    const allSlots = uniqBy([...availableSlots, ...selectedSlotsForDay], "slotId");

    // Group slots by groupedId (if exists)
    const groupedSlots = allSlots.reduce<Record<string, Array<AvailabilitySlot>>>((acc, slot) => {
        const key = slot.groupedId || slot.slotId;
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(slot);
        return acc;
    }, {});

    // Convert to GroupedSlot format
    const formattedSlots: Array<SlotOfTheDay> = Object.values(groupedSlots).map((slots) => ({
        slots: slots.sort((a, b) => parseISO(a.start_date).getTime() - parseISO(b.start_date).getTime()),
        isGroup: slots.length > 1,
    }));

    // Sort by the start time of first slot
    return formattedSlots.sort((a, b) => parseISO(a.slots[0].start_date).getTime() - parseISO(b.slots[0].start_date).getTime());
};




