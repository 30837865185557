import { Box, CircularProgress } from "@mui/material";

export const CalendarLoader = () => {
    return (
        <Box
            sx={{
                position: "absolute",
                top: "51%",
                left: "59%",
                transform: "translate(-50%, -50%)",
                zIndex: 2,
            }}
        >
            <CircularProgress size={50} />
        </Box>
    );
};
