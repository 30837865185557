import { UseFormReturn, FieldValues, Controller, RegisterOptions } from "react-hook-form";
import { useRecords } from "../../api/useRecords";
import { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";

type AsyncAutoCompleteNewProps = {
    entityForm: UseFormReturn<FieldValues>;
    fieldName: string;
    disabled?: boolean;
    placeholder?: string;
    rules?: RegisterOptions<FieldValues, string>;
    recordsOptions: {
        entity: string;
        fieldsToQuery: string;
        filter?: any;
        customFilter?: (value: string) => any;
        labelKey: string | ((option: any) => string);
        idFromParams?: string;
    };
    enabled?: boolean;
};

export const AsyncAutoCompleteNew = ({
    entityForm,
    fieldName,
    disabled,
    placeholder,
    recordsOptions,
    rules,
    enabled,
}: AsyncAutoCompleteNewProps) => {
    const { entity, fieldsToQuery, filter, customFilter, idFromParams } = recordsOptions;
    const [queryDetails, setQueryDetails] = useState<any>(null);
    const [inputValue, setInputValue] = useState<string>("");
    const [items, setItems] = useState<readonly any[]>([]);

    const { data, isInitialLoading, refetch } = useRecords(entity, fieldsToQuery, {
        extraOptions: {
            filter: queryDetails?.filter,
        },
        enabled: enabled,
    });


    useEffect(() => {
        const shouldFetchFromParams = idFromParams && !queryDetails;
        const newQueryDetails = shouldFetchFromParams
            ? {
                  entity: recordsOptions.entity,
                  fieldsToQuery: fieldsToQuery,
                  filter: { id: { _eq: idFromParams } },
              }
            : queryDetails;

        if (newQueryDetails) {
            setQueryDetails(newQueryDetails);
            refetch();
        }
    }, [queryDetails, idFromParams]);

    useEffect(() => {
        if (!data) return;

        const currentValue = entityForm.getValues(fieldName);
        const newItems = [...data.items];

        if (currentValue && !newItems.some((item) => item.id === currentValue.id)) {
            newItems.unshift(currentValue);
        }

        setItems(newItems);

        if (idFromParams && data.items.length > 0) {
            const selectedItem = data.items[0];
            entityForm.setValue(fieldName, selectedItem);
            setInputValue(
                typeof recordsOptions.labelKey === "function"
                    ? recordsOptions.labelKey(selectedItem)
                    : selectedItem[recordsOptions.labelKey] || ""
            );
        }
    }, [data]);

    const debouncedSave = useCallback(
        debounce((newValue: string) => {
            setQueryDetails({
                entity: recordsOptions.entity,
                fieldsToQuery: fieldsToQuery,
                filter: customFilter ? customFilter(newValue) : filter(newValue),
            });
        }, 500),
        []
    );

    const updateValue = (newValue: string) => {
        setInputValue(newValue);
        debouncedSave(newValue);
    };

    return (
        <Controller
            name={fieldName}
            control={entityForm.control}
            defaultValue={null}
            rules={rules}
            render={({ field }) => (
                <Autocomplete
                    {...field}
                    onInputChange={(_, newInputValue) => {
                        setInputValue(newInputValue);
                        if (newInputValue.length > 2) {
                            updateValue(newInputValue);
                        }
                    }}
                    fullWidth
                    onChange={(_, newValue) => {
                        field.onChange(newValue);
                    }}
                    options={items}
                    loading={isInitialLoading}
                    disabled={disabled}
                    isOptionEqualToValue={(option, value) => {
                        return option.id === value.id;
                    }}
                    getOptionLabel={(option) => {
                        if (!option) return "";

                        if (typeof recordsOptions.labelKey === "function") {
                            return recordsOptions.labelKey(option);
                        }

                        return option[recordsOptions.labelKey] || "";
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            value={inputValue}
                            label={placeholder || "Pesquisar..."}
                            size="medium"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: <>{isInitialLoading ? <CircularProgress color="inherit" size={20} /> : null}</>,
                            }}
                        />
                    )}
                />
            )}
        />
    );
};
