import { useQuery } from "@tanstack/react-query";
import { api } from "./config";
import { formatField } from "../utils/formatFields";
import { useContext } from "react";
import { CollectionsContext } from "../components/root/CollectionsProvider";

export const useField = (entity: string, field: string) => {
    const collections = useContext(CollectionsContext);

    const { data: rawField, status } = useQuery({
        queryKey: [`${entity}-${field}`],
        queryFn: () => {
            const hasAccessToField = collections?.fields.find(
                (fieldApi) => fieldApi.field === field && fieldApi.collection === entity
            );
            if (!hasAccessToField) return null;

            return getField(entity, field);
        },
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        retry: false,
    });

    if (status === "loading" || status === "error") return { rawField, formatedFields: [], status };

    if (!rawField) return { rawField, formatedFields: [], status };

    const formatedField = formatField(rawField);

    return { rawField, formatedField, status };
};

export async function getField(entity: string, field: string) {
    const response = await api({
        method: "GET",
        url: `/fields/${entity}/${field}`,
    });

    return response.data.data;
}
