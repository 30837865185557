import { fieldsQuery as fieldsQueryTreatments } from "../screens/treatments/treatmentRoutes";

export const servicesSearchFilter = (newValue: string) => {
    return {
        _or: [
            {
                sku: {
                    _icontains: newValue,
                },
            },
            {
                name: {
                    _icontains: newValue,
                },
            },
        ],
    };
};

export const servicesVariantSearchFilter = (serviceId: number | string) => {
    return {
        _and: [
            {
                service: {
                    _eq: serviceId,
                },
            },
        ],
    };
};

export const clientsSearchSettings = (newValue: string) => {
    return {
        fieldsQuery: "*.*",
        filter: {
            _or: [
                {
                    client_id: {
                        _icontains: newValue,
                    },
                },
                {
                    user_id: {
                        first_name: {
                            _icontains: newValue,
                        },
                    },
                },
                {
                    user_id: {
                        last_name: {
                            _icontains: newValue,
                        },
                    },
                },
                {
                    user_id: {
                        email: {
                            _icontains: newValue,
                        },
                    },
                },
                {
                    user_id: {
                        phone: {
                            _icontains: newValue,
                        },
                    },
                },
                {
                    user_id: {
                        email: {
                            _icontains: newValue,
                        },
                    },
                },
            ],
        },
    };
};

export const treatmentsSearchSettings = (newValue: string) => {
    return {
        fieldsQuery: fieldsQueryTreatments,

        filter: {
            _or: [
                {
                    client: {
                        client_id: {
                            _icontains: newValue,
                        },
                    },
                },
                {
                    client: {
                        user_id: {
                            first_name: {
                                _icontains: newValue,
                            },
                        },
                    },
                },
                {
                    client: {
                        user_id: {
                            last_name: {
                                _icontains: newValue,
                            },
                        },
                    },
                },
                {
                    client: {
                        user_id: {
                            phone: {
                                _icontains: newValue,
                            },
                        },
                    },
                },
                {
                    client: {
                        user_id: {
                            email: {
                                _icontains: newValue,
                            },
                        },
                    },
                },
            ],
        },
    };
};

export const leadsSearchSettings = (newValue: string) => {
    return {
        fieldsQuery: "*.*",
        filter: {
            _or: [
                {
                    first_name: {
                        _icontains: newValue,
                    },
                },
                {
                    last_name: {
                        _icontains: newValue,
                    },
                },
                {
                    email: {
                        _icontains: newValue,
                    },
                },
                {
                    phone: {
                        _icontains: newValue,
                    },
                },
            ],
        },
    };
};
