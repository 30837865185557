import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

type FilterTypeProps = {
    filterType: "_and" | "_or";
    setFilterType: React.Dispatch<React.SetStateAction<"_and" | "_or">>;
};
export const FilterType = ({ filterType, setFilterType }: FilterTypeProps) => {
    return (
        <FormControl fullWidth>
            <InputLabel>Tipo de filtro</InputLabel>
            <Select
                label="Tipo de filtro"
                fullWidth
                defaultValue={filterType}
                value={filterType}
                onChange={(e) => {
                    setFilterType(e.target.value as "_and" | "_or");
                }}
            >
                <MenuItem value="_and">Todas as condições tem que ser verdade</MenuItem>
                <MenuItem value="_or">Pelo menos uma das condições tem que ser verdade</MenuItem>
            </Select>
        </FormControl>
    );
};
