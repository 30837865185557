import { Box, Typography } from "@mui/material";
import { RenderTreatmentOptionProps, RenderClientOptionProps, RenderLeadOptionProps } from "../../types/globalSearch.types";
import { findField } from "../../utils/findField";
import { renderFieldView } from "../fieldsViews/renderFieldView";
import { EmojiPeople, Groups, Psychology } from "@mui/icons-material";
import { useFields } from "../../api/useFields";

const styles = {
    display: "flex",
    alignItems: "center",
    columnGap: 4,
};

export const RenderTreatmentOption = ({ option }: RenderTreatmentOptionProps) => {
    const { formatedFields } = useFields("treatments");
    const statusField = findField(formatedFields, "treatment_status");

    return (
        <Box sx={styles}>
            <Box>
                <Psychology color="secondary" />
            </Box>
            <Box>
                <Typography>Cliente #: {option.client.client_id}</Typography>
                <Typography>
                    Nome: {option.client?.user_id?.first_name} {option.client?.user_id?.last_name}
                </Typography>
                <Box display="flex" alignItems="center" gap={3}>
                    <Typography>Estado:</Typography>
                    {statusField &&
                        renderFieldView(statusField, option, "group", { field: "treatment_status", style: { size: "small" } })}
                </Box>
            </Box>
        </Box>
    );
};

export const RenderClientOption = ({ option }: RenderClientOptionProps) => {
    return (
        <Box sx={styles}>
            <Box>
                <Groups color="secondary" />
            </Box>
            <Box>
                <Typography>Cliente #: {option.client_id}</Typography>
                <Typography>
                    Nome: {option?.user_id?.first_name} {option?.user_id?.last_name}
                </Typography>
                <Typography>
                    Telemóvel: {option?.user_id?.phone} {option?.user_id?.phone}
                </Typography>
                <Typography>Email: {option?.user_id?.email}</Typography>
            </Box>
        </Box>
    );
};

export const RenderLeadOption = ({ option }: RenderLeadOptionProps) => {
    const { formatedFields } = useFields("leads");
    const statusField = findField(formatedFields, "status");
    return (
        <Box sx={styles}>
            <Box>
                <EmojiPeople color="secondary" />
            </Box>
            <Box>
                <Typography>
                    Nome: {option.first_name} {option.last_name}
                </Typography>
                <Typography>Email: {option.email}</Typography>
                <Typography>Telemóvel: {option.phone}</Typography>
                <Box display="flex" alignItems="center" gap={3}>
                    <Typography>Estado: </Typography>
                    {statusField && renderFieldView(statusField, option, "group", { field: "status", style: { size: "small" } })}
                </Box>
            </Box>
        </Box>
    );
};
