import { Box, FormControl, IconButton, InputLabel, MenuItem, Select } from "@mui/material";
import { useStore } from "../../store/store";
import { SaveAndUpdateSettings } from "./SaveAndUpdateSettings";
import { ViewColumnRounded } from "@mui/icons-material";

export const SelectSettings = () => {
    const { gridSettings } = useStore();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        gridSettings.setGridSettings({ configColumnsModalIsOpen: true, configColumnsModalAnchorEl: event.currentTarget });
    };


    return (
        <Box padding={2} display="flex" justifyContent="space-between" alignItems="center">
            <FormControl fullWidth>
                <InputLabel>Vista</InputLabel>
                <Select
                    label="Vista"
                    size="small"
                    value={
                        gridSettings.selectedSettings?.collection === gridSettings.entity ? gridSettings.selectedSettings?.id : ""
                    }
                    onChange={(event) => {
                        const itemId = event.target.value;
                        const selectedItem = gridSettings.apiSettings?.find((item) => item.id === itemId);
                        if (selectedItem) {
                            gridSettings.setGridSettings({ selectedSettings: selectedItem });
                        }
                    }}
                >
                    {gridSettings.apiSettings?.map((item) => {
                        return (
                            <MenuItem key={item.id} value={item.id}>
                                {item.name} {item.isActive ? "- Ativo" : null}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
            <SaveAndUpdateSettings />
            <IconButton onClick={handleClick}>
                <ViewColumnRounded fontSize="large" color="primary" />
            </IconButton>
        </Box>
    );
};
