import { UseRecordsOptions } from "../../api/useRecords";

export const templatesOptions = {
    fieldsToRemove: {
        fieldNamesToRemove: ["id", "content"],
        fieldTypesToRemove: ["alias"],
    },
    groupsOptions: {
        fieldsWithExtraOptions: [
            {
                field: "field_with_template",
                sort: "collection",
                enabled: true,
            },
        ],
    },
} as unknown as UseRecordsOptions;
