export type UserItem = {
    user_id: {
        id: string;
        avatar: string;
        email: string;
        first_name: string;
        last_name: string;
        phone: string;
    };
    client_user_id: string;
    user_avatar: string;
    user_first_name: string;
    user_last_name: string;
    user_email: string;
    user_phone: string;
};

export function checkIfHasUserAndAddUserValues(item: UserItem) {
    if (item.user_id) {
        item.user_avatar = item.user_id.avatar;
        item.user_first_name = item.user_id.first_name;
        item.user_last_name = item.user_id.last_name;
        item.user_email = item.user_id.email;
        item.user_phone = item.user_id.phone;
        item.client_user_id = item.user_id.id;
    }
}
