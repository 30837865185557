import { Record } from "../../components/record/Record";
import { Records } from "../../components/records/Records";
import { LeadDetailHeader } from "./LeadDetailHeader";
import { tabs } from "./leadsTabs";
import { actions } from "./leadsActions";
import { DetailTabs } from "../../components/detailTabs/DetailTabs";
import { EntityForm } from "../../components/entityForm/EntityForm";
import { Groups } from "../../components/groups/Groups";
import { LeadsListHeader } from "./LeadsListHeader";
import { leadsOptions } from "./leadsOptions";
import { NotesComponent } from "../../components/notes/NotesComponent";
import { recordsRelatedCustomFilter } from "../../utils/recordsRelatedCustomFilter";
import { RecordsRelated } from "../../components/recordsRelated/RecordsRelated";
import { tasksOptions } from "../tasks/tasksOptions";
import { relativesOptions } from "../relatives/relativesOptions";
import { sessionsOptions } from "../sessions/sessionsOptions";
import { communicationsOptions } from "../communications/communicationsOptions";

const fieldsQuery = "*.*,client.user_id.*,diagnose_secondary_multi.disorders_id.*";

const heightOfHeaders = 375;

export const leadsRoutes = [
    {
        path: "/leads",
        element: (
            <Records
                entity="leads"
                fieldsQuery={fieldsQuery}
                header={<LeadsListHeader />}
                options={leadsOptions}
                heightOfHeaders={240}
            />
        ),
    },
    {
        path: "/leads/:id",
        element: (
            <Record
                entity="leads"
                type="detail"
                fieldsQuery={fieldsQuery}
                header={<LeadDetailHeader actions={actions} />}
                body={<DetailTabs tabs={tabs} />}
            />
        ),
        children: [
            {
                path: "/leads/:id/detail",
                element: <Groups groupsOptions={leadsOptions.groupsOptions} />,
            },
            {
                element: (
                    <RecordsRelated
                        entity="sessions"
                        fieldsToQuery="*.*,technician.user_id.*"
                        fieldToFilter="lead"
                        recordsOptions={sessionsOptions}
                        heightOfHeaders={heightOfHeaders}
                    />
                ),
                path: "/leads/:id/sessions",
            },
            {
                element: (
                    <RecordsRelated
                        entity="relatives"
                        fieldsToQuery="*.*,lead.user_id.*"
                        fieldToFilter="lead"
                        recordsOptions={relativesOptions}
                    />
                ),
                path: "/leads/:id/relatives",
            },
            {
                element: <NotesComponent />,
                path: "/leads/:id/notes",
            },
            {
                element: (
                    <RecordsRelated
                        entity="tasks"
                        fieldsToQuery="*.*,assigned_users.directus_users_id.*, related_with.item:leads.*, related_with.item:clients.*, related_with.item:treatments.client.client_id,status, comments.user_created.*"
                        customFilter={recordsRelatedCustomFilter}
                        recordsOptions={tasksOptions}
                        heightOfHeaders={heightOfHeaders}
                    />
                ),
                path: "/leads/:id/tasks",
            },
            {
                element: (
                    <RecordsRelated
                        entity="communications"
                        fieldsToQuery="*.*,related_with.item:leads.*, related_with.item:clients.*"
                        recordsOptions={communicationsOptions}
                        customFilter={recordsRelatedCustomFilter}
                        heightOfHeaders={heightOfHeaders}
                    />
                ),
                path: "/leads/:id/communications",
            },
        ],
    },
    {
        path: "/leads/:id/update",
        element: (
            <Record
                entity="leads"
                type="update"
                fieldsQuery={fieldsQuery}
                header={<LeadDetailHeader />}
                body={<EntityForm groupsOptions={leadsOptions?.groupsOptions} />}
            />
        ),
    },
    {
        path: "/leads/create",
        element: (
            <Record
                entity="leads"
                type="create"
                fieldsQuery={fieldsQuery}
                body={<EntityForm groupsOptions={leadsOptions?.groupsOptions} />}
            />
        ),
    },
    // {
    //     path: "/leads/communications",
    //     element: (
    //         <Records
    //             entity="communications"
    //             fieldsQuery="*.*"
              
    //             heightOfHeaders={240}
    //         />
    //     ),
    // },
];
