import { DetailHeader } from "../../components/detailHeader/DetailHeader";
import { Box, Typography } from "@mui/material";
import { RecordContext } from "../../components/record/Record";
import { useContext } from "react";
import { Actions } from "../../components/actionsMenu/ActionsMenu";
import { Progress } from "../../components/progress/Progress";
import { CallButton } from "../../components/phoneLetsCall/CallButton";


type LeadsHeaderProps = {
    actions?: Actions;
};

export const LeadDetailHeader = ({ actions }: LeadsHeaderProps) => {
    const record = useContext(RecordContext);
    

    if (!record) return null;
    const { first_name, last_name, phone } = record.item;

    return (
        <>
            <DetailHeader actions={actions} collection={record.collection}>
                <Typography variant="h6" color="secondary">
                    Lead
                </Typography>
                <Typography variant="h5">
                    {first_name} {last_name}
                </Typography>
                <Box display="flex" alignItems="center" gap={4}>
                    <Typography variant="body1">{phone} </Typography>
                    <CallButton phone={phone} />
                </Box>
            </DetailHeader>
            {record.type === "detail" ? <Progress context={record} /> : null}
        </>
    );
};
