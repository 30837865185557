import { GridCellParams } from "@mui/x-data-grid";
import { UseRecordsOptions } from "../../api/useRecords";
import { UpdateStatusTransactionShortcut } from "./UpdateStatusTransactionShortcut";

export const bankTransactionsOptions = {
    fieldsToRemove: {
        fieldNamesToRemove: ["id"],
    },
    groupsOptions: {
        fieldsWithExtraOptions: [
            {
                field: "category",
                sort: "parent_transaction_category.name",
                enabled: true,
                relatedWith: "company",
                dependency: {
                    field: "company",
                    placeHolder: "Selecione uma empresa",
                },
                createFilter: (params: any) => filterCategories(params),
            },
            {
                field: "vendor",
                sort: "name",
                enabled: true,
            },
            {
                field: "company",
                sort: "name",
                enabled: true,
            },
            {
                field: "documents",
                fileFolder: "765cd0db-5414-464d-9640-81861878839e",
            },
        ],
    },
    dataGridOptions: {
        quickSearchToolBar: {
            active: true,
            inputLabel: "Pesquise por descrição ou valor...",
            fieldsToSearch: ["description", "amount"],
        },
        extraColumns: {
            beggining: [
                {
                    field: "status",
                    headerName: "Estado",
                    width: 75,
                    renderCell: (params: GridCellParams) => {
                        return <UpdateStatusTransactionShortcut id={params.row.id} status={params.row.status} />;
                    },
                },
            ],
            end: [],
        },
    },
} as unknown as UseRecordsOptions;

const filterCategories = (params: any) => {
    var filter = {};

    if (!params || !params.id) return {};
    filter = {
        _and: [
            {
                companies: {
                    company_id: {
                        id: {
                            _eq: params.id,
                        },
                    },
                },
            },
        ],
    } as any;

    return filter;
};
