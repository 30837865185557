import { DetailHeader } from "../../components/detailHeader/DetailHeader";
import { Box, CircularProgress, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { RecordContext } from "../../components/record/Record";
import { useContext } from "react";
import { Actions } from "../../components/actionsMenu/ActionsMenu";
import { ClientSessionsSummary } from "../clients/ClientSessionsSummary";
import { useClientSessionsSummary } from "../../api/useClientSessionsSummary";
import { CallButton } from "../../components/phoneLetsCall/CallButton";

type TreatmentHeaderProps = {
    actions?: Actions;
};

export const TreatmentHeader = ({ actions }: TreatmentHeaderProps) => {
    const recordContext = useContext(RecordContext);

    const clientId = recordContext?.item?.client?.id;
    const treatmentId = recordContext?.item?.id;
    const { data: sessions, status } = useClientSessionsSummary(clientId, treatmentId);

    if (!recordContext) return null;

    const { client } = recordContext.item;
    const phone = client?.user_id?.phone;
    return (
        <DetailHeader actions={actions} collection={recordContext.collection}>
            <Grid container alignItems="flex-start" display="flex" flexWrap="nowrap" flexDirection="row" rowGap={5}>
                <Grid minWidth={300} xs={12} sm={6}>
                    <Typography variant="h6" color="secondary">
                        Tratamento
                    </Typography>
                    <Typography variant="h5">{client?.client_id}</Typography>

                    <Typography variant="body1">
                        {client?.user_id?.first_name} {client?.user_id?.last_name}
                    </Typography>
                    <Box display="flex" alignItems="center" gap={4}>
                        <Typography variant="body1">{phone}</Typography>
                        {phone && <CallButton phone={phone} />}
                    </Box>
                </Grid>
                <Grid xs={12} sm={6}>
                    {status === "loading" ? <CircularProgress /> : null}
                    {sessions && (
                        <ClientSessionsSummary sessions={sessions} options={{ flexDirection: "row" }} isTreatment={true} />
                    )}
                </Grid>
            </Grid>
        </DetailHeader>
    );
};
