import { TextField, Button, Grid, Typography, Paper } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { createFormattedSchedules } from "../utils/scheduleUtils";
import { useCreateRecord } from "../../../api/useCreateRecord";
import { useStore } from "../../../store/store";
import { renderFieldInput } from "../../../components/fieldsInputs/renderFieldInput";
import { findField } from "../../../utils/findField";
import { useFields } from "../../../api/useFields";
import { useContext } from "react";
import { RecordContext } from "../../../components/record/Record";
import { getFieldExtraOption } from "../../../utils/getFieldExtraOption";
import { AddCircle } from "@mui/icons-material";
import { parseISO } from "date-fns";
import { SchedulesHeader } from "./SchedulesHeader";
import { secondaryColor } from "../../../theme";
import { weekDays, weekDaysTranslation } from "../../../utils/constants";


export const SchedulesAddMultiple = () => {
    const context = useContext(RecordContext);
    const createSchedules = useCreateRecord();
    const {
        notifications: { setNotification },
    } = useStore();

    const { formatedFields } = useFields("schedules", context?.options);
    const servicesField = findField(formatedFields, "services");
    const extraOptions = context?.options?.groupsOptions?.fieldsWithExtraOptions;
    const fieldExtraOptions = extraOptions ? getFieldExtraOption(extraOptions, servicesField) : null;

    const entityForm = useForm({
        defaultValues: {
            user: "",
            startDate: null,
            endDate: null,
            schedules: weekDays.map((day) => ({ day, slots: [] })),
        },
    });

    const { fields } = useFieldArray({
        control: entityForm.control,
        name: "schedules",
    });

    const onSubmit = async (data: any) => {
        if (!data.startDate || !data.endDate || !data.user) {
            setNotification({ isOpen: true, message: "Please fill in all required fields", severity: "error" });
            return;
        }

        const formattedSchedules = createFormattedSchedules(
            data.startDate,
            data.endDate,
            data.user,
            data.clinic,
            data.schedules.reduce((acc: any, { day, slots }: any) => ({ ...acc, [day]: slots }), {})
        );

        try {
            await createSchedules.mutateAsync({ entity: "schedules", data: formattedSchedules });
            setNotification({ isOpen: true, message: "Schedules created successfully", severity: "success" });
        } catch (error) {
            setNotification({ isOpen: true, message: "Error creating schedules", severity: "error" });
        }
    };

    const hasSlotsAdded = (index: number) => entityForm.watch(`schedules.${index}.slots`).length > 0;

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <form onSubmit={entityForm.handleSubmit(onSubmit)}>
                <SchedulesHeader entityForm={entityForm} />
                <Grid container spacing={2} p={2}>
                    {fields.map((field, index) => (
                        <Paper
                            elevation={0}
                            sx={{
                                backgroundColor: "background.paper",
                                p: 3,
                                width: "100%",
                                mt: 6,
                                borderRadius: 4,
                                boxShadow: hasSlotsAdded(index) ? `0 0 20px ${secondaryColor}30` : "none",
                                border: hasSlotsAdded(index) ? `2px solid ${secondaryColor}30` : "1px solid rgba(0, 0, 0, 0.12)",
                                transition: "all 0.3s ease-in-out",
                            }}
                            key={field.id}
                        >
                            <Grid item xs={12} mb={5}>
                                <Typography mb={4} variant="h6" color={hasSlotsAdded(index) ? "primary" : "gray"}>
                                    {weekDaysTranslation[field.day]}
                                </Typography>
                                <NestedArray
                                    nestIndex={index}
                                    entityForm={entityForm}
                                    servicesField={servicesField}
                                    fieldExtraOptions={fieldExtraOptions}
                                />
                            </Grid>
                        </Paper>
                    ))}
                </Grid>
            </form>
        </LocalizationProvider>
    );
};

const NestedArray = ({
    nestIndex,
    entityForm,
    servicesField,
    fieldExtraOptions,
}: {
    nestIndex: number;
    entityForm: any;
    servicesField: any;
    fieldExtraOptions: any;
}) => {
    const { fields, append, remove } = useFieldArray({
        control: entityForm.control,
        name: `schedules.${nestIndex}.slots`,
    });

    const handleAddTimeSlot = (timeSlot: "morning" | "afternoon") => {
        const isSaturday = weekDays[nestIndex] === "Saturday";
        const defaultSlots = {
            morning: {
                start: parseISO(`2000-01-01T${isSaturday ? "09:00" : "10:00"}`),
                end: parseISO("2000-01-01T13:00"),
            },
            afternoon: {
                start: parseISO("2000-01-01T14:00"),
                end: parseISO(`2000-01-01T${isSaturday ? "19:00" : "20:00"}`),
            },
        };

        append({
            start: defaultSlots[timeSlot].start,
            end: defaultSlots[timeSlot].end,
            type: "service",
            notes: "",
        });
    };

    return (
        <>
            {fields.map((item, k) => (
                <Grid container spacing={2} key={item.id} mb={2}>
                    <Grid item xs={3}>
                        <Controller
                            name={`schedules.${nestIndex}.slots.${k}.start`}
                            control={entityForm.control}
                            defaultValue={null}
                            render={({ field }) => <TimePicker {...field} label="Hora de início" ampm={false} />}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Controller
                            name={`schedules.${nestIndex}.slots.${k}.end`}
                            control={entityForm.control}
                            defaultValue={null}
                            render={({ field }) => <TimePicker {...field} label="Hora de fim" ampm={false} />}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        {servicesField &&
                            renderFieldInput(servicesField, null, "group", entityForm, {
                                ...fieldExtraOptions,
                                field: `schedules.${nestIndex}.slots.${k}.services`,
                            })}
                    </Grid>
                    <Grid item xs={3}>
                        <Controller
                            name={`schedules.${nestIndex}.slots.${k}.notes`}
                            control={entityForm.control}
                            defaultValue=""
                            render={({ field }) => <TextField {...field} label="Notes" fullWidth />}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <Button onClick={() => remove(k)}>Remove</Button>
                    </Grid>
                </Grid>
            ))}
            <Grid container spacing={2}>
                <Grid item>
                    <Button startIcon={<AddCircle />} onClick={() => handleAddTimeSlot("morning")} sx={{ mt: 2 }}>
                        Adicionar Horário Manhã
                    </Button>
                </Grid>
                <Grid item>
                    <Button startIcon={<AddCircle />} onClick={() => handleAddTimeSlot("afternoon")} sx={{ mt: 2 }}>
                        Adicionar Horário Tarde
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};
