import { Unstable_Grid2 as Grid, Typography, Switch } from "@mui/material";
import { Wysiwyg } from "../wysiwyg/Wysiwyg";
import { useFields } from "../../api/useFields";
import { RecordContextType } from "../record/Record";
import { renderFieldInput } from "../fieldsInputs/renderFieldInput";
import { findField } from "../../utils/findField";

import { RelatedWithInputField } from "../fieldsInputs/fields/RelatedWithInputField";
import { tasksOptions } from "../../screens/tasks/tasksOptions";
import { getFieldExtraOption } from "../../utils/getFieldExtraOption";
import { formatISO } from "date-fns";
import { TaskDoneShortcut } from "./TaskDoneShortcut";
import { useState } from "react";
import { Controller } from "react-hook-form";

type TasksFormProps = {
    context: RecordContextType;
};

export const TasksForm = ({ context }: TasksFormProps) => {
    const { formatedFields } = useFields("tasks", context.options);

    const dueDateField = findField(formatedFields, "due_date");
    const usersField = findField(formatedFields, "assigned_users");
    const relatedWithField = findField(formatedFields, "related_with");
    const contentField = findField(formatedFields, "content");
    const serviceField = findField(formatedFields, "service");
    const service_alert_number_field = findField(formatedFields, "service_alert_number");
    const [showServiceAlert, setShowServiceAlert] = useState(context.item?.alert || false);

    const { entityForm } = context;
    const date = new Date();
    date.setHours(8, 0, 0, 0);

    const isEmptyObject = (obj: object) => Object.keys(obj).length === 0;
    const formValues = isEmptyObject(entityForm.getValues()) ? context.item : entityForm.getValues();
    const hasTreatment =
        formValues?.multiple_related_with?.create?.some((item: any) => item.collection === "treatments" && item.item?.id) ||
        formValues?.multiple_related_with?.update?.some((item: any) => item.collection === "treatments" && item.item?.id) ||
        formValues?.related_with?.some((item: any) => item.collection === "treatments" && item.id);

    dueDateField.settings.readonly = showServiceAlert ? true : false;

    return (
        <Grid container spacing={2} mt={2} justifyContent="space-between" alignItems="flex-start">
            <Grid xs={8}>
                <Wysiwyg entityForm={entityForm} initialValue={context?.item?.content} field={contentField} size="485px" />
            </Grid>
            <Grid xs={4} container spacing={5} pl={5}>
                {context.item ? (
                    <Grid xs={12} display="flex" alignItems="center">
                        <Typography variant="h6">Concluída:</Typography>
                        <TaskDoneShortcut id={context.item.id} status={context.item.status} />
                    </Grid>
                ) : null}

                <Grid xs={12}>
                    {dueDateField
                        ? renderFieldInput(
                              dueDateField,
                              showServiceAlert ? { due_date: null } : context?.item || { due_date: formatISO(date) },
                              "group",
                              entityForm
                          )
                        : null}
                </Grid>
                <Grid xs={12}>
                    {usersField
                        ? //@ts-ignore
                          renderFieldInput(
                              usersField,
                              context.item,
                              "group",
                              entityForm,
                              getFieldExtraOption(tasksOptions?.groupsOptions?.fieldsWithExtraOptions, usersField)
                          )
                        : null}
                </Grid>
                <Grid xs={12}>
                    {relatedWithField && (
                        <RelatedWithInputField item={context?.item} entityForm={entityForm} field={relatedWithField} />
                    )}
                </Grid>
                <Grid xs={12} container spacing={5} pl={5} mt={2}>
                    <Typography variant="h6"> Alerta por nº de sessões:</Typography>

                    <Controller
                        name={"alert"}
                        control={entityForm.control}
                        render={({ field }) => (
                            <Switch
                                {...field}
                                checked={showServiceAlert}
                                onChange={(e) => {
                                    field.onChange(e.target.checked);
                                    setShowServiceAlert(e.target.checked);
                                }}
                            />
                        )}
                    />
                    {showServiceAlert && !hasTreatment && (
                        <Typography color="error" mb={2}>Tem que escolher um tratamento para definir alerta</Typography>
                    )}
                    {showServiceAlert && (
                        <>
                            <Grid xs={12}>
                                {serviceField && renderFieldInput(serviceField, context?.item, "group", entityForm)}
                            </Grid>
                            <Grid xs={12}>
                                {service_alert_number_field &&
                                    renderFieldInput(service_alert_number_field, context?.item, "group", entityForm)}
                            </Grid>
                        </>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};
