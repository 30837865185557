import { Avatar as AvatarMui, SxProps } from "@mui/material";
import store2 from "store2";
import { FieldView } from "../../../types/fields.types";
import { AccountCircle } from "@mui/icons-material";
import { baseUrl } from "../../../api/config";

type AvatarProps = {
    value?: string;
    fieldView?: FieldView;
    styles?: SxProps;
};

export function Avatar({ value, fieldView, styles }: AvatarProps) {
    if (!value) return <AccountCircle fontSize="large" color="disabled" />;
    const access_token = store2.get("auth")?.access_token;

    if (!access_token) return <AccountCircle fontSize="large" color="disabled" />;

    return (
        <AvatarMui sx={styles} src={`${baseUrl}/assets/${value}?key=thumbnail&access_token=${store2.get("auth").access_token}`} />
    );
}
