import TextField from "@mui/material/TextField";
import { Controller } from "react-hook-form";
import { FieldProps } from "../renderFieldInput";
import { get } from "lodash";

interface TextInputFieldProps extends FieldProps {
    value: string;
}

export const TextInputField = ({ field, value, entityForm, options, extraOptions }: TextInputFieldProps) => {
    const fieldName = extraOptions?.field ? extraOptions?.field : field.field;
    let fieldError = entityForm.formState.errors;
    const errorMessage = get(fieldError, fieldName)?.message as string;

    return (
        <Controller
            name={fieldName}
            control={entityForm.control}
            defaultValue={value || ""}
            rules={extraOptions?.rules}
            render={({ field: fieldInput }) => (
                <TextField
                    {...fieldInput}
                    disabled={options?.disabled}
                    fullWidth
                    type="text"
                    label={field.headerName}
                    {...options}
                    error={errorMessage ? true : false}
                    helperText={errorMessage}
                />
            )}
        />
    );
};
