import { Unstable_Grid2 as Grid, Typography } from "@mui/material";
import { format, differenceInYears } from "date-fns";

type DateFieldProps = {
    value: {
        [key: string]: any;
    };
    fieldView: string;
};

export function BirthdayAndAge({ value, fieldView }: DateFieldProps) {
    if (!value.value && !value.birthday) return null;

    const birthday = new Date(fieldView === "grid" ? value.value : value.birthday);
    const today = new Date();

    return (
        <Grid container flexDirection="column">
            <Grid>
                <Typography variant="body1">{format(birthday, "dd/MM/yyyy")}</Typography>
            </Grid>
            <Grid>
                <Typography variant="body1">Idade: {differenceInYears(today, birthday)}</Typography>
            </Grid>
        </Grid>
    );
}
