import { FieldProps } from "../../renderFieldInput";
import { DecimalInputField } from "../DecimalInputField";

interface AmountInputFieldProps extends FieldProps {
    value: {
        amount: number;
    };
}

export const AmountInputField = ({ field, value, entityForm, options, extraOptions }: AmountInputFieldProps) => {
    const fieldValue = value?.amount;

    return (
        <DecimalInputField
            field={field}
            value={fieldValue}
            entityForm={entityForm}
            options={options}
            extraOptions={extraOptions}
        />
    );
};
