import { useEffect, useState } from "react";
import logo from "../../public/neuroimprove_fav.png";
import { SubscriptionData } from "../api/useWebSockets";
import { useUser, useUserNotificationPreferences } from "../api/useAuth";
import { validateNotification } from "../utils/validateNotification";


const env = process.env.NODE_ENV;
const frontEndUrl = env === "development" ? "http://localhost:5173" : "https://crm.neuroimprove.pt";

type BrowserNotification = {
    title: string;
    body: string;
    itemPath: string;
};

export const useBrowserNotification = () => {
    const [permission, setPermission] = useState("Notification" in window ? Notification.permission : null);
    const { data: notificationPreferences } = useUserNotificationPreferences();
    const { data: user } = useUser();

    useEffect(() => {
        if ("Notification" in window && Notification.permission === "default") {
            Notification.requestPermission().then((permission) => {
                setPermission(permission);
            });
        }
    }, []);

    const showNotification = ({ title, body, itemPath }: BrowserNotification) => {
        if (permission === "granted") {
            const notification = new Notification(title, {
                body,
                icon: logo,
                requireInteraction: true,
            });

            notification.onclick = () => {
                window.open(`${frontEndUrl}/${itemPath}`, "_blank");
                notification.close();
            };
        }
    };

    const createLeadNotification = (subscriptionData: SubscriptionData) => {
        const { event, uid, data } = subscriptionData;
        const isNotificationValid = validateNotification({ notificationPreferences, uid, event, user });
        if (isNotificationValid && uid === "leads") {
            data.forEach((lead: any) => {
                showNotification({
                    title: "Temos uma nova lead! 📞",
                    body: `Nome: ${lead.first_name} ${lead.last_name}`,
                    itemPath: `leads/${lead.id}/detail`,
                });
            });
        }
    };

    return createLeadNotification;
};
