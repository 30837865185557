import { useQuery } from "@tanstack/react-query";
import { api } from "./config";

export const useUsers = (fieldsQuery: string = "*.*", filter?: any, sort?: string) => {
    const queryKey = `directus_users`;
    return useQuery({
        queryKey: [queryKey],
        queryFn: () => getUsers(fieldsQuery, filter, sort),
    });
};

export const getUsers = async (fieldsQuery?: string, filter?: any, sort?: string) => {
    const response = await api({
        method: "GET",
        url: `users`,
        params: {
            fields: fieldsQuery,
            filter: filter,
            sort: sort,
        },
    });

    const items = response.data.data;

    return { items };
};
