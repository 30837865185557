import { useContext } from "react";
import { RecordsHeader } from "../../components/recordsHeader/RecordsHeader";
import { RecordsContext } from "../../components/records/Records";
import { Box } from "@mui/material";

export const ClientListHeader = () => {
    const recordsContext = useContext(RecordsContext);
    if (!recordsContext) return null;

    return (
        <Box>
            <RecordsHeader collection={recordsContext?.collection} hideCreateButton={true} />
        </Box>
    );
};
