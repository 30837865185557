import { mainColor } from "../../theme";

export const styles = {
    phoneWidget: {
        backgroundColor: mainColor,
        position: "fixed",
        bottom: 10,
        right: "35%",
        width: 300,
        display: "flex",
        flexDirection: "column",
    },
    phoneWidgetHeader: {
        display: "flex",
        alignItems: "center",
        p: 1,        
    },
    phoneWidgetIframe: {
        border: "none",
        backgroundColor: "#f5f5f5",
        height: 200,
    },
};
