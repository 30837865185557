import { useQuery } from "@tanstack/react-query";
import { getItems } from "./items";
import { RecordsRelatedCustomFilterParamsType } from "../utils/recordsRelatedCustomFilter";
import { UseRecordsOptions, generateQueryValue } from "./useRecords";

export const useRelatedRecords = (
    entity: string,
    fieldsQuery: string,
    fieldToFilter?: string,
    parentId: string | number | null | undefined = "",
    customFilter?: (params: RecordsRelatedCustomFilterParamsType) => any,
    options?: UseRecordsOptions
) => {
    const extraQueryValue = generateQueryValue(options);

    return useQuery({
        queryKey: [`${entity}-items-parentId-${parentId}${extraQueryValue}`],
        queryFn: () => getRelatedRecords(entity, fieldsQuery, fieldToFilter, parentId, customFilter, options),
    });
};

export const getRelatedRecords = async (
    entity: string,
    fieldsQuery: string,
    fieldToFilter?: string,
    parentId?: string | number | null | undefined,
    customFilter?: (params: RecordsRelatedCustomFilterParamsType) => any,
    options?: UseRecordsOptions
) => {
    if (!parentId) return { items: [], fields: [], entity };

    let filter;

    if (customFilter) {
        filter = customFilter;
    } else if (fieldToFilter) {
        filter = {
            [fieldToFilter]: {
                _eq: parentId,
            },
        };
    }

    const { items, metadata } = await getItems(entity, fieldsQuery, {
        ...options?.extraOptions,
        filter: filter,
    });

    return { items, metadata, entity };
};
