import Grid from "@mui/material/Unstable_Grid2";
import { RecordContext } from "../../components/record/Record";
import { useContext } from "react";
import { format } from "date-fns";
import { renderFieldInput } from "../../components/fieldsInputs/renderFieldInput";
import { ordersOptions } from "./ordersOptions";
import { findField } from "../../utils/findField";
import { getFieldExtraOption } from "../../utils/getFieldExtraOption";

export const OrderHeaderFields = () => {
    const recordContext = useContext(RecordContext);

    if (!recordContext) return null;

    const paymentMethodField = findField(recordContext.formatedFields, "payment_method");
    const dateField = findField(recordContext.formatedFields, "order_date");
    const treatmentField = findField(recordContext.formatedFields, "treatment");

    return (
        <Grid container alignItems="center" flexGrow={1} justifyContent="flex-start" spacing={3} mt={5}>
            <Grid xs={12} md={6} lg={4}>
                {treatmentField &&
                    renderFieldInput(
                        treatmentField,
                        { treatment: recordContext.item?.treatment || "" },
                        "group",
                        recordContext.entityForm,
                        getFieldExtraOption(ordersOptions?.fieldsWithExtraOptions, treatmentField)
                    )}
            </Grid>
            <Grid xs={12} md={6} lg={2}>
                {dateField &&
                    renderFieldInput(
                        dateField,
                        { order_date: recordContext.item?.order_date || format(new Date(), "yyyy-MM-dd HH:mm") },
                        "group",
                        recordContext.entityForm
                    )}
            </Grid>
            <Grid xs={12} md={6} lg={2}>
                {paymentMethodField &&
                    renderFieldInput(
                        paymentMethodField,
                        { payment_method: recordContext.item?.payment_method || "" },
                        "group",
                        recordContext.entityForm
                    )}
            </Grid>
        </Grid>
    );
};
