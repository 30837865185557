import { Record } from "../../components/record/Record";
import { Records } from "../../components/records/Records";
import { CallCenterLicensesHeader } from "./CallCenterLicensesHeader";
import { tabs } from "./callCenterLicensesTabs";
import { EntityForm } from "../../components/entityForm/EntityForm";
import { Groups } from "../../components/groups/Groups";
import { DetailTabs } from "../../components/detailTabs/DetailTabs";
import { callCenterLicensesOptions } from "./callCenterLicensesOptions";
import { CallCenterLicensesListHeader } from "./CallCenterLicensesListHeader";

export const fieldsQuery = "*.*";

export const callCenterLicensesRoutes = [
    {
        path: "/call_center_licenses",
        element: (
            <Records
                entity="call_center_licenses"
                fieldsQuery={fieldsQuery}
                header={<CallCenterLicensesListHeader />}
                heightOfHeaders={190}
            />
        ),
    },
    {
        path: "/call_center_licenses/:id",
        element: (
            <Record
                entity="call_center_licenses"
                type="detail"
                fieldsQuery={fieldsQuery}
                header={<CallCenterLicensesHeader />}
                body={<DetailTabs tabs={tabs} />}
            />
        ),
        children: [
            {
                path: "/call_center_licenses/:id/detail",
                element: <Groups groupsOptions={callCenterLicensesOptions.groupsOptions} />,
            },
        ],
    },
    {
        path: "/call_center_licenses/create",
        element: <Record entity="call_center_licenses" type="create" body={<EntityForm />} />,
    },
    {
        path: "/call_center_licenses/:id/update",
        element: (
            <Record
                entity="call_center_licenses"
                type="update"
                fieldsQuery={fieldsQuery}
                body={<EntityForm groupsOptions={callCenterLicensesOptions.groupsOptions} />}
            />
        ),
    },
];
