import { useUpdateRecord } from "../../api/useUpdateRecord";
import { FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material";

import { useField } from "../../api/useField";
import { CustomChip } from "../../components/chips/CustomChip";

type UpdateStatusTransactionShortcutProps = {
  id: number;
  status: string;
};

export const UpdateStatusTransactionShortcut = ({ id, status }: UpdateStatusTransactionShortcutProps) => {
  const { formatedField: field } = useField("bank_transactions", "status");
  const value = status || "";

  const mutation = useUpdateRecord();

  const handleChange = (e: SelectChangeEvent) => {
    const newValue = e.target.value as string;

    mutation.mutate({
      entity: "bank_transactions",
      id: id,
      data: {
        status: newValue,
      },
    });
  };

  if (!field) return null;

  return (
    <FormControl fullWidth>
      <Select
        multiple={field.settings.interface === "select-multiple-dropdown"}
        value={value}
        renderValue={() => <CustomChip field={field} label={value} />}
        onChange={(e) => handleChange(e)}
        sx={{
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        }}
      >
        {field.settings.options.choices.map((choice) => {
          return (
            <MenuItem key={choice.value} value={choice.value}>
              {choice.text}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
