import { UseRecordsOptions } from "../../api/useRecords";

export const leadsOptions = {
    fieldsToRemove: {
        fieldTypesToRemove: ["alias"],
        fieldNamesToRemove: ["id"],
    },
    groupsOptions: {
        fieldsWithExtraOptions: [
            {
                field: "client",
                hidden: {
                    create: true,
                },
            },
            {
                field: "clinic",
                enabled: true,
                defaultValue: { id: 1, label: "Porto" }
            },
            {
                field: "diagnose_main",
                sort: "name",
                enabled: true,
            },

            {
                field: "diagnose_secondary_multi",
                relatedTable: "disorders",
                displayTemplate: "{{name}}",
                sort: "name",
                enabled: true,
            }
        ],
    },
} as UseRecordsOptions;
