import { Record } from "../../components/record/Record";
import { tabs } from "./treatmentTabs";
import { DetailTabs } from "../../components/detailTabs/DetailTabs";
import { EntityForm } from "../../components/entityForm/EntityForm";
import { Groups } from "../../components/groups/Groups";
import { RecordsRelated } from "../../components/recordsRelated/RecordsRelated";
import { ordersOptions } from "../orders/ordersOptions";
import { sessionsOptions } from "../sessions/sessionsOptions";
import { treatmentsOptions } from "./treatmentOptions";
import { TreatmentHeader } from "./TreatmentHeader";
import { NotesComponent } from "../../components/notes/NotesComponent";
import { recordsRelatedCustomFilter } from "../../utils/recordsRelatedCustomFilter";
import { tasksOptions } from "../tasks/tasksOptions";
import { evaluationsOptions } from "../evaluations/evaluationsOptions";
import { feedbackOptions } from "../feedbacks/feedbackOptions";
import { actions } from "./treatmentActions";
import { Treatments } from "./Treatments";
import { customerSurveyOptions } from "../customer-surveys/CustomerSurveyOptions";
import { partnersOptions } from "../partners/partnersOptions";
import { SessionsRelated } from "../sessions/components/SessionsRelated";
import { AvailabilityFinderButton } from "../../components/availability-finder/components/AvailabilityFinderButton";
import { AssociateRecord } from "../../components/associate-record/AssociateRecord";
import { Sandbox } from "../../sandbox/Sandbox";

export const fieldsQuery =
  "*.*,client.user_id.*,client_owner.user_id.*,theurapist.user_id.*,client_manager.technicians_id.*,client_manager.technicians_id.user_id.*, theurapists_involved_in_treamtent.technicians_id.user_id.*, theurapists_involved_in_treamtent.technicians_id.*, relative_resposible_for_treatment.relatives_id.*,partners.professional_partners_id.*&deep[sessions][_limit]=-1";

const heightOfHeaders = 398;

export const treatmentRoutes = [
  {
    path: "/treatments",
    element: (
      <Treatments entity="treatments" fieldsQuery={fieldsQuery} options={treatmentsOptions} />
    ),
  },
  {
    path: "/treatments/:id",
    element: (
      <Record
        entity="treatments"
        fieldsQuery={fieldsQuery}
        type="detail"
        header={<TreatmentHeader actions={actions} />}
        body={<DetailTabs tabs={tabs} />}
      />
    ),

    children: [
      {
        path: "/treatments/:id/detail",
        element: <Groups groupsOptions={treatmentsOptions.groupsOptions} />,
      },
      {
        element: (
          <RecordsRelated
            entity="sessions"
            fieldsToQuery="*.*,client.user_id.*,technician.user_id.*,treatment.client.*, treatment.client.user_id.*"
            fieldToFilter="treatment"
            recordsOptions={sessionsOptions}
            heightOfHeaders={heightOfHeaders}
            customComponentHeader={<AvailabilityFinderButton />}
            customComponentContent={<SessionsRelated heightOfHeaders={heightOfHeaders} />}
          />
        ),
        path: "/treatments/:id/sessions",
      },
      {
        element: (
          <RecordsRelated
            entity="evaluations"
            fieldsToQuery="*.*, client.user_id.*, treatment.client.*,  treatment.client.user_id.*"
            fieldToFilter="treatment"
            recordsOptions={evaluationsOptions}
            heightOfHeaders={heightOfHeaders}
          />
        ),
        path: "/treatments/:id/evaluations",
      },
      {
        element: (
          <RecordsRelated
            entity={"orders"}
            fieldsToQuery="*.*,client_id.user_id.*,treatment.client.*, treatment.client.user_id.*"
            fieldToFilter="treatment"
            recordsOptions={ordersOptions}
            heightOfHeaders={heightOfHeaders}
          />
        ),
        path: "/treatments/:id/orders",
      },
      {
        element: <NotesComponent />,
        path: "/treatments/:id/notes",
      },
      {
        element: (
          <RecordsRelated
            entity={"feedbacks"}
            fieldsToQuery="*.*, treatment.client.*, treatment.client.user_id.*"
            fieldToFilter="treatment"
            recordsOptions={feedbackOptions}
            heightOfHeaders={heightOfHeaders}
          />
        ),
        path: "/treatments/:id/feedbacks",
      },
      {
        element: (
          <RecordsRelated
            entity="tasks"
            fieldsToQuery="*.*,assigned_users.directus_users_id.*, related_with.item:leads.*, related_with.item:clients.*, related_with.item:treatments.client.client_id,status, comments.user_created.*"
            customFilter={recordsRelatedCustomFilter}
            recordsOptions={tasksOptions}
            heightOfHeaders={heightOfHeaders}
          />
        ),
        path: "/treatments/:id/tasks",
      },
      {
        element: (
          <RecordsRelated
            entity="customer_surveys"
            fieldsToQuery="*.*,treatment.client.*.*"
            fieldToFilter="treatment"
            recordsOptions={customerSurveyOptions}
            heightOfHeaders={heightOfHeaders}
          />
        ),
        path: "/treatments/:id/customer_surveys",
      },
      {
        element: (
          <RecordsRelated
            entity="professional_partners"
            fieldsToQuery="*.*"
            customFilter={(params) => {
              return {
                treatments: {
                  treatments_id: {
                    id: {
                      _eq: params.parentId,
                    },
                  },
                },
              };
            }}
            recordsOptions={partnersOptions}
            heightOfHeaders={heightOfHeaders}
            customComponentHeader={<AssociateRecord />}
          />
        ),
        path: "/treatments/:id/professional_partners",
      },
      {
        element: <Sandbox />,
        path: "/treatments/:id/timeline",
      },
    ],
  },
  {
    path: "/treatments/:id/update",
    element: (
      <Record
        entity="treatments"
        fieldsQuery={fieldsQuery}
        type="update"
        body={<EntityForm groupsOptions={treatmentsOptions.groupsOptions} />}
      />
    ),
  },
  {
    path: "/treatments/create",
    element: (
      <Record
        entity="treatments"
        fieldsQuery={fieldsQuery}
        type="create"
        body={<EntityForm groupsOptions={treatmentsOptions.groupsOptions} />}
      />
    ),
  },
];
