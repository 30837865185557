import { useEffect, useState } from "react";
import { ToggleButtonGroup, ToggleButton, Typography } from "@mui/material";
import { RecordContextType } from "../../components/record/Record";
import { findField } from "../../utils/findField";
import { useUpdateRecord } from "../../api/useUpdateRecord";
import { RecordsContextType } from "../../components/records/Records";
import { isArray, get } from "lodash";
import { useStore } from "../../store/store";

type ProgressProps = {
    context: RecordContextType | RecordsContextType;
};

type ProgressStatusType = {
    progress: {
        _eq: string;
    };
};

export const Progress = ({ context }: ProgressProps) => {
    const [progressStatus, setProgressStatus] = useState<string | Array<string> | null>();
    const updateProgresStatus = useUpdateRecord();
    const [progressFilter, setProgressFilter] = useStore((state) => [
        state.progressFilter || state.gridSettings.selectedSettings?.config?.filters?.progress,
        state.setProgressFilter,
  
    ]);


    useEffect(() => {
        if (context.type === "detail") {
            setProgressStatus(context.item.progress);
        }

        if (context.type === "list") {
            const progressStatusObj = progressFilter && progressFilter[context.collection.collection]?._or;

            if (!progressStatusObj) return;

            const allStatus = progressStatusObj.reduce((acc: Array<string>, cur: ProgressStatusType) => {
                const value = get(cur, "progress._eq");
                acc.push(value);
                return acc;
            }, []);

            setProgressStatus(allStatus);
        }
    }, [context, progressFilter]);

    if (!context) return null;

    const progressField = findField(context.formatedFields, "progress");

    const handleStatusChange = async (event: React.MouseEvent<HTMLElement>, newProgressStatus: string | Array<string> | null) => {
        if (context.type === "detail") {
            await updateProgresStatus.mutateAsync({
                id: context.item.id,
                entity: context.collection.collection,
                data: { progress: newProgressStatus },
            });
        }

        if (context.type === "list") {
            if (!newProgressStatus || !isArray(newProgressStatus)) return;

            const entity = context.collection.collection;

            setProgressFilter({
                ...progressFilter,
                [entity]: {
                    _or: newProgressStatus.map((progressStatus) => {
                        return {
                            progress: {
                                _eq: progressStatus,
                            },
                        };
                    }),
                },
            });
        }
    };

    return (
        <ToggleButtonGroup
            value={progressStatus}
            exclusive={context.type !== "list" ? true : false}
            onChange={handleStatusChange}
            color="secondary"
            fullWidth
            sx={{ mb: 2, backgroundColor: "white" }}
        >
            {progressField &&
                progressField.settings.options.choices.map((choice: { value: string; text: string }) => {
                    return (
                        <ToggleButton key={choice.value} value={choice.value} size="small" sx={{ textTransform: "capitalize" }}>
                            <Typography variant="body1">{choice.text}</Typography>
                        </ToggleButton>
                    );
                })}
        </ToggleButtonGroup>
    );
};
