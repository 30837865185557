import { GridPreview } from "../../components/gridPreview/GridPreview";
import { GridCellParams } from "@mui/x-data-grid";
import { OrdersDetail } from "./OrdersDetail";
import { Record, RecordContextType } from "../../components/record/Record";
import { treatmentsSearchSettings } from "../../utils/createSearchFilter";
import { OrderHeader } from "./OrderHeader";
import { Typography } from "@mui/material";
import { useCalculateOrder } from "./utils";

const fieldsQuery = "*.*,client_id.user_id.*,treatment.client.*, treatment.client.user_id.*";

export const ordersOptions = {
    fieldsWithExtraOptions: [
        {
            field: "treatment",
            createFilter: (params: any) => treatmentsSearchSettings(params),
        },
    ],
    dataGridOptions: {
        extraColumns: {
            beggining: [
                {
                    headerName: "Preview",
                    field: "preview",
                    width: 75,
                    renderCell: (params: GridCellParams) => {
                        return (
                            <GridPreview
                                key={params.id}
                                component={
                                    <Record
                                        idFromProps={params.id}
                                        entity="orders"
                                        type="detail"
                                        fieldsQuery={fieldsQuery}
                                        header={<OrderHeader />}
                                        body={<OrdersDetail />}
                                    />
                                }
                            />
                        );
                    },
                },
                {
                    headerName: "Valor Total",
                    field: "total_value",
                    width: 75,
                    renderCell: (params: GridCellParams) => {
                        if(!params.row.order_items) return;
                        return <TotalOrder row={params.row} />;
                    },
                },
            ],
            end: [],
        },
    },
    fieldsToRemove: {
        fieldNamesToRemove: ["order_items", "id"],
        fieldTypesToRemove: [],
    },
    createRelatedParams: (params: RecordContextType | undefined) => createOrderParams(params),
    extraOptions: {
        sort: "-order_date",
    },
};

const createOrderParams = async (recordContext: RecordContextType | undefined) => {
    if (!recordContext?.item) return;

    if (recordContext.entity === "treatments") return `[treatments][id][*.*,client.user_id.*]=${recordContext.item.id}`;

    if (recordContext.entity === "clients") return `[clients][id]=${recordContext.item.id}`;
};

const TotalOrder = ({ row }: any) => {
    const calculateOrderTotal = useCalculateOrder();
    const total = calculateOrderTotal(row.order_items);
    const totalInEuros = total.toLocaleString("pt-PT", { style: "currency", currency: "EUR" });

    return <Typography>{totalInEuros}</Typography>;
};
