import { ViewProps, Navigate, DateLocalizer, Views } from "react-big-calendar";
import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import { format, addMonths, isWithinInterval } from "date-fns";
import pt from "date-fns/locale/pt";
import { CalendarItem, CalendarItems } from "../calendar.types";
import { capitalize } from "lodash";
import { ServiceIcon } from "../../../settings-screens/schedules/components/ScheduleCustomEvent";
import "./overrides.css";
import {
    getDayColor,
    getDayEvent,
    EVENT_CONFIGS,
    shouldRenderBackground,
    BACKGROUND_RENDER_ORDER,
    DayEvents,
    getIconColor,
} from "./utils";

type MonthCalendarProps = {
    month: Date;
    events?: CalendarItems;
    onSelectDay: (day: Date) => void;
};

type EventBoxProps = {
    event: CalendarItem;
    psychologyEvent?: CalendarItem;
    neurofeedback?: CalendarItem;
    eeg?: CalendarItem;
    report?: CalendarItem;
};

export default function CustomYearView({ date, localizer, onView, onNavigate, events }: ViewProps) {
    const months = CustomYearView.range(new Date(date), { localizer });

    return (
        <Grid container spacing={2} columns={{ xs: 1, sm: 2, md: 3, lg: 3 }}>
            {months.map((month, index) => (
                <Grid item xs={1} key={index}>
                    <MonthCalendar
                        month={month}
                        //@ts-ignore
                        events={events}
                        onSelectDay={(day) => {
                            onView?.(Views.DAY);
                            onNavigate(day);
                        }}
                    />
                </Grid>
            ))}
        </Grid>
    );
}

const MonthCalendar = ({ month, events, onSelectDay }: MonthCalendarProps) => {
    // Get all days in the month
    const startDate = new Date(month.getFullYear(), month.getMonth(), 1);
    const endDate = new Date(month.getFullYear(), month.getMonth() + 1, 0);
    const days: Array<Date | null> = [];

    // Add empty slots for days before the first day of the month
    const firstDayWeekday = startDate.getDay() === 0 ? 6 : startDate.getDay() - 1;
    for (let i = 0; i < firstDayWeekday; i++) {
        days.push(null);
    }

    // Add the actual days
    for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
        days.push(new Date(d));
    }

    // Weekday headers in Portuguese, starting with Monday
    const weekDays = ["S", "T", "Q", "Q", "S", "S", "D"];

    return (
        <Paper elevation={3} sx={{ p: 2, height: "400px", borderRadius: 2 }}>
            <Typography variant="subtitle1" style={{ textAlign: "center" }} color="primary">
                {capitalize(format(month, "MMMM", { locale: pt }))}
            </Typography>
            <Divider sx={{ mb: 4, mt: 3, ml: 4, mr: 4 }} />
            <Grid container columns={7}>
                {/* Render weekday headers */}
                {weekDays.map((day, idx) => (
                    <Grid item xs={1} key={`header-${idx}`}>
                        <Box
                            sx={{
                                p: 1,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <Typography variant="caption" color="primary">
                                {day}
                            </Typography>
                        </Box>
                    </Grid>
                ))}

                {/* Render calendar days */}
                {days.map((day, idx) => {
                    const slotHeight = 50;
                    if (!day)
                        return (
                            <Grid item xs={1} key={`empty-${idx}`}>
                                <Box sx={{ p: 1, height: slotHeight }} />
                            </Grid>
                        );

                    const dayEvents = getDayEvent(day, events);

                    return (
                        <Grid item xs={1} key={idx}>
                            <Box
                                sx={{
                                    position: "relative",
                                    p: 1,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: slotHeight,
                                }}
                            >
                                {/* Render backgrounds */}
                                {BACKGROUND_RENDER_ORDER.map((eventType) => {
                                    const event = dayEvents[eventType];
                                    if (event && shouldRenderBackground(eventType, dayEvents)) {
                                        return (
                                            <BackgroundBox
                                                key={eventType}
                                                event={event}
                                                psychologyEvent={dayEvents.psychologyEvent}
                                                neurofeedback={dayEvents.neurofeedback}
                                                eeg={dayEvents.eeg}
                                                report={dayEvents.report}
                                            />
                                        );
                                    }
                                    return null;
                                })}

                                {/* Render all icons */}
                                {EVENT_CONFIGS.map((config) => {
                                    const event = dayEvents[config.type];
                                    if (config.condition(dayEvents)) {
                                        return (
                                            <ServiceIconWrapper
                                                key={config.type}
                                                event={event!}
                                                position={config.position}
                                                dayEvents={dayEvents}
                                            />
                                        );
                                    }
                                    return null;
                                })}

                                <Typography
                                    variant="subtitle1"
                                    sx={{
                                        position: "relative",
                                    }}
                                >
                                    {format(day, "d")}
                                </Typography>
                            </Box>
                        </Grid>
                    );
                })}
            </Grid>
        </Paper>
    );
};

const BackgroundBox = ({ event, psychologyEvent, neurofeedback }: EventBoxProps) => {
    const getBackground = () => {
        const color = getDayColor(event);
        const category = event.service?.category;

        if (!category) {
            return "transparent";
        }

        // Only Psychology and Neurofeedback can affect the background
        if (category === "psicologia" && neurofeedback) {
            return `linear-gradient(to bottom right, ${color} 50%, ${getDayColor(neurofeedback)} 50%)`;
        } else if (category === "neurofeedback" && psychologyEvent) {
            return `linear-gradient(to bottom right, ${getDayColor(psychologyEvent)} 50%, ${color} 50%)`;
        } else if (category === "psicologia" || category === "neurofeedback") {
            return color;
        } else if (!psychologyEvent && !neurofeedback) {
            // If it's a standalone service (not psychology or neurofeedback)
            return color;
        }

        return "transparent";
    };

    return (
        <Box
            sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                background: getBackground(),
            }}
        />
    );
};

const ServiceIconWrapper = ({
    event,
    position,
    dayEvents,
}: {
    event: CalendarItem;
    position: { top?: number; bottom?: number; left?: number; right?: number };
    dayEvents: DayEvents;
}) => {
    const iconColor = getIconColor(event, dayEvents);
    return (
        <Box sx={{ position: "absolute", ...position }}>
            <ServiceIcon
                service_id={event.service?.id}
                styles={{
                    fontSize: "1rem",
                    color: iconColor,
                }}
            />
        </Box>
    );
};

// Keep the static methods but convert to date-fns
CustomYearView.range = (date: Date, { localizer }: { localizer: DateLocalizer }) => {
    const year = date.getFullYear();
    const start = new Date(year, 0, 1); // January 1st of the current year
    const end = new Date(year, 11, 31); // December 31st of the current year
    const months: Array<Date> = [];

    let current = start;
    while (isWithinInterval(current, { start, end })) {
        months.push(new Date(current));
        current = addMonths(current, 1);
    }

    return months;
};

CustomYearView.navigate = (date: Date, action: any, { localizer }: { localizer: DateLocalizer }) => {
    if (action instanceof Date) {
        return action;
    }

    switch (action) {
        case Navigate.NEXT:
            return new Date(date.getFullYear() + 1, 0, 1);
        case Navigate.PREVIOUS:
            return new Date(date.getFullYear() - 1, 0, 1);
        default:
            return date;
    }
};

CustomYearView.title = (date: Date) => {
    return `Ano ${date.getFullYear()}`;
};
