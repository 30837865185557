import { CircularProgress, Box, Button, Typography, useTheme } from "@mui/material";
import { useRecords } from "../../api/useRecords";
import { useUpdateRecord } from "../../api/useUpdateRecord";
import { useUser } from "../../api/useAuth";

export const SelectOperatorOnCall = () => {
    const theme = useTheme();
    const { data: user } = useUser();
    const { data, status: operators_status } = useRecords("call_center_licenses", "*.*", {
        extraOptions: { filter: { user: { id: { _eq: "$CURRENT_USER" } } } },
    });

    const operatorOnCall = data?.items[0];
    const onCallLicenseId = 1;
    const updateOnCallOperator = useUpdateRecord();

    const userIsInboundCallCenter = operatorOnCall && operatorOnCall.id === onCallLicenseId;

    if (operators_status === "loading") return <CircularProgress />;
    if (operators_status === "error") return <div>error...</div>;

    return (
        <Box
            sx={{
                display: "flex",
                gap: 4,
                alignItems: "center",
                bgcolor: "background.default",
                p: "4px 16px",
                minHeight: 44,
                borderRadius: 2,
                boxShadow: 1,
            }}
        >
            <Box sx={{ display: "flex", columnGap: 2 }}>
                <Typography>Estou em piquete:</Typography>
                <Typography
                    color={userIsInboundCallCenter ? theme.palette.success.main : theme.palette.primary.main}
                    fontWeight={600}
                >
                    {userIsInboundCallCenter ? "SIM" : "NÃO"}{" "}
                </Typography>
            </Box>

            <Button
                size="small"
                color="primary"
                disabled={userIsInboundCallCenter}
                onClick={async () => {
                    await updateOnCallOperator.mutateAsync(
                        {
                            entity: "call_center_licenses",
                            id: onCallLicenseId,
                            data: {
                                user: user?.id,
                            },
                        },
                        {
                            onError: (error) => {
                                let errorMessage = "";
                                error.response.data.errors.forEach((error: any) => {
                                    if (error.extensions.code === "RECORD_NOT_UNIQUE") {
                                        errorMessage = "Já estás como operador de outra licença, pede ajuda à Joana ou ao Jorge para alterar";
                                    } else {
                                        errorMessage += error.message + "\n";
                                    }
                                });
                                alert(errorMessage);
                            },
                        }
                    );
                }}
            >
                Alterar para mim
            </Button>
        </Box>
    );
};
