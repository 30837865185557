import { useState, useEffect, useCallback, useContext } from "react";
import { TextField, Autocomplete, CircularProgress, Box, Divider } from "@mui/material";
import debounce from "lodash/debounce";
import { GlobalSearchOption } from "./GlobalSearchOption";
import { getGlobalSearch } from "../../api/getGlobalSearch";
import { useQueryClient } from "@tanstack/react-query";
import { orderBy } from "lodash";
import { CollectionsContext } from "../root/CollectionsProvider";

export type EntityToSearch = {
    entityName: string;
    entityLabel: string;
};

export function GlobalSearch() {
    const queryClient = useQueryClient();
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState<readonly any[]>([]);
    const [filter, setFilter] = useState<any>(null);
    const [inputValue, setInputValue] = useState<string | null>(null);
    const allowedCollections = useContext(CollectionsContext);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSave = useCallback(
        debounce((newValue) => {
            return setFilter(newValue);
        }, 250),
        []
    );

    const updateValue = (newValue: string) => {
        setInputValue(newValue);
        debouncedSave(newValue);
    };

    useEffect(() => {
        let active = true;

        const getSearchData = async () => {
            setIsLoading(true);
            const searchData = await queryClient.fetchQuery({
                queryKey: ["global-search"],
                queryFn: async () => getGlobalSearch(filter),
            });
            if (active && searchData) {
                const sortOrder = ["treatments", "clients", "leads"];

                const sortedSearch = orderBy(searchData, (item) => {
                    return sortOrder.indexOf(item.entity);
                });

                setOptions(sortedSearch);
            }
            setIsLoading(false);
        };

        getSearchData();

        return () => {
            active = false;
        };
    }, [allowedCollections, filter, queryClient]);

    return (
        <Autocomplete
            sx={{ width: "100%" }}
            id="no-autofill"
            size="small"
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            filterOptions={(x) => x}
            options={options}
            loading={isLoading}
            getOptionLabel={(option) => {
                return "";
            }}
            renderOption={(props, option, state) => {
                return (
                    <Box key={`${option.id}-${option.entity}`}>
                        <GlobalSearchOption option={option} entityToSearch={option.entity} filter={filter} setOpen={setOpen} />
                        {state.index === options.length - 1 ? null : <Divider variant="middle" />}
                    </Box>
                );
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    value={inputValue}
                    label="Pesquisar..."
                    size="small"
                    sx={{
                        backgroundColor: "white",
                        "& .MuiOutlinedInput-root": {
                            borderRadius: 2,
                        },
                        borderRadius: 2,
                        boxShadow: "0 1px 3px 1px rgba(0, 0, 0, .3)",
                    }}
                    onChange={(e) => {
                        updateValue(e.target.value);
                    }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: <>{isLoading ? <CircularProgress color="inherit" size={20} /> : null}</>,
                        autoComplete: "new-password",
                    }}
                />
            )}
        />
    );
}
