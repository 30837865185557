import * as Fields from "./fields/index";
import { Typography } from "@mui/material";
import { FieldExtraOption, FieldView } from "../../types/fields.types";
import { FormatedField } from "../../types/fields.types";
import { setFieldTypeDetail } from "../../api/setFieldType";
import { setFieldValueAcordingToView } from "../../api/setFieldValueAcordingToView";

export type FieldProps = {
    key: string;
    label: string;
    field: FormatedField;
    fieldView: FieldView;
    value: any;
    extraOptions?: any;
};

export const renderFieldView = (
    field: FormatedField,
    value: any,
    fieldView: FieldView,
    extraOptions?: FieldExtraOption | null
) => {
    const fieldType = setFieldTypeDetail(field);

    //@ts-ignore
    const Field: React.FC<FieldProps> = Fields[fieldType as keyof typeof Fields];

    if (!Field) {
        return <Typography>field not configurated yet</Typography>;
    }

    const fieldValue = setFieldValueAcordingToView(field, value, fieldView);
    return (
        <Field
            key={field.field}
            label={field.headerName}
            field={field}
            fieldView={fieldView}
            value={fieldValue}
            extraOptions={extraOptions}
        />
    );
};
