import { createUrl } from "../../components/calendar/calendarUtils";
import { filterUsersRoles } from "../../utils/filterUsersRoles";

export const schedulesOptions = {
    fieldsToRemove: {
        fieldNamesToRemove: ["end_date", "group_one", "group_two", "group_thre"],
        fieldTypesToRemove: [],
    },

    groupsOptions: {
        fieldsWithExtraOptions: [
            {
                field: "clinic_id",
                defaultValue: { id: 1, label: "Porto" },
                enabled: true,
            },
            {
                field: "technician",
                sort: "user_id.first_name",
                enabled: true,
            },
            {
                field: "user",
                relatedTable: "directus_users",
                displayTemplate: "{{first_name}} {{last_name}}",
                filter: filterUsersRoles,
                enabled: true,
            },
            {
                field: "services",
                relatedTable: "services",
                displayTemplate: "{{name}}",
                enabled: true,
            },

            {
                field: "start_date",
                dateFormat: "eeee, dd/MM/yyyy HH:mm",
            },
        ],
        navigateAfterCreate: (params: any) => createUrl(params, "schedules"),
        navigateAfterUpdate: (params: any) => createUrl(params, "schedules"),
    },
};
