import { StaffWorkLogsList } from "./StaffWorkLogsList";
import { StaffWorkLogsDetail } from "./StaffWorkLogsDetail";

export const staffRoutes = [
    {
        path: "/staff-work-logs",
        element: <StaffWorkLogsList />,
    },
    {
        path: "/staff-work-logs/:id",
        element: <StaffWorkLogsDetail />,
    },
];
