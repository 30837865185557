import { FormatedField, FieldView } from "../types/fields.types";
import { isCustomizedField } from "../utils/isCustomizedField";

export const setFieldValueAcordingToView = (field: FormatedField, value: any, fieldView: FieldView) => {
    if (!value) return null;

    if (isCustomizedField(field)) return value;

    switch (fieldView) {
        case "grid":
            return value.value;
        case "group":
            return value[field.field];
    }
};
