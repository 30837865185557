import {
    parseISO,
    format,
    isSameDay,
    differenceInHours,
    differenceInMinutes,
    min,
    max,
    eachDayOfInterval,
    startOfDay,
} from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { useRecords } from "../../api/useRecords";
import { useFieldSelectOptions } from "../../utils/useFieldSelectOptions";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useStore } from "../../store/store";
import { orderBy } from "lodash";
import { ROLES } from "../../utils/constants";
import { WorkLog, TimeOff, Totals, EmployeeSummary, ExportSettings } from "./types";
import { useUser } from "../../api/useAuth";

const TIMEZONE = "Europe/Lisbon";

const calculateTimeOffTotals = (entries: any[]) => {
    const holidayDays = entries.reduce((sum, entry) => {
        const start = startOfDay(parseISO(entry.start_date));
        const end = startOfDay(parseISO(entry.end_date));

        const daysInRange = eachDayOfInterval({ start, end }).length;

        return sum + daysInRange;
    }, 0);

    return {
        totalHolidays: holidayDays,
    };
};

export const calculateDayStats = (logs: WorkLog[]) => {
    if (logs.length === 0) return { timeStart: "", timeEnd: "", workHours: 0, clientsHours: 0, timeOffHours: 0 };

    const timeStart = min(logs.map((entry) => parseISO(entry.start_date)));
    const timeEnd = max(logs.map((entry) => parseISO(entry.end_date)));

    const workTotals = calculateHoursTotals(logs);

    return {
        timeStart: format(timeStart, "HH:mm"),
        timeEnd: format(timeEnd, "HH:mm"),
        workHours: workTotals.schedules,
        clientsHours: workTotals.clients,
    };
};

export const calculateTotals = (workLogs: WorkLog[], timeOff: TimeOff[]): Totals => {
    const workTotals = calculateHoursTotals(workLogs);
    const { totalHolidays } = calculateTimeOffTotals(timeOff);

    return {
        totalWorkHours: workTotals.schedules,
        effectiveWorkHours: workTotals.schedules,
        totalClientHours: workTotals.clients,
        totalHolidays: totalHolidays,
    };
};

export const formatToLisbonTime = (dateString: string) => {
    const date = utcToZonedTime(parseISO(dateString), TIMEZONE);
    return format(date, "yyyy-MM-dd'T'HH:mm");
};

export const calculateHours = (start: string, end: string) => {
    const startDate = utcToZonedTime(parseISO(start), TIMEZONE);
    const endDate = utcToZonedTime(parseISO(end), TIMEZONE);
    const hours = differenceInHours(endDate, startDate);
    const minutes = differenceInMinutes(endDate, startDate) % 60;
    return `${hours}h ${minutes}m`;
};

export const calculateHoursTotals = (entries: any[]) => {
    const types = ["schedules", "clients"];
    return types.reduce((totals: Record<string, number>, type) => {
        totals[type] = entries
            .filter((entry) => entry.type === type)
            .reduce((sum, entry) => sum + convertToHours(calculateHours(entry.start_date, entry.end_date)), 0);
        return totals;
    }, {});
};

type CalculateTotalValueServiceProvider = {
    effectiveWorkHours: number;
    valuePerHour: number;
    totalClientHours: number;
    valuePerClient: number;
};

export const calculateTotalValueServiceProvider = ({
    effectiveWorkHours,
    valuePerHour,
    totalClientHours,
    valuePerClient,
}: CalculateTotalValueServiceProvider) => {
    return effectiveWorkHours * valuePerHour + totalClientHours * valuePerClient;
};

function convertToHours(timeString: string): number {
    const [hours, minutes] = timeString.split("h ");
    return parseInt(hours) + parseInt(minutes) / 60;
}

export const getDaysArray = (start: Date, end: Date) => {
    const arr = Array<Date>();
    for (let dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
        arr.push(new Date(dt));
    }
    return arr;
};

const createTimeOffEntries = (timeOffRaw: any[]): TimeOff[] => {
    return timeOffRaw.flatMap((entry) => {
        const start = parseISO(entry.start_date);
        const end = parseISO(entry.end_date);

        if (isSameDay(start, end)) {
            return [entry];
        }

        return getDaysArray(start, end).map((day) => ({
            ...entry,
            start_date: format(day, "yyyy-MM-dd'T'10:00:00"),
            end_date: format(day, "yyyy-MM-dd'T'18:00:00"),
        }));
    });
};

export const useWorkLogs = (startDate: Date, endDate: Date, userId?: string) => {
    const dateFilter = {
        start_date: { _gte: startDate.toISOString(), _lte: endDate.toISOString() },
    };

    const technicianUser = userId ? { technician: { user_id: { id: { _eq: userId } } } } : {};

    const user = userId ? { user: { id: { _eq: userId } } } : {};

    const { data: currentUser } = useUser();
    const isCurrentUserAdmin = currentUser?.role.id === ROLES.admin || currentUser?.role.id === ROLES.accounting;

    const { data: sessions, status: sessionsStatus } = useRecords(
        "sessions",
        "id,start_date,end_date,title,technician.user_id.*",
        {
            extraOptions: {
                filter: {
                    ...dateFilter,
                    ...technicianUser,
                    status: { _eq: "done" },
                    service: { _nnull: true },
                },
                pageSize: -1,
            },
        }
    );

    const { data: schedules, status: schedulesStatus } = useRecords("schedules", "id,start_date,end_date,notes,user.*", {
        extraOptions: {
            filter: {
                ...dateFilter,
                ...user,
            },
            pageSize: -1,
        },
    });

    const { data: timeOffRaw, status: timeOffStatus } = useRecords("time_off", "*.*", {
        extraOptions: {
            filter: {
                ...dateFilter,
                ...user,
            },
            pageSize: -1,
        },
    });
    const year = startDate.getFullYear();

    const { data: fullYearTimeOff, status: fullYearTimeOffStatus } = useRecords("time_off", "*.*", {
        extraOptions: {
            filter: {
                start_date: { _gte: `${year}-01-01` },
                end_date: { _lt: `${year + 1}-01-01` },
                user: { id: { _eq: userId } },
            },
        },
    });

    const timeOffOptions = useFieldSelectOptions("time_off", "type");

    const createWorklogs = (sessions: any[], schedules: any[]): WorkLog[] => {
        const worklogs: WorkLog[] = [];

        sessions.forEach((session) => {
            worklogs.push({
                id: session.id,
                start_date: session.start_date,
                end_date: session.end_date,
                user: session.technician?.user_id,
                type: "clients",
            });
        });

        schedules.forEach((schedule) => {
            worklogs.push({
                id: schedule.id,
                start_date: schedule.start_date,
                end_date: schedule.end_date,
                user: schedule.user,
                type: "schedules",
                notes: schedule.notes,
            });
        });

        return worklogs;
    };

    const workLogs =
        sessionsStatus === "success" && schedulesStatus === "success" ? createWorklogs(sessions.items, schedules.items) : [];

    const timeOff = timeOffStatus === "success" ? createTimeOffEntries(timeOffRaw.items) : [];

    const yearHolidays = fullYearTimeOff ? calculateTimeOffTotals(fullYearTimeOff.items) : { totalHolidays: 0 };
    const thisYearHolidays = yearHolidays.totalHolidays;

    const isLoading =
        sessionsStatus === "loading" ||
        schedulesStatus === "loading" ||
        timeOffStatus === "loading" ||
        fullYearTimeOffStatus === "loading";
    const isError =
        sessionsStatus === "error" ||
        schedulesStatus === "error" ||
        timeOffStatus === "error" ||
        fullYearTimeOffStatus === "error";

    return {
        workLogs,
        timeOff,
        timeOffTypes: timeOffOptions,
        thisYearHolidays,
        currentUser,
        isCurrentUserAdmin,
        isLoading,
        isError,
    };
};

const rolesMapping = [
    {
        role: "admin",
        id: ROLES.admin,
    },
    {
        role: "directors",
        id: ROLES.director,
    },
    {
        role: "nfb_techncians",
        id: ROLES.nfb_techncians,
    },
    {
        role: "theurapist",
        id: ROLES.theurapist,
    },
    {
        role: "recepcionist",
        id: ROLES.recepcionist,
    },
    {
        role: "other",
        id: ROLES.other,
    },
];

export const getExportData = (employeeSummaries: EmployeeSummary[], type: "salaries" | "subsidies") => {
    const roleOrder = new Map(rolesMapping.map((role, index) => [role.id, index]));
    const PUSH_TO_END = 999999;
    const SUBSIDY_AGREEMENT_TYPES = ["term_contract", "full_term"];
    const sortedSummaries = orderBy(employeeSummaries, (summary) => {
        const roleId = summary.user.role?.id ?? summary.user.role;
        return roleOrder.get(roleId) ?? PUSH_TO_END;
    });

    const exportSettings = {
        data: [],
        columns: [],
    } as ExportSettings;

    const mapToExportFormat = (summary: EmployeeSummary) => {
        const { first_name, last_name } = summary.user;
        const { amount_receipt_to_transfer, subsidy, staff_agreement } = summary.payment || {};
        const { agreement_type, iban, nif } = staff_agreement || {};

        const fullName = `${first_name} ${last_name}`;
        const truncatedName = fullName.substring(0, 27);

        const description =
            agreement_type && SUBSIDY_AGREEMENT_TYPES.includes(agreement_type)
                ? `SALARIO ${first_name} ${last_name}`
                : `PSERVICOS ${first_name} ${last_name}`;

        // Smart truncation that tries to break at word boundary
        const smartTruncate = (str: string, maxLength: number) => {
            if (str.length <= maxLength) return str;
            const truncated = str.substring(0, maxLength);
            const lastSpace = truncated.lastIndexOf(" ");
            return lastSpace > 0 ? truncated.substring(0, lastSpace) : truncated;
        };

        const truncatedDescription = smartTruncate(description, 25);

        return {
            name: truncatedName,
            nif: nif || "",
            "": "", // Empty column
            amount_to_pay: amount_receipt_to_transfer || "",
            subsidy: subsidy || "",
            iban: iban || "",
            description: truncatedDescription,
        };
    };

    if (type === "salaries") {
        const data = sortedSummaries
            .filter((summary) => {
                // Filter out entries without agreement type
                if (!summary.payment?.staff_agreement?.agreement_type) return false;
                // Filter out entries without amount
                const amount = summary.payment?.amount_receipt_to_transfer;
                return amount !== null && amount !== undefined;
            })
            .map(mapToExportFormat);

        const columns = [
            { key: "iban", width: 30, header: "IBAN" },
            { key: "name", width: 20, header: "Name" },
            { key: "", width: 20, header: "" },
            { key: "amount_to_pay", width: 15, header: "Amount to Pay" },
            { key: "description", width: 30, header: "Description" },
        ];

        exportSettings.data = data;
        exportSettings.columns = columns;

        return exportSettings;
    }

    if (type === "subsidies") {
        const subsidiesData = sortedSummaries
            .filter((summary) => {
                const agreementType = summary.payment?.staff_agreement?.agreement_type;
                return agreementType && SUBSIDY_AGREEMENT_TYPES.includes(agreementType);
            })
            .map(mapToExportFormat);

        const subsidyColumns = [
            { key: "nif", width: 20, header: "NIF Colaborador" },
            { key: "name", width: 20, header: "Nome do Colaborador" },
            { key: "", width: 20, header: "" },
            { key: "subsidy", width: 15, header: "Valor" },
        ];

        exportSettings.data = subsidiesData;
        exportSettings.columns = subsidyColumns;

        return exportSettings;
    }

    return exportSettings;
};

export const useStaffWorkLogsFilter = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { selectedYear, setSelectedYear, selectedMonth, setSelectedMonth } = useStore((state) => state.staffWorkLogs);

    useEffect(() => {
        const yearParam = searchParams.get("year");
        const monthParam = searchParams.get("month");

        if (yearParam) {
            setSelectedYear(parseInt(yearParam));
        }
        if (monthParam) {
            setSelectedMonth(parseInt(monthParam) - 1); // Subtract 1 since months are 0-based in JS
        }
    }, [searchParams, setSelectedYear, setSelectedMonth]);

    useEffect(() => {
        setSearchParams({
            year: selectedYear.toString(),
            month: (selectedMonth + 1).toString(), // Add 1 to match human-readable months (1-12)
        });
    }, [selectedYear, selectedMonth, setSearchParams]);

    return { selectedYear, setSelectedYear, selectedMonth, setSelectedMonth };
};

export const filterEmployeeSummaries = (employeeSummaries: any[], selectedAgreementType: string) => {
    return employeeSummaries.filter((summary) => {
        const agreementType = summary.payment?.staff_agreement?.agreement_type;
        if (selectedAgreementType === "all") return true;
        if (selectedAgreementType === "services_provider" && agreementType === "services_provider") return true;
        if (selectedAgreementType === "contracts" && ["full_term", "term_contract"].includes(agreementType)) return true;
        return false;
    });
};
