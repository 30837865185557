import { Box, LinearProgress, styled } from "@mui/material";
import { useTreatmentsSummary } from "../../api/useTreatmentsSummary";
import { DataGrid as MuiDataGrid } from "@mui/x-data-grid";
import { columns } from "./columnsDefinition";
import { useNavigate } from "react-router-dom";
import { QuickSearchToolbar } from "../DataGridQuickSearchToolBar/QuickSearchToolBar";
import { useGoToScrollPosition } from "../datagrid/useGoToScrollPosition";
import { saveScrollPosition } from "../datagrid/utils";
import { useStore } from "../../store/store";

type TreatmentsSummaryProps = {
    heightOfHeaders: number;
    header: React.ReactNode;
};

export const TreatmentsSummary = ({ heightOfHeaders, header }: TreatmentsSummaryProps) => {
    const { data, status } = useTreatmentsSummary();
    const navigate = useNavigate();
    const { apiRef } = useGoToScrollPosition("treatments", data);
    const {
        gridSettings,
        gridSettings: { paginationModel },
    } = useStore();

    if (status === "loading") return <LinearProgress color="secondary" />;
    if (status === "error") return <div>error</div>;

    const StyledDataGrid = styled(MuiDataGrid)(({ theme }) => ({
        "& .payment-missing": {
            backgroundColor: "#eba2a2",
            "&:hover": {
                backgroundColor: "#eba2a2",
            },
        },
    }));

    return (
        <Box display="flex" flexDirection="column">
            {header}
            <Box height={`calc(100vh - ${heightOfHeaders}px)`} sx={{ backgroundColor: "#fff" }}>
                <StyledDataGrid
                    apiRef={apiRef}
                    rows={data}
                    columns={columns}
                    getRowClassName={(params) => {
                        if (
                            params.row.service_33_done > params.row.service_33_bought ||
                            params.row.service_34_done > params.row.service_34_bought
                        )
                            return "payment-missing";
                        return "";
                    }}
                    paginationModel={paginationModel}
                    pageSizeOptions={[paginationModel?.pageSize || 100]}
                    onCellClick={(params) => {
                        navigate(`/${"treatments"}/${params.id}/detail`);
                        saveScrollPosition(gridSettings, apiRef, params, "treatments");
                    }}
                    onPaginationModelChange={(paginationModel) => {
                        gridSettings.setGridSettings({
                            paginationModel: paginationModel,
                            scrollPosition: {
                                top: 0,
                                left: 0,
                            },
                        });
                    }}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    slots={{ toolbar: QuickSearchToolbar }}
                />
            </Box>
        </Box>
    );
};
