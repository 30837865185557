import { Typography } from "@mui/material";
import { isNumber } from "lodash";

type IntegerFieldProps = {
  value: number;
};

export function IntegerField({ value }: IntegerFieldProps) {
  if(!isNumber(value)) return null;
  return <Typography variant="body1">{value}</Typography>;
}
