import store2 from "store2";
import {baseUrl}  from "./config";

export const useCreateFileUrl = () => {
	return createFileUrl;
};

const createFileUrl = (fileId: string) => {
	return `${baseUrl}/assets/${fileId}?&access_token=${
		store2.get("auth").access_token
	}`;
};
