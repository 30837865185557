import { Info, Notes, AddTask, FamilyRestroom, CalendarMonth, Sms } from "@mui/icons-material";

export const tabs = [
    {
        icon: <Info />,
        entity: "detail",
        label: "Detalhes",
    },
    {
        icon: <CalendarMonth />,
        entity: "sessions",
        label: "Sessões",
    },

    {
        icon: <FamilyRestroom />,
        entity: "relatives",
        label: "Parentes",
    },
    {
        icon: <Notes />,
        entity: "notes",
        label: "Notas",
    },
    {
        icon: <AddTask />,
        entity: "tasks",
        label: "Tarefas",
    },
    {
        icon: <Sms />,
        entity: "communications",
        label: "Comunicações",
    },
];
