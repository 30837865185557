import { useGridApiRef } from "@mui/x-data-grid";
import { useStore } from "../../store/store";
import { ScrollPosition } from "../../store/store.types";
import { GridApiCommunity } from "@mui/x-data-grid/internals";
import { useEffect } from "react";

export const useGoToScrollPosition = (entity: string, items?: any) => {
    const { gridSettings } = useStore();
    const apiRef = useGridApiRef();

    useEffect(() => {
    
        if (gridSettings.entity && entity !== gridSettings.entity) {
            gridSettings.setGridSettings({
                scrollPosition: {
                    top: 0,
                    left: 0,
                },
                entity: entity,
            });

            return;
        }
        setTimeout(() => {
            goToScrollPosition(gridSettings.scrollPosition, apiRef);
        }, 1);
    }, [apiRef, entity, gridSettings, gridSettings.scrollPosition, items]);

    return { apiRef };
};

export const goToScrollPosition = (scrollPosition: ScrollPosition, apiRef: React.MutableRefObject<GridApiCommunity>) => {
    const { top, left, id, field } = scrollPosition;
    apiRef.current.scroll({ top: top, left: left });
    id && field && apiRef.current.setCellFocus(id, field);
};
