import { useContext } from "react";
import { RecordsHeader } from "../../components/recordsHeader/RecordsHeader";
import { RecordsContext } from "../../components/records/Records";
import { Box, IconButton, Paper, Typography } from "@mui/material";
import { useRecords } from "../../api/useRecords";
import store2 from "store2";
import { api, baseUrl } from "../../api/config";
import { Error, CheckCircle } from "@mui/icons-material";

type Bank = {
    id: number;
    name: string;
    is_bank: boolean;
    is_active: boolean;
    institution_id: string;
    balance: string;
    order: number;
    hide_from_header: boolean;
};

type Banks = Array<Bank>;

export const BankTransactionsListHeader = () => {
    const recordsContext = useContext(RecordsContext);
    const { data: banks } = useRecords("banks", "*.*", { extraOptions: { sort: "name" } });

    if (!recordsContext) return null;

    return (
        <Box>
            <RecordsHeader collection={recordsContext?.collection} />
            {banks && hasAccessToBalance(banks.items) === false ? (
                <Paper sx={{ height: 68, mt: -3, mb: 2, borderBottomRightRadius: 2 }} /> // this is a hack to fix the height of the header
            ) : (
                <Paper sx={{ mb: 2, display: "flex", p: 2, flexWrap: "wrap", gap: 4 }}>
                    <Box>
                        <Typography sx={{ mb: 1, fontWeight: 600 }}>Todos os bancos:</Typography>
                        <Typography sx={{ color: "#2e7d32", fontWeight: 600 }}>€ {banks && sumBalances(banks.items)}</Typography>
                    </Box>
                    {banks &&
                        sortBanks(banks?.items)?.map((bank: Bank) => {
                            if (bank.is_bank && !bank.hide_from_header) {
                                return (
                                    <Box key={bank.id} sx={{ pl: 6, pr: 6 }}>
                                        <Box>
                                            <Typography sx={{ mb: 1 }}>{bank.name}</Typography>
                                        </Box>
                                        <Box sx={{ display: "flex" }}>
                                            <IconButton
                                                sx={{ p: 0, mr: 2 }}
                                                onClick={async () => {
                                                    await authenticateBank(bank);
                                                }}
                                            >
                                                {bank.is_active ? <CheckCircle color="success" /> : <Error color="error" />}
                                            </IconButton>
                                            <Typography>€ {formatNumber(bank.balance)}</Typography>
                                        </Box>
                                    </Box>
                                );
                            } else {
                                return null;
                            }
                        })}
                </Paper>
            )}
        </Box>
    );
};

const authenticateBank = async (bank: Bank) => {
    const response = await api({
        method: "POST",
        url: `${baseUrl}/ai-finance/authenticate-bank`,
        data: {
            institution_id: bank.institution_id,
            bank_id: bank.id,
            user_token: store2.get("auth").access_token,
        },
    });

    if (response.data) {
        window.open(response.data, "_blank");
    }
};

const sumBalances = (banks: Banks) => {
    const total = banks.reduce((acc, bank) => {
        if (bank.is_bank && bank.balance) {
            return acc + parseFloat(bank.balance);
        } else {
            return acc;
        }
    }, 0);

    const totalFormated = formatNumber(total);
    return totalFormated;
};

const formatNumber = (value: number | string) => {
    const number = parseFloat(value.toString());
    return new Intl.NumberFormat("pt-PT", {
        minimumFractionDigits: 2,
    }).format(number);
};

const hasAccessToBalance = (banks: Banks): boolean => {
    return banks.every((bank) => bank.hasOwnProperty("balance"));
};

const sortBanks = (banks: Banks) => {
    return banks.sort((a, b) => a.order - b.order);
};
