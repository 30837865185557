import { CalendarFilters } from "../../../components/calendar/CalendarFilters";
import { Unstable_Grid2 as Grid, Paper } from "@mui/material";
import { findField } from "../../../utils/findField";
import { UseFormReturn } from "react-hook-form";
import { CalendarFiltersType } from "../../../components/calendar/calendar.types";
import { useFields } from "../../../api/useFields";
import { ToggleBetweenCalendarAndList } from "./ToggleBetweenCalendarAndList";
import { AvailabilityFinderButton } from "../../../components/availability-finder/components/AvailabilityFinderButton";

type SessionCalendarHeaderProps = {
    entityForm: UseFormReturn;
    calendarFilters: CalendarFiltersType | null;
    setView: React.Dispatch<React.SetStateAction<"calendar" | "list">>;
};

export const SessionsCalendarHeader = ({ entityForm, calendarFilters, setView }: SessionCalendarHeaderProps) => {
    const { formatedFields } = useFields("sessions");

    return (
        <Paper elevation={1} sx={{ mb: 2, p: 4, minHeight: 100 }}>
            <Grid container spacing={2} justifyContent="space-between" alignItems="center" flexGrow={1}>
                <Grid xs={12} lg={3} display="flex" alignItems="center">
                    <ToggleBetweenCalendarAndList setView={setView} />
                    <AvailabilityFinderButton />
                </Grid>
                <Grid display="flex" xs={12} lg={9} flexGrow={1} justifyContent="flex-end">
                    <CalendarFilters
                        entityForm={entityForm}
                        calendarFilters={calendarFilters}
                        fields={{ statusField: findField(formatedFields, "status") }}
                    />
                </Grid>
            </Grid>
        </Paper>
    );
};
