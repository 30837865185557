import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/material";
import { useStore } from "../../store/store";

export const AlertDialog = () => {
    const {
        alerts: { isOpen, setIsOpen, setAllowExecution, title, message },
    } = useStore();

    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <Box>
            <Dialog
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle color="error" id="alert-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={handleClose}>
                        Cancelar
                    </Button>
                    <Button onClick={() => setAllowExecution(true)}>Continuar</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};
