import { Info, Psychology, FamilyRestroom, Notes, AddTask } from "@mui/icons-material";

export const tabs = [
    {
        icon: <Info />,
        entity: "detail",
        label: "Detalhes",
        isDetail: true,
    },
    {
        icon: <Psychology />,
        entity: "treatments",
        label: "Tratamentos",
    },
    {
        icon: <FamilyRestroom />,
        entity: "relatives",
        label: "Parentes",
    },
    {
        icon: <Notes />,
        entity: "notes",
        label: "Notas",
    },
    {
        icon: <AddTask />,
        entity: "tasks",
        label: "Tarefas",
    },
];
