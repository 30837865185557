import { Record } from "../../components/record/Record";
import { Records } from "../../components/records/Records";
import { CustomerSurveyHeader } from "./CustomerSurveyHeader";
import { tabs } from "./customerSurveyTabs";
import { EntityForm } from "../../components/entityForm/EntityForm";
import { Groups } from "../../components/groups/Groups";
import { DetailTabs } from "../../components/detailTabs/DetailTabs";
import { customerSurveyOptions } from "./CustomerSurveyOptions";

export const fieldsQuery = "*.*,treatment.client.*.*";

export const customerSurveyRoutes = [
    {
        path: "/customer_surveys",
        element: <Records entity="customer_surveys" fieldsQuery={fieldsQuery} options={customerSurveyOptions} heightOfHeaders={190} />,
    },
    {
        path: "/customer_surveys/:id",
        element: (
            <Record
                entity="customer_surveys"
                type="detail"
                fieldsQuery={fieldsQuery}
                header={<CustomerSurveyHeader />}
                body={<DetailTabs tabs={tabs} />}
            />
        ),
        children: [
            {
                path: "/customer_surveys/:id/detail",
                element: <Groups />,
            },
        ],
    },
    {
        path: "/customer_surveys/:id/update",
        element: (
            <Record
                entity="customer_surveys"
                type="update"
                header={<CustomerSurveyHeader />}
                body={<EntityForm groupsOptions={customerSurveyOptions?.groupsOptions} />}
            />
        ),
    },
    {
        path: "/customer_surveys/create",
        element: <Record entity="customer_surveys" type="create" body={<EntityForm groupsOptions={customerSurveyOptions?.groupsOptions} />} />,
    },
];
