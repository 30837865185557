import { useQuery } from "@tanstack/react-query";
import { api } from "./config";
import { createFilters } from "../utils/createFilters";
import { createFieldsQuery } from "../utils/createFieldsQuery";

export const useTreatmentsSummary = () => {
  return useQuery({
    queryKey: [`treatments-summary`],
    queryFn: async () => {
      const treatments = await getTreatmentsSummary();
      const sessions = await getSessionsGroupedByTreatment();
      const orders = await getOrders();

      const combinedTreatmentsAndSessions = combineArrays(treatments.items, sessions.items, orders.items);

      return combinedTreatmentsAndSessions;
    },
  });
};

const TREATMENT_SUMMARY_FIELDS = [
  "id",
  "treatment_plan",
  "next_step",
  "client.client_id",
  "fast_notes",
  "client.user_id.first_name",
  "client.user_id.last_name",
  "treatment_provided",
  "sessions.start_date",
  "sessions.service",
  "sessions.status",
] as const;

const getTreatmentsSummary = async () => {
  const filter = createFilters({ _and: [{ treatment_status_new: { _eq: "all_good" } }] });
  const response = await api({
    method: "GET",
    params: {
      fields: createFieldsQuery(TREATMENT_SUMMARY_FIELDS),
      filter: filter?.normalFilters && JSON.stringify(filter.normalFilters),
      sort: "client.client_id",
      meta: "*",
      limit: -1,
      deep: {
        sessions: {
          _limit: -1, // This will remove the limit on sessions
        },
      },
    },
    url: "/items/treatments",
  });

  const items = response.data.data;
  const metadata = response.data.meta;

  return { items, metadata };
};

const getSessionsGroupedByTreatment = async () => {
  const filter = createFilters({ _and: [{ treatment: { treatment_status_new: { _eq: "all_good" } } }] });
  const response = await api({
    method: "GET",
    params: {
      fields: "*.*",
      filter: filter?.normalFilters && JSON.stringify(filter.normalFilters),
      "aggregate[count]": "id",
      "groupBy[]": ["treatment", "service", "status"],
      limit: -1,
      meta: "*",
    },
    url: "/items/sessions",
  });

  const items = response.data.data;
  const metadata = response.data.meta;

  return { items, metadata };
};

const getOrders = async () => {
  const filter = createFilters({ _and: [{ treatment: { treatment_status_new: { _eq: "all_good" } } }] });
  const response = await api({
    method: "GET",
    params: {
      fields: "treatment.id,order_items.service_variant.service,order_items.quantity",
      filter: filter?.normalFilters && JSON.stringify(filter.normalFilters),
      meta: "*",
      limit: -1,
    },
    url: "/items/orders",
  });

  const items = response.data.data;
  const metadata = response.data.meta;

  return { items, metadata };
};

type TreatmentType = {
  id: number;
  [key: string]: any;
};

type SessionType = {
  treatment: number;
  service: number | null;
  status: string;
  count: { id: string };
};

type OrderType = {
  treatment: {
    id: number;
  };
  order_items: Array<{
    quantity: number;
    service_variant: {
      service: number;
    };
  }>;
};

type DynamicServiceCounts = {
  [key: string]: number;
};

const combineArrays = (
  treatments: Array<TreatmentType>,
  sessions: Array<SessionType>,
  orders: Array<OrderType>
): TreatmentType[] => {
  // Preprocess sessions into a map by treatment
  const sessionsByTreatment: { [key: number]: typeof sessions } = {};

  sessions.forEach((session) => {
    if (!sessionsByTreatment[session.treatment]) {
      sessionsByTreatment[session.treatment] = [];
    }
    sessionsByTreatment[session.treatment].push(session);
  });

  const boughtByTreatment: { [key: number]: { [service: number]: number } } = {};
  orders.forEach((order) => {
    const treatmentId = order.treatment.id;
    order.order_items.forEach((item) => {
      if ([33, 34].includes(item.service_variant.service)) {
        if (!boughtByTreatment[treatmentId]) {
          boughtByTreatment[treatmentId] = {};
        }
        if (!boughtByTreatment[treatmentId][item.service_variant.service]) {
          boughtByTreatment[treatmentId][item.service_variant.service] = 0;
        }
        boughtByTreatment[treatmentId][item.service_variant.service] += item.quantity;
      }
    });
  });

  return treatments.map((treatment) => {
    const combined = {
      service_33_cancelled: 0,
      service_33_booked: 0,
      service_33_done: 0,
      service_33_bought: 0,
      service_34_cancelled: 0,
      service_34_booked: 0,
      service_34_done: 0,
      service_34_bought: 0,
    } as DynamicServiceCounts;

    const relevantSessions = sessionsByTreatment[treatment.id] || [];

    relevantSessions.reduce((acc: DynamicServiceCounts, session) => {
      if (session.service !== null) {
        const baseKey = `service_${session.service}`;

        // Handle special case for "done" and "cancelled"
        const statusKey = session.status !== "done" && session.status !== "cancelled" ? "booked" : session.status;

        const combinedKey = `${baseKey}_${statusKey}`;

        if (!acc[combinedKey]) {
          acc[combinedKey] = 0;
        }

        acc[combinedKey] += parseInt(session.count.id);
      }
      return acc;
    }, combined);

    if (boughtByTreatment[treatment.id]) {
      if (boughtByTreatment[treatment.id][33]) {
        combined["service_33_bought"] = boughtByTreatment[treatment.id][33];
      }
      if (boughtByTreatment[treatment.id][34]) {
        combined["service_34_bought"] = boughtByTreatment[treatment.id][34];
      }
    }

    return {
      id: treatment.id,
      treatment_plan: treatment.treatment_plan,
      next_step: treatment.next_step,
      treatment_provided: treatment.treatment_provided,
      sessions: treatment.sessions,
      client_id: treatment.client?.client_id,
      client_name: `${treatment.client?.user_id?.first_name} ${treatment.client?.user_id?.last_name}`,
      fast_notes: treatment.fast_notes,
      ...combined,
    };
  });
};
