import { Grid, Select, MenuItem, Box, FormControl, InputLabel, Divider, Button, CircularProgress } from "@mui/material";
import { Avatar } from "../../../components/fieldsViews/fields/Avatar";
import { Link } from "react-router-dom";
import { useUsers } from "../../../api/useUsers";
import { useRecords } from "../../../api/useRecords";
import { filterUsersRoles } from "../../../utils/filterUsersRoles";
import { useStore } from "../../../store/store";
import { getRelateServicesFromConstants } from "../../../utils/getRelatedServicesFromConstants";

export const SchedulesFilters = () => {
    const {
        schedules: { servicesSelected, setServicesSelected, eventTypeFilter, setEventTypeFilter, userId, setUserId },
    } = useStore();

    const nfbRelatedServicesIds = getRelateServicesFromConstants("nfb");
    const psyRelatedServicesIds = getRelateServicesFromConstants("psy");

    const { data: services, status: servicesStatus } = useRecords("services", "*.*", { staleTime: Infinity });
    const { data: users, status: usersStatus } = useUsers("*.*", filterUsersRoles);

    if (servicesStatus === "loading" || usersStatus === "loading") return <CircularProgress />;
    if (servicesStatus === "error" || usersStatus === "error") return <div>error...</div>;

    return (
        <Grid container spacing={2} alignItems="center" justifyContent="space-between" style={{ marginBottom: "20px" }}>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <InputLabel> Selecionar Técnico</InputLabel>
                    <Select
                        value={userId}
                        onChange={(e) => setUserId(e.target.value)}
                        sx={{ maxHeight: "56px" }}
                        label="Selecionar Técnico"
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    maxHeight: 600,
                                    overflow: "auto",
                                },
                            },
                        }}
                    >
                        <MenuItem value={0}>Remover</MenuItem>
                        {users?.items.map((user: any) => (
                            <MenuItem key={user.id} value={user.id}>
                                <Box display="flex" alignItems="center" gap={2}>
                                    <Avatar value={user.avatar?.id} />
                                    {user.first_name} {user.last_name}
                                </Box>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <InputLabel>Serviços</InputLabel>
                    <Select
                        multiple
                        value={servicesSelected}
                        onChange={(e) => {
                            const value = e.target.value as Array<string | number>;
                            if (value.includes("nfb_related")) {
                                setServicesSelected(nfbRelatedServicesIds);
                            } else if (value.includes("psy_related")) {
                                setServicesSelected(psyRelatedServicesIds);
                            } else {
                                setServicesSelected(value.includes(0) ? [] : (value as Array<number>));
                            }
                        }}
                        label="Serviços"
                        renderValue={(selected) =>
                            services?.items
                                .filter((service: any) => selected.includes(service.id))
                                .map((service: any) => service.name)
                                .join(", ")
                        }
                    >
                        <MenuItem value={0}>Remover</MenuItem>
                        {services?.items.map((service: any) => (
                            <MenuItem key={service.id} value={service.id as number}>
                                {service.name}
                            </MenuItem>
                        ))}
                        <Divider />

                        <MenuItem value={"nfb_related"}>
                            <Button fullWidth variant="contained" color="secondary">
                                Relacionado com Neurofeedback
                            </Button>
                        </MenuItem>
                        <MenuItem value={"psy_related"}>
                            <Button fullWidth variant="contained" color="secondary">
                                Relacionado com Psicologia
                            </Button>
                        </MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <InputLabel>Event Type</InputLabel>
                    <Select
                        value={eventTypeFilter}
                        onChange={(e) => setEventTypeFilter(e.target.value as string)}
                        label="Event Type"
                    >
                        <MenuItem value="all">All</MenuItem>
                        <MenuItem value="schedule">Schedules</MenuItem>
                        <MenuItem value="time_off">Time Offs</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={3} display="flex" justifyContent="center" gap={2}>
                <Button variant="contained" size="small" color="primary" component={Link} to="/schedules/add_multiple">
                    Adicionar Múltiplos
                </Button>
                <Button variant="contained" color="secondary" component={Link} to="/time_off/create">
                    Criar indisponibilidade
                </Button>
            </Grid>
        </Grid>
    );
};
