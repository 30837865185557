import { useState } from "react";
import { SessionsCalendar } from "./components/SessionsCalendar";
import { Box, LinearProgress } from "@mui/material";
import { UseRecordsOptions } from "../../api/useRecords";
import { RecordsHeader } from "../../components/recordsHeader/RecordsHeader";
import { useCollection } from "../../api/useCollection";
import { ToggleBetweenCalendarAndList } from "./components/ToggleBetweenCalendarAndList";

import { Records } from "../../components/records/Records";

type View = "list" | "calendar";

type SessionsListProps = {
    entity: string;
    fieldsQuery: string;
    options?: UseRecordsOptions;
};

export function Sessions({ entity, fieldsQuery, options }: SessionsListProps) {
    const [view, setView] = useState<View>("calendar");
    const { data: collection, status: statusCollection } = useCollection(entity);

    if (view === "list") {
        if (statusCollection === "loading") return <LinearProgress color="secondary" />;
        if (statusCollection === "error") return <div>error</div>;

        return (
            <Records
                entity={entity}
                fieldsQuery={fieldsQuery}
                options={options}
                heightOfHeaders={228}
                header={
                    <RecordsHeader
                        collection={collection}
                        optionalComponents={{ bellowTitle: <ToggleBetweenCalendarAndList setView={setView} /> }}
                    />
                }
            />
        );
    }

    return (
        <Box>
            <SessionsCalendar setView={setView} />
        </Box>
    );
}
