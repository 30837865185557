import { Avatar as AvatarMui } from "@mui/material";
import store2 from "store2";
import { FieldView } from "../../../types/fields.types";
import { baseUrl } from "../../../api/config";

type AvatarInputProps = {
  value: string;
  fieldView?: FieldView;
  styles?: any;
};



// NOT DONE YET
export function AvatarInputField({ value, fieldView, styles }: AvatarInputProps) {
 
  if (!value) return null;
  return (
    <AvatarMui
      sx={styles}
      src={`${baseUrl}/${value}?key=thumbnail&access_token=${
        store2.get("auth").access_token
      }`}
    />
  );
}
