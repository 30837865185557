import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Order, OrderItem } from "../screens/orders/ordersGridTypes.types";
import { api } from "./config";
import { createOrderItem } from "./useCreateOrder";

export const useUpdateOrder = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (order: Order) => updateOrder(order),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [`orders-${data.id}`] });
            queryClient.invalidateQueries({ queryKey: [`order_items-items-parentId-${data.id}`] });
        },
    });
};

const updateOrder = async (order: Order) => {
    const response = await updateOrderHeader(order);
    await updateOrderItems(order);

    return response.data.data;
};

const updateOrderItems = async (order: Order) => {
    if (!order.orderItems) return;

    await Promise.all(
        order.orderItems.map(async (item) => {
            if (item.newRow && order.order_id) {
                await createOrderItem(order.order_id, item);
            } else {
                await api({
                    method: "PATCH",
                    url: `items/order_items/${item.order_item_id}`,
                    data: item,
                });
            }
        })
    );

    if (order.order_items_to_delete && order.order_items_to_delete.length > 0) {
        await deleteOrderItems(order.order_items_to_delete);
    }
};

const updateOrderHeader = async (order: Order) => {
    return await api({
        method: "PATCH",
        url: `items/orders/${order.order_id}`,
        data: order,
    });
};

const deleteOrderItems = async (orderItemsToDelete: OrderItem[]) => {
    if (!orderItemsToDelete || orderItemsToDelete.length === 0) return;

    const orderItemsIds = mapOrderItemsIds(orderItemsToDelete);

    if (orderItemsIds.length === 0) return;

    await api({
        method: "DELETE",
        url: `items/order_items`,
        data: orderItemsIds,
    });
    return orderItemsIds;
};

const mapOrderItemsIds = (orderItemsToDelete: OrderItem[]) => {
    return orderItemsToDelete.map((item) => item.order_item_id).filter((orderItemId) => orderItemId !== undefined);
};
