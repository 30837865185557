import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Controller } from "react-hook-form";
import { parseISO } from "date-fns";
import { FieldProps } from "../../renderFieldInput";

interface DateInputFieldProps extends FieldProps {
	value: {
		birthday: string;
	};
}

export const BirthdayAndAgeInputField = ({
	field,
	value,
	entityForm,
	options,
	extraOptions,
}: DateInputFieldProps) => {
	const fieldName = extraOptions?.field ? extraOptions?.field : field.field;

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<Controller
				name={fieldName}
				control={entityForm.control}
				defaultValue={value?.birthday ? parseISO(value?.birthday) : null}
				render={({ field: { ref, ...rest } }) => (
					<DatePicker
						{...options}
						format="dd/MM/yyyy"
						label={field.headerName}
						sx={{ width: "100%" }}
						{...rest}
					/>
				)}
			/>
		</LocalizationProvider>
	);
};
