import { Box, Typography } from "@mui/material";
import { FieldProps } from "../renderFieldView";
import { extractTextAndTruncate } from "../../../utils/extractTextAndTruncate";

interface WysiwygFieldProps extends FieldProps {}

export function WysiwygField({ field, value, fieldView }: WysiwygFieldProps) {
    if (fieldView === "grid") {
        const text = extractTextAndTruncate(value);
        return <Typography>{text}</Typography>;
    }
    return <Box dangerouslySetInnerHTML={{ __html: value }} />;
}
