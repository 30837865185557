import { differenceInYears } from "date-fns";
import { getRecords } from "../../../api/useRecords";
import { Notifications } from "../../../store/store.types";
import { AvailabilityPreferences } from "./types";

export const findAvailabilityFormValidations = (data: AvailabilityPreferences, notifications: Notifications) => {
    const firstServiceId = data.firstService?.id;
    const secondServiceId = data.secondService?.id;
    const preferredDays = data.preferredDays;
    const treatmentOrLead = data.treatment || data.lead;

    const callNotification = (message: string) => {
        notifications.setNotification({
            message,
            isOpen: true,
            severity: "error",
        });
    };

    if (!treatmentOrLead) {
        callNotification("Tem que escolher uma lead ou tratamento");
        return;
    }

    if (preferredDays.length === 0) {
        callNotification("Tem que escolher pelo menos um dia da semana");
        return;
    }

    if (!firstServiceId && !secondServiceId) {
        callNotification("Tem que escolher um serviço");
        return;
    }

    if (firstServiceId && secondServiceId && firstServiceId === secondServiceId) {
        callNotification("Não pode escolher o mesmo serviço para as duas sessões");
        return;
    }

    if (data.lead && firstServiceId !== 39) {
        callNotification("Só é possível escolher Primeiro EEG para leads");
        return;
    }

    return true;
};



export const titleIfFirstSession = async (preferences: AvailabilityPreferences) => {
    const treatmentId = preferences.treatment?.id;
    if (!treatmentId) return null;
    const treatmentSessions = await getRecords("sessions", "*.*", {
        extraOptions: {
            filter: {
                _and: [
                    {
                        treatment: {
                            _eq: treatmentId,
                        },
                        service: {
                            _neq: 39, // does not include EEG
                        },
                    },
                ],
            },
        },
    });

    if (treatmentSessions.metadata.filter_count === 0) {
        return "1st Session";
    }
    return null;
};

export const titleIfFirstEeg = (preferences: AvailabilityPreferences) => {
    if (preferences.firstService.id === 39 && preferences.lead) {
        // add title only for EEG if lead is selected
        const birthday = new Date(preferences.lead.birthday);
        const title = `EEG: ${preferences.lead.first_name} ${preferences.lead.last_name}, ${differenceInYears(
            new Date(),
            birthday
        )}y   `;
        return title;
    }
    return "";
};
