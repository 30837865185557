import { createContext } from "react";
import { Collection } from "../../types/collections.types";
import { LinearProgress } from "@mui/material";
import { useCollections } from "../../api/useCollections";


export const CollectionsContext = createContext<{
    collections: Collection[];
    fields: Array<{ field: string; collection: string }>;
} | null>(null);

export const CollectionsProvider = ({ children }: { children: React.ReactNode }) => {
    const { data: collections, status: collectionStatus } = useCollections();

    if (collectionStatus === "loading") return <LinearProgress color="secondary" />;
    if (collectionStatus === "error") return <div>Error</div>;

    return <CollectionsContext.Provider value={collections}>{children}</CollectionsContext.Provider>;
};
