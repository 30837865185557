import { Record } from "../../components/record/Record";
import { Records } from "../../components/records/Records";
import { FeedbackHeader } from "./FeedbackHeader";
import { tabs } from "./feedbackTabs";
import { EntityForm } from "../../components/entityForm/EntityForm";
import { Groups } from "../../components/groups/Groups";
import { DetailTabs } from "../../components/detailTabs/DetailTabs";
import { feedbackOptions } from "./feedbackOptions";

export const fieldsQuery = "*.*,treatment.client.user_id.*,responsible.user_id.*,treatment.client.*";

export const feedbackRoutes = [
    {
        path: "/feedbacks",
        element: <Records entity="feedbacks" fieldsQuery={fieldsQuery} options={feedbackOptions} heightOfHeaders={190} />,
    },
    {
        path: "/feedbacks/:id",
        element: (
            <Record
                entity="feedbacks"
                type="detail"
                fieldsQuery={fieldsQuery}
                header={<FeedbackHeader />}
                body={<DetailTabs tabs={tabs} />}
            />
        ),
        children: [
            {
                path: "/feedbacks/:id/detail",
                element: <Groups />,
            },
        ],
    },
    {
        path: "/feedbacks/:id/update",
        element: (
            <Record
                entity="feedbacks"
                type="update"
                fieldsQuery={fieldsQuery}
                header={<FeedbackHeader />}
                body={<EntityForm groupsOptions={feedbackOptions?.groupsOptions} />}
            />
        ),
    },
    {
        path: "/feedbacks/create",
        element: (
            <Record
                entity="feedbacks"
                type="create"
                fieldsQuery={fieldsQuery}
                body={<EntityForm groupsOptions={feedbackOptions?.groupsOptions} />}
            />
        ),
    },
];
