import { useWatch } from "react-hook-form";

import { TextInputField } from "../TextInputField";
import { WysiwygInputField } from "../WysiwygInputField";
import { FieldProps } from "../../renderFieldInput";
import { TextField } from "@mui/material";

interface TemplateContentInputFieldProps extends FieldProps {}

export const TemplateContentInputField = ({ field, value, entityForm }: TemplateContentInputFieldProps) => {
    const field_with_template = useWatch({
        control: entityForm.control,
        name: "field_with_template",
    });

    const contentType = field_with_template?.item?.type || value?.field_with_template?.type;

    if (contentType === "text") return <TextInputField field={field} value={value?.content} entityForm={entityForm} />;

    if (contentType === "html") return <WysiwygInputField field={field} value={value?.content} entityForm={entityForm} />;

    return <TextField fullWidth placeholder="Selecione o campo para editar conteúdo" disabled />;
};
