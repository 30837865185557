import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from "@mui/material";
import { UseFormReturn, Controller } from "react-hook-form";
import { setFieldTypeForm } from "../../api/setFieldType";
import { findField } from "../../utils/findField";
import { RecordsContextType } from "../records/Records";
import { typeOfFilters } from "./filterTypes";

type FilterOperatorProps = {
    context: RecordsContextType;
    index: number;
    entityForm: UseFormReturn;
    formErrors: any[];
};

export const FilterOperator = ({ context, entityForm, index, formErrors }: FilterOperatorProps) => {
    if (!context?.formatedFields) return null;

    const watchedFields = entityForm.watch();

    const fieldOperator = findField(context?.formatedFields, watchedFields.filters[index].field.field);
    if (!fieldOperator) return null;

    const fieldType = setFieldTypeForm(fieldOperator);

    const operators = typeOfFilters.find((filterType) => filterType.type.includes(fieldType));

    return (
        <Controller
            name={`filters.${index}.operator`}
            control={entityForm.control}
            defaultValue={""}
            rules={{ required: "Este campo não pode estar vazio" }}
            render={({ field: inputField }) => (
                <FormControl fullWidth error={formErrors[index]?.operator ? true : false}>
                    <InputLabel>Operador</InputLabel>
                    <Select {...inputField} label="Campo" fullWidth>
                        {operators?.options.map((option) => {
                            return (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            );
                        })}
                    </Select>
                    <FormHelperText>{formErrors[index]?.operator?.message}</FormHelperText>
                </FormControl>
            )}
        />
    );
};
