import { Document, Page, Text, View, Image } from "@react-pdf/renderer";
import { styles } from "./reportStyles";
import store2 from "store2";
import { baseUrl } from "../../api/config";

export type ReportData = {
    patientName: string;
    birthDate: string;
    age: string;
    evaluationDate: string;
    medication: string;
    eyesOpen: {
        incidenciaAbsoluta: string;
        conectividade: string;
        images: string[];
    };
    eyesClosed: {
        incidenciaAbsoluta: string;
        conectividade: string;
        images: string[];
    };
    observations: string;
    technicianName: string;
    technicianTitle: string;
    technicianLicense: string;
    signature: string;
    otherImages: string[];
};
type MyDocumentProps = {
    reportData: ReportData;
};

export const EegDocument = ({ reportData }: MyDocumentProps) => {
    const signatureUrl = `${baseUrl}/assets/${reportData.signature}?access_token=${store2.get("auth").access_token}`;
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <Header />
                <View style={styles.contentWrapper}>
                    <View style={styles.titleContainer}>
                        <Text style={styles.title}>Relatório de Avaliação</Text>
                        <Text style={styles.title}>Eletroencefalografia Quantitativa</Text>
                    </View>
                    <View style={styles.tableContainer}>
                        <View style={styles.topSection}>
                            <View style={styles.tableRow}>
                                <Text style={styles.tableLabel}>Nome:</Text>
                                <Text style={styles.tableContent}>{reportData.patientName}</Text>
                            </View>
                            <View style={styles.tableRow}>
                                <Text style={styles.tableLabel}>Data de Nascimento:</Text>
                                <Text style={styles.tableContent}>{`${reportData.birthDate} (${reportData.age} anos)`}</Text>
                            </View>
                            <View style={styles.tableRow}>
                                <Text style={styles.tableLabel}>Data da Avaliação:</Text>
                                <Text style={styles.tableContent}>{reportData.evaluationDate}</Text>
                            </View>
                            <View style={styles.tableRow}>
                                <Text style={styles.tableLabel}>Medicação no ato do exame:</Text>
                                <Text style={styles.tableContent}>{reportData.medication}</Text>
                            </View>
                        </View>
                        <View style={styles.otherFields}>
                            <View style={styles.tableRow}>
                                <Text style={styles.tableLabel}>Técnicas:</Text>
                                <View>
                                    <Text style={styles.tableContent}>
                                        EEG de 19 canais segundo o Sistema Internacional 10-20, intervalo de frequências de 1-40
                                        Hz, com uso da Montagem Linked Ear.
                                    </Text>
                                </View>
                            </View>
                            <View style={styles.tableRow}>
                                <Text style={styles.tableLabel}>Instrumentos:</Text>
                                <View>
                                    <Text style={styles.tableContent}>
                                        BrainAvatar System (BrainMaster Technology Inc.){"\n"}Neuroguide database (Applied Neuroscience
                                        Inc.)
                                    </Text>
                                </View>
                            </View>
                            <View style={styles.tableRow}>
                                <Text style={styles.tableLabel}>Condições do Registo EEG:</Text>
                                <View>
                                    <Text style={styles.tableContent}>Olhos abertos e olhos fechados | Cliente sentado</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View style={styles.noteSection}>
                        <Text style={styles.noteText}>
                            Está cientificamente estudado que as ondas cerebrais são responsáveis pelo funcionamento elétrico do
                            cérebro e a presença de desregulações nesse funcionamento estão associadas à presença de
                            sintomatologia no indivíduo. Os dados do presente relatório constituem uma análise comparativa entre
                            as ondas cerebrais registadas e a base de dados normativa para o correspondente sexo e idade do
                            cliente, com o propósito de identificar e analisar possíveis desregulações no padrão de atividade
                            cerebral. O exame realizado é um meio complementar de avaliação e não se constitui um exame de
                            diagnóstico. Os clientes não deverão descurar o aconselhamento médico profissional ou atrasar
                            tratamento médico em virtude dos resultados obtidos. Desta forma, a NeuroImprove Clinic, não se
                            responsabiliza pelo uso indevido dos dados que constam neste documento.
                        </Text>
                    </View>
                    <View style={styles.signatureContent}>
                        <Image style={styles.signature} src={signatureUrl} />
                        <View style={styles.separator} />
                        <Text style={styles.technicianName}>{reportData.technicianName}</Text>
                        <Text style={styles.professionalInfo}>
                            {reportData.technicianTitle} ({reportData.technicianLicense})
                        </Text>
                    </View>
                </View>
                <Footer />
            </Page>

            <Page size="A4" style={styles.page}>
                <Header />
                <View style={styles.contentWrapper}>
                    <Text style={styles.secondPageTitle}>Síntese dos Resultados</Text>

                    <Text style={styles.sectionTitle}>Olhos abertos:</Text>
                    
                    <View >
                        <Text style={styles.sectionSubTitle}>Incidência absoluta:</Text>
                        <Text style={styles.text}>{reportData.eyesOpen.incidenciaAbsoluta}</Text>
                    </View>
                    <View style={styles.conectividadeSection}>
                        <Text style={styles.sectionSubTitle}>Conectividade:</Text>
                        <Text style={styles.text}>{reportData.eyesOpen.conectividade}</Text>
                    </View>

                    <Text style={styles.sectionTitle}>Olhos fechados:</Text>
                    <View >
                        <Text style={styles.sectionSubTitle}>Incidência absoluta:</Text>
                        <Text style={styles.text}>{reportData.eyesClosed.incidenciaAbsoluta}</Text>
                    </View>
                    <View style={styles.conectividadeSection}>
                        <Text style={styles.sectionSubTitle}>Conectividade:</Text>
                        <Text style={styles.text}>{reportData.eyesClosed.conectividade}</Text>
                    </View>
                </View>
                <View>
                    <Text style={styles.subtitle}>Observações:</Text>
                    <Text style={styles.text}>{reportData.observations}</Text>
                </View>
                <Footer />
            </Page>

            {reportData.eyesOpen.images.map((image, index) => (
                <Page key={`open-${index}`} size="A4" style={styles.page}>
                    <Header />
                    <View style={styles.contentWrapper}>
                        {index === 0 && <Text style={[styles.subtitle, styles.centeredText]}>Olhos Abertos:</Text>}
                        <Image style={styles.brainImage} src={image} cache={false} />
                    </View>
                    <Footer />
                </Page>
            ))}

            {reportData.eyesClosed.images.map((image, index) => (
                <Page key={`closed-${index}`} size="A4" style={styles.page}>
                    <Header />
                    <View style={styles.contentWrapper}>
                        {index === 0 && <Text style={[styles.subtitle, styles.centeredText]}>Olhos Fechados:</Text>}
                        <Image style={styles.brainImage} src={image} cache={false} />
                    </View>
                    <Footer />
                </Page>
            ))}

            {reportData.otherImages.map((image, index) => (
                <Page key={`other-${index}`} size="A4" style={styles.page}>
                    <Header />
                    <View style={styles.contentWrapper}>
                        {index === 0 && <Text style={[styles.subtitle, styles.centeredText]}>Imagens Adicionais:</Text>}
                        <Image style={styles.brainImage} src={image} cache={false} />
                    </View>
                    <Footer />
                </Page>
            ))}
        </Document>
    );
};

const Header = () => (
    <View style={styles.header} fixed>
        <Image
            style={styles.logo}
            src={`${baseUrl}/assets/88dbbcd8-7572-4d78-95cc-2ce20fc24b2f?key=thumbnail&access_token=${
                store2.get("auth").access_token
            }`}
        />
    </View>
);

const Footer = () => (
    <View style={styles.footer} fixed>
        <Text style={styles.footerContent}>
            <Text style={styles.footerBold}>Clínica NeuroImprove</Text>
            {"\n"}
            Rua Calouste Gulbenkian, 52, 3º andar, sala 9, 4050-144 Porto{"\n"}
            Tel.: 224 102 805 | www.neuroimprove.pt
        </Text>
    </View>
);
