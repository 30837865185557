import { DetailHeader } from "../../components/detailHeader/DetailHeader";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { RecordContext } from "../../components/record/Record";
import { useContext } from "react";

export const CustomerSurveyHeader = () => {
    const feedback = useContext(RecordContext);

    if (!feedback) return null;

    return (
        <DetailHeader collection={feedback.collection}>
            <Grid>
                <Typography variant="h6" color="secondary">
                    Questionário de satisfação
                </Typography>
                <Typography variant="h5">{feedback.item?.treatment?.client?.client_id}</Typography>
                <Typography variant="body1">
                    {feedback.item?.treatment?.client?.user_id?.first_name} {feedback.item?.treatment?.client?.user_id?.last_name}
                </Typography>
            </Grid>
        </DetailHeader>
    );
};
