import { useState } from "react";
import { useRecords } from "../../api/useRecords";
import { LinearProgress, Tabs, Tab, Box } from "@mui/material";
import { Dashboard } from "./Dashboard";
import { useWindowWidth } from "../../utils/useWindowWith";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
    item: {
        workspace: string;
        zoho_view: string;
        criteria: string;
    };
}

function CustomTabPanel(props: TabPanelProps) {
    const { value, index, item } = props;

    if (value === index) {
        return (
            <div style={{ display: "flex", flexDirection: "column", height: "100vh" }} role="tabpanel" hidden={value !== index}>
                <Dashboard workspace={item.workspace} view={item.zoho_view} criteria={item.criteria} />
            </div>
        );
    } else {
        return null;
    }
}

export const DashboardTabs = () => {
    const { data, status } = useRecords("dashboards");
    const windowWidth = useWindowWidth();

    const [value, setValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    if (status === "loading") return <LinearProgress color="secondary" />;
    if (status === "error") return <div>error</div>;

    return (
        <Box>
            <Box borderBottom={1} borderColor="divider" maxWidth={windowWidth - 17}>
                <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons={false}>
                    {data.items.map((item: any) => {
                        return <Tab key={item.id} label={item.name} />;
                    })}
                </Tabs>
            </Box>
            <Box>
                {data.items.map((item: any, index: number) => {
                    return <CustomTabPanel key={item.id} value={value} index={index} item={item} />;
                })}
            </Box>
        </Box>
    );
};
