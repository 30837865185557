import { api } from "../../api/config";
import { RecordContextType } from "../../components/record/Record";
import { getStore } from "../../store/store";
import { isLessThan18YearsOld } from "../../utils/isLessThan18YearsOld";

export const actions = [
    {
        code: "contract_informed_consent_child",
        name: "Enviar consentimento informado - Menores",
        actionFn: (context: RecordContextType, code: string) => createAndSendContract(context, code),
    },
    {
        code: "contract_informed_consent_adult",
        name: "Enviar consentimento informado - Adultos",
        actionFn: (context: RecordContextType, code: string) => createAndSendContract(context, code),
    },
    {
        code: "data_for_research_child",
        name: "Enviar consentimento para investigação - Menores",
        actionFn: (context: RecordContextType, code: string) => createAndSendContract(context, code),
    },
    {
        code: "data_for_research_adult",
        name: "Enviar consentimento para investigação - Adultos",
        actionFn: (context: RecordContextType, code: string) => createAndSendContract(context, code),
    },
    {
        code: "ask_testimonial",
        name: "Enviar link para testemunho",
        actionFn: (context: RecordContextType) => askTestimonial(context),
    },
    {
        code: "send_payment_link",
        name: "Enviar link de pagamento",
        actionFn: (context: any) => openPaymentDialog(context),
    },
];

const createAndSendContract = async (context: RecordContextType, code: string) => {
    if (!context.item.client.birthday) throw Error("Este cliente não tem data de nascimento definida");

    if (isLessThan18YearsOld(context.item.client.birthday) && context.item.relative_resposible_for_treatment.length === 0)
        throw Error("Este cliente é menor de idade, por favor defina o responsável pelo tratamento");

    const response = await api({
        method: "POST",
        url: "https://hook.eu1.make.com/efw5elytixkcew4c4vzkt719fbfonirt",
        data: {
            item: context.item,
            contract: code,
        },
    });

    return response.data;
};

const askTestimonial = async (context: RecordContextType) => {
    const confirmed = window.confirm("De certeza que deseja enviar o link para testemunho?");
    if (!confirmed) return;

    const response = await api({
        method: "POST",
        url: "https://hook.eu1.make.com/1yaey5zj7aa495ncsbo30yrc1gcneu2l",
        data: {
            item: context.item,
        },
    });
    return response.data;
};

const openPaymentDialog = (context: RecordContextType) => {
    try {
        const { payment } = getStore();
        payment.setIsOpen(true);
        payment.setContext(context);

        return {
            showSuccessMessage: "no",
        };
    } catch (error: any) {
        throw new Error("algo correu al");
    }
};
