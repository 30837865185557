import React, { useState } from "react";
import { Button, Paper, Typography } from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import Grid from "@mui/material/Unstable_Grid2";
import { Link } from "react-router-dom";
import { Collection } from "../../types/collections.types";
import { FilterDialog } from "../filters/FilterDialog";
import { useStore } from "../../store/store";
import { FilterAlt } from "@mui/icons-material";
import { SelectSettings } from "../configColumns/SelectSettings";

type RecordsHeaderProps = {
    collection: Collection;
    hideCreateButton?: boolean;
    optionalComponents?: {
        headerTitle?: string;
        bellowTitle?: React.ReactNode;
        leftOfButtons?: React.ReactNode;
    };
};

export const RecordsHeader = ({ collection, hideCreateButton, optionalComponents }: RecordsHeaderProps) => {
    const [filtersIsOpen, setFilterIsOpen] = useState<boolean>(false);
    const [recordsFilter] = useStore((state) => [
        state.recordsFilter || state.gridSettings.selectedSettings?.config?.filters?.records,
    ]);
    const currentEntityFilters = recordsFilter && recordsFilter[collection.collection];

    return (
        <>
            <Paper elevation={1} sx={{ mt: 2, mb: 2, p: 6, minHeight: 100 }}>
                <Grid container alignItems="center" justifyContent="space-between" rowSpacing={5}>
                    <Grid xs={12} md={4} display="flex" alignItems="center" gap={4}>
                        <Grid>
                            <Typography variant="h4">
                                {optionalComponents?.headerTitle ||
                                    (collection.meta.translations && collection.meta.translations[0].plural)}
                            </Typography>
                            {optionalComponents?.bellowTitle}
                        </Grid>
                        <Grid>
                            <SelectSettings />
                        </Grid>
                    </Grid>
                    <Grid container display="flex" alignItems="center" md={8} gap={2} justifyContent="flex-end">
                        <Grid>{optionalComponents?.leftOfButtons}</Grid>
                        <Grid>
                            <Button
                                startIcon={currentEntityFilters ? <FilterAlt /> : null}
                                color="secondary"
                                size="large"
                                onClick={() => setFilterIsOpen(true)}
                            >
                                {currentEntityFilters ? "Editar Filtros" : "Filtrar"}
                            </Button>
                        </Grid>
                        {hideCreateButton ? null : (
                            <Grid>
                                <Button component={Link} to={`/${collection.collection}/create`} startIcon={<AddCircle />}>
                                    Criar
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Paper>
            <FilterDialog filtersIsOpen={filtersIsOpen} setFilterIsOpen={setFilterIsOpen} />
        </>
    );
};
