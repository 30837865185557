import { UseRecordsOptions } from "../../api/useRecords";
import { RecordContextType } from "../../components/record/Record";

// For text area fields
const charactersLimit = 1000;

export const evaluationsOptions = {
  fieldsToRemove: {
    fieldTypesToRemove: ["alias"],
  },
  groupsOptions: {
    fieldsWithExtraOptions: [
      {
        field: "files",
        fileFolder: "a09a0214-e0ee-46f2-a0b4-0c863836ba9e",
      },
      {
        field: "eo_images",
        fileFolder: "93a1c6db-4b79-401f-9977-5b94351238e1",
      },
      {
        field: "ec_images",
        fileFolder: "93a1c6db-4b79-401f-9977-5b94351238e1",
      },
      {
        field: "other_images",
        fileFolder: "93a1c6db-4b79-401f-9977-5b94351238e1",
      },
      {
        field: "eeg_report",
        fileFolder: "a09a0214-e0ee-46f2-a0b4-0c863836ba9e",
      },
      {
        field: "evaluation_sent_by",
        sort: "user_id.first_name",
        enabled: true,
      },
      {
        field: "evaluation_sent_by",
        sort: "user_id.first_name",
        enabled: true,
      },
      {
        field: "eeg_session",
        createFilter: (params: any) => eegSessionFilter(params),
        enabled: true,
        relatedWith: "treatment",
        sort: "start_date",
      },
      {
        field: "ec_absolute_incidence",
        charactersLimit,
      },
      {
        field: "ec_conectivity",
        charactersLimit,
      },
      {
        field: "eo_absolute_incidence",
        charactersLimit,
      },
      {
        field: "eo_conectivity",
        charactersLimit,
      },
    ],
  },
  createRelatedParams: (params: RecordContextType | undefined) => createEvaluationParams(params),
} as UseRecordsOptions;

const createEvaluationParams = async (recordContext: RecordContextType | undefined) => {
  if (!recordContext?.item) return;
  if (recordContext.entity === "clients") return `[clients][id]=${recordContext.item.id}`;
  if (recordContext.entity === "leads") return `[lead][id]=${recordContext.item.id}`;
  if (recordContext.entity === "treatments")
    return `[clients][id]=${recordContext.item.client.id}&[treatments][id][*,client.*,client.user_id.*]=${recordContext.item.id}`;
};

export const eegSessionFilter = (params: any) => {
  var filter = {};

  const treatmentId = params?.id;

  if (treatmentId) {
    filter = {
      _and: [
        {
          treatment: {
            id: {
              _eq: treatmentId,
            },
          },
        },
        {
          service: {
            id: {
              _in: [35, 39],
            },
          },
        },
        {
          status: {
            _eq: "done",
          },
        },
      ],
    };
  }

  return filter;
};
