import { Box, TextField } from "@mui/material";
import { useStore } from "../../store/store";

import { GridSettings } from "../../store/store.types";
import { useEffect } from "react";

type QuickSearchToolbarProps = {
    inputLabel: string;
    fieldsToSearch: string[];
    gridSettings: GridSettings;
    entity: string;
};

export const QuickSearchToolbar = ({ inputLabel, fieldsToSearch, gridSettings, entity }: QuickSearchToolbarProps) => {
    const [setRecordsFilter, recordsFilter, quickSearchToolBar] = useStore((state) => [
        state.setRecordsFilter,
        state.recordsFilter,
        state.quickSearchToolBar,
    ]);

    useEffect(() => {
        if (entity !== quickSearchToolBar.inputValue.entity) {
            quickSearchToolBar.setInputValue({
                entity: entity,
                inputValue: "",
            });
        }
    }, [entity, quickSearchToolBar]);

    const submitSearch = (inputValue: string) => {
        if (!inputValue) return;
        quickSearchToolBar.setInputValue({
            entity: entity,
            inputValue: inputValue,
        });

        const formattedInputValue = inputValue.replace(/(\d),(\d)/g, "$1.$2");
        const newOrObject = {
            _or: fieldsToSearch.map((field) => {
                // Find the field type from gridColumns
                const column = gridSettings.gridColumns.find((col) => col.field === field);
                const fieldType = column?.settings?.type;

                // Determine the filter based on the field type
                if (fieldType === "decimal" || fieldType === "integer") {
                    const numericValue = Number(formattedInputValue);
                    return { [field]: { _eq: numericValue } };
                } else {
                    return { [field]: { _icontains: inputValue } };
                }
            }),
        };

        if (recordsFilter?.[entity]?._and) {
            const orIndex = recordsFilter[entity]._and.findIndex((item: any) => item.hasOwnProperty("_or"));

            if (orIndex !== -1) {
                recordsFilter[entity]._and[orIndex] = newOrObject;
            } else {
                recordsFilter[entity]._and.push(newOrObject);
            }
            setRecordsFilter(recordsFilter);
        } else {
            setRecordsFilter({ ...recordsFilter, [entity]: { _and: [newOrObject] } });
        }
    };

    return (
        <Box
            sx={{
                pt: 4,
                pb: 2,
                pl: 2,
            }}
        >
            <TextField
                label={inputLabel}
                value={quickSearchToolBar.inputValue.inputValue}
                onChange={(e) =>
                    quickSearchToolBar.setInputValue({
                        entity: entity,
                        inputValue: e.target.value,
                    })
                }
                size="small"
                sx={{ minWidth: 300 }}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        submitSearch((e.target as HTMLInputElement).value);
                    }
                }}
                InputProps={{
                    autoComplete: "new-password",
                }}
            />
        </Box>
    );
};
