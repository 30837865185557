import { FieldProps, renderFieldInput } from "../../renderFieldInput";
import { Unstable_Grid2 as Grid, ToggleButtonGroup, ToggleButton } from "@mui/material";
import { useField } from "../../../../api/useField";
import { useEffect, useState } from "react";
import { Groups, EmojiPeople } from "@mui/icons-material";

interface ClientAndLeadInputFieldProps extends FieldProps {}

export const ClientAndLeadInputField = ({
	field,
	value,
	entityForm,
	options,
	extraOptions,
}: ClientAndLeadInputFieldProps) => {
	const { formatedField: clientField, status: clientFieldStatus } = useField("sessions", "client");
	const { formatedField: leadField, status: leadFieldStatus } = useField("sessions", "lead");
	const [entityType, setEntityType] = useState(value?.lead ? "lead" : "client");

	const handleChange = (event: React.MouseEvent<HTMLElement>, newEntityType: string) => {
		entityForm.resetField(entityType);
		setEntityType(newEntityType);
	};

	const watchClientField = entityForm.watch("client");

	useEffect(() => {
		const treatmentField = entityForm.getValues("treatment");
		if (treatmentField) {
			entityForm.resetField("treatment");
		}
	}, [watchClientField, entityForm]);

	const leadFilter = {
		client: {
			_null: true,
		},
	};

	if (clientFieldStatus !== "success" || leadFieldStatus !== "success") return null;

	return (
		<Grid container alignItems="center" spacing={1}>
			<Grid flexGrow={1}>
				{entityType === "client"
					? clientField && renderFieldInput(clientField, value, "group", entityForm, extraOptions)
					: leadField &&
					  renderFieldInput(leadField, value, "group", entityForm, {
							field: "lead",
							filter: leadFilter,
					  })}
			</Grid>
			<Grid>
				<ToggleButtonGroup value={entityType} exclusive onChange={handleChange}>
					<ToggleButton value="lead" size="large">
						<EmojiPeople />
					</ToggleButton>
					<ToggleButton value="client" size="large">
						<Groups />
					</ToggleButton>
				</ToggleButtonGroup>
			</Grid>
		</Grid>
	);
};
