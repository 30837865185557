import { Select, MenuItem, Box, CircularProgress } from "@mui/material";
import { Avatar } from "../../../components/fieldsViews/fields/Avatar";
import { Controller, UseFormReturn } from "react-hook-form";
import { useUsers } from "../../../api/useUsers";
import { filterUsersRoles } from "../../../utils/filterUsersRoles";
type UsersDropdownProps = {
    entityForm: UseFormReturn;
    fieldName?: string;
};

export const UsersDropdown = ({ entityForm, fieldName = "user" }: UsersDropdownProps) => {
    const { data: users, status: usersStatus } = useUsers("*.*", filterUsersRoles);

    if (usersStatus === "loading") return <CircularProgress />;
    if (usersStatus === "error") return <div>error...</div>;

    return (
        <Controller
            name={fieldName}
            control={entityForm.control}
            render={({ field }) => (
                <Select
                    {...field}
                    fullWidth
                    displayEmpty
                    onChange={(event) => field.onChange(event.target.value)}
                    sx={{ maxHeight: "56px" }}
                >
                    <MenuItem value="" disabled>
                        Selecionar Técnico
                    </MenuItem>
                    {users?.items.map((user: any) => (
                        <MenuItem key={user.id} value={user.id}>
                            <Box display="flex" alignItems="center" gap={2}>
                                <Avatar value={user.avatar?.id} />
                                {user.first_name} {user.last_name}
                            </Box>
                        </MenuItem>
                    ))}
                </Select>
            )}
        />
    );
};
