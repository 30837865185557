import { TableCell, styled, Table } from "@mui/material";
import { isLightColor } from "../../../../utils/isLightColor";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    padding: "8px 16px",
    borderRight: `1px solid ${theme.palette.divider}`,
    verticalAlign: "middle",
    "&:last-child": {
        borderRight: "none",
    },
    "&.header": {
        backgroundColor: theme.palette.grey[800],
        color: "#fff",
        fontWeight: "bold",
    },
    "&.name-column": {
        minWidth: "50px",
        width: "15%",
        textAlign: "left",
        borderRight: `2px solid ${theme.palette.divider}`,
    },
    "&.data-column": {
        width: "calc(85% / 7)",
        textAlign: "center",
        "& .MuiSvgIcon-root": {
            fontSize: 16,
            verticalAlign: "middle",
            color: theme.palette.primary.main,
        },
     
    },
    "&.category-header": {
        backgroundColor: theme.palette.grey[800],
        color: "white",
        fontWeight: "bold",
        textAlign: "left",
        padding: "12px 16px",
    },
    "&.data-column-category": {
        width: "calc(85% / 7)",
        textAlign: "center",
        backgroundColor: theme.palette.grey[800],
        color: "white",
        "& .MuiSvgIcon-root": {
            fontSize: 16,
            verticalAlign: "middle",
            color: "white",
        },
    },

    "&.weekday-header": {
        textAlign: "center",
    },
}));

export const StyledTable = styled(Table)(({ theme }) => ({
    tableLayout: "fixed",
    minWidth: "1400px",
    borderCollapse: "separate",
    "& .MuiTableCell-root": {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
}));

export const getUserRowStyle = (userColor?: string) => ({
    backgroundColor: userColor || "#d1cfcf",
    "&:hover": {
        opacity: 0.9,
    },
    "& .MuiTableCell-root": {
        color: isLightColor(userColor || "#d1cfcf") ? "#404040" : "#fff",
    },
});
