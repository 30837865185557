type ValidateNotificationType = {
    notificationPreferences: any;
    uid: string;
    event: string;
    user: any;
};

export const validateNotification = ({ notificationPreferences, uid, event, user }: ValidateNotificationType) => {
    if (!notificationPreferences) return false;

    const notificationType = notificationPreferences?.items.find((item: any) => {
        return item.collection === uid && item.type === event;
    });

    if (notificationType) {
        return notificationType?.users.find((user_directus: any) => user_directus.directus_users_id === user?.id);
    }
    return false;
};
