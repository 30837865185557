import { Grid, MenuItem, Select } from "@mui/material";
import { eachYearOfInterval, eachMonthOfInterval, startOfYear, endOfYear, format } from "date-fns";
import { pt } from "date-fns/locale";

interface DateFilterProps {
    selectedYear?: number;
    selectedMonth?: number;
    setSelectedYear: (year: number) => void;
    setSelectedMonth: (month: number) => void;
}

export const DateFilter = ({ selectedYear, selectedMonth, setSelectedYear, setSelectedMonth }: DateFilterProps) => {
    const currentYear = new Date().getFullYear();
    const yearOptions = eachYearOfInterval({
        start: new Date(currentYear - 4, 0),
        end: new Date(currentYear, 0),
    });
    const monthOptions = eachMonthOfInterval({
        start: startOfYear(new Date()),
        end: endOfYear(new Date()),
    });

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <Select value={selectedYear} onChange={(e) => setSelectedYear(Number(e.target.value))} fullWidth>
                    {yearOptions.map((year) => (
                        <MenuItem key={year.getTime()} value={year.getFullYear()}>
                            {year.getFullYear()}
                        </MenuItem>
                    ))}
                </Select>
            </Grid>
            <Grid item xs={12} md={6}>
                <Select value={selectedMonth} onChange={(e) => setSelectedMonth(Number(e.target.value))} fullWidth>
                    {monthOptions.map((month) => (
                        <MenuItem key={month.getTime()} value={month.getMonth()}>
                            {format(month, "MMMM", { locale: pt })}
                        </MenuItem>
                    ))}
                </Select>
            </Grid>
        </Grid>
    );
};
