export type AddTemplateTypes = "initialValue" | "autoCompleteOptions";

export const addTemplate = (
	apiValues: Array<any>,
	templates:
		| Array<{ entity: string; template: string; templatesWithoutItem?: string }>
		| undefined,
	type: AddTemplateTypes = "initialValue",
	entity?: string
) => {
	if (!apiValues || !templates) return apiValues;

	return apiValues.map((apiValue) => {
		const matchedTemplate = templates.find(
			(template) => template.entity === (entity || apiValue.collection)
		);

		if (matchedTemplate) {
			// Deep clone the apiValue to prevent modification of the original object
			const newApiValue = JSON.parse(JSON.stringify(apiValue));

			// Add the template property to the apiValue
			switch (type) {
				case "autoCompleteOptions":
					newApiValue.template = matchedTemplate.templatesWithoutItem;
					break;
				case "initialValue":
					newApiValue.template = matchedTemplate.template;
					break;
				default:
					newApiValue.template = matchedTemplate.template;
			}

			return newApiValue;
		}

		return apiValue;
	});
};
