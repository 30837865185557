import {
    Dialog,
    DialogTitle,
    Button,
    DialogContent,
    DialogActions,
    TextField,
    CircularProgress,
    MenuItem,
    FormControl,
    Select,
    InputLabel,
    InputAdornment,
} from "@mui/material";
import { useStore } from "../../store/store";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import { useState, useEffect } from "react";
import { useRelatedRecords } from "../../api/useRelatedRecords";

export const PaymentDialog = () => {
    const { payment, notifications } = useStore();
    const [isLoading, setIsLoading] = useState(false);
    const { data: relatives } = useRelatedRecords("relatives", "*.*", "client_id", payment.context?.item?.client?.id);

    const emails = possibleEmails(payment.context?.item, relatives?.items);
    const entityForm = useForm({
        defaultValues: {
            payment_amount: "",
            payment_description: "",
            email_to_send: "",
        },
    });

    useEffect(() => {
        if (emails?.[0]?.email) {
            entityForm.setValue("email_to_send", emails[0].email);
        }
    }, [emails]);

    const handleSend = async (formData: { payment_amount: string; payment_description: string; email_to_send: string }) => {
        notifications.setNotification({
            isOpen: false,
        });
        setIsLoading(true);

        const treatment = payment.context.item;
        try {
            await axios({
                method: "POST",
                url: "https://hook.eu1.make.com/sl4h4yivdamstije1ddfdqdd5ur6jwoq",
                data: {
                    id: `${treatment.id}_${treatment.client.client_id}`,
                    amount: formData.payment_amount,
                    description: formData.payment_description,
                    first_name: treatment.client?.user_id?.first_name,
                    email: formData.email_to_send,
                },
            });

            notifications.setNotification({
                isOpen: true,
                message: `Link de pagamento enviado com sucesso para email ${payment.context.item.client?.user_id?.email}`,
                severity: "success",
            });

            entityForm.reset();
            payment.setIsOpen(false);
        } catch (error: any) {
            const errorMessage = error?.response?.data?.errorMessage || "Erro ao enviar link de pagamento";
            notifications.setNotification({
                isOpen: true,
                message: errorMessage,
                severity: "error",
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Dialog open={payment.isOpen} onClose={() => payment.setIsOpen(false)} maxWidth="sm" fullWidth>
            <DialogTitle>Enviar link de pagamento</DialogTitle>
            <form onSubmit={entityForm.handleSubmit(handleSend)}>
                <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                    <Controller
                        name="payment_amount"
                        control={entityForm.control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                autoFocus
                                margin="dense"
                                label="Valor a pagar"
                                fullWidth
                                type="number"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">€</InputAdornment>,
                                }}
                            />
                        )}
                    />
                    <Controller
                        name="payment_description"
                        control={entityForm.control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                autoFocus
                                margin="dense"
                                label="Descrição    "
                                type="text"
                                fullWidth
                                multiline
                                rows={4}
                            />
                        )}
                    />
                    <Controller
                        name="email_to_send"
                        control={entityForm.control}
                        render={({ field: { onChange, value, ...field } }) => (
                            <FormControl fullWidth>
                                <InputLabel>Email para enviar o link</InputLabel>
                                <Select
                                    {...field}
                                    value={value}
                                    onChange={(e) => onChange(e.target.value)}
                                    label="Email para enviar o link"
                                >
                                    {emails &&
                                        emails.map((email: any) => (
                                            <MenuItem key={email.email} value={email.email}>
                                                {email.name} | {email.email} ({email.type === "relative" ? "Parente" : "Cliente"})
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        )}
                    />
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={() => payment.setIsOpen(false)}>
                        Cancelar
                    </Button>
                    <Button type="submit">{isLoading ? <CircularProgress color="inherit" size={24} /> : "Enviar"}</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

const possibleEmails = (treatment: any, relatives?: { name: string; email: string }[]) => {
    if (!treatment) return;

    const possibleEmails = [
        {
            type: "client",
            email: treatment.client.user_id.email,
            name: `${treatment.client.user_id.first_name} ${treatment.client.user_id.last_name}`,
        },
    ];
    if (relatives && relatives?.length > 0) {
        relatives.forEach((relative: any) => {
            possibleEmails.push({
                type: "relative",
                email: relative.email,
                name: `${relative.Nome}`,
            });
        });
    }

    return possibleEmails;
};
