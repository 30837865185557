import { Box, TextField } from "@mui/material";
import { titleIfFirstEeg, titleIfFirstSession } from "../utils/formsValidations";
import { SlotOfTheDay } from "../utils/utils";
import { AvailabilityPreferences } from "../utils/types";
import { useEffect, useState } from "react";

type SlotTitleInputProps = {
    slotOfTheDay: SlotOfTheDay;
    preferences: AvailabilityPreferences;
};

export const SlotTitleInput = ({ slotOfTheDay, preferences }: SlotTitleInputProps) => {
    const [slotTitles, setSlotTitles] = useState<Record<string, string>>({});

    useEffect(() => {
        const getTitles = async () => {
            const hasFirstEegTitle = titleIfFirstEeg(preferences);
            const hasFirstSessionTitle = await titleIfFirstSession(preferences);
            const defaultTitle = hasFirstEegTitle || hasFirstSessionTitle || "";

            const newSlotTitles: Record<string, string> = {};

            if (slotOfTheDay.isGroup) {
                const earliestSlot = slotOfTheDay.slots.reduce((earliest, current) => {
                    return earliest.start_date < current.start_date ? earliest : current;
                });
                newSlotTitles[earliestSlot.slotId] = defaultTitle;
                earliestSlot.title = defaultTitle;
            } else {
                slotOfTheDay.slots.forEach((slot) => {
                    newSlotTitles[slot.slotId] = defaultTitle;
                    slot.title = defaultTitle;
                });
            }

            setSlotTitles(newSlotTitles);
        };

        if (Object.keys(slotTitles).length > 0) return;
        getTitles();
    }, [preferences]);

    return (
        <Box key={slotOfTheDay.slots[0].slotId} sx={{ display: "flex", flexDirection: "row", gap: 1, mt: 2 }}>
            {slotOfTheDay.slots.map((slot) => {
                return (
                    <TextField
                        key={slot.slotId}
                        label={`Título ${slot.serviceName}`}
                        variant="outlined"
                        size="small"
                        color="secondary"
                        fullWidth
                        value={slotTitles[slot.slotId] || ""}
                        onChange={(e) => {
                            setSlotTitles((prev) => ({
                                ...prev,
                                [slot.slotId]: e.target.value,
                            }));
                            slot.title = e.target.value;
                        }}
                    />
                );
            })}
        </Box>
    );
};
