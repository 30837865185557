import { Troubleshoot, InterpreterMode, Assignment, FavoriteBorder, MonitorHeartOutlined } from "@mui/icons-material";
import { Box, SxProps, Typography } from "@mui/material";
import { Avatar } from "../../../components/fieldsViews/fields/Avatar";
import { CustomEventType } from "../utils/types";

export const ScheduleCustomEvent = ({ event }: { event: CustomEventType }) => {
    const withServices = event.services && event.services.length > 0 ? event.services : null;

    return (
        <Box
            display="flex"
            alignItems="flex-start"
            flexDirection="column"
            gap={2}
            width="100%"
            sx={{ position: "relative", mt: 1 }}
        >
            {event.userAvatar && <Avatar value={event.userAvatar} styles={{ ml: 1, width: 25, height: 25 }} />}
            {withServices ? (
                withServices.map((service_id: number) => <ServiceIcon service_id={service_id} key={service_id} />)
            ) : event.type === "schedule" ? (
                <Assignment />
            ) : null}

            <Typography variant="caption">{event.notes}</Typography>
        </Box>
    );
};

type ServiceIconProps = {
    service_id?: number;
    styles?: SxProps;
};

export const ServiceIcon = ({ service_id, styles }: ServiceIconProps) => {
    switch (service_id) {
        case 33:
            return <MonitorHeartOutlined sx={styles} />;
        case 35:
        case 39:
            return <Troubleshoot sx={styles} />;
        case 34:
        case 36:
            return <FavoriteBorder sx={styles} />;
        case 37:
        case 40:
            return <InterpreterMode sx={styles} />;
        default:
            return null;
    }
};
