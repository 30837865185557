import { Record } from "../../components/record/Record";
import { Records } from "../../components/records/Records";
import { tabs } from "./partnersTabs";
import { DetailTabs } from "../../components/detailTabs/DetailTabs";
import { EntityForm } from "../../components/entityForm/EntityForm";
import { Groups } from "../../components/groups/Groups";
import { partnersOptions } from "./partnersOptions";
import { PartnersListHeader } from "./PartnersListHeader";
import { PartnersDetailHeaderHeader } from "./PartnersDetailHeader";
import { RecordsRelated } from "../../components/recordsRelated/RecordsRelated";
import { treatmentsOptions } from "../treatments/treatmentOptions";

const fieldsQuery = "*.*,treatments.*";

export const partnersRoutes = [
    {
        path: "/professional_partners",
        element: (
            <Records
                entity="professional_partners"
                fieldsQuery={fieldsQuery}
                options={partnersOptions}
                header={<PartnersListHeader />}
                heightOfHeaders={264}
            />
        ),
    },
    {
        path: "/professional_partners/:id",
        element: (
            <Record
                entity="professional_partners"
                type="detail"
                fieldsQuery={fieldsQuery}
                body={<DetailTabs tabs={tabs} />}
                header={<PartnersDetailHeaderHeader />}
            />
        ),
        children: [
            {
                path: "/professional_partners/:id/detail",
                element: <Groups groupsOptions={partnersOptions.groupsOptions} />,
            },
            {
                element: (
                    <RecordsRelated
                        entity="treatments"
                        fieldsToQuery="*.*,client.user_id.*,client_owner.user_id.*,psychologist.user_id.*,client_manager.user_id.*, theurapists_involved_in_treamtent.technicians_id.user_id.*"
                        customFilter={(params) => {
                            return {
                                partners: {
                                    professional_partners_id: {
                                        id: {
                                            _eq: params.parentId,
                                        },
                                    },
                                },
                            };
                        }}
                        recordsOptions={{ ...treatmentsOptions, relatedRecords: { hideCreationButton: true } }}
                        heightOfHeaders={264}
                    />
                ),
                path: "/professional_partners/:id/treatments",
            },
        ],
    },
    {
        path: "/professional_partners/:id/update",
        element: (
            <Record
                entity="professional_partners"
                type="update"
                fieldsQuery={fieldsQuery}
                body={<EntityForm groupsOptions={partnersOptions.groupsOptions} />}
                header={<PartnersDetailHeaderHeader />}
            />
        ),
    },
    {
        path: "/professional_partners/create",
        element: (
            <Record
                entity="professional_partners"
                type="create"
                fieldsQuery={fieldsQuery}
                body={<EntityForm groupsOptions={partnersOptions.groupsOptions} />}
            />
        ),
    },
];
