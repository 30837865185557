import { Box, Button, Grid, Paper, Typography, Divider, LinearProgress } from "@mui/material";
import { format, parseISO } from "date-fns";
import { AvailabilityPreferences, AvailabilitySlot, WeeklyAvailabilityFromApi } from "../utils/types";
import { formatDayAndDate, getSlotsForDay } from "../utils/utils";
import { SessionsCreationFeedback } from "./SessionsCreationFeedback";
import { useStore } from "../../../store/store";
import { createSessionsFromAvailabilitiesFinder } from "../../../api/availabilities-finder";
import { SlotButton } from "./SlotButton";
import { SlotTitleInput } from "./SlotTitleInput";

type AvailabilityResultsProps = {
    preferences: AvailabilityPreferences;
    weeks: WeeklyAvailabilityFromApi[];
    selectedSlots: AvailabilitySlot[];
    setSelectedSlots: (slots: AvailabilitySlot[]) => void;
    setWeeks: (weeks: WeeklyAvailabilityFromApi[]) => void;
    isLoading: boolean;
    setIsLoading: (isLoading: boolean) => void;
};

export const AvailabilityResults = ({
    preferences,
    weeks,
    selectedSlots,
    setSelectedSlots,
    setWeeks,
    isLoading,
    setIsLoading,
}: AvailabilityResultsProps) => {
    const { notifications } = useStore();

    const addSlotToSelectedSlots = (slot: AvailabilitySlot | AvailabilitySlot[]) => {
        if (Array.isArray(slot)) {
            setSelectedSlots([...selectedSlots, ...slot]);
        } else {
            setSelectedSlots([...selectedSlots, slot]);
        }
    };

    const removeSlotFromSelectedSlots = (slot: AvailabilitySlot | AvailabilitySlot[]) => {
        if (Array.isArray(slot)) {
            setSelectedSlots(selectedSlots.filter((s) => !slot.includes(s)));
        } else {
            setSelectedSlots(selectedSlots.filter((s) => s.slotId !== slot.slotId));
        }
    };

    const handleCreateSessions = async (selectedSlots: Array<AvailabilitySlot>, preferences: AvailabilityPreferences) => {
        if (selectedSlots.length === 0) {
            return notifications.setNotification({
                message: "Tem que selecionar pelo menos uma sessão",
                isOpen: true,
                severity: "error",
            });
        }

        setIsLoading(true);

        const data = await createSessionsFromAvailabilitiesFinder(preferences, selectedSlots);
        const autoHideDuration = 20000;

        if (!data.isValid) {
            notifications.setNotification({
                message: <SessionsCreationFeedback sessionsOrSlots={data.slotsNotAvailable} type="error" />,
                isOpen: true,
                severity: "error",
                autoHideDuration,
            });
        } else {
            notifications.setNotification({
                message: <SessionsCreationFeedback sessionsOrSlots={data.sessionsCreated} type="success" />,
                isOpen: true,
                severity: "success",
                autoHideDuration,
            });
        }

        setSelectedSlots([]);
        setWeeks([]);
        setIsLoading(false);
    };

    if (isLoading) {
        return <LinearProgress color="secondary" />;
    }

    return (
        <Paper sx={{ p: 3 }}>
            <Box sx={{ mb: 2, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Typography variant="h6" gutterBottom>
                    Disponibilidades
                </Typography>
                <Button variant="contained" color="secondary" onClick={() => handleCreateSessions(selectedSlots, preferences)}>
                    Criar sessões
                </Button>
            </Box>

            {weeks.map((week, weekIndex) => {
                const weekStartDate = parseISO(week.weekStartDate);
                return (
                    <Paper key={weekIndex} sx={{ p: 2, mb: 4 }}>
                        <Typography variant="subtitle1" gutterBottom color="primary">
                            Semana de {format(weekStartDate, "PP")}
                        </Typography>
                        <Divider sx={{ my: 2 }} />
                        <Grid container spacing={4}>
                            {preferences.preferredDays.map((day) => {
                                const slotsOfTheDay = getSlotsForDay(weekStartDate, day, week.slots[day] || [], selectedSlots);
                                return (
                                    <Grid item xs={12} md={4} key={day}>
                                        <Typography variant="subtitle2">{formatDayAndDate(day, weekStartDate)}</Typography>
                                        <Box sx={{ mt: 1 }}>
                                            {slotsOfTheDay.map((slotOfTheDay, index) => {
                                                const reactKey = slotOfTheDay.slots[0].slotId;
                                                const isSlotSelected = selectedSlots.some(
                                                    (s) => s.slotId === slotOfTheDay.slots[0].slotId
                                                );
                                                return (
                                                    <Box key={reactKey} display="flex" flexDirection="column" gap={2}>
                                                        {isSlotSelected && (
                                                            <SlotTitleInput
                                                                slotOfTheDay={slotOfTheDay}
                                                                preferences={preferences}
                                                            />
                                                        )}
                                                        <SlotButton
                                                            slotOfTheDay={slotOfTheDay}
                                                            isSlotSelected={isSlotSelected}
                                                            onSlotSelect={addSlotToSelectedSlots}
                                                            onSlotDeselect={removeSlotFromSelectedSlots}
                                                            index={index}
                                                        />
                                                    </Box>
                                                );
                                            })}
                                            {!week.slots[day]?.length && (
                                                <Typography variant="body2" color="text.secondary">
                                                    Não disponibilidades para este dia
                                                </Typography>
                                            )}
                                        </Box>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Paper>
                );
            })}
        </Paper>
    );
};
