import {  Typography, IconButton, Box } from "@mui/material";
import { Avatar } from "../fieldsViews/fields/Avatar";
import { Logout, Schedule, CalendarMonth } from "@mui/icons-material";
import store2 from "store2";
import { Link } from "react-router-dom";
import { UserFromAPI } from "../../types/auth.types";

type UserProps = {
    user: UserFromAPI;
};

export const MenuUser = ({ user }: UserProps) => {
    
    return (
        <Box>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" pb={2}>
                <Avatar
                    value={typeof user.avatar === "object" ? user.avatar?.id : user.avatar}
                    styles={{ width: 75, height: 75 }}
                />
                <Typography color="#fff" variant="body1">
                    {user.first_name} {user.last_name}
                </Typography>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="center" pb={2}>
                <IconButton
                    size="small"
                    sx={{ color: "white" }}
                    onClick={() => {
                        store2.clearAll();
                        window.location.reload();
                    }}
                >
                    <Logout />
                </IconButton>
                <IconButton size="small" sx={{ color: "white" }} component={Link} to={`/staff-work-logs/${user.id}`}>
                    <Schedule />
                </IconButton>
                <IconButton size="small" sx={{ color: "white" }} component={Link} to={`/schedules`}>
                    <CalendarMonth />
                </IconButton>
            </Box>
        </Box>
    );
};
