import { api } from "./config";
import { checkIfHasUserAndAddUserValues, UserItem } from "../utils/checkIfHasUserAndAddUserValues";
import { createFilters } from "../utils/createFilters";
import { createRoute } from "../utils/createRoute";
import { useQuery } from "@tanstack/react-query";

export type UseItemsOptions = {
    queryKeyExtraValue?: string;
    filter?: any;
};

export const useItems = (entity: string, fieldsQuery: string = "*.*", options?: UseItemsOptions) => {
    const queryKey = `${entity}-items${options?.queryKeyExtraValue ? "-" + options?.queryKeyExtraValue : null}`;

    return useQuery({
        queryKey: [queryKey],
        queryFn: () => getItems(entity, fieldsQuery, options),
    });
};

export const getItems = async (
    entity: string,
    fieldsQuery: string = "*.*",
    options?: {
        filter?: any;
        pageSize?: number;
        limit?: number;
        page?: number;
        sort?: string;
    }
): Promise<{ items: any; metadata: { total_count?: number; filter_count?: number } }> => {
    if (!entity) return { items: [], metadata: { total_count: 0 } };

    const filters = createFilters(options?.filter);

    const response = await api({
        method: "GET",
        params: {
            fields: fieldsQuery,
            filter: filters?.normalFilters ? JSON.stringify(filters?.normalFilters) : undefined,
            custom_filters: filters?.customFilters ? JSON.stringify(filters?.customFilters) : undefined,
        },
        url: createRoute({
            entity,
            limit: options?.pageSize || 300,
            page: options?.page ? options?.page + 1 : 1,
            sort: options?.sort || "-date_created",
            customFilters: filters?.customFilters,
        }),
    });

    const items = response.data.data;
    const metadata = response.data.meta;

    items.forEach((item: UserItem) => {
        checkIfHasUserAndAddUserValues(item);
    });

    return { items, metadata };
};
