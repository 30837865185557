import { DetailHeader } from "../../components/detailHeader/DetailHeader";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { RecordContext } from "../../components/record/Record";
import { useContext } from "react";
import { renderFieldView } from "../../components/fieldsViews/renderFieldView";
import { findField } from "../../utils/findField";

export const PartnersDetailHeaderHeader = () => {
    const record = useContext(RecordContext);

    if (!record) return null;
    const { name } = record.item;
    const jobPositionField = findField(record.formatedFields, "job_position");

    return (
        <>
            <DetailHeader collection={record.collection}>
                <Grid container spacing={4} alignItems="flex-start">
                    <Grid>
                        <Typography variant="h6" color="secondary">
                            Parceiro
                        </Typography>
                        <Typography variant="h5" sx={{ mb: 4 }}>
                            {name}
                        </Typography>
                        {jobPositionField &&
                            renderFieldView(jobPositionField, { job_position: record.item.job_position }, "group")}
                    </Grid>
                </Grid>
            </DetailHeader>

        </>
    );
};
