import { ScreenSearchDesktop } from "@mui/icons-material";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { RecordContext } from "../../record/Record";
import { useContext } from "react";

export const AvailabilityFinderButton = () => {
    const recordContext = useContext(RecordContext);

    const createRelatedParams = () => {
        if (recordContext?.entity === "treatments") {
            const treatmentId = recordContext?.id;
            const clinicId = recordContext?.item?.client?.clinic_id;
            return `[treatment][id]=${treatmentId}&[clinic][id]=${clinicId}`;
        }

        if (recordContext?.entity === "leads") {
            const leadId = recordContext?.id;
            const clinicId = recordContext?.item?.clinic.id;
            return `[lead][id]=${leadId}&[clinic][id]=${clinicId}`;
        }
    };

    const relatedParams = createRelatedParams();

    return (
        <Button
            startIcon={<ScreenSearchDesktop />}
            component={Link}
            variant="contained"
            color="secondary"
            size="medium"
            to={`/sessions/find-availabilities${relatedParams ? `?${relatedParams}` : ""}`}
            sx={{ ml: 2 }}
        >
            Encontrar Disponibilidades
        </Button>
    );
};
