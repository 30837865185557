import {
  Info,
  CalendarMonth,
  Euro,
  Troubleshoot,
  Notes,
  AddTask,
  TextSnippet,
  Quiz,
  Handshake,
  History
} from "@mui/icons-material";

export const tabs = [
  {
    icon: <Info />,
    entity: "detail",
    label: "Detalhes",
    isDetail: true,
  },
  {
    icon: <Troubleshoot />,
    entity: "evaluations",
    label: "Avaliações",
  },
  {
    icon: <CalendarMonth />,
    entity: "sessions",
    label: "Sessões",
  },

  {
    icon: <Euro />,
    entity: "orders",
    label: "Compras",
  },
  {
    icon: <Notes />,
    entity: "notes",
    label: "Notas",
  },
  {
    icon: <TextSnippet />,
    entity: "feedbacks",
    label: "Feedbacks",
  },
  {
    icon: <AddTask />,
    entity: "tasks",
    label: "Tarefas",
  },
  {
    icon: <Quiz />,
    entity: "customer_surveys",
    label: "Questionários",
  },
  {
    icon: <Handshake />,
    entity: "professional_partners",
    label: "Parceiros",
  },
  {
    icon: <History />,
    entity: "timeline",
    label: "Histórico",
  },
];
