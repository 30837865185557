import { SchedulesFilters } from "./components/SchedulesFilters";
import { Box, CircularProgress } from "@mui/material";
import { SchedulesCalendar } from "./components/SchedulesCalendar";
import { ScheduleWeekTotal } from "./components/schedule-week-totals/ScheduleWeekTotal";
import { useFilteredSchedules, useStaffAvailability } from "./utils/scheduleUtils";
import { useStore } from "../../store/store";
import { useDefaultFilters } from "../../components/calendar/calendarUtils";
import { useUser } from "../../api/useAuth";
import { ROLES } from "../../utils/constants";

export const Schedules = () => {
    const {
        schedules: { calendarFilters },
    } = useStore();
    const { data: user } = useUser();

    const isDirectorOrAdmin = user?.role.id === ROLES.director || user?.role.id === ROLES.admin;
    const defaultFilters = useDefaultFilters();

    const { events, isLoading, isError, refetch } = useStaffAvailability(calendarFilters || defaultFilters);

    const filteredEvents = useFilteredSchedules(events);

    if (isLoading) return <CircularProgress />;
    if (isError) return <div>error...</div>;

    return (
        <Box>
            <SchedulesFilters />
            <SchedulesCalendar refetch={refetch} filteredEvents={filteredEvents} />
            {isDirectorOrAdmin && <ScheduleWeekTotal events={filteredEvents} />}
        </Box>
    );
};
