import { OpenInNew } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { GridCellParams, useGridApiContext } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { saveScrollPosition } from "./utils";
import { useStore } from "../../store/store";

type OpenInNewTabProps = {
    params: GridCellParams;
    entity: string;
};

export const OpenInNewTab = ({ params, entity }: OpenInNewTabProps) => {
    const { gridSettings } = useStore();
    const apiRef = useGridApiContext();

    return (
        <IconButton
            sx={{ p: 0 }}
            onClick={(e) => {
                saveScrollPosition(gridSettings, apiRef, params, entity);
                e.stopPropagation();
            }}
            color="secondary"
            component={Link}
            to={`/${entity}/${params.id}/detail`}
            target="_blank"
        >
            <OpenInNew />
        </IconButton>
    );
};
