export const componentStyles = () => {
    return {
        editButton: {
            ml: "auto",
        },
        mainWrapper: {
            columnGapgap: 2,
            alignItems: "flex-start",
            justifyContent: "space-between",
            flexDirection: "row",
            flexGrow: 1,
            rowGap: 5,
        },
    };
};
