import { Unstable_Grid2 as Grid } from "@mui/material";
import { FormatedField } from "../../../types/fields.types";
import { ChipExtraOption, CustomChip } from "../../chips/CustomChip";

type SelectFieldProps = {
    field: FormatedField;
    value: string;
    extraOptions?: ChipExtraOption;
};

export function SelectField({ field, value, extraOptions }: SelectFieldProps) {
    if (!value) return null;

    let labels = [];

    if (Array.isArray(value)) {
        labels = [...value];
    } else {
        labels = [value];
    }

    return (
        <Grid container spacing={2}>
            {labels.map((label) => (
                <Grid key={label}>
                    <CustomChip field={field} label={label} extraOptions={extraOptions} />
                </Grid>
            ))}
        </Grid>
    );
}
