import { useRecords } from "../../api/useRecords";
import { useStore } from "../../store/store";
import {
    Badge,
    Box,
    Card,
    CardActionArea,
    CardContent,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Snackbar as MuiSnackBar,
    Typography,
} from "@mui/material";
import { CancelOutlined, MarkEmailRead, Notifications } from "@mui/icons-material";
import { Avatar } from "../fieldsViews/fields/Avatar";
import {  UserFromAPI } from "../../types/auth.types";
import { formatDistanceToNow, parseISO } from "date-fns";
import pt from "date-fns/locale/pt"; // Import Portuguese locale
import { removeHtmlTagsAndEntities } from "../../utils/removeHtmlTagsAndEntities";
import { useUpdateRecord } from "../../api/useUpdateRecord";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const filterNotifications = {
    _and: [
        { _or: [{ is_read: { _eq: false } }, { is_read: { _null: true } }] },
        { user_to_be_notified: { _eq: "$CURRENT_USER" } },
    ],
};

export const NotificationsList = () => {
    const { notifications } = useStore();
    const { data } = useRecords("notifications", "*.*,item.item.*.*", {
        fetch: { fetchInterval: 900000 },
        extraOptions: { filter: filterNotifications },
    });
    const [notificationsNumber, setNotificationsNumber] = useState<number | null>();
    const updateRecord = useUpdateRecord({ queryInvalidatePrefix: "notifications" });
    const navigate = useNavigate();

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === "clickaway") {
            return;
        }

        notifications.setNotificationsList({ isOpen: false });
    };

    useEffect(() => {
        if (!data) return;

        const notifications = data?.items.filter((item: any) => {
            return item.item[0].item !== null;
        });
        setNotificationsNumber(notifications?.length);
    }, [data]);

    return (
        <Box>
            <IconButton
                size="large"
                color="inherit"
                onClick={() => {
                    notifications.setNotificationsList({ isOpen: true });
                }}
            >
                <Notifications />
                <Badge badgeContent={notificationsNumber} color="error" />
            </IconButton>

            <MuiSnackBar
                open={notifications.notificationsList.isOpen}
                onClose={handleClose}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <Box sx={{ width: "500px", bgcolor: "secondary.main", borderRadius: 2, boxShadow: 3 }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", p: 5 }}>
                        <Typography>{notificationsNumber === 0 ? "Não tem novas notificações" : "Notificações"}</Typography>
                        <CancelOutlined
                            sx={{ cursor: "pointer" }}
                            onClick={() => notifications.setNotificationsList({ isOpen: false })}
                        />
                    </Box>
                    <List
                        sx={{ maxHeight: "800px", overflowY: "auto", "::-webkit-scrollbar": { display: "none" }, pl: 5, pr: 5 }}
                    >
                        {data?.items.map((notificationItem: NotificationItem) => {
                            if (notificationItem.is_read) return null;

                            const relateditem = notificationItem.item[0];
                            if (!relateditem.item) return null;

                            return (
                                <ListItem key={notificationItem.id} disablePadding sx={{ pb: 2, pt: 2 }}>
                                    <Card sx={{ width: "100%" }}>
                                        <CardContent>
                                            <Box sx={{ display: "flex", alignItems: "center", gap: 5 }}>
                                                <Avatar value={notificationItem.user_that_generated_notification?.avatar} />
                                                <ListItemText
                                                    primary={`${notificationItem.user_that_generated_notification?.first_name} ${notificationItem.user_that_generated_notification?.last_name}`}
                                                    secondary={`Criou um(a) ${handleNotificationType(
                                                        notificationItem.type
                                                    )} ${handleNotificationTinming(relateditem.item.date_created)}`}
                                                />
                                                <MarkEmailRead
                                                    sx={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        updateRecord.mutate({
                                                            id: notificationItem.id,
                                                            entity: "notifications",
                                                            data: { is_read: true },
                                                        });
                                                    }}
                                                    color="primary"
                                                />
                                            </Box>
                                            <CardActionArea
                                                sx={{ border: "1px solid rgba(0, 0, 0, 0.12)", borderRadius: 2, p: 4, mt: 4 }}
                                                onClick={() => {
                                                    navigate(notificationItem.navigation_path);
                                                    notifications.setNotificationsList({ isOpen: false });
                                                }}
                                            >
                                                <Typography>{removeHtmlTagsAndEntities(notificationItem.content)}</Typography>
                                            </CardActionArea>
                                        </CardContent>
                                    </Card>
                                </ListItem>
                            );
                        })}
                    </List>
                </Box>
            </MuiSnackBar>
        </Box>
    );
};

type NotificationUser = UserFromAPI & {
    avatar: string;
}

type RelatedItemData = { content: string; id: string; date_created: string; task: { id: string } };

type RelatedItem = { collection: string; item: RelatedItemData };

type NotificationItem = {
    id: string;
    date_created: string;
    is_read: boolean;
    item: Array<RelatedItem>;
    user_that_generated_notification: NotificationUser;
    user_to_be_notified: NotificationUser;
    content: string;
    navigation_path: string;
    type: string;
};

const handleNotificationType = (type: string) => {
    switch (type) {
        case "tasks":
            return "tarefa";
        case "task_comments":
            return "comentário";
        case "notes":
            return "nota";
        default:
            return "";
    }
};

const handleNotificationTinming = (date_created: string) => {
    return formatDistanceToNow(parseISO(date_created), {
        addSuffix: true,
        locale: pt,
    });
};
