import { useMutation } from "@tanstack/react-query";
import { api } from "./config";
import { ErrorResponsesCreateOrUpdate } from "../types/records.types";
import { Order, OrderItem } from "../screens/orders/ordersGridTypes.types";
import { queryClient } from "../main";
import { useContext } from "react";
import { RecordContext } from "../components/record/Record";

export const useCreateOrder = () => {
    const recordContext = useContext(RecordContext);
    
    return useMutation<any, ErrorResponsesCreateOrUpdate, Order, any>({
        mutationFn: (newOrder: Order) => createOrder(newOrder),
        onSuccess(data, variables) {
            createOrderItems(data.id, variables);
            queryClient.invalidateQueries({
				predicate(query: any) {
					return query.queryKey[0].startsWith("orders");
				},
			});
            recordContext?.entityForm.reset();
        },
    });
};

const createOrder = async (newOrder: Order) => {
    const response = await api({
        method: "POST",
        url: `items/orders`,
        data: newOrder,
    });

    return response.data.data;
};

export const createOrderItems = async (id: number, newOrder: Order) => {
    if (!newOrder.orderItems) return;

    const orderItems = newOrder.orderItems.map((item) => {
        return {
            order_id: id,
            service_variant: item.variantId,
            quantity: item.quantity,
            price: item.price,
            discount: item.discount,
        };
    });

    const response = await api({
        method: "POST",
        url: `items/order_items`,
        data: orderItems,
    });

    return response.data.data;
};

export const createOrderItem = async (orderId: number, orderItem: OrderItem) => {
    const response = await api({
        method: "POST",
        url: `items/order_items`,
        data: {
            order_id: orderId,
            service_variant: orderItem.variantId,
            quantity: orderItem.quantity,
            price: orderItem.price,
            discount: orderItem.discount,
        },
    });

    return response.data.data;
};
