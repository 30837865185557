import { useEffect, useState } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { Collection } from "../../types/collections.types";
import { useWindowWidth } from "../../utils/useWindowWith";
import { CollectionAndFieldsPermission } from "../../api/useCollections";

type TabPanelProps = {
    children?: React.ReactNode;
    index: number;
    value: number;
};

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <Box
            role="tabpanel"
            id={`simple-tabpanel-${index}`}
            {...other}
            sx={{
                display: value === index ? "flex" : "none", // adjust here
            }}
        >
            {value === index && (
                <Box display="flex" flex={1} flexDirection="column">
                    {children}
                </Box>
            )}
        </Box>
    );
}

type DetailTabsProps = {
    tabs: Array<{ label: string; icon: React.ReactElement; entity: string }>;
};

export function DetailTabs({ tabs }: DetailTabsProps) {
    const location = useLocation();
    const [value, setValue] = useState<number | 0>(0);
    const windowWidth = useWindowWidth();

    const queryClient = useQueryClient();
    const collectionsWithPermission = queryClient.getQueryData(["collections"]) as CollectionAndFieldsPermission;

    const allowedTabs = tabs.filter(
        (tab) =>
            tab.entity === "detail" || tab.entity === "timeline" ||
            collectionsWithPermission?.collections?.some((collection: Collection) => collection.collection === tab.entity)
    );

    useEffect(() => {
        const currentTab = location.pathname.split("/").pop();
        const initialValue = allowedTabs.findIndex((tab) => tab.entity === currentTab);

        setValue(initialValue);
    }, [location.pathname, allowedTabs]);

    const navigate = useNavigate();

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        navigate(allowedTabs[newValue].entity);
        setValue(newValue);
    };

    return (
        <Box display="flex" flexDirection="column" maxWidth={windowWidth - 17}>
            <Tabs
                value={allowedTabs.length === 1 ? 0 : value}
                onChange={handleChange}
                sx={{ borderTopLeftRadius: 8 }}
                variant="scrollable"
                scrollButtons={false}
            >
                {allowedTabs.map((tab) => (
                    <Tab icon={tab.icon} iconPosition="start" key={tab.label} label={tab.label} />
                ))}
            </Tabs>

            {allowedTabs.map((tab, index) => (
                <TabPanel value={value} index={index} key={tab.label}>
                    <Outlet />
                </TabPanel>
            ))}
        </Box>
    );
}
