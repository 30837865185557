const templates = [
    {
        entity: "leads",
        template: "[L] {{item.first_name}} {{item.last_name}}",
    },
];

export const communicationsOptions = {
    fieldsToRemove: {
        fieldNamesToRemove: ["id"],
        fieldTypesToRemove: [],
    },

    groupsOptions: {
        fieldsWithExtraOptions: [
            {
                field: "related_with",
                settings: {
                    type: "manytoany",
                    template_many_to_any: templates,
                },
            },
        ],
    },
};
