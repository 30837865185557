import { UseRecordsOptions } from "../../api/useRecords";

const charactersLimit = 1000;

export const candidatesOptions = {
  fieldsToRemove: {
    fieldTypesToRemove: ["alias"],
    fieldNamesToRemove: ["id"],
  },
  groupsOptions: {
    fieldsWithExtraOptions: [
      {
        field: "files",
        fileFolder: "0dcbd88d-8175-42d6-8ee9-38c86a96f7be",
      },
      {
        field: "positive_aspects",
        charactersLimit: charactersLimit,
      },
      {
        field: "negative_aspects",
        charactersLimit: charactersLimit,
      },
    ],
  },
  dataGridOptions: {
    quickSearchToolBar: {
      active: true,
      inputLabel: "Pesquise por nome, email ou número de telefone...",
      fieldsToSearch: ["name", "email", "phone"],
    },
  },
} as UseRecordsOptions;
