import { UseRecordsOptions } from "../../api/useRecords";
import { APP_USERS_ROLES_FILTER } from "../../utils/constants";

export const callCenterLicensesOptions = {
    fieldsToRemove: {
        fieldNamesToRemove: ["id"],
        fieldTypesToRemove: ["alias"],
    },
    hideCreateButton: true,
    groupsOptions: {
        fieldsWithExtraOptions: [
            {
                field: "user",
                enabled: true,
                filter: APP_USERS_ROLES_FILTER,
            },
        ],
    },
} as unknown as UseRecordsOptions;
