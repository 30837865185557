import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "./config";
import { ErrorResponsesCreateOrUpdate, NewRecord } from "../types/records.types";
import { MutationOptions } from "../types/mutation.types";

export const useCreateRecord = (options?: MutationOptions) => {
	const queryClient = useQueryClient();

	return useMutation<any, ErrorResponsesCreateOrUpdate, NewRecord, any>({
		mutationFn: (newRecord: NewRecord) => createRecord(newRecord),
		onSuccess(data, variables) {
			queryClient.invalidateQueries({
				predicate(query: any) {
					return query.queryKey[0].startsWith(options?.queryInvalidatePrefix || variables.entity);
				},
			});
		},
	});
};

const createRecord = async (newRecord: NewRecord) => {
	const response = await api({
		method: "POST",
		url: `items/${newRecord.entity}`,
		data: newRecord.data,
	});

	return response.data.data;
};
