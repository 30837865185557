import { useUpdateRecord } from "../../api/useUpdateRecord";
import { Checkbox } from "@mui/material";
import { useState } from "react";

type TaskGridDoneProps = {
    id: number;
    status: string;
};

export const TaskDoneShortcut = ({ id, status }: TaskGridDoneProps) => {
    const [checked, setChecked] = useState(status === "done" ? true : false);
    const mutation = useUpdateRecord();

    const handleChange = (checked: boolean) => {
        mutation.mutate({
            entity: "tasks",
            id: id,
            data: {
                status: checked ? "done" : "open",
            },
        });
        setChecked(checked);
    };

    return (
        <Checkbox
            checked={checked}
            onClick={(e) => {
                e.stopPropagation();
            }}
            onChange={(e) => {
                handleChange(e.target.checked);
            }}
        />
    );
};
