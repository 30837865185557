import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "./config";
import { findQueryByPrefix } from "../utils/findQueries";
import { FormatedField } from "../types/fields.types";

export type FileData = {
    file: File;
    entity: string;
    itemId: string | number;
    folder?: string;
};

export const useFileUploadToField = (field: FormatedField) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (file: FileData) => uploadFile(file, field),
        onSuccess(data, variables) {
            queryClient.invalidateQueries({
                predicate: (query) => {
                    return findQueryByPrefix(query, `${variables.entity}`);
                },
            });
        },
    });
};

const uploadFile = async (file: FileData, field: FormatedField) => {
    const formData = new FormData();
    if (file.folder) {
        formData.append("folder", file.folder);
    }
    formData.append("file", file.file);

    const response = await api({
        method: "post",
        url: "/files",
        data: formData,
    });

    const uploadedFile = response.data.data;
    
    const fileAssociated = await associateFileToField(uploadedFile.id, file, field);

    return fileAssociated;
};

const associateFileToField = async (directusFileId: number, file: FileData, field: FormatedField) => {
    const data = dataToAssociateFile(directusFileId, field);

    const response = await api({
        method: "patch",
        url: `items/${file.entity}/${file.itemId}`,
        data: data,
    });

    return response.data.data;
};

const dataToAssociateFile = (directusFileId: number, field: FormatedField) => {
    const fieldType = field.settings.interface;

    if (fieldType === "file") {
        return {
            [field.field]: directusFileId,
        };
    } else if (fieldType === "files") {
        return {
            [field.field]: {
                create: [
                    {
                        directus_files_id: { id: directusFileId },
                    },
                ],
                update: [],
            },
        };
    }
};
