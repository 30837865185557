import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Controller } from "react-hook-form";
import { parseISO } from "date-fns";
import pt from "date-fns/locale/pt";
import { FieldProps } from "../renderFieldInput";

interface DateInputFieldProps extends FieldProps {
    value: string;
}

export const DateInputField = ({ field, value, entityForm, options, extraOptions }: DateInputFieldProps) => {
    const fieldName = extraOptions?.field ? extraOptions?.field : field.field;

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={pt}>
            <Controller
                name={fieldName}
                control={entityForm.control}
                defaultValue={value ? parseISO(value) : null}
                render={({ field: { ref, ...rest } }) => (
                    <DatePicker
                        {...options}
                        disabled={options?.disabled}
                        format="dd/MM/yyyy"
                        label={field.headerName}
                        sx={{ width: "100%" }}
                        {...rest}
                    />
                )}
            />
        </LocalizationProvider>
    );
};
