export type RecordsRelatedCustomFilterParamsType = {
	parentId?: string | number | undefined | null;
	collection: string | undefined;
};

export const recordsRelatedCustomFilter = (
	params: RecordsRelatedCustomFilterParamsType
) => {

	if (!params.parentId || !params.collection) return;

	return {
		related_with: {
			[`item:${params.collection}`]: {
				id: {
					_eq: params.parentId,
				},
			},
		},
	};
};
