import { Unstable_Grid2 as Grid } from "@mui/material";
import { Groups, EmojiPeople } from "@mui/icons-material";
import { useField } from "../../../../api/useField";
import { renderFieldView } from "../../renderFieldView";
import { FormatedField } from "../../../../types/fields.types";
import { ReactComponentElement } from "react";

type ClientAndLeadFieldProps = {
  value: any;
};

export const ClientAndLeadField = ({ value }: ClientAndLeadFieldProps) => {
  const { formatedField: clientField, status: clientFieldStatus } = useField("sessions", "client");
  const { formatedField: leadField, status: leadFieldStatus } = useField("sessions", "lead");

  if (clientFieldStatus !== "success" || leadFieldStatus !== "success") return null;

  if (value.client && clientField) {
    return <ClientOrLeadField value={value} field={clientField} icon={<Groups color="primary" />} />;
  } else if (value.lead && leadField) {
    return <ClientOrLeadField value={value} field={leadField} icon={<EmojiPeople color="primary" />} />;
  }
};

type ClientFieldProps = {
  value: any;
  field: FormatedField;
  icon: ReactComponentElement<any>;
};

const ClientOrLeadField = ({ value, field, icon }: ClientFieldProps) => {

  return (
    <Grid container alignItems="flex-end" spacing={2}>
      <Grid>{icon}</Grid>
      <Grid>{renderFieldView(field, value, "group")}</Grid>
    </Grid>
  );
};
