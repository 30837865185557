import store2 from "store2";
import { baseUrl } from "../api/config";

export const convertBmpToJpeg = async (fileId: string): Promise<string> => {
    const url = `${baseUrl}/assets/${fileId}?access_token=${store2.get("auth").access_token}`;

    return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = "anonymous";
        img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            if (img.width > 1350) {
                canvas.width = img.height;
                canvas.height = img.width;
                ctx?.translate(canvas.width / 2, canvas.height / 2);
                ctx?.rotate((-90 * Math.PI) / 180); // Rotate 90 degrees to the left
                ctx?.drawImage(img, -img.width / 2, -img.height / 2);
            } else {
                canvas.width = img.width;
                canvas.height = img.height;
                ctx?.drawImage(img, 0, 0);
            }

            resolve(canvas.toDataURL("image/jpeg"));
        };
        img.onerror = reject;
        img.src = url;
    });
};
