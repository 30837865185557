import { useContext, useState } from "react";
import { NotesList } from "./NotesList";
import { Box, Button, Unstable_Grid2 as Grid } from "@mui/material";
import { useRecord } from "../../api/useRecord";
import { Wysiwyg } from "../wysiwyg/Wysiwyg";
import { useForm } from "react-hook-form";
import { useCreateRecord } from "../../api/useCreateRecord";
import { useUpdateRecord } from "../../api/useUpdateRecord";
import { RecordContext } from "../record/Record";

type NotesComponentProps = {
    extraEntities?: Array<string>;
};

export function NotesComponent({ extraEntities }: NotesComponentProps) {
    const [selectedItem, setSelectedItem] = useState<number | null>(null);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const entityForm = useForm();
    const createNote = useCreateRecord();
    const updateNote = useUpdateRecord();

    const { data } = useRecord("notes", "*.*", selectedItem);

    const recordContext = useContext(RecordContext);

    const onSubmit = async (data: any) => {
        if (!selectedItem && recordContext) {
            const response = await createNote.mutateAsync({
                entity: "notes",
                data: {
                    content: data.content,
                    [recordContext.entity]: recordContext?.id,
                },
            });
            setSelectedItem(response.id);
        }
        if (selectedItem && recordContext) {
            updateNote.mutate({
                entity: "notes",
                id: selectedItem,
                data: {
                    content: data.content,
                },
            });
        }
        setIsEditing(false);
    };

    if (!recordContext) return null;

    return (
        <form
            onSubmit={entityForm.handleSubmit(onSubmit)}
            style={{
                height: "calc(100vh - 270px)",
                width: "100%",
                backgroundColor: "#fff",
            }}
        >
            <Grid container p={2}>
                <Grid xs={3}>
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={() => {
                            setSelectedItem(null);
                            setIsEditing(true);
                            entityForm.reset();
                        }}
                    >
                        Adicionar Nota
                    </Button>
                    <Box sx={{ mt: 2 }}>
                        <NotesList selectedItem={selectedItem} setSelectedItem={setSelectedItem} extraEntities={extraEntities} />
                    </Box>
                </Grid>
                <Grid xs={9} pl={4}>
                    <Grid xs={12} display="flex" justifyContent="flex-end" mb={2}>
                        {isEditing ? (
                            <Grid container spacing={2}>
                                <Grid>
                                    <Button color="secondary" type="submit" onClick={() => setIsEditing(false)}>
                                        Cancelar
                                    </Button>
                                </Grid>
                                <Grid>
                                    <Button type="submit">Guardar</Button>
                                </Grid>
                            </Grid>
                        ) : (
                            selectedItem && (
                                <Button type="submit" onClick={() => setIsEditing(true)}>
                                    Editar
                                </Button>
                            )
                        )}
                    </Grid>
                    <Grid xs={12} mt={isEditing || selectedItem ? 4.5 : 15}>
                        <Wysiwyg
                            entityForm={entityForm}
                            initialValue={data?.item?.content}
                            isDisabled={!isEditing}
                            //@ts-ignore
                            field={{ field: "content", settings: { entity: "leads" } }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
}
