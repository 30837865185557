import { useContext } from "react";
import { RecordsHeader } from "../../components/recordsHeader/RecordsHeader";
import { RecordsContext } from "../../components/records/Records";

import { Box } from "@mui/material";
import { Progress } from "../../components/progress/Progress";

export const CandidatesListHeader = () => {
	const recordsContext = useContext(RecordsContext);
	if (!recordsContext) return null;

	return (
		<Box>
			<RecordsHeader collection={recordsContext?.collection} />
			<Progress context={recordsContext} />
		</Box>
	);
};
