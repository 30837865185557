import { AuthenticatedRoute } from "./AuthenticatedRoute";

type AuthenticatedRouteProps = Array<
    Array<{
        path: string;
        element: React.ReactNode;
    }>
>;

export const withAuthenticatedRoute = (routesToAuthenticate: AuthenticatedRouteProps) => {
    const authenticatedRoutes = routesToAuthenticate.flatMap((route) => {
        const routesChanged = route.map((r) => {
            r.element = (<AuthenticatedRoute>{r.element}</AuthenticatedRoute>);
            return r;
        });

        return routesChanged;
    });

   
    return authenticatedRoutes;
};
