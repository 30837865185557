import { CollectionsProvider } from "./CollectionsProvider";
import { AppContent } from "./AppContent";

export const Root = () => {
    return (
        <CollectionsProvider>
            <AppContent />
        </CollectionsProvider>
    );
};
