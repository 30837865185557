const hexToRgb = (hex: string) => {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16),
          }
        : null;
};

export const isLightColor = (hexColor: string) => {
    let rgbColor = hexToRgb(hexColor);
    if(!rgbColor) return false;
    let brightness = Math.round(
        (parseInt(rgbColor.r.toString()) * 299 + parseInt(rgbColor.g.toString()) * 587 + parseInt(rgbColor.b.toString()) * 114) /
            1000
    );
    return brightness > 128;
};