import { Box, IconButton, Paper, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { CloseFullscreen, OpenInFull } from "@mui/icons-material";
import { styles } from "./styles";
import { useRecords } from "../../api/useRecords";
import { UserFromAPI } from "../../types/auth.types";
import { useStore } from "../../store/store";
import { useRenderOnce } from "../../utils/useRenderOnce";
import { useIsMobile } from "../../utils/useIsMobile";
import { useUser } from "../../api/useAuth";

export const PhoneWidget = () => {
    const [minimized, setMinimized] = useState(false);
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const { data } = useRecords("call_center_licenses", "*.*", { staleTime: Infinity });
    const { data: user } = useUser();
    const {
        phoneClient,
    } = useStore();
    const { shouldRender, setShouldRender } = useRenderOnce("phone_widget");
    const isMobile = useIsMobile();

    const handleMinimize = () => {
        setMinimized(true);
    };

    const handleMaximize = () => {
        setMinimized(false);
    };

    useEffect(() => {
        if (data && user) {
            phoneClient.iframeRef = iframeRef;

            const connectionUrl = createConnectionUrl(data.items, user) || "";
            if (connectionUrl) {
                phoneClient.setUrl(connectionUrl);
                setShouldRender(true);
            } else {
                phoneClient.setUrl("");
                setShouldRender(false);
            }
        }
        //@ts-ignore
    }, [iframeRef, data, user, phoneClient.url]);

    if (isMobile) return null;
    if (!phoneClient.url) return null;
    if (!shouldRender) return null;

    return (
        <Paper elevation={3} sx={{ ...styles.phoneWidget, height: minimized ? 50 : "auto" }}>
            <Box sx={styles.phoneWidgetHeader}>
                <IconButton onClick={minimized ? handleMaximize : handleMinimize} sx={{ color: "white" }}>
                    {minimized ? <OpenInFull /> : <CloseFullscreen />}
                </IconButton>
                <Typography color="white">Telefone</Typography>
            </Box>

            <Box
                component="iframe"
                ref={iframeRef}
                id="webphone"
                src={phoneClient.url}
                allow="microphone *"
                sx={{ ...styles.phoneWidgetIframe, display: minimized ? "none" : "block" }}
            />
        </Paper>
    );
};

type CallCenterLicenses = Array<CallCenterLicense>;
type CallCenterLicense = {
    api_key: string;
    user: UserFromAPI;
};
export const createConnectionUrl = (licenses: CallCenterLicenses, user: UserFromAPI) => {
    const license = licenses.find((license) => {
        return license.user && license.user.id === user.id;
    });

    if (license) {
        return `https://app.letscall.io?iframe=default&apiKey=${license.api_key}`;
    }
};
