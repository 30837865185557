export const extractTextAndTruncate = (html: string, charactersLimit = 300): string => {
    
    // Create a new DOM element and set its innerHTML to the provided HTML string
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;

    // Extract the text content from the element
    let textContent = tempDiv.textContent || tempDiv.innerText || "";

    // Truncate the text content to 150 characters and add ellipses if necessary
    return textContent.length > charactersLimit  ? `${textContent.substring(0, charactersLimit)}...` : textContent;
};
