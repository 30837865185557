import { ChangeEvent, useState } from "react";
import { Checkbox } from "@mui/material";
import { FormatedField, FormatedFields } from "../../types/fields.types";
import { useStore } from "../../store/store";

type FieldVisibilityProps = {
    gridColumn: FormatedField;
};

export const ToggleColumnVisibility = ({ gridColumn }: FieldVisibilityProps) => {
    const [isChecked, setIsChecked] = useState<boolean>(gridColumn?.visible || false);
    const { gridSettings } = useStore();

    const setCheckedByField = (e: ChangeEvent<HTMLInputElement>, gridColumns: FormatedFields, field: string) => {
        const newItems = gridColumns.map((gridColumn) => {
            if (gridColumn.field === field) {
                return { ...gridColumn, visible: e.target.checked };
            }
            return gridColumn;
        });
        setIsChecked(e.target.checked);
        gridSettings.setGridSettings({ gridColumns: newItems });
    };

    return <Checkbox checked={isChecked} onChange={(e) => setCheckedByField(e, gridSettings.gridColumns, gridColumn.field)} />;
};
