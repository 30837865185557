import { Info, Psychology } from "@mui/icons-material";

export const tabs = [
	{
		icon: <Info />,
		entity: "detail",
		label: "Detalhes",
	},
	{
		icon: <Psychology />,
		entity: "treatments",
		label: "Tratamentos",
	},
];
