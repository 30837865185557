import Box from "@mui/material/Box";
import Editor from "ckeditor5-custom-build";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useRecords } from "../../api/useRecords";
import { useState, useEffect } from "react";
import { UserFromAPI } from "../../types/auth.types";
import { Controller, UseFormReturn } from "react-hook-form";
import { SelectTemplate } from "../selectTemplate/SelectTemplate";
import { FormatedField } from "../../types/fields.types";
import { ROLES } from "../../utils/constants";

type WysiwygProps = {
    initialValue?: string;
    entityForm?: UseFormReturn;
    isDisabled?: boolean;
    size?: string;
    field?: FormatedField;
};
type UsersForMentions = {
    id: string;
    text: string;
};

export function Wysiwyg({ initialValue, entityForm, isDisabled, size, field }: WysiwygProps) {
    const [usersForMentions, setUsersForMentions] = useState<Array<UsersForMentions>>([]);
    const { data } = useRecords("directus_users", "*.*", {
        extraOptions: {
            filter: {
                role: {
                    _in: [ROLES.recepcionist, ROLES.theurapist, ROLES.nfb_techncians, ROLES.director, ROLES.admin],
                },
            },
        },
    });

    useEffect(() => {
        if (data) {
            const users = data.items.map((user: UserFromAPI) => {
                return {
                    id: `@${user.id}`,
                    text: `${user.first_name} ${user.last_name}`,
                };
            });

            setUsersForMentions(users);
        }
    }, [data]);

    const handleEditorChange = (editor: Editor, fieldOnChange: Function) => {
        const data = editor.getData();
        fieldOnChange(data);
    };

    if (usersForMentions.length === 0) return null;

    if (entityForm && !isDisabled) {
        return (
            <Box display="flex" flexDirection="column" gap={4}>
                {!isDisabled && entityForm && field && (
                    <SelectTemplate entityForm={entityForm} field={field.field} entity={field.settings.entity} />
                )}
                <Controller
                    name="content"
                    control={entityForm?.control}
                    defaultValue={initialValue || ""}
                    render={({ field }) => {
                        return (
                            <Box>
                                <CKEditor
                                    data={field.value || initialValue}
                                    disabled={isDisabled}
                                    editor={Editor}
                                    onChange={(event, editor) => {
                                        handleEditorChange(editor, field.onChange);
                                    }}
                                    onReady={(editor) => {
                                        editor.editing.view.change((writer) => {
                                            //@ts-ignore
                                            writer.setStyle("height", size || "450px", editor.editing.view.document.getRoot());
                                        });
                                    }}
                                    config={{
                                        placeholder: "Escreva aqui... pode mencionar alguém com @",
                                        mention: {
                                            feeds: [
                                                {
                                                    marker: "@",
                                                    feed: (e) => {
                                                        const searchText = e.toLowerCase();
                                                        return usersForMentions.filter((user) => {
                                                            return user.text.toLowerCase().includes(searchText);
                                                        });
                                                    },
                                                    minimumCharacters: 1,
                                                    itemRenderer: (item) => {
                                                        return item.text || "";
                                                    },
                                                },
                                            ],
                                        },
                                    }}
                                />
                            </Box>
                        );
                    }}
                />
            </Box>
        );
    } else {
        return (
            <Box>
                <CKEditor data={initialValue || ""} disabled={true} editor={Editor} />
            </Box>
        );
    }
}
