import { EmojiPeople, Psychology } from "@mui/icons-material";
import { ToggleButtonGroup, ToggleButton, Box } from "@mui/material";
import { Controller, UseFormReturn } from "react-hook-form";
import { AsyncAutoCompleteNew } from "../../async-auto-complete/AsyncAutoCompleteNew";
import { useEffect } from "react";
import { FieldFromParam, getValueByEntity } from "../../../api/useItemsFromSearchParams";

type LeadOrTratmentSelectProps = {
    entityForm: UseFormReturn;
    fieldName: string;
    itemFromParams?: Array<FieldFromParam>;
};

export const LeadOrTratmentSelect = ({ entityForm, fieldName, itemFromParams }: LeadOrTratmentSelectProps) => {
    const learOrTreatmentButton = entityForm.watch(fieldName);

    const treatmentIdFromParams = itemFromParams && getValueByEntity(itemFromParams, "treatment");
    const leadIdFromParams = itemFromParams && getValueByEntity(itemFromParams, "lead");
    const toogleDefaultValue = treatmentIdFromParams ? "treatment" : leadIdFromParams ? "lead" : "treatment";

    useEffect(() => {
        entityForm.setValue("treatment", null);
        entityForm.setValue("lead", null);
    }, [learOrTreatmentButton]);

    return (
        <Box display="flex" gap={2}>
            <Controller
                name={fieldName}
                control={entityForm.control}
                defaultValue={toogleDefaultValue}
                render={({ field }) => (
                    <ToggleButtonGroup
                        color="secondary"
                        value={field.value}
                        exclusive
                        onChange={(event, value) => {
                            field.onChange(value);
                        }}
                        aria-label="view mode"
                        size="small"
                    >
                        <ToggleButton value="lead">
                            <EmojiPeople />
                        </ToggleButton>
                        <ToggleButton value="treatment">
                            <Psychology />
                        </ToggleButton>
                    </ToggleButtonGroup>
                )}
            />
            {learOrTreatmentButton === "treatment" && (
                <AsyncAutoCompleteNew
                    entityForm={entityForm as unknown as UseFormReturn}
                    fieldName="treatment"
                    placeholder="Selecionar tratamento"
                    enabled={false}
                    recordsOptions={{
                        idFromParams: treatmentIdFromParams,
                        entity: "treatments",
                        fieldsToQuery:
                            "*,client.user_id.first_name,client.user_id.last_name, client.client_id,theurapists_involved_in_treamtent.technicians_id.*.*",
                        customFilter: (value: string) => ({
                            client: {
                                client_id: {
                                    _icontains: value,
                                },
                            },
                        }),
                        labelKey: (option) => {
                            const client_id = option?.client?.client_id;
                            const client_name = `${option?.client?.user_id?.first_name} ${option?.client?.user_id?.last_name}`;
                            const treatment_status = option?.treatment_status_new;

                            return `${client_id} - ${client_name} [${treatment_status}]`;
                        },
                    }}
                />
            )}
            {learOrTreatmentButton === "lead" && (
                <AsyncAutoCompleteNew
                    entityForm={entityForm as unknown as UseFormReturn}
                    fieldName="lead"
                    placeholder="Selecionar lead"
                    recordsOptions={{
                        idFromParams: leadIdFromParams,
                        entity: "leads",
                        fieldsToQuery: "*.*",
                        customFilter: (value: string) => ({
                            _or: [
                                { first_name: { _icontains: value } },
                                { last_name: { _icontains: value } },
                                { email: { _icontains: value } },
                                { phone: { _icontains: value } },
                            ],
                        }),
                        labelKey: (option) => {
                            const first_name = option?.first_name;
                            const last_name = option?.last_name;
                            const phone = option?.phone;

                            return `${first_name} ${last_name} - ${phone}`;
                        },
                    }}
                />
            )}
        </Box>
    );
};
