import { Controller } from "react-hook-form";
import { FieldProps } from "../renderFieldInput";
import { Select, MenuItem, FormControl, FormHelperText, InputLabel } from "@mui/material";
import { useEffect } from "react";

interface BooleanInputFieldProps extends FieldProps {
  value: boolean;
}

export const BooleanInputField = ({ field, value, entityForm, options, extraOptions }: BooleanInputFieldProps) => {
  const inputValue = value ? "true" : "false";

  useEffect(() => {
    entityForm.setValue(field.field, inputValue === "true" ? true : false);
  }, [inputValue, field.field, entityForm]);

  const fieldName = extraOptions?.field ? extraOptions?.field : field.field;

  return (
    <Controller
      name={fieldName}
      control={entityForm.control}
      defaultValue={inputValue || "false"}
      render={({ field: inputField }) => (
        <FormControl fullWidth error={options?.error}>
          <InputLabel>{field.headerName}</InputLabel>
          <Select
            {...inputField}
            label={field.headerName}
            disabled={options?.disabled}
            onChange={inputField.onChange}
          >
            <MenuItem value={"true"}>Sim</MenuItem>
            <MenuItem value={"false"}>Não</MenuItem>
          </Select>
          <FormHelperText>{options?.helperText}</FormHelperText>
        </FormControl>
      )}
    />
  );
};
