import { Autocomplete, Box, Button, TextField, Typography } from "@mui/material";
import { useRecords } from "../../api/useRecords";
import { useCreateRecord } from "../../api/useCreateRecord";
import { useContext, useEffect, useState } from "react";
import { useStore } from "../../store/store";
import { getItems } from "../../api/items";
import { useFields } from "../../api/useFields";
import { FormatedField } from "../../types/fields.types";
import { CustomChip } from "../chips/CustomChip";
import { RecordContext } from "../record/Record";
import { RelatedRecordsContext } from "../recordsRelated/RecordsRelated";
type ParentId = string | number | undefined | null;

type Item = {
    id: number;
    name: string;
};

export const AssociateRecord = () => {
    const recordContext = useContext(RecordContext);
    const relatedRecordsContext = useContext(RelatedRecordsContext);

    const parentEntity = recordContext?.entity;
    const parentId = recordContext?.id;
    const entity = relatedRecordsContext?.entity;

    const [selectedItemId, setSelectedItemId] = useState<number | null>(null);
    const { data, status } = useRecords(entity || "", "*.*");
    const { formatedFields } = useFields(entity || "");

    const associateRecord = useCreateRecord({ queryInvalidatePrefix: "professional_partners" });
    const { notifications } = useStore();

    const associateItemToRecord = async (entity: string, parentEntity: string, id: number, parentId: ParentId) => {
        const entityToUse = `${entity}_${parentEntity}`;
        const response = await getItems(entityToUse, "*.*", {
            filter: {
                _and: [
                    {
                        [`${entity}_id`]: id,
                    },
                    {
                        [`${parentEntity}_id`]: parentId,
                    },
                ],
            },
            sort: "id",
        });

        if (response.items.length > 0) {
            notifications.setNotification({
                isOpen: true,
                message: `Registo já está associado`,
                severity: "error",
            });
            return;
        }

        associateRecord.mutate({
            entity: entityToUse,
            data: {
                [`${entity}_id`]: id,
                [`${parentEntity}_id`]: parentId,
            },
        });
    };

    useEffect(() => {
        if (associateRecord.isSuccess) {
            notifications.setNotification({
                isOpen: true,
                message: `Registo associado com sucesso`,
                severity: "success",
            });
            setSelectedItemId(null);
        }
        //@ts-ignore
    }, [associateRecord.isSuccess]);

    if (status !== "success") return null;
    // To abstract later so we can pass fields as settings
    const specializationField = formatedFields.find((field) => field.field === "specialization");

    return (
        <Box sx={{ display: "flex", gap: 6 }}>
            <Autocomplete
                sx={{ minWidth: 400 }}
                value={data?.items.find((item: Item) => item.id === selectedItemId) || null}
                options={data?.items || []}
                onChange={(event, newValue: Item | null) => {
                    setSelectedItemId(newValue ? newValue.id : null);
                }}
                getOptionLabel={(option) => {
                    return `${option.name}`;
                }}
                renderOption={(props, option) => {
                    return (
                        // To abstract later so we can pass fields as settings
                        <RenderAssociateOption key={option.id} props={props} option={option} field={specializationField} />
                    );
                }}
                renderInput={(params) => {
                    const selectedItem = data?.items.find((item: Item) => item.id === selectedItemId);
                    return (
                        <TextField
                            {...params}
                            label="Select Item"
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {params.InputProps.endAdornment}
                                        {selectedItem && specializationField && (
                                            <CustomChip field={specializationField} label={selectedItem.specialization} />
                                        )}
                                    </>
                                ),
                            }}
                        />
                    );
                }}
            />
            <Button
                onClick={() => {
                    if (selectedItemId && parentEntity && entity) {
                        associateItemToRecord(entity, parentEntity, selectedItemId, parentId);
                    }
                }}
            >
                Associar
            </Button>
        </Box>
    );
};

type RenderAssociateOptionProps = {
    props: any;
    option: any;
    field?: FormatedField;
};
const RenderAssociateOption = ({ props, option, field }: RenderAssociateOptionProps) => {
    return (
        <Box {...props} display="flex" gap={2} p={2} alignItems="center">
            <Typography variant="body1">{option.name}</Typography>
            {field && <CustomChip field={field} label={option.specialization} />}
        </Box>
    );
};
