import { Controller, useFieldArray } from "react-hook-form";
import { FieldProps } from "../../renderFieldInput";
import {
    Select,
    MenuItem,
    FormControl,
    FormHelperText,
    InputLabel,
    Typography,
    Paper,
    Button,
    Unstable_Grid2 as Grid,
    IconButton,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import _ from "lodash";
import { useEffect } from "react";
import { TreatmentData } from "../../../../types/fields.types";

interface TreatmentProvidedInputFieldProps extends FieldProps {}

export const TreatmentProvidedInputField = ({
    field: apiField,
    value,
    entityForm,
    options,
    extraOptions,
}: TreatmentProvidedInputFieldProps) => {
    const fieldName = extraOptions?.field ? extraOptions?.field : apiField.field;

    const { fields, append, remove } = useFieldArray({
        name: fieldName,
        control: entityForm.control,
        rules: {
            validate: (data: unknown[]) => hasDuplicateTreatments(data as TreatmentData[]),
        },
    });

    useEffect(() => {
        if (value) {
            entityForm.setValue(fieldName, value.treatment_provided);
        }
    }, [entityForm, fieldName, value]);

    return (
        <Paper variant="outlined" sx={{ p: 2 }}>
            <Typography marginBottom={2}>{apiField.headerName} </Typography>
            {entityForm.formState.errors.treatment_provided ? (
                <Typography mb={2} color="red">
                    {entityForm.formState.errors.treatment_provided?.root?.message as string}{" "}
                </Typography>
            ) : null}
            {fields.map((item, index) => {
                return (
                    <Paper key={item.id} variant="outlined" sx={{ display: "flex", mb: 1 }}>
                        <Grid container flexWrap="nowrap" flexGrow={1}>
                            {apiField.settings.options.fields.map((field) => {
                                return (
                                    <Grid key={field.field} flexGrow={1} flex={1}>
                                        <Controller
                                            name={`${fieldName}.${index}.${field.field}`}
                                            control={entityForm.control}
                                            defaultValue={value || ""}
                                            render={({ field: inputField }) => (
                                                <FormControl fullWidth error={options?.error} sx={{ m: 1 }}>
                                                    <InputLabel>{handleLabel(field.field)}</InputLabel>
                                                    <Select
                                                        {...inputField}
                                                        disabled={options?.disabled}
                                                        label={handleLabel(field.field)}
                                                    >
                                                        {field.meta.options.choices.map((choice) => {
                                                            return (
                                                                <MenuItem key={choice.value} value={choice.value}>
                                                                    {choice.text}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </Select>
                                                    <FormHelperText>{options?.helperText}</FormHelperText>
                                                </FormControl>
                                            )}
                                        />
                                    </Grid>
                                );
                            })}
                            <Grid>
                                <IconButton sx={{ mt: 2 }} type="button" onClick={() => remove(index)}>
                                    <Delete />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Paper>
                );
            })}
            <Button
                sx={{ mt: 2 }}
                type="button"
                onClick={() =>
                    append({
                        treatment: "",
                        status: "",
                    })
                }
            >
                Adicionar
            </Button>
        </Paper>
    );
};

const hasDuplicateTreatments = (data: TreatmentData[]) => {
    const uniqueTreatments = _.uniqBy(data, "treatment");

    if (uniqueTreatments?.length !== data?.length && data) {
        // If lengths are different, then there are duplicates
        return "Não pode usar o mesmo tratamento mais do que uma vez";
    }
};

const handleLabel = (field: string) => {
    return field === "treatment" ? "Tratamento" : "Estado";
};
