import { UseFormReturn } from "react-hook-form";
import { Unstable_Grid2 as Grid } from "@mui/material";
import { FormatedField } from "../../types/fields.types";
import { CalendarFiltersType } from "./calendar.types";
import { useRecords } from "../../api/useRecords";
import { CalenderFilterSelectField } from "./CalenderFilterSelectField";
import { useIsMobile } from "../../utils/useIsMobile";

export type CalendarFiltersProps = {
    entityForm: UseFormReturn;
    calendarFilters: CalendarFiltersType | null;
    fields: {
        statusField: FormatedField | undefined;
    };
};

export const CalendarFilters = ({ entityForm, fields }: CalendarFiltersProps) => {
    const { statusField } = fields;
    const isMobile = useIsMobile();

    return (
        <Grid container maxWidth="100%" flexDirection="row" rowSpacing={5} columnSpacing={isMobile ? 0 : 5} flexGrow={1}>
            <Grid xs={12} lg={3}>
                <ClinicFilter entityForm={entityForm} />
            </Grid>
            <Grid xs={12} lg={3}>
                <ServiceCategoryFilter entityForm={entityForm} />
            </Grid>
            <Grid xs={12} lg={3}>
                <TechnicianFilter entityForm={entityForm} />
            </Grid>
            <Grid xs={12} lg={3}>
                {statusField && <SessionStatusFilter entityForm={entityForm} field={statusField} />}
            </Grid>
        </Grid>
    );
};

type ClinicFilterProps = {
    entityForm: UseFormReturn;
};

const ClinicFilter = ({ entityForm }: ClinicFilterProps) => {
    const { status, data } = useRecords("clinics", "*.*");

    const defaultValue = [1];
    if (status !== "success") return null;

    return (
        <CalenderFilterSelectField
            entityForm={entityForm}
            fieldName="clinic_id"
            label="Clínica"
            defaultValue={defaultValue}
            options={data.items}
            propertyToMatch="id"
        />
    );
};

type SessionStatusProps = {
    entityForm: UseFormReturn;
    field: FormatedField;
};

const SessionStatusFilter = ({ entityForm, field }: SessionStatusProps) => {
    const defaultValue = ["booked", "confirmed", "awaiting_in_clinic", "in_session", "done"];

    return (
        <CalenderFilterSelectField
            entityForm={entityForm}
            fieldName="status"
            label="Estado"
            defaultValue={defaultValue}
            options={field.settings.options.choices}
            propertyToMatch="value"
        />
    );
};

type ServiceCategoryProps = {
    entityForm: UseFormReturn;
};

const ServiceCategoryFilter = ({ entityForm }: ServiceCategoryProps) => {
    const options = [
        {
            value: "neurofeedback",
            text: "NeuroFeedback",
        },
        {
            value: "psicologia",
            text: "Psicologia",
        },
        {
            value: "avaliacoes",
            text: "Avaliações",
        },
        {
            value: "report",
            text: "Relatórios",
        },
    ];

    return (
        <CalenderFilterSelectField
            entityForm={entityForm}
            fieldName="service_category"
            label="Serviço"
            options={options}
            propertyToMatch="value"
        />
    );
};

type TechnicianFilterProps = {
    entityForm: UseFormReturn;
};

const TechnicianFilter = ({ entityForm }: TechnicianFilterProps) => {
    const { status, data } = useRecords("technicians", "*.*", { extraOptions: { sort: "user_id.first_name" } });

    if (status !== "success") return null;

    const onlyEnabledOptions = data?.items.filter((option: any) => option.disabled !== true);

    return (
        <CalenderFilterSelectField
            entityForm={entityForm}
            fieldName="technician"
            label="Técnico(a)"
            options={onlyEnabledOptions}
            propertyToMatch="id"
        />
    );
};
