import { createUrl } from "../../components/calendar/calendarUtils";
import { filterUsersRoles } from "../../utils/filterUsersRoles";
import { isSameDay } from "date-fns";

export const timeOffOptions = {
    fieldsToRemove: {
        fieldNamesToRemove: ["end_date", "group_one", "group_two", "group_thre"],
        fieldTypesToRemove: [],
    },

    groupsOptions: {
        fieldsWithExtraOptions: [
            {
                field: "clinic_id",
                defaultValue: { id: 1, label: "Porto" },
                enabled: true,
            },
            {
                field: "user",
                relatedTable: "directus_users",
                displayTemplate: "{{first_name}} {{last_name}}",
                filter: filterUsersRoles,
                enabled: true,
            },
            {
                field: "type",
                defaultValue: "holiday",
            },

            {
                field: "start_date",
                dateFormat: "eeee, dd/MM/yyyy HH:mm",
            },
        ],
        isFormValid: (data: any) => isFormValid(data),
        navigateAfterCreate: (params: any) => createUrl(params, "schedules"),
        navigateAfterUpdate: (params: any) => createUrl(params, "schedules"),
    },
};

const isFormValid = (data: any) => {
    const start = new Date(data.start_date);
    const end = new Date(data.end_date);

    if (!isSameDay(start, end)) {
        return { valid: false, message: "A indisponibilidade tem de ser no mesmo dia" };
    }

    return { valid: true };
};
