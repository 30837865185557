import { Typography } from "@mui/material";

type FeedbackTitleProps = {
    value: {
        [key: string]: any;
    };
    fieldView: string;
};

export const FeedbackTitle = ({ value, fieldView }: FeedbackTitleProps) => {
    return (
        <Typography sx={{ whiteSpace: "pre-line" }} variant="body1">
            {fieldView === "grid" ? value.value : value.title}
        </Typography>
    );
};
