import { FormatedField } from "../types/fields.types";

type CustomizedField = {
    name: string;
    entity: string;
};

export const isCustomizedField = (field: FormatedField) => {
    const customizedFields = [
        { name: "treatment_provided", entity: "treatments" },
        { name: "client_treatment_provided", entity: "clients" },
        { name: "birthday", entity: "clients" },
        { name: "birthday", entity: "leads" },
        { name: "birthday", entity: "candidates" },
        { name: "comments", entity: "tasks" },
        { name: "title", entity: "feedbacks" },
        { name: "user_created", entity: "*" },
        { name: "amount", entity: "bank_transactions" },
        { name: "content", entity: "templates_content" },
    ];

    const containsField = (customizedFields: Array<CustomizedField>, field: FormatedField) => {
        return customizedFields.some((customizedField) => {
            if (customizedField.name === "user_created" && field.field === "user_created") {
                return true;
            }

            return customizedField.name === field.field && customizedField.entity === field.settings.entity;
        });
    };

    return containsField(customizedFields, field);
};
