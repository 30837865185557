import { useContext, useEffect, useState } from "react";
import { Box, Checkbox, Divider, FormControlLabel, Typography } from "@mui/material";
import { Grid } from "@mui/material";
import { Paper } from "@mui/material";
import { WorkHoursSummary } from "./WorkHoursSummary";
import { ClientHoursSummary } from "./ClientHoursSummary";
import { TimeOffSummary } from "./TimeOffSummary";
import { Payment } from "../types";
import { Avatar } from "../../../components/fieldsViews/fields/Avatar";
import { useUpdateRecord } from "../../../api/useUpdateRecord";
import { useCreateRecord } from "../../../api/useCreateRecord";
import { useField } from "../../../api/useField";
import { FileField } from "../../../components/fieldsViews/fields/FileField";
import { SalariesUploadButton } from "./SalariesUploadButton";
import { formatCurrency } from "../../../utils/formatCurrency";
import { StaffWorkLogsContext } from "../StaffWorkLogsDetail";
import { NoteField } from "./NoteField";
import { SPECIAL_USERS } from "../../../utils/constants";
import { calculateTotalValueServiceProvider } from "../workLogsUtils";

export const EmployeeSummary = () => {
    const { currentUser, isCurrentUserAdmin, payment, selectedMonth, selectedYear, userId, totals, staffAgreement } =
        useContext(StaffWorkLogsContext);

    const { formatedField: documentField } = useField("staff_monthly_payments", "documents");

    const updatePayment = useUpdateRecord();
    const createPayment = useCreateRecord();
    const [staffValidated, setStaffValidated] = useState(false);
    const [adminValidated, setAdminValidated] = useState(false);

    useEffect(() => {
        setStaffValidated(payment?.staff_validated || false);
        setAdminValidated(payment?.admin_validated || false);
    }, [payment]);

    const isAdminOrCurrentUser = isCurrentUserAdmin || currentUser?.id === userId;

    const handlePaymentUpdate = async (data: Partial<Payment>) => {
        if (payment) {
            updatePayment.mutateAsync({
                entity: "staff_monthly_payments",
                id: payment.id,
                data,
            });
        } else {
            createPayment.mutateAsync({
                entity: "staff_monthly_payments",
                data: {
                    user: { id: userId },
                    month: selectedMonth + 1, // +1 because make returns 0 for january
                    year: selectedYear,
                    staff_agreement: { id: staffAgreement.id },
                    ...data,
                },
            });
        }
    };

    const isServiceProvider = staffAgreement?.agreement_type === "services_provider";
    const valuePerHour = staffAgreement?.value_per_hour;
    const valuePerClient = staffAgreement?.value_per_client;

    const totalValue = calculateTotalValueServiceProvider({
        effectiveWorkHours: totals.effectiveWorkHours,
        valuePerHour: valuePerHour,
        totalClientHours: totals.totalClientHours,
        valuePerClient: valuePerClient,
    });

    return (
        <Box>
            <Paper sx={{ p: 2, mb: 2, padding: 4 }}>
                {staffAgreement ? (
                    <>
                        <Box display="flex" alignItems="top" justifyContent="space-between" gap={2}>
                            <Box display="flex" flexDirection="column" alignItems="start">
                                <Box display="flex" alignItems="center" gap={2} alignSelf="start">
                                    <Avatar value={staffAgreement.user.avatar as string} fieldView="group" />
                                    <Typography variant="h6" color="primary">
                                        {staffAgreement.user.first_name} {staffAgreement.user.last_name}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Box display="flex" alignItems="center" gap={2}>
                                        {isAdminOrCurrentUser && totalValue !== 0 && isServiceProvider && (
                                            <Typography>Valor para recibo: {formatCurrency(totalValue)}</Typography>
                                        )}
                                        {isAdminOrCurrentUser && isServiceProvider && payment && (
                                            <SalariesUploadButton
                                                selectedYear={selectedYear}
                                                selectedMonth={selectedMonth}
                                                type="service_provider_receipt"
                                                buttonText="Enviar recibo"
                                                display="icon"
                                                currentUser={currentUser}
                                            />
                                        )}
                                    </Box>
                                    {payment?.amount_receipt_to_transfer && (
                                        <Typography>
                                            Total a receber: {formatCurrency(payment.amount_receipt_to_transfer)}
                                        </Typography>
                                    )}
                                </Box>

                                <Box>
                                    {payment?.subsidy && (
                                        <Typography variant="body1" color="primary">
                                            Subsídio alimentação: {formatCurrency(payment.subsidy)}
                                        </Typography>
                                    )}
                                </Box>
                            </Box>

                            <Box display="flex" alignItems="start" flexDirection="column">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={staffValidated}
                                            onChange={(e) => {
                                                setStaffValidated(e.target.checked);
                                                handlePaymentUpdate({ staff_validated: e.target.checked });
                                            }}
                                        />
                                    }
                                    label="Validado por colaborador"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={adminValidated}
                                            disabled={
                                                currentUser?.id === SPECIAL_USERS.joana_director || isCurrentUserAdmin
                                                    ? false
                                                    : true
                                            }
                                            onChange={(e) => {
                                                setAdminValidated(e.target.checked);
                                                handlePaymentUpdate({ admin_validated: e.target.checked });
                                            }}
                                        />
                                    }
                                    label="Validado por admin"
                                />
                            </Box>
                            {documentField && payment?.id && (
                                <FileField
                                    value={payment.documents}
                                    field={documentField}
                                    fieldView="group"
                                    entity="staff_monthly_payments"
                                    id={payment.id}
                                    extraOptions={{
                                        fileFolder: "da5abe27-4c83-40fd-abac-47f5e8af2677",
                                        field: "documents",
                                        hidden: {
                                            detail: payment.staff_agreement.agreement_type === "services_provider",
                                        },
                                    }}
                                />
                            )}
                        </Box>
                        <Divider sx={{ my: 4 }} />
                        <Grid container spacing={2} display="flex" alignItems="top" justifyContent="space-between">
                            <Grid item>
                                <WorkHoursSummary
                                    isAdminOrCurrentUser={isAdminOrCurrentUser}
                                    isServiceProvider={isServiceProvider}
                                />
                            </Grid>
                            <Grid item>
                                <ClientHoursSummary />
                            </Grid>
                            <Grid item>
                                <TimeOffSummary />
                            </Grid>
                            <Grid item>
                                <NoteField adminValidated={adminValidated} handlePaymentUpdate={handlePaymentUpdate} />
                            </Grid>
                        </Grid>
                    </>
                ) : (
                    <Typography color="error">Sem acordo de colaborador</Typography>
                )}
            </Paper>
        </Box>
    );
};
