import { useEffect, useState } from "react";
import { GridRowsProp, DataGrid, GridRowModel } from "@mui/x-data-grid";
import { v4 as uuidv4 } from "uuid";
import { useColumns } from "./columns";
import { Service, OrderItem } from "./ordersGridTypes.types";
import { useCalculateOrder } from "./utils";
import { useCreateOrder } from "../../api/useCreateOrder";
import { useUpdateOrder } from "../../api/useUpdateOrder";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { RecordContext, RecordContextType } from "../../components/record/Record";
import { OrdersFooter } from "./OrdersFooter";
import { OrdersServiceSearch } from "./OrdersServiceSearch";
import { useRelatedRecords } from "../../api/useRelatedRecords";
import { convertApiItemsToGridRows } from "./utils";
import { OrderHeaderFields } from "./OrderHeaderFields";
import { setFieldsErrors } from "../../utils/setFieldsErrors";
import { Typography } from "@mui/material";
import { useStore } from "../../store/store";

const gridRows: GridRowsProp = [];

declare module "@mui/x-data-grid" {
  interface FooterPropsOverrides {
    orderTotal: number;
    recordContext: RecordContextType;
    type: "form" | "detail";
  }
}

export const OrderForm = () => {
  const [item, setItem] = useState<Service | null>(null);
  const [orderTotal, setOrderTotal] = useState(0);
  const createOrder = useCreateOrder();
  const updateOrder = useUpdateOrder();
  const navigate = useNavigate();
  const recordContext = useContext(RecordContext);
  const [rows, setRows] = useState(gridRows);
  const [rowsToDelete, setRowsToDelete] = useState<Array<any>>([]);
  const calculateOrderTotal = useCalculateOrder();
  const relatedRecords = useRelatedRecords(
    "order_items",
    "*.*,service_variant.service.*.*,service_variant.agreement.*",
    "order_id",
    recordContext?.item?.id
  );

  const columns = useColumns({
    rows: rows,
    setRows: setRows,
    setRowsToDelete: setRowsToDelete,
    type: "create",
  });
  const { notifications } = useStore();

  useEffect(() => {
    if (!item) return;
    const id = uuidv4();
    setRows((oldRows) => [
      ...oldRows,
      {
        id: id,
        variantId: item.variantId,
        sku: item.sku,
        serviceName: item.serviceName,
        agreementName: item.agreementName,
        type: item.type,
        quantity: 1,
        price: item.price,
        discount: 0,
        total: item.price,
        newRow: true,
      },
    ]);

    setItem(null);
  }, [item]);

  useEffect(() => {
    if (recordContext && recordContext.type === "update") {
      if (rows.length > 0) return;
      if (relatedRecords.data && relatedRecords.data.items.length > 0) {
        const items = convertApiItemsToGridRows(relatedRecords.data.items);
        setRows(items);
      }
    }
  }, [relatedRecords, rows, recordContext]);

  useEffect(() => {
    const total = calculateOrderTotal(rows);
    setOrderTotal(total);
    recordContext?.entityForm.setValue("order_items", rows);
    recordContext?.entityForm.setValue("order_items_to_delete", rowsToDelete);
    recordContext?.entityForm.setValue("total", total);
  }, [rows, calculateOrderTotal, recordContext?.entityForm, rowsToDelete]);

  useEffect(() => {
    if (createOrder.isSuccess) {
      navigate(`/orders/${createOrder.data.id}/detail`);
      recordContext?.entityForm.reset();
    } else if (createOrder.isError) {
      const errors = createOrder.error.response.data.errors;
      if (errors[0].extensions.code === "INTERNAL_SERVER_ERROR" && !notifications.notification) {
        notifications.setNotification({
          isOpen: true,
          message: `Error - ${errors[0].message}`,
          severity: "error",
        });
      } else {
        if (!recordContext?.entityForm) return;
        setFieldsErrors(errors, recordContext.entityForm);
      }
    }

    if (updateOrder.isSuccess) {
      navigate(`/orders/${updateOrder.data.id}/detail`);
      recordContext?.entityForm.reset();
    } else if (updateOrder.isError) {
      console.log("Error updating order", updateOrder);
    }
  }, [createOrder, navigate, updateOrder, notifications, recordContext?.entityForm]);

  const processRowUpdate = (newRow: GridRowModel, oldRow: any) => {
    const updatedRow = { ...newRow };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const onSubmit = (data: any) => {
    if (!data.order_date || !data.order_items || !data.payment_method || !data.treatment) {
      alert("Preencha todos os campos obrigatórios");
      return;
    }

    if (data.order_items.length === 0) {
      alert("Adicione pelo menos um serviço");
      return;
    }

    const newOrder = {
      treatment: data.treatment?.id,
      payment_method: data.payment_method,
      payment_status: data.payment_status,
      order_date: data.order_date,
      orderItems: data.order_items as OrderItem[],
      notes: data.notes,
    };

    if (recordContext?.type === "create") {
      createOrder.mutate(newOrder);
    } else if (recordContext?.type === "update") {
      updateOrder.mutate({
        order_id: recordContext?.item?.id,
        order_items_to_delete: data.order_items_to_delete,
        ...newOrder,
      });
    }
  };

  if (!recordContext) return null;
  return (
    <form
      id={recordContext.entity}
      onSubmit={recordContext.entityForm.handleSubmit(onSubmit)}
      style={{ backgroundColor: "#fff", width: "100%", padding: "8px" }}
    >
      <OrderHeaderFields />
      <Typography mt={6} mb={2} variant="h5">
        Adicione serviços:
      </Typography>
      {recordContext?.type !== "detail" ? <OrdersServiceSearch setItem={setItem} /> : null}
      {rows.length > 0 && (
        <DataGrid
          sx={{ mt: 5, width: "100%" }}
          autoHeight
          rows={rows}
          columns={columns}
          editMode="cell"
          processRowUpdate={processRowUpdate}
          slots={{
            footer: OrdersFooter,
          }}
          slotProps={{
            footer: { orderTotal, recordContext, type: "form" },
          }}
        />
      )}
    </form>
  );
};
