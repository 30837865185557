import { Record } from "../../components/record/Record";
import { Records } from "../../components/records/Records";
import { OrderHeader } from "./OrderHeader";
import { Groups } from "../../components/groups/Groups";
import { OrderForm } from "./OrderForm";
import { ordersOptions } from "./ordersOptions";
import { OrdersDetail } from "./OrdersDetail";

export const fieldsQuery = "*.*,client_id.user_id.*,treatment.client.*, treatment.client.user_id.*";

export const orderRoutes = [
    {
        path: "/orders",
        element: <Records entity="orders" fieldsQuery={fieldsQuery} options={ordersOptions} heightOfHeaders={190} />,
    },
    {
        path: "/orders/:id",
        element: (
            <Record entity="orders" type="detail" fieldsQuery={fieldsQuery} header={<OrderHeader />} body={<OrdersDetail />} />
        ),
        children: [
            {
                path: "/orders/:id/detail",
                element: <Groups />,
            },
        ],
    },
    {
        path: "/orders/:id/update",
        element: <Record entity="orders" fieldsQuery={fieldsQuery} type="update" header={<OrderHeader />} body={<OrderForm />} />,
    },
    {
        path: "/orders/create",
        element: <Record entity="orders" type="create" body={<OrderForm />} />,
    },
];
