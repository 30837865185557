import { Navigate } from "react-router-dom";


type AuthenticatedRouteProps = {
    children: React.ReactNode;
};

export const AuthenticatedRoute = ({ children }: AuthenticatedRouteProps) => {
    const auth = localStorage.getItem("auth");

    if (!auth) {
        // Redirect to login if not authenticated
        return <Navigate to="/login" />;
    }

    // Render children if authenticated
    return <>{children}</>;
};
