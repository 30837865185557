import { Box, Divider, Unstable_Grid2 as Grid, Paper, Typography } from "@mui/material";
import { GridSlotsComponentsProps } from "@mui/x-data-grid";
import { findField } from "../../utils/findField";
import { renderFieldInput } from "../../components/fieldsInputs/renderFieldInput";
import { renderFieldView } from "../../components/fieldsViews/renderFieldView";

export const OrdersFooter = (props: NonNullable<GridSlotsComponentsProps["footer"]>) => {
    if (!props.recordContext) return null;
    const notesField = findField(props.recordContext.formatedFields, "notes");

    return (
        <Paper>
            <Divider />
            <Grid container mt={5} display="flex" columnGap={10} p={4} justifyContent="space-between">
                <Grid xs={6}>
                    {notesField &&
                        props.type === "form" &&
                        renderFieldInput(
                            notesField,
                            { notes: props.recordContext.item?.notes || null },
                            "group",
                            props.recordContext.entityForm
                        )}
                    {notesField && props.type === "detail" && (
                        <Box>
                            <Typography variant="h5">Notas:</Typography>
                            {renderFieldView(notesField, props.recordContext.item, "group")}
                        </Box>
                    )}
                </Grid>

                <Grid xs={4} justifyContent="flex-end">
                    <Typography align="right" variant="h4">
                        Total: € {props.orderTotal}
                    </Typography>{" "}
                </Grid>
            </Grid>
        </Paper>
    );
};
