import { KeyboardArrowDown } from "@mui/icons-material";
import { Box, Unstable_Grid2 as Grid, Button, MenuItem, Menu } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { entitiesToSearch } from "../../tasks/tasksConfig";
import { entityToSearchLabel, handleFieldsQuery } from "../../tasks/tasksConfig";
import { renderFieldInput } from "../renderFieldInput";
import { UseFormReturn } from "react-hook-form";
import { FormatedField } from "../../../types/fields.types";
import { getFieldsFromParams } from "../../../api/useItemsFromSearchParams";
import { useSearchParams } from "react-router-dom";
import { clientsSearchSettings, leadsSearchSettings, treatmentsSearchSettings } from "../../../utils/createSearchFilter";

type RelatedWithInputFieldProps = {
    item: {
        related_with: [{ collection: string }];
    };
    field: FormatedField;
    entityForm: UseFormReturn;
};

export const RelatedWithInputField = ({ item, entityForm, field }: RelatedWithInputFieldProps) => {
    const relatedWith = item?.related_with.length > 0 ? item?.related_with[0]?.collection : "treatments";

    const [entityToSearch, setEntityToSearch] = useState({
        id: relatedWith,
        label: entityToSearchLabel(relatedWith),
    });
    const [params] = useSearchParams();

    const allFields = useMemo(() => getFieldsFromParams(params), [params])[0];

    useEffect(() => {
        if (allFields) {
            setEntityToSearch({
                id: allFields.entity,
                label: entityToSearchLabel(allFields.entity),
            });
        }
    }, [allFields]);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isOpen = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelection = (e: React.MouseEvent<HTMLElement>) => {
        setEntityToSearch({
            id: e.currentTarget.title,
            label: entityToSearchLabel(e.currentTarget.title),
        });
        entityForm.resetField("related_with");
        handleClose();
    };

    field.settings.interface = "";
    field.settings.relatedTable = entityToSearch.id;

    return (
        <Box>
            <Grid container alignItems="center" spacing={5}>
                <Grid xs={12}>
                    <Button
                        color="inherit"
                        sx={{ color: "black" }}
                        size="large"
                        onClick={handleClick}
                        endIcon={<KeyboardArrowDown />}
                    >
                        {entityToSearch?.label ? entityToSearch?.label : "Associar"}
                    </Button>
                    <Menu anchorEl={anchorEl} open={isOpen} onClose={handleClose}>
                        {entitiesToSearch.map((entity) => {
                            return (
                                <MenuItem
                                    key={entity.entity}
                                    title={entity.entity}
                                    onClick={(e) => {
                                        handleSelection(e);
                                    }}
                                >
                                    {entity.label}
                                </MenuItem>
                            );
                        })}
                    </Menu>
                </Grid>
                <Grid xs={12}>
                    {renderFieldInput(field, item, "group", entityForm, {
                        field: "related_with",
                        fieldsQuery: handleFieldsQuery(entityToSearch.id),
                        relatedTable: entityToSearch.id,
                        createFilter: (inputValue: string) => handleFilterAccordingToEntity(entityToSearch.id, inputValue),
                    })}
                </Grid>
            </Grid>
        </Box>
    );
};

const handleFilterAccordingToEntity = (entity: string, inputValue: string) => {
    switch (entity) {
        case "leads":
            return leadsSearchSettings(inputValue);
        case "treatments":
            return treatmentsSearchSettings(inputValue);
        case "clients":
            return clientsSearchSettings(inputValue);
    }
};
