
export const APP_USERS_ROLES_FILTER = {
    _and: [
        {
            role: {
                id: {
                    _neq: "33b88ee8-1af3-4b8f-a2aa-ebe5ba596625",
                },
            },
        },
    ],
} as any;

export const ROLES = {
    admin: "d6b0779e-70fc-4854-9525-91e41e555389",
    director: "0676bc30-2754-439a-bc48-8e75b81cb36c",
    nfb_techncians: "55720c74-c013-44f0-96e5-3737adf14963",
    theurapist: "0295dd26-17ab-454c-bacf-044dd349f21e",
    recepcionist: "3b835836-7e73-4f72-af50-597f9a49261f",
    other: "0675b953-7b2a-4778-b578-2818b22794d8",
    accounting: "83e16f3b-d544-433d-8f55-466c51b1246a",
    virtual_assistant: "be3622e2-3796-4458-9c8f-e4738c5b4524",
};

export const SPECIAL_USERS = {
    joana_director: "453f4b6d-3bd8-4838-ba27-36c6bc43e5e7",
};

export const weekDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

export const weekDaysTranslation: Record<string, string> = {
    Monday: "Segunda-feira",
    Tuesday: "Terça-feira",
    Wednesday: "Quarta-feira",
    Thursday: "Quinta-feira",
    Friday: "Sexta-feira",
    Saturday: "Sábado",
    Sunday: "Domingo",
};

export const OVERBOOKED_RATIO = 0.9;

export const CATEGORIES_CONFIG = {
    nfb: {
        key: "nfb",
        name: "Neurofeedback",
        services: [
            {
                id: 33,
            },
            {
                id: 35,
            },
            {
                id: 39,
            },
        ],
        duration: 45,
        timeForSessions: 1,
    },
    psy: {
        key: "psy",
        name: "Psychology",
        services: [
            {
                id: 34,
            },
            {
                id: 36,
            },
        ],
        duration: 60,
        timeForSessions: 1,
    },
    reports: {
        key: "reports",
        name: "Reports",
        services: [
            {
                id: 37,
            },
        ],
        duration: 45,
        timeForSessions: 1,
    },
    others: {
        key: "others",
        name: "Others",
        services: [],
        duration: 60,
        timeForSessions: 1,
    },
};

export const CLINIC_SCHEDULES = {
    morning: {
        start: { hours: 9, minutes: 0 },
        end: { hours: 13, minutes: 0 },
    },
    afternoon: {
        start: { hours: 14, minutes: 0 },
        end: { hours: 21, minutes: 0 },
    },
};


