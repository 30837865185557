import { formatFields } from "../utils/formatFields";
import { api } from "./config";
import { useQuery } from "@tanstack/react-query";
import { UseRecordsOptions } from "./useRecords";

export const useFields = (entity: string, options?: UseRecordsOptions) => {
    const { data: rawFields, status } = useQuery({
        queryKey: [`${entity}-fields`],
        queryFn: () => getFields(entity),
        staleTime: Infinity,
    });

    if (status === "loading" || status === "error") return { rawFields, formatedFields: [], status };

    const formatedFields = formatFields(rawFields, options);

    return { rawFields, formatedFields, status };
};

export async function getFields(entity: string, options?: UseRecordsOptions) {
    const response = await api({
        method: "GET",
        url: `/fields/${entity}`,
    });

    return response.data.data;
}