import { Settings } from "@mui/icons-material";
import { Box, ListItemButton, ListItemIcon, ListItemText, Popover, ListItem, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { menuItemsStyle } from "./MenuItems";
import { settingsMenuItems } from "./SettingsMenuItems";
import { useContext, useState } from "react";
import { getMenuItems } from "../../utils/getMenuItems";
import { CollectionsContext } from "../root/CollectionsProvider";
import { ROLES } from "../../utils/constants";
import { useUser } from "../../api/useAuth";

export const SettingsMenu = () => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);
    const theme = useTheme();
    const { data: user } = useUser();
    const collections = useContext(CollectionsContext);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    if (!user) return null;
    const formatedMenuItems = getMenuItems(collections?.collections || [], settingsMenuItems, user);

    const textsAndIconColor = "white";
    const allowedRoles = [ROLES.director, ROLES.admin, ROLES.accounting];

    if (!allowedRoles.includes(user.role.id)) return null;

    return (
        <Box sx={{ mb: 16 }}>
            <ListItemButton onClick={handleClick} sx={{ ...menuItemsStyle }}>
                <ListItemIcon sx={{ color: textsAndIconColor }}>
                    <Settings />
                </ListItemIcon>
                <ListItemText primary="Gestão" />
            </ListItemButton>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                elevation={0}
                sx={{ ".MuiPaper-root": { borderRadius: 0, borderTopRightRadius: 4, borderBottomRightRadius: 4 } }}
            >
                <Box sx={{ backgroundColor: theme.palette.primary.main, borderRadius: 0 }}>
                    {formatedMenuItems.map((item) => (
                        <ListItem key={item.collection} disablePadding sx={menuItemsStyle}>
                            <ListItemButton component={Link} to={item.link} onClick={handleClose} sx={{ pl: 10, pr: 10 }}>
                                <ListItemIcon sx={{ color: textsAndIconColor }}>{item.icon}</ListItemIcon>
                                <ListItemText sx={{ color: textsAndIconColor }} primary={item.name} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </Box>
            </Popover>
        </Box>
    );
};
