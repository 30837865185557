import { Record } from "../../components/record/Record";
import { Records } from "../../components/records/Records";
import { tabs } from "./bankTransactionsTabs";
import { DetailTabs } from "../../components/detailTabs/DetailTabs";
import { EntityForm } from "../../components/entityForm/EntityForm";
import { Groups } from "../../components/groups/Groups";
import { bankTransactionsOptions } from "./bankTransactionsOptions";
import { BankTransactionsListHeader } from "./BankTransactionsListHeader";

const fieldsQuery = "*.*,category.*.*";

export const bankTransactionsRoutes = [
    {
        path: "/bank_transactions",
        element: (
            <Records
                entity="bank_transactions"
                header={<BankTransactionsListHeader />}
                fieldsQuery={fieldsQuery}
                options={bankTransactionsOptions}
                heightOfHeaders={296}
            />
        ),
    },
    {
        path: "/bank_transactions/:id",
        element: <Record entity="bank_transactions" type="detail" fieldsQuery={fieldsQuery} body={<DetailTabs tabs={tabs} />} />,
        children: [
            {
                path: "/bank_transactions/:id/detail",
                element: <Groups groupsOptions={bankTransactionsOptions.groupsOptions} />,
            },
        ],
    },
    {
        path: "/bank_transactions/:id/update",
        element: (
            <Record
                entity="bank_transactions"
                type="update"
                fieldsQuery={fieldsQuery}
                body={<EntityForm groupsOptions={bankTransactionsOptions?.groupsOptions} />}
            />
        ),
    },
    {
        path: "/bank_transactions/create",
        element: (
            <Record
                entity="bank_transactions"
                type="create"
                fieldsQuery={fieldsQuery}
                body={<EntityForm groupsOptions={bankTransactionsOptions?.groupsOptions} />}
            />
        ),
    },
];
